import React, { useEffect } from 'react';
import {
  Col, Form, Input, InputNumber, Row, Select, Typography, UploadFile,
} from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { enumToOptionsArray } from '../../../../../utils';
import { AvailabilityEnum } from '../../../../../enums';
import { LevelEnum } from '../../../../../enums/trainings';
import SelectContentStatus from '../../../../Common/Selects/SelectStatus/SelectContentStatus';
import QuillRichTextEditor from '../../../../Common/QuillRichTextEditor';
import {
  createRulesForAntd,
  validationComplex,
  messages as errorMessages,
} from '../../../../../utils/validations';
import ImageUpload from '../../../../Common/Uploaders/ImageUpload';

const validationRules = createRulesForAntd(validationComplex);

const defaultSelectProps = { placeholder: 'Выберите', allowClear: true };

interface ComplexesFormItemsGeneral {
  bannerFile: UploadFile[];
  setBannerFile: React.Dispatch<React.SetStateAction<UploadFile[]>>;
}

function ComplexesFormItemsGeneral({ bannerFile, setBannerFile }: ComplexesFormItemsGeneral) {
  const form = useFormInstance();

  useEffect(() => {
    form.setFieldValue('imageUrl', bannerFile[0]?.url ? bannerFile[0].url : '');
    if (bannerFile[0]?.url) {
      form.validateFields(['imageUrl']);
    }
  }, [bannerFile]);

  return (
    <Row gutter={24}>
      <Form.Item name="weeks" className="no-space-form-item hidden-form-item">
        <Input type="hidden" disabled />
      </Form.Item>

      <Col span={12}>
        <Form.Item name="nameRU" label="Название (RU)" rules={[{ required: true, ...validationRules }]}>
          <Input placeholder="Введите" />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item name="nameUA" label="Название (UA)" rules={[{ required: true, ...validationRules }]}>
          <Input placeholder="Введите" />
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item name="availability" label="Доступность" rules={[{ required: true, ...validationRules }]}>
          <Select options={enumToOptionsArray(AvailabilityEnum)} {...defaultSelectProps} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item name="contentState" label="Статус">
          <SelectContentStatus />
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item
          name="durationInWeeks"
          label="Количество недель"
          rules={[{ required: true, message: errorMessages.required }]}
          extra={(
            <span>При уменьшении количества недель данные о добавленных тренировках могут быть утеряны</span>
          )}
        >
          <InputNumber placeholder="Введите" style={{ width: '100%' }} min={1} max={10} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item name="level" label="Уровень" rules={[{ required: true, ...validationRules }]}>
          <Select options={enumToOptionsArray(LevelEnum)} {...defaultSelectProps} />
        </Form.Item>
      </Col>

      <Col span={24}>
        <div className="image-banner-block">
          <ImageUpload
            name="imageUrl"
            multiple={false}
            fileList={bannerFile}
            setFileList={setBannerFile}
            wide
          />
          <div>
            <Typography.Paragraph className="upload-paragraph">
              Фото обложки
            </Typography.Paragraph>
            <Typography.Paragraph type="secondary" className="upload-text">
              Требования к файлу: JPG, PNG. Рекомендованный минимальный размер 1125 х 1125 px. Соотношение сторон 16:9.
            </Typography.Paragraph>
          </div>
        </div>
      </Col>

      <Col span={12}>
        <Form.Item name="descriptionRU" label="Описание (RU)">
          <QuillRichTextEditor />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item name="descriptionUA" label="Описание (UA)">
          <QuillRichTextEditor />
        </Form.Item>
      </Col>
    </Row>
  );
}

export default ComplexesFormItemsGeneral;
