import React from 'react';
import { Col, Row } from 'antd';

import styles from './index.module.scss';
import { Logo } from '../../../Common/Icon';

export interface WrapperProps {
  children: React.ReactNode
}

export default function Wrapper({ children }: WrapperProps): React.ReactNode {
  return (
    <Row justify="center">
      <Col className={styles.wrapper}>
        <div className={styles.content}>
          <Logo size="medium" className={styles.logo} />

          {children}
        </div>
      </Col>
    </Row>
  );
}
