import React from 'react';
import { Radio } from 'antd';
import SelectUsersGroups from '../../../Common/Selects/SelectUsersGroups';
import { UsersGroupsEnum } from '../../../../enums/emails';
import { EmailLanguage } from '../../../../hooks/api/emails';

import styles from './index.module.scss';

interface EmailFiltersProps {
  isCreate: boolean
  selectedGroup: UsersGroupsEnum | undefined
  selectedLanguage: EmailLanguage
  handleSelectGroup: (payload: UsersGroupsEnum) => void
  handleLanguageChange: (payload: EmailLanguage) => void
}

function EmailFilters({
  handleSelectGroup, handleLanguageChange, selectedGroup, selectedLanguage, isCreate,
}: EmailFiltersProps) {
  return (
    <div className={styles.container}>
      <div className={styles.filter}>
        <div>Группа получателе</div>
        <SelectUsersGroups
          style={{ width: 244 }}
          value={selectedGroup}
          disabled={!isCreate}
          onSelect={(value) => handleSelectGroup(value)}
        />
      </div>
      <div className={styles.filter}>
        <div>Язык шаблона</div>
        <Radio.Group value={selectedLanguage} onChange={(e) => handleLanguageChange(e.target.value)}>
          <Radio.Button value="ru">RU</Radio.Button>
          <Radio.Button value="ua">UA</Radio.Button>
        </Radio.Group>
      </div>
    </div>
  );
}

export default EmailFilters;
