import React, { useEffect } from 'react';
import {
  Col, Form, Input, Row, Select, Switch, Typography, UploadFile,
} from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import clsx from 'clsx';
import { enumToOptionsArray } from '../../../../utils';
import { AvailabilityEnum } from '../../../../enums';
import SelectContentStatus from '../../../Common/Selects/SelectStatus/SelectContentStatus';
import QuillRichTextEditor from '../../../Common/QuillRichTextEditor';
import { createRulesForAntd, validationLecture } from '../../../../utils/validations';
import ImageUpload from '../../../Common/Uploaders/ImageUpload';
import { LecturesCategoryEnum } from '../../../../enums/lectures';
import AudioUpload from '../../../Common/Uploaders/AudioUpload';

import styles from './index.module.scss';

const { TextArea } = Input;
const textAreaProps = {
  placeholder: 'Введите', allowClear: true, showCount: true, maxLength: 220, rows: 5,
};

const validationRules = createRulesForAntd(validationLecture);

const defaultSelectProps = { placeholder: 'Выберите', allowClear: true };

interface RecipeFormItems {
  bannerFile: UploadFile[];
  setBannerFile: React.Dispatch<React.SetStateAction<UploadFile[]>>;
  audioFileRU: UploadFile[];
  audioFileUA: UploadFile[];
  setAudioFileRU: React.Dispatch<React.SetStateAction<UploadFile[]>>;
  setAudioFileUA: React.Dispatch<React.SetStateAction<UploadFile[]>>;
}

function LectureFormItems({
  bannerFile, setBannerFile, audioFileRU, audioFileUA, setAudioFileRU, setAudioFileUA,
}: RecipeFormItems) {
  const form = useFormInstance();

  const audioUrlRUWatch = Form.useWatch('audioUrlRU', form);
  const audioUrlUAWatch = Form.useWatch('audioUrlUA', form);
  const contentRUWatch = Form.useWatch('contentRU', form);
  const contentUAWatch = Form.useWatch('contentUA', form);

  useEffect(() => {
    form.setFieldValue('imageUrl', bannerFile[0]?.url ? bannerFile[0].url : '');
    if (bannerFile[0]?.url) {
      form.validateFields(['imageUrl']);
    }
  }, [bannerFile]);

  useEffect(() => {
    form.setFieldValue('audioUrlRU', audioFileRU[0]?.url ? audioFileRU[0].url : '');
    if (audioFileRU.length && audioFileRU[0].url) {
      form.validateFields(['audioUrlRU', 'contentRU', 'contentUA']);
    }
  }, [audioFileRU]);

  useEffect(() => {
    form.setFieldValue('audioUrlUA', audioFileUA[0]?.url ? audioFileUA[0].url : '');
    if (audioFileUA.length && audioFileUA[0].url) {
      form.validateFields(['audioUrlUA', 'contentRU', 'contentUA']);
    }
  }, [audioFileUA]);

  return (
    <Row gutter={24}>
      <Col span={12}>
        <Form.Item name="nameRU" label="Название (RU)" rules={[{ required: true, ...validationRules }]}>
          <Input placeholder="Введите" />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item name="nameUA" label="Название (UA)" rules={[{ required: true, ...validationRules }]}>
          <Input placeholder="Введите" />
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item name="availability" label="Доступность" rules={[{ required: true, ...validationRules }]}>
          <Select options={enumToOptionsArray(AvailabilityEnum)} {...defaultSelectProps} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item name="contentState" label="Статус">
          <SelectContentStatus />
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item name="forCatalog" label="Отображение в каталоге" valuePropName="checked">
          <Switch checkedChildren="Да" unCheckedChildren="Нет" />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item name="category" label="Категория" rules={[{ required: true, ...validationRules }]}>
          <Select options={enumToOptionsArray(LecturesCategoryEnum)} {...defaultSelectProps} />
        </Form.Item>
      </Col>

      <Col span={24}>
        <div className={clsx('image-banner-block', styles.bannerBlock)}>
          <ImageUpload
            required={false}
            name="imageUrl"
            multiple={false}
            fileList={bannerFile}
            setFileList={setBannerFile}
          />
          <div>
            <Typography.Paragraph className="upload-paragraph">
              Фото обложки
            </Typography.Paragraph>
            <Typography.Paragraph type="secondary" className="upload-text">
              Требования к файлу: JPG, PNG. Рекомендованный минимальный размер 1125 х 1125 px.
            </Typography.Paragraph>
          </div>
        </div>
      </Col>

      <Col span={12}>
        <div className={clsx('container-with-image', styles.audioBlock)}>
          <div>
            <Typography.Paragraph className="upload-paragraph">
              Аудиофайл лекции (RU)
            </Typography.Paragraph>
            <Typography.Paragraph type="secondary" className="upload-text">
              Требования к файлу: MP3, M4A
            </Typography.Paragraph>
          </div>
          <AudioUpload
            required={!contentRUWatch && !contentUAWatch}
            name="audioUrlRU"
            fileList={audioFileRU}
            onDurationChange={(payload: number) => form.setFieldValue('durationRU', Math.floor(payload || 0))}
            setFileList={setAudioFileRU}
          />
        </div>
      </Col>
      <Col span={12}>
        <div className={clsx('container-with-image', styles.audioBlock)}>
          <div>
            <Typography.Paragraph className="upload-paragraph">
              Аудиофайл лекции (UA)
            </Typography.Paragraph>
            <Typography.Paragraph type="secondary" className="upload-text">
              Требования к файлу: MP3, M4A
            </Typography.Paragraph>
          </div>
          <AudioUpload
            required={!contentRUWatch && !contentUAWatch}
            name="audioUrlUA"
            fileList={audioFileUA}
            onDurationChange={(payload: number) => form.setFieldValue('durationUA', Math.floor(payload || 0))}
            setFileList={setAudioFileUA}
          />
        </div>
      </Col>

      <Form.Item name="durationRU" className="hidden-form-item">
        <Input type="hidden" disabled />
      </Form.Item>

      <Form.Item name="durationUA" className="hidden-form-item">
        <Input type="hidden" disabled />
      </Form.Item>

      <Col span={12}>
        <Form.Item name="descriptionRU" label="Краткое описание (RU)">
          <TextArea {...textAreaProps} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item name="descriptionUA" label="Краткое описание (UA)">
          <TextArea {...textAreaProps} />
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item
          name="contentRU"
          label="Текст лекции (RU)"
          rules={!audioUrlRUWatch ? [{ required: true, ...validationRules }] : undefined}
        >
          <QuillRichTextEditor />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name="contentUA"
          label="Текст лекции (UA)"
          rules={!audioUrlUAWatch ? [{ required: true, ...validationRules }] : undefined}
        >
          <QuillRichTextEditor />
        </Form.Item>
      </Col>
    </Row>
  );
}

export default LectureFormItems;
