import React from 'react';
import { config } from '@triare/auth-redux';
import Layout from '../../components/Layout';
import ComplexesContent from '../../components/Pages/Complexes';

const { displayName } = config;

export default function Complexes(): React.ReactNode | null {
  document.title = `${displayName}: Комплексы`;

  return (
    <Layout>
      <ComplexesContent />
    </Layout>
  );
}
