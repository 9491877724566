import React from 'react';
import { config } from '@triare/auth-redux';
import Simple from '../../../components/Layout/Simple';
import ResetPasswordContent from '../../../components/Pages/Auth/ResetPassword';

const { displayName } = config;

export default function ResetPassword(): React.ReactNode {
  document.title = `${displayName}: Новый пароль`;

  return (
    <Simple>
      <ResetPasswordContent />
    </Simple>
  );
}
