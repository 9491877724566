import { combineReducers, configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { spawn } from 'redux-saga/effects';
import { EnhancedStore } from '@reduxjs/toolkit/src/configureStore';
import { moduleName as authModuleName, reducer as authReducer, saga as authSaga } from './auth';
// import { moduleName as userModuleName, reducer as userReducer, saga as userSaga } from './user';
import { moduleName as commonModuleName, reducer as commonReducer, saga as commonSaga } from './common';

export const sagaMiddleware = createSagaMiddleware();

const store: EnhancedStore = configureStore({
  reducer: combineReducers({
    [authModuleName]: authReducer,
    // [userModuleName]: userReducer,
    [commonModuleName]: commonReducer,
  }),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    thunk: false,
  }).concat([sagaMiddleware]),
  devTools: process.env.REACT_APP_ENV !== 'production',
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

sagaMiddleware.run(function* runSaga() {
  yield spawn(authSaga, store);
  yield spawn(commonSaga, store);
  // yield spawn(userSaga);
});

export default store;
