import React from 'react';
import UserMarathonThumbnails from './Thumbnails';
import { Participant } from '../../../../../../hooks/api/user';

import styles from './index.module.scss';

export interface MarathonMedia {
  before?: string;
  after?: string;
  dataKey?: MediaDataKey;
}

export type MediaDataKey = 'imageUrl1' | 'imageUrl2' | 'imageUrl3' | 'imageUrl4' | 'videoUrl' | string;

interface UserMarathonMediaProps {
  images: MarathonMedia[];
  videoUrls: string[];
  selectedMarathon?: Participant;
}

function UserMarathonMedia({ images, videoUrls, selectedMarathon }: UserMarathonMediaProps) {
  return (
    <div className={styles.wrap}>
      <UserMarathonThumbnails
        data={images}
        videoUrls={videoUrls}
        selectedMarathon={selectedMarathon}
      />
    </div>
  );
}

UserMarathonMedia.defaultProps = {
  data: undefined,
};

export default UserMarathonMedia;
