import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { actions, useCommonState } from '../../../store/common';

export default function ReduxRedirect() {
  const navigate = useNavigate();
  const { redirect } = useCommonState();
  const dispatch = useDispatch();

  useEffect(() => {
    if (redirect && redirect.to) {
      navigate(redirect.to, redirect?.options);

      dispatch({
        type: actions.triggerRedirect.type,
        payload: null,
      });
    }
  }, [redirect]);

  return null;
}
