import React from 'react';
import Card from 'antd/es/card/Card';
import { Button, message } from 'antd';
import { Link, useParams } from 'react-router-dom';
import clsx from 'clsx';
import { UserExtended } from '../../../../../../hooks/api/user';
import {
  MarathonResultsUserAction,
  useMarathonResultsUpdate,
} from '../../../../../../hooks/api/results';
import Loading from '../../../../../Common/Loading';
import UserMarathonMedia from '../../../../Users/Profile/Marathon/Media';

import styles from './index.module.scss';

interface MarathonResultStageUserItemProps {
  data: UserExtended;
  handleUser: (id: string) => void
}

function MarathonResultStageUserItem({ data, handleUser }: MarathonResultStageUserItemProps) {
  const { marathonId = '' } = useParams<{ marathonId: string }>();
  const updateResults = useMarathonResultsUpdate();

  const handleClick = (action: MarathonResultsUserAction) => {
    updateResults.fetch(undefined, `${marathonId}/users/${data.id}/${action}`).then((res) => {
      if (res !== null) {
        message.success('Изменения успешно сохранены');
        handleUser(data.id);
      }
    });
  };

  return (
    <Card
      styles={{
        header: { padding: '24px 24px 0 24px' },
        body: { paddingTop: 0 },
      }}
      className={clsx(styles.wrap, { [styles.disabled]: updateResults.loading })}
      title={(
        <div className={styles.head}>
          <Link to={`/users/${data.id}`} target="_blank">{data.name}</Link>
          <div className={styles.actions}>
            <Button type="primary" className={styles.allow} onClick={() => handleClick('next')}>
              Принять
            </Button>
            <div>
              {/* {data.participants.length && data.participants[0].measureAfter?.weight
              && data.participants[0].measureBefore?.weight ? (
                  `${data.participants[0].measureAfter.weight - data.participants[0].measureBefore.weight} кг`
                ) : null} */}
              {data.participants.length && (
                (() => {
                  const afterWeight = data.participants[0].measureAfter?.weight2
                    ?? data.participants[0].measureAfter?.weight;
                  const beforeWeight = data.participants[0].measureBefore?.weight2
                    ?? data.participants[0].measureBefore?.weight;

                  return afterWeight && beforeWeight ? `${afterWeight - beforeWeight} кг` : null;
                })()
              )}
            </div>
            <Button type="primary" danger onClick={() => handleClick('skip')}>
              Отклонить
            </Button>
          </div>
        </div>
      )}
    >
      <Loading visible={updateResults.loading} absolute />
      {data.participants.length ? (
        <UserMarathonMedia
          images={[
            {
              before: data.participants[0].measureBefore?.imageUrl1,
              after: data.participants[0].measureAfter?.imageUrl1,
            },
            {
              before: data.participants[0].measureBefore?.imageUrl2,
              after: data.participants[0].measureAfter?.imageUrl2,
            },
            {
              before: data.participants[0].measureBefore?.imageUrl3,
              after: data.participants[0].measureAfter?.imageUrl3,
            },
          ]}
          videoUrls={[
            data.participants[0].measureBefore?.videoUrl || '',
            data.participants[0].measureAfter?.videoUrl || '',
          ]}
        />
      ) : null}
    </Card>
  );
}

export default MarathonResultStageUserItem;
