export enum SubscriptionEnum {
  year = 'Годовая',
  month = 'Месяц',
  none = 'Нет подписки',
}

export enum UserStatusEnum {
  pending = 'Ожидание',
  active = 'Активный',
  inactive = 'Неактивный',
}

export enum AvailabilityEnum {
  paid = 'Платный',
  free = 'Бесплатный',
}

export enum ContentStateEnum {
  draft = 'Черновик',
  published = 'Опубликовано',
}

export enum GenderEnum {
  male = 'Мужской',
  female = 'Женский'
}

export enum ForCatalogEnum {
  yes = 'Да',
  no = 'Нет',
}
export enum ForCatalogAPIEnum {
  yes = 'true',
  no = 'false',
}
