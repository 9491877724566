import React from 'react';
import { SelectProps } from 'antd/lib/select';
import { Select } from 'antd';
import { UserSubscription } from '../../../../hooks/api/user';
import { Option } from '../../../../types';

function SelectSubscription({ ...props }: SelectProps<UserSubscription, Option>) {
  return (
    <Select {...props} />
  );
}

SelectSubscription.defaultProps = {
  options: [
    { label: 'Год', value: 'year' },
    { label: 'Месяц', value: 'month' },
    { label: 'Нет подписки', value: 'none' },
  ],
  allowClear: true,
  placeholder: 'Выберите',
};

export default SelectSubscription;
