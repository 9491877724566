import React from 'react';
import {
  Button, Divider, Form, Input,
} from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { messages } from '../../../../../../utils/validations';
import { initialHabitsState } from '../index';

import styles from './index.module.scss';

function HabitsFormFields() {
  return (
    <Form.List name="habits">
      {(fields, { add, remove }) => (
        <>
          {fields.map((field) => (
            <div className={styles.fieldGrid} key={field.key}>
              <div className={styles.fields}>
                <Form.Item
                  name={[field.name, 'nameRU']}
                  className="no-space-form-item"
                  rules={[{ required: true, message: messages.required }, { max: 255, message: messages.nameMax255 }]}
                >
                  <Input placeholder="Ввведите" addonBefore="RU" />
                </Form.Item>
                <Form.Item
                  name={[field.name, 'nameUA']}
                  className="no-space-form-item"
                  rules={[{ required: true, message: messages.required }, { max: 255, message: messages.nameMax255 }]}
                >
                  <Input placeholder="Ввведите" addonBefore="UA" />
                </Form.Item>
                <Divider />
              </div>
              <Form.Item>
                <Button
                  size="middle"
                  type="default"
                  icon={<DeleteOutlined style={{ fontSize: 18 }} />}
                  onClick={() => remove(field.name)}
                  title="Удалить"
                />
              </Form.Item>
            </div>
          ))}
          <Button
            type="default"
            onClick={() => add(initialHabitsState)}
            icon={<PlusOutlined />}
            className={styles.addBtn}
          >
            Добавить привычку
          </Button>
        </>
      )}
    </Form.List>
  );
}

export default HabitsFormFields;
