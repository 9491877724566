export const numberOTP = 4;

export const timeResendOTPCode = 2 * 60 * 1000; // 2 minutes

export const pageDefault = 1;

export const pageSizeDefault = 10;

export const pageSizePossible = [10, 20, 30, 50];

export const pagingParamList = ['page', 'pageSize'];

export const dateFormat = 'DD/MM/YYYY HH:mm';
export const dateFormatISO = 'YYYY-MM-DDTHH:mm:ss.SSSZ';

export const datePickerFormat = 'YYYY-MM-DD';
export const dateFormatMarathon = 'D/MM/YYYY';
export const dateFormatShort = 'D/M/YY';

export const timeFormat12 = 'h:mm a';

export const stringDateFormat = 'dddd, MMMM DD';

export const timeFormat = 'HH:mm';
