import React from 'react';
import { SelectProps } from 'antd/lib/select';
import { Select } from 'antd';
import { UserSubscription } from '../../../../hooks/api/user';
import { Option } from '../../../../types';

function SelectTargetMulti({ ...props }: SelectProps<UserSubscription, Option>) {
  return (
    <Select {...props} mode="multiple" optionFilterProp="label" />
  );
}

SelectTargetMulti.defaultProps = {
  options: [
    { label: 'Ноги', value: 'legs' },
    { label: 'Ягодицы', value: 'glutes' },
    { label: 'Верх тела', value: 'upperBody' },
    { label: 'Пресс', value: 'core' },
    { label: 'Все тело', value: 'fullBody' },
  ],
  allowClear: true,
  placeholder: 'Выберите',
};

export default SelectTargetMulti;
