import React, { useEffect, useState } from 'react';
import { Empty } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { Participant, UserExtended } from '../../../../../hooks/api/user';
import UserMarathonDescription from './Desctiption';
import UserMarathonMedia from './Media';
import UserMarathonSelect from './Select';
import EditCurrentMarathon from './Edit';
import { useUserProfileProvider } from '../../../../../context/userProfile';

import styles from './index.module.scss';

function UserMarathon({ data }: { data: UserExtended }) {
  const [selectedMarathon, setSelectedMarathon] = useState<Participant>();
  const navigate = useNavigate();
  const { userById } = useUserProfileProvider();
  const { id: userId = '' } = useParams<{ id: string; }>();

  useEffect(() => {
    if (data.participants.length) {
      if (selectedMarathon) {
        setSelectedMarathon(
          data.participants.find((marathon) => marathon.id === selectedMarathon.id)
         || data.participants[0],
        );
      } else {
        setSelectedMarathon(data.participants[0]);
      }
    }
  }, [data]);

  if (!data.participants.length) {
    return (
      <Empty
        className={styles.empty}
        imageStyle={{ width: '185px', height: '120px', margin: '0 auto' }}
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description="Нет марафонов для отображения"
      />
    );
  }

  const handleDeleteUserMarathon = () => {
    navigate(`/users/${userId}/?tab=general`);
    userById.fetch(undefined, userId);
  };

  const handleChange = (value: string) => {
    if (!data.participants.length) return;

    setSelectedMarathon(data.participants.filter((el) => el.dateStart === value)[0]);
  };

  return (
    <>
      <UserMarathonSelect
        selected={selectedMarathon}
        data={data.participants}
        handleChange={handleChange}
        handleDeleteUserMarathon={handleDeleteUserMarathon}
      />
      <UserMarathonDescription data={data} selectedMarathon={selectedMarathon} />
      <UserMarathonMedia
        images={[
          {
            before: selectedMarathon?.measureBefore?.imageUrl1,
            after: selectedMarathon?.measureAfter?.imageUrl1,
            dataKey: 'imageUrl1',
          },
          {
            before: selectedMarathon?.measureBefore?.imageUrl2,
            after: selectedMarathon?.measureAfter?.imageUrl2,
            dataKey: 'imageUrl2',
          },
          {
            before: selectedMarathon?.measureBefore?.imageUrl3,
            after: selectedMarathon?.measureAfter?.imageUrl3,
            dataKey: 'imageUrl3',
          },
        ]}
        videoUrls={[selectedMarathon?.measureBefore?.videoUrl || '', selectedMarathon?.measureAfter?.videoUrl || '']}
        selectedMarathon={selectedMarathon}
      />
      <EditCurrentMarathon selectedMarathon={selectedMarathon} />
    </>
  );
}

export default UserMarathon;
