import React from 'react';
import {
  Button, Divider, Form, Input,
} from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Prize } from '../../../../../../hooks/api/prizes';
import { messages } from '../../../../../../utils/validations';

import styles from './index.module.scss';

const initialState: Partial<Prize> = {
  placeUA: '',
  placeRU: '',
  prizeUA: '',
  prizeRU: '',
};

function MarathonPrizesFormFields() {
  return (
    <Form.List name="prizes">
      {(fields, { add, remove }) => (
        <>
          {fields.map((field, index) => (
            <div key={field.key}>
              <div className={styles.inner}>
                <div className={styles.inputs}>
                  <div>
                    <Form.Item
                      label={index === 0 ? 'Место (например, 1 место)' : ''}
                      className="no-space-form-item"
                      name={[field.name, 'placeRU']}
                      rules={[{ max: 255, message: messages.nameMax255 }]}
                    >
                      <Input addonBefore="RU" placeholder="Введите" />
                    </Form.Item>
                    <Form.Item
                      className="no-space-form-item"
                      name={[field.name, 'placeUA']}
                      rules={[{ max: 255, message: messages.nameMax255 }]}
                    >
                      <Input addonBefore="UA" placeholder="Введите" />
                    </Form.Item>
                  </div>
                  <div>
                    <Form.Item
                      label={index === 0 ? 'Приз' : ''}
                      className="no-space-form-item"
                      name={[field.name, 'prizeRU']}
                    >
                      <Input addonBefore="RU" placeholder="Введите" />
                    </Form.Item>
                    <Form.Item className="no-space-form-item" name={[field.name, 'prizeUA']}>
                      <Input addonBefore="UA" placeholder="Введите" />
                    </Form.Item>
                  </div>
                  <Divider className={styles.divider} />
                </div>
                <Form.Item>
                  <Button
                    style={index === 0 ? { marginTop: 30 } : undefined}
                    size="middle"
                    type="default"
                    icon={<DeleteOutlined style={{ fontSize: 18 }} />}
                    onClick={() => remove(field.name)}
                    title="Удалить"
                  />
                </Form.Item>
              </div>
            </div>
          ))}
          <Button type="default" onClick={() => add(initialState)} icon={<PlusOutlined />}>
            Добавить призовое место
          </Button>
        </>
      )}
    </Form.List>
  );
}

export default MarathonPrizesFormFields;
