// version: 3.2.1 // 22.09.2023

import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import axios, {
  AxiosError, AxiosProgressEvent, AxiosRequestConfig, AxiosResponse,
} from 'axios';
import { config as authConfig, refreshToken, AnyObject } from '@triare/auth-redux';
import { Unsubscribe } from 'redux';
import { capitalizeFirstLetter } from '../utils';
import { moduleName as authModuleName, useAuthState } from '../store/auth';
import store from '../store';

/** Set default header for all axios requests */
axios.defaults.headers.common['x-lang'] = 'ru-RU';

export const FILE_FORMAT: { [key: string]: string } = {
  aac: 'audio/aac',
  abw: 'application/x-abiword',
  arc: 'application/x-freearc',
  avif: 'image/avif',
  avi: 'video/x-msvideo',
  azw: 'application/vnd.amazon.ebook',
  bin: 'application/octet-stream',
  bmp: 'image/bmp',
  bz: 'application/x-bzip',
  bz2: 'application/x-bzip2',
  cda: 'application/x-cdf',
  csh: 'application/x-csh',
  css: 'text/css',
  csv: 'text/csv',
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  eot: 'application/vnd.ms-fontobject',
  epub: 'application/epub+zip',
  gz: 'application/gzip',
  gif: 'image/gif',
  htm: 'text/html',
  html: 'text/html',
  ico: 'image/vnd.microsoft.icon',
  ics: 'text/calendar',
  jar: 'application/java-archive',
  jpeg: 'image/jpeg',
  jpg: 'image/jpeg',
  js: 'text/javascript',
  json: 'application/json',
  jsonld: 'application/ld+json',
  mid: 'audio/midi',
  midi: 'audio/x-midi',
  mjs: 'text/javascript',
  mp3: 'audio/mpeg',
  mp4: 'video/mp4',
  mpeg: 'video/mpeg',
  mpkg: 'application/vnd.apple.installer+xml',
  odp: 'application/vnd.oasis.opendocument.presentation',
  ods: 'application/vnd.oasis.opendocument.spreadsheet',
  odt: 'application/vnd.oasis.opendocument.text',
  oga: 'audio/ogg',
  ogv: 'video/ogg',
  ogx: 'application/ogg',
  opus: 'audio/opus',
  otf: 'font/otf',
  png: 'image/png',
  pdf: 'application/pdf',
  php: 'application/x-httpd-php',
  ppt: 'application/vnd.ms-powerpoint',
  pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  rar: 'application/vnd.rar',
  rtf: 'application/rtf',
  sh: 'application/x-sh',
  svg: 'image/svg+xml',
  tar: 'application/x-tar',
  tif: 'image/tiff',
  tiff: 'image/tiff',
  ts: 'video/mp2t',
  ttf: 'font/ttf',
  txt: 'text/plain',
  vsd: 'application/vnd.visio',
  wav: 'audio/wav',
  weba: 'audio/webm',
  webm: 'video/webm',
  webp: 'image/webp',
  woff: 'font/woff',
  woff2: 'font/woff2',
  xhtml: 'application/xhtml+xml',
  xls: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  xml: 'application/xml',
  xul: 'application/vnd.mozilla.xul+xml',
  zip: 'application/zip',
  '3gp': 'video/3gpp; audio/3gpp',
  '3g2': 'video/3gpp2; audio/3gpp2',
  '7z': 'application/x-7z-compressed',
};

export interface RequestResult {
  loading: boolean;
  error: Error | null;
}

export interface FetchSuccess {
  success: boolean;
}

export interface PagingParams extends AnyObject {
  page?: number;
  pageSize?: number;
  orderBy?: 'ASC' | 'DESC';
  orderByColumn?: string;
}

export interface PagingDataResponse<I> {
  data: I[];
  meta: {
    currentPage: number;
    itemsPerPage: number;
    totalItems: number;
    totalPages: number;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
  };
}
async function awaitAccessToken<T = unknown, E = Error | null>(
  callback: (token: string) => T,
  callbackError: (value: E) => E | void,
): Promise<T> {
  const history: {
    currentValue: string;
    unsubscribe?: Unsubscribe;
  } = {
    currentValue: store.getState()[authModuleName]?.access?.token || '',
    unsubscribe: () => {
      // unsubscribe store
    },
  };

  const result = await new Promise((resolve, reject) => {
    history.unsubscribe = store.subscribe(() => {
      const state = store.getState()[authModuleName];
      const token = state?.access?.token;
      const previousValue = history.currentValue;

      history.currentValue = token;

      if (history.currentValue && previousValue !== history.currentValue) {
        resolve(callback(history.currentValue));
      }

      if (state.error) {
        reject(callbackError(state.error));
      }
    });
  });

  if (history.unsubscribe) {
    history.unsubscribe();
  }

  return result as T;
}

export interface FetchProps<Data, Props = undefined, DecorateData = Data> {
  fetchCreator: (token?: string, props?: Props, ...args: unknown[]) => Promise<AxiosResponse<Data>>;
  decorateData?: (data: Data) => DecorateData;
  startStateLoading?: boolean;
  multiple?: string;
  cacheLifetime?: number;
  authorization?: boolean;
}

export type DefaultFetchError = {
  message?: string | string[]
  error?: string
};

export interface DefaultFetch<Data = undefined, Error = DefaultFetchError, Props = undefined, DecorateData = Data>
  extends RequestResult {
  fetch: (props?: Props) => Promise<DecorateData | null>;
  finish: (data?: DecorateData) => void;
  error: AxiosError<Error> | null;
  response: AxiosResponse<Data> | undefined;
  clearError: () => void;
  clearResponse: () => void;
  name?: string;
}

export interface FetchHooks<Data, Error = DefaultFetchError, Props = undefined, DecorateData = Data>
  extends DefaultFetch<Data, Error, Props, DecorateData> {
  data?: DecorateData;
}

export const defaultFetchData = {
  data: undefined,
  // fetch: async (params, id) => null,
  // finish: (data) => undefined,
  loading: false,
  error: null,
  response: undefined,
  clearError: () => undefined,
  clearResponse: () => undefined,
  name: undefined,
};

// eslint-disable-next-line
const requestQueue: { [key: string]: Promise<any>; } = {};

interface Cache<Data> {
  cacheLifetime: number; // milliseconds
  response: AxiosResponse<Data> | undefined;
}

// eslint-disable-next-line
const cache: { [key: string]: Cache<any>; } = {};

export function useFetch<Data, Error = DefaultFetchError, Props = undefined, DecorateData = Data>({
  fetchCreator,
  decorateData,
  authorization,
  startStateLoading = false,
  multiple, // string key
  cacheLifetime = 200, // milliseconds
}: FetchProps<Data, Props, DecorateData>): FetchHooks<Data, Error, Props, DecorateData> {
  const live = useRef<boolean>(true);
  const { access } = useAuthState();
  const { token: accessToken } = access || {};

  const [loading, setLoading] = useState(startStateLoading);
  const [error, setError] = useState<AxiosError<Error> | null>(null);
  const [data, setData] = useState<DecorateData>();
  const [response, setResponse] = useState<AxiosResponse<Data> | undefined>();

  useEffect(() => {
    if (response && cacheLifetime && multiple) {
      cache[multiple] = {
        cacheLifetime: Date.now() + cacheLifetime,
        response,
      };

      setTimeout(() => {
        delete cache[multiple];
      }, cacheLifetime);
    }
  }, [response]);

  const fetch = useCallback(async (params?: Props, ...args: unknown[]): Promise<DecorateData | null> => {
    const cacheResponse = multiple && cache[multiple] ? cache[multiple].response : undefined;

    setError(() => null);
    setLoading(() => true);

    if (authConfig.fetchDelay) {
      // eslint-disable-next-line no-promise-executor-return
      await new Promise((resolve) => setTimeout(resolve, authConfig.fetchDelay || 0));
    }

    const checkResponse = async (useReLogin = false, token = accessToken): Promise<DecorateData | null> => {
      let promise = useReLogin && multiple ? requestQueue[multiple] : undefined;

      const prepareData = (res: AxiosResponse<Data>): DecorateData | null => {
        const result = decorateData ? decorateData(res.data) : res.data;

        setData(() => result as DecorateData);
        setResponse(() => res);
        setLoading(() => false);

        return result as DecorateData;
      };

      if (!promise) {
        if (cacheResponse) {
          return prepareData(cacheResponse);
        }

        let validToken: string | undefined = token;

        if (!token && authorization) {
          // eslint-disable-next-line
          validToken = await awaitAccessToken<string, AxiosError<Error, any>>(
            (newToken) => newToken,
            (err) => {
              setError(() => err);
              setLoading(() => false);
            },
          );
        }

        promise = fetchCreator(validToken || '', params, ...args, cacheResponse);

        if (multiple) {
          requestQueue[multiple] = promise;
        }
      }

      return await promise.then((res) => {
        if (!live.current) {
          return null as DecorateData;
        }

        return prepareData(res);
      }).catch(async (e) => {
        if (!live.current) {
          return null;
        }

        if (access && useReLogin && e.response?.status === 401) {
          store.dispatch(refreshToken());

          // eslint-disable-next-line
          return await awaitAccessToken<Promise<DecorateData | null>, AxiosError<Error, any>>(
            (newToken) => checkResponse(false, newToken),
            (err) => {
              setError(() => err);
              setLoading(() => false);
            },
          );
        }

        setError(() => e);
        setLoading(() => false);

        return e;
      }).finally(() => {
        if (multiple) {
          delete requestQueue[multiple];
        }
      });
    };

    return checkResponse(true);
  }, [accessToken]);

  useEffect(() => () => {
    live.current = false;
  }, []);

  return {
    loading,
    error,
    data,
    fetch,
    response,
    finish: (result) => {
      setData(() => result);
      setLoading(() => false);
      setError(() => null);
    },
    clearError: () => setError(() => null),
    clearResponse: () => {
      setData(() => undefined);
      setResponse(() => undefined);
    },
  } as FetchHooks<Data, Error, Props, DecorateData>;
}

// eslint-disable-next-line
export interface FetchGet<Data = any, Props = any, Error = DefaultFetchError, DecorateData = Data>
  extends DefaultFetch<Data, Error, Props, DecorateData> {
  data?: DecorateData;
}

export interface FetchOptions<Data, Props, DecorateData = Data> {
  name?: string; // name fetch function
  url?: string;
  authorization?: boolean;
  decorateData?: (data: Data) => DecorateData;
  config?: AxiosRequestConfig;
  params?: Props;
  autoStart?: boolean;
  multiple?: string;
  cacheLifetime?: number;
  startStateLoading?: boolean;
}

export type FetchGetOptions<Data, Props, DecorateData = Data> = FetchOptions<Data, Props, DecorateData>;

export function useFetchGet<Data, Error = DefaultFetchError, Props = undefined, DecorateData = Data>(
  path: string,
  options?: FetchGetOptions<Data, Props, DecorateData>,
): FetchGet<Data, Props, Error, DecorateData> {
  const {
    name,
    url,
    decorateData,
    config = {
      headers: undefined,
      params: undefined,
    },
    params = {},
    autoStart = true,
    authorization = true,
    startStateLoading = true,
    multiple,
    cacheLifetime,
  } = options || {};

  const { fetch, ...args } = useFetch<Data, Error, Props, DecorateData>({
    fetchCreator: (token, paramsCreator?: Props) => axios.get<Data>(
      url || `${authConfig.api.url}${path}`,
      {
        ...config,
        headers: {
          Authorization: authorization ? `Bearer ${token}` : undefined,
          ...config?.headers,
        },
        params: {
          ...config?.params,
          ...params,
          ...paramsCreator,
        },
      },
    ),
    authorization,
    decorateData,
    startStateLoading,
    multiple,
    cacheLifetime,
  });

  useEffect(() => {
    if (autoStart) {
      fetch();
    }
  }, []);

  return {
    ...args,
    name,
    fetch,
  };
}

export interface FetchGetId<Data = AnyObject, Error = DefaultFetchError, Props = undefined, DecorateData = Data>
  extends DefaultFetch<Data, Error, Props, DecorateData> {
  data?: DecorateData;
  fetch: (params?: Props, id?: string | number) => Promise<DecorateData | null>;
}

export type FetchGetIdOptions<Data, Props, DecorateData = Data> = FetchOptions<Data, Props, DecorateData>;

export function useFetchGetId<Data, Error = DefaultFetchError, Props = undefined, DecorateData = Data>(
  path: string,
  // eslint-disable-next-line default-param-last
  initialId = '',
  // eslint-disable-next-line default-param-last
  options: FetchGetIdOptions<Data, Props, DecorateData> = {},
  responseType: 'arraybuffer' | 'json' | 'blob' | 'text' | 'stream' | 'document' = 'json',
  axiosOnDownloadProgress: (progressEvent: AxiosProgressEvent) => void = () => undefined,
): FetchGetId<Data, Error, Props, DecorateData> {
  const {
    url,
    decorateData,
    config = {
      headers: undefined,
      params: undefined,
    },
    params = {},
    autoStart = true,
    authorization = true,
    startStateLoading = false,
    multiple,
  } = options || {};

  const { fetch, ...args } = useFetch<Data, Error, Props, DecorateData>({
    fetchCreator: (token, paramsCreator?: Props, id = initialId) => axios.get<Data>(
      url || `${authConfig.api.url}${path}${id ? `/${id}` : ''}`,
      {
        ...config,
        headers: {
          Authorization: authorization ? `Bearer ${token}` : undefined,
          ...config?.headers,
        },
        params: {
          ...config?.params,
          ...params,
          ...paramsCreator,
        },
        responseType,
        onDownloadProgress: axiosOnDownloadProgress
          ? (progressEvent) => axiosOnDownloadProgress(progressEvent) : undefined,
      },
    ),
    authorization,
    decorateData,
    startStateLoading,
    multiple,
  });

  useEffect(() => {
    if (autoStart) {
      fetch();
    }
  }, []);

  return {
    ...args,
    fetch,
  };
}

// eslint-disable-next-line
export interface FetchCreate<Data = FetchSuccess, Error = DefaultFetchError, Props = any>
  extends DefaultFetch<Data, Error, Props> {
  data?: Data;
  fetch: (formData?: Props, id?: string) => Promise<Data | null>;
}

export type FetchCreateOptions<Data, Props> = FetchOptions<Data, Props>;

export function useFetchCreate<Data, Error, Props>(
  path: string,
  options?: FetchCreateOptions<Data, Props>,
  axiosOnUploadProgress: (progressEvent: AxiosProgressEvent) => void = () => undefined,
): FetchCreate<Data, Error, Props> {
  const {
    url,
    decorateData,
    config = {
      headers: undefined,
      params: undefined,
    },
    params = {},
    authorization = true,
    startStateLoading = false,
  } = options || {};

  return useFetch<Data, Error, Props>({
    fetchCreator: (token, formData?: Props, partUrl = '') => axios.post<Data>(
      url || `${authConfig.api.url}${path}${partUrl ? `/${partUrl}` : ''}`,
      formData,
      {
        ...config,
        headers: {
          Authorization: authorization ? `Bearer ${token}` : undefined,
          ...config?.headers,
        },
        params: {
          ...config?.params,
          ...params,
        },
        onUploadProgress: axiosOnUploadProgress
          ? (progressEvent) => axiosOnUploadProgress(progressEvent) : undefined,
      },
    ),
    authorization,
    decorateData,
    startStateLoading,
  });
}

// eslint-disable-next-line
export interface FetchUpdate<Data = FetchSuccess, Error = DefaultFetchError, Props = any>
  extends DefaultFetch<Data, Error, Props> {
  data?: Data;
  fetch: (params?: Props, id?: string | number) => Promise<Data | null>;
}

export function useFetchUpdate<Data, Error, Props>(
  path: string,
  initialId = '',
): FetchUpdate<Data, Error, Props> {
  return useFetch<Data, Error, Props>({
    fetchCreator: (token, params?: Props, id = initialId) => axios.patch<Data>(
      `${authConfig.api.url}${path}${id ? `/${id}` : ''}`,
      params,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    ),
  });
}

// eslint-disable-next-line
export interface FetchDelete<Data = any, Error = DefaultFetchError, Props = string>
  extends DefaultFetch<Data, Error, Props> {
  data?: Data;
  fetch: (id?: Props) => Promise<Data | null>;
}

export function useFetchDelete<Data, Error, Props = string>(
  path: string,
  initialId = '',
): FetchDelete<Data, Error, Props> {
  return useFetch<Data, Error, Props>({
    fetchCreator: (token, id) => axios.delete<Data>(
      `${authConfig.api.url}${path}${id || initialId ? `/${id || initialId}` : ''}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    ),
  });
}

// eslint-disable-next-line
export interface FetchPut<Data = FetchSuccess, Error = DefaultFetchError, Props = any>
  extends DefaultFetch<Data, Error, Props> {
  data?: Data;
  fetch: (params?: Props, id?: string | number) => Promise<Data | null>;
}

export function useFetchPut<Data, Error, Props>(
  path: string,
  initialId = '',
): FetchPut<Data, Error, Props> {
  return useFetch<Data, Error, Props>({
    fetchCreator: (token, params?: Props, id = initialId) => axios.put<Data>(
      `${authConfig.api.url}${path}${id ? `/${id}` : ''}`,
      params,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    ),
  });
}

// eslint-disable-next-line
export function getMessageInError(err: any): string {
  if (!err) {
    return 'Unknown error';
  }

  const message = err?.data?.message
    || err.response?.data?.detail
    || err.response?.data?.message
    || err.response?.data?.error
    || err.message;

  if (message) {
    return capitalizeFirstLetter(Array.isArray(message) ? message[0] : message);
  }

  return 'Something went wrong!';
}

export interface SendAllFetch<Props> {
  loading: boolean
  error: AxiosError<DefaultFetchError> | null
  list: Props[]
  fetch: (list: Props[]) => void
  stop: () => void
}

// eslint-disable-next-line
export function useSendAllFetch<Action = DefaultFetch | FetchCreate | FetchGet | FetchUpdate | FetchDelete, Props = AnyObject>(
  action: Action & (DefaultFetch | FetchCreate | FetchGet | FetchUpdate | FetchDelete),
  initialList?: Props[],
): SendAllFetch<Props> {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<AxiosError<DefaultFetchError> | null>(null);
  const copyList = useRef<Props[]>(initialList === undefined ? [] : [...initialList].reverse());

  useEffect(() => {
    if (action.response && !action.error) {
      if (copyList.current.length) {
        // eslint-disable-next-line
        action.fetch.apply(null, copyList.current.pop() as any);
      } else {
        setLoading(false);
      }
    }
  }, [action.response]);

  useEffect(() => {
    if (action.error) {
      setLoading(false);
      setError(action.error);
    }
  }, [action.error]);

  return {
    loading,
    error,
    list: copyList.current,
    fetch: (list?: Props[]) => {
      if (typeof list !== 'undefined') {
        copyList.current = [...list].reverse();
      }
      if (copyList.current.length) {
        setLoading(true);
        setError(null);
        // eslint-disable-next-line
        action.fetch.apply(null, copyList.current.pop() as any);
      }
    },
    stop: () => {
      copyList.current = [];
      setLoading(false);
    },
  };
}
