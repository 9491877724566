import React, { useEffect } from 'react';
import {
  Col, Form, Input, InputNumber, Row, Select, Switch, Typography, UploadFile,
} from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import SelectContentStatus from '../../../../../Common/Selects/SelectStatus/SelectContentStatus';
import { enumToOptionsArray } from '../../../../../../utils';
import { LevelEnum, PlaceEnum } from '../../../../../../enums/trainings';
import { AvailabilityEnum } from '../../../../../../enums';
import {
  createRulesForAntd,
  messages as errorMessages,
  validationTrainingHouse,
} from '../../../../../../utils/validations';
import ImageUpload from '../../../../../Common/Uploaders/ImageUpload';

const validationRules = createRulesForAntd(validationTrainingHouse);

const defaultSelectProps = { placeholder: 'Выберите', allowClear: true };

interface FormTrainingGymGeneral {
  bannerFile: UploadFile[];
  setBannerFile: React.Dispatch<React.SetStateAction<UploadFile[]>>;
}

function TrainingGymFormItemsGeneral({ bannerFile, setBannerFile }: FormTrainingGymGeneral) {
  const form = useFormInstance();

  useEffect(() => {
    form.setFieldValue('thumbnail', bannerFile[0]?.url ? bannerFile[0].url : '');
    if (bannerFile[0]?.url) {
      form.validateFields(['thumbnail']);
    }
  }, [bannerFile]);

  return (
    <Row gutter={24}>
      <Form.Item name="exercises" className="no-space-form-item hidden-form-item">
        <Input type="hidden" disabled />
      </Form.Item>

      <Col span={12}>
        <Form.Item name="nameRU" label="Название (RU)" rules={[{ required: true, ...validationRules }]}>
          <Input placeholder="Введите" />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item name="nameUA" label="Название (UA)" rules={[{ required: true, ...validationRules }]}>
          <Input placeholder="Введите" />
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item name="availability" label="Доступность" rules={[{ required: true, ...validationRules }]}>
          <Select options={enumToOptionsArray(AvailabilityEnum)} {...defaultSelectProps} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item name="contentState" label="Статус">
          <SelectContentStatus />
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item name="forCatalog" label="Отображение в каталоге" valuePropName="checked">
          <Switch checkedChildren="Да" unCheckedChildren="Нет" />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item name="place" label="Место" rules={[{ required: true, ...validationRules }]}>
          <Select options={enumToOptionsArray(PlaceEnum)} {...defaultSelectProps} disabled />
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item name="level" label="Уровень" rules={[{ required: true, ...validationRules }]}>
          <Select options={enumToOptionsArray(LevelEnum)} {...defaultSelectProps} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name="duration"
          label="Продолжительность, минут"
          rules={[{ required: true, message: errorMessages.required }]}
        >
          <InputNumber placeholder="Введите" style={{ width: '100%' }} min={1} max={1000} />
        </Form.Item>
      </Col>

      <Col span={24}>
        <div className="image-banner-block">
          <ImageUpload
            required
            name="thumbnail"
            multiple={false}
            fileList={bannerFile}
            setFileList={setBannerFile}
            wide
          />
          <div>
            <Typography.Paragraph className="upload-paragraph required">
              Фото обложки
            </Typography.Paragraph>
            <Typography.Paragraph type="secondary" className="upload-text">
              Требования к файлу: JPG, PNG. Рекомендованный минимальный размер 1125 рх по ширине.
              Соотношение сторон 16:9
            </Typography.Paragraph>
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default TrainingGymFormItemsGeneral;
