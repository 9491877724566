import React, {
  createContext, ReactNode, useContext, useEffect, useMemo, useState,
} from 'react';
import { useBlocker, useLocation } from 'react-router-dom';
import { Modal, Space } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { asyncDelay } from '../utils';

interface UnsavedChangesProps {
  unsavedChanges: boolean;
  handleUnsavedChanges: (payload: boolean) => Promise<void>;
  htmlEditorTouched: boolean;
  handleEditorTouched: (payload: boolean) => Promise<void>;
}

const defaultValue = {
  unsavedChanges: false,
  handleUnsavedChanges: async () => undefined,
  htmlEditorTouched: false,
  handleEditorTouched: async () => undefined,
};

export const UnsavedChangesContext = createContext<UnsavedChangesProps>(defaultValue);

function UnsavedChangesProvider({ children }: { children: ReactNode }) {
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
  const [htmlEditorTouched, setHtmlEditorTouched] = useState(false);
  const location = useLocation();

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) => (
      /** if unsaved changes or html editor touched - then show popup */
      (unsavedChanges || htmlEditorTouched) && currentLocation.pathname !== nextLocation.pathname
    ),
  );

  const handleUnsavedChanges = async (payload: boolean) => {
    setUnsavedChanges(payload);
    await asyncDelay(50);
  };

  const handleEditorTouched = async (payload: boolean) => {
    setHtmlEditorTouched(payload);
    await asyncDelay(50);
  };

  const memoizedValue = useMemo(() => ({
    unsavedChanges,
    handleUnsavedChanges,
    htmlEditorTouched,
    handleEditorTouched,
  }), [unsavedChanges, handleUnsavedChanges, htmlEditorTouched, handleEditorTouched]);

  useEffect(() => {
    handleUnsavedChanges(false);
    handleEditorTouched(false);
  }, [location.pathname]);

  return (
    <UnsavedChangesContext.Provider value={memoizedValue}>
      {
        blocker.state === 'blocked' ? (
          <Modal
            open
            title={(
              <Space size={12}>
                <ExclamationCircleOutlined style={{ color: '#FAAD14', fontSize: 20 }} />
                Покинуть страницу без сохранения?
              </Space>
            )}
            cancelText="Отмена"
            centered
            okText="Покинуть"
            width={400}
            okButtonProps={{ type: 'primary', danger: true }}
            onOk={() => {
              handleUnsavedChanges(false);
              handleEditorTouched(false);
              blocker.proceed();
            }}
            onCancel={() => blocker.reset()}
          >
            <div style={{ marginLeft: '31px' }}>Все изменения будут потеряны.</div>
          </Modal>
        ) : null
      }
      {children}
    </UnsavedChangesContext.Provider>
  );
}

export default UnsavedChangesProvider;

export const useUnsavedChanges = (): UnsavedChangesProps => useContext(UnsavedChangesContext);
