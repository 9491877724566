import React from 'react';
import {
  App, Button, Form, Input,
} from 'antd';
import { ItemType } from 'antd/es/breadcrumb/Breadcrumb';
import { NavLink, useNavigate } from 'react-router-dom';
import SinglePageContainer from '../../../Common/SinglePageContainer';
import { Admin, useAdminCreate } from '../../../../hooks/api/admins';
import { createRulesForAntd, validationAdminCreate } from '../../../../utils/validations';
import { useMessageError } from '../../../../hooks/common';

const breadcrumbs: ItemType[] = [
  { title: <NavLink to="/admins">Администраторы</NavLink> },
  { title: 'Добавить администратора' },
];

const validationRules = createRulesForAntd(validationAdminCreate);

function AddAdmin() {
  const adminCreate = useAdminCreate();

  const { message } = App.useApp();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const handleSubmit = () => {
    form.validateFields().then((values) => (
      adminCreate.fetch({ ...values, role: 'admin' })
        .then((res) => {
          if (res?.id) {
            message.success('Приглашение отправлено');
            navigate('/admins');
          }
        })
    ));
  };

  useMessageError([adminCreate]);

  return (
    <SinglePageContainer
      breadcrumbItems={breadcrumbs}
      title="Добавить администратора"
      cardLoading={false}
      extra={(
        <Button
          type="primary"
          onClick={form.submit}
          loading={adminCreate.loading}
        >
          Отправить приглашение
        </Button>
      )}
    >
      <Form<Admin>
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        name="admin-create"
        style={{ width: '465px' }}
      >
        <Form.Item name="name" label="Имя" rules={[{ required: true, ...validationRules }]}>
          <Input placeholder="Введите" />
        </Form.Item>
        <Form.Item name="email" label="Электронная почта" rules={[{ required: true, ...validationRules }]}>
          <Input placeholder="Введите" />
        </Form.Item>
      </Form>
    </SinglePageContainer>
  );
}

export default AddAdmin;
