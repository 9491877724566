import React, { useEffect, useState } from 'react';
import { Button, message, Select } from 'antd';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { Participant, useUserMarathonDelete } from '../../../../../../hooks/api/user';
import { useSimpleModal } from '../../../../../Common/Modal/Simple';

import styles from './index.module.scss';

interface UserMarathonSelectProps {
  selected?: Participant,
  data: Participant[]
  handleChange: (value: string) => void
  handleDeleteUserMarathon: () => void
}

function UserMarathonSelect({
  data, handleChange, selected, handleDeleteUserMarathon,
}: UserMarathonSelectProps) {
  const { open, contextHolder } = useSimpleModal();

  const userMarathonDelete = useUserMarathonDelete();
  const { id: userId = '' } = useParams<{ id: string; }>();
  const currentDate = dayjs();
  const [disabled, setDisabled] = useState<boolean>(true);

  useEffect(() => {
    if (!selected) return;
    setDisabled(dayjs(selected.dateEnd).isBefore(currentDate));
  }, [selected]);

  const handleDeleteClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (!selected) return;

    open({
      title: 'Деактивировать текущий марафон?',
      content: 'Вы уверены, что хотите деактивировать текущий марафон?',
      cancelText: 'Отмена',
      centered: true,
      okText: 'Деактивировать',
      okButtonProps: {
        type: 'primary',
        danger: true,
      },
      onOk: () => userMarathonDelete.fetch(`${userId}/participants/${selected.id}`).then(() => {
        message.success('Марафон успешно деативирован!');
        handleDeleteUserMarathon();
      }),
      maskClosable: true,
    });
  };

  return (
    <div className={styles.wrap}>
      {contextHolder}
      <Select
        value={selected?.dateStart || ''}
        onChange={(value) => handleChange(value)}
        style={{ width: 450 }}
        options={data.map((el) => (
          { value: el.dateStart, label: `Марафон (${dayjs(el.dateStart).format('DD/MM/YYYY')})` }))}
      />
      <Button
        type="primary"
        danger
        onClick={(e) => handleDeleteClick(e)}
        disabled={disabled}
      >
        Деактивировать текущий марафон
      </Button>
    </div>
  );
}

export default UserMarathonSelect;
