export enum LecturesCategoryEnum {
  trainings = 'Тренировки',
  nutrition = 'Питание',
  theory = 'Теория похудения',
  psychology = 'Психология похудения',
  meditation = 'Медитация'
}

export enum KindEnum {
  marathon = 'Марафон',
  app = 'Приложение',
}
