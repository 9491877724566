import React, { useState } from 'react';
import { ItemType } from 'antd/es/breadcrumb/Breadcrumb';
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import {
  App, Button, Form, UploadFile,
} from 'antd';
import { useLectureCreate } from '../../../../hooks/api/lectures';
import { useMessageError } from '../../../../hooks/common';
import SinglePageContainer from '../../../Common/SinglePageContainer';
import LectureFormItems from '../Form';
import { useUnsavedChanges } from '../../../../context/unsavedChanges';

const breadcrumbs: ItemType[] = [
  {
    title: <NavLink to="/lectures">Лекции</NavLink>,
  },
  {
    title: 'Добавить лекцию',
  },
];

export default function LectureCreate() {
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const lectureCreate = useLectureCreate();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { handleEditorTouched } = useUnsavedChanges();

  const [bannerFile, setBannerFile] = useState<UploadFile[]>([]);
  const [audioFileRU, setAudioFileRU] = useState<UploadFile[]>([]);
  const [audioFileUA, setAudioFileUA] = useState<UploadFile[]>([]);

  const handleSubmit = () => {
    form.validateFields().then((values) => (
      lectureCreate.fetch(values)
        .then((res) => {
          if (res?.id) {
            handleEditorTouched(false).then(() => {
              message.success('Лекция успешно добавлена.');
              navigate(searchParams.get('from') || `/lectures/${res.id}`);
            });
          }
        })
    ));
  };

  useMessageError([lectureCreate]);

  return (
    <SinglePageContainer
      breadcrumbItems={breadcrumbs}
      title="Добавить лекцию"
      cardLoading={false}
      extra={(
        <Button
          type="primary"
          onClick={form.submit}
          loading={lectureCreate.loading}
        >
          Сохранить
        </Button>
      )}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          imageUrl: undefined,
          contentRU: '',
          contentUA: '',
          descriptionRU: '',
          descriptionUA: '',
          availability: 'free',
          contentState: 'draft',
          forCatalog: true,
        }}
        onFinish={handleSubmit}
        name="lecture-create"
      >
        <LectureFormItems
          audioFileRU={audioFileRU}
          setAudioFileRU={setAudioFileRU}
          audioFileUA={audioFileUA}
          setAudioFileUA={setAudioFileUA}
          bannerFile={bannerFile}
          setBannerFile={setBannerFile}
        />
      </Form>
    </SinglePageContainer>
  );
}
