import React, { useEffect } from 'react';
import {
  App, Button, Col, Form, Input, Row,
} from 'antd';
import Card from 'antd/es/card/Card';
import { SendOutlined } from '@ant-design/icons';
import { CreateNotificationPayload, useNotificationGroupCreate } from '../../../../hooks/api/notifications';
import SelectUsersGroups from '../../../Common/Selects/SelectUsersGroups';
import { createRulesForAntd, validationNotificationGroup } from '../../../../utils/validations';
import { useMessageError } from '../../../../hooks/common';

const { TextArea } = Input;
const textAreaProps = { placeholder: 'Введите', allowClear: true, showCount: true };

const validationRules = createRulesForAntd(validationNotificationGroup);

const initialState: Partial<CreateNotificationPayload> = {
  group: undefined,
  titleRU: '',
  titleUA: '',
  messageRU: '',
  messageUA: '',
};

function CreateForm(): React.ReactNode {
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const notificationGroupCreate = useNotificationGroupCreate();

  const handleSubmit = () => {
    form.validateFields().then((values) => (
      notificationGroupCreate.fetch(values)
    ));
  };

  useEffect(() => {
    if (notificationGroupCreate.data || notificationGroupCreate.response?.status === 204) {
      message.success('Push-уведомления отправлены.');
      notificationGroupCreate.clearResponse();
      form.resetFields();
    }
  }, [notificationGroupCreate.data]);

  useMessageError([notificationGroupCreate]);

  return (
    <Card
      title="Отправить push-уведомления"
      extra={(
        <div className="flex-row-wrapper">
          <Button onClick={() => form.resetFields()}>Сбросить</Button>
          <Button
            type="primary"
            icon={<SendOutlined />}
            onClick={form.submit}
            loading={notificationGroupCreate.loading}
          >
            Отправить
          </Button>
        </div>
    )}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={initialState}
        onFinish={handleSubmit}
      >
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item name="group" label="Группа получателей" rules={[{ required: true, ...validationRules }]}>
              <SelectUsersGroups />
            </Form.Item>
          </Col>
          <Col span={12} />

          <Col span={12}>
            <Form.Item name="titleRU" label="Заголовок (RU)" rules={[{ required: true, ...validationRules }]}>
              <TextArea maxLength={50} {...textAreaProps} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="titleUA" label="Заголовок (UA)" rules={[{ required: true, ...validationRules }]}>
              <TextArea maxLength={50} {...textAreaProps} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name="messageRU" label="Сообщение (RU)" rules={[{ required: true, ...validationRules }]}>
              <TextArea maxLength={150} rows={5} {...textAreaProps} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="messageUA" label="Сообщение (UA)" rules={[{ required: true, ...validationRules }]}>
              <TextArea maxLength={150} rows={5} {...textAreaProps} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
}

export default CreateForm;
