import React, { useEffect, useState } from 'react';
import {
  App,
  Empty, Select, Spin, Typography,
} from 'antd';
import { useParams } from 'react-router-dom';
import { useMarathonId, useMarathonEntityCopy, MarathonMenu } from '../../../../../hooks/api/marathons';
import { MenuType, Option } from '../../../../../types';
import { sortShoppingMenus } from '../../Form/EntitiesCollapse/sortUtils';
import { MenuTypeEnum } from '../../../../../enums/recipes';
import { useSimpleModal } from '../../../../Common/Modal/Simple';

interface CopySelectProps {
  currentMenuType: MenuType | undefined;
  currentCalories: number | undefined;
  onCopy: (response: MarathonMenu) => void;
}

function CopySelect({ currentMenuType, currentCalories, onCopy }: CopySelectProps) {
  const { message } = App.useApp();
  const { open, contextHolder } = useSimpleModal();
  const { marathonId = '', id: entityId = '' } = useParams<{ marathonId: string; id: string }>();
  const marathonById = useMarathonId();
  const marathonPostCopy = useMarathonEntityCopy<MarathonMenu>();

  const [value] = useState<string | null>(null);
  const [options, setOptions] = useState<Option[]>([]);

  useEffect(() => {
    if (marathonById.data?.menus) {
      const newOptions: Option[] = sortShoppingMenus(marathonById.data.menus).map((shop) => ({
        label: `${MenuTypeEnum[shop.menuType]} меню (${shop.calories || 0} ккал)`,
        value: shop.id,
        disabled: shop.menuType === currentMenuType && shop.calories === currentCalories,
      }));

      setOptions(newOptions);
    }
  }, [marathonById.data?.shoppings]);

  const handleCopy = (selectedId: string) => {
    if (selectedId) {
      open({
        title: 'Копировать контент',
        content: 'Вы уверены, что хотите копировать и заменить все данные в текущем меню?',
        cancelText: 'Отмена',
        centered: true,
        okText: 'Копировать',
        okType: 'primary',
        onOk: () => marathonPostCopy.fetch(undefined, `${marathonId}/menus/${entityId}/copy-from/${selectedId}`)
          .then((res) => {
            if (res?.id) {
              message.success('Контент успешно скопирован.');
              onCopy(res);
            }
          }),
        maskClosable: true,
      });
    }
  };

  return (
    <>
      {contextHolder}
      <Select
        options={options}
        placeholder="Копировать из..."
        onFocus={() => { /** If there's no data yet - on first open of select do request to load options. */
          if (!marathonById.data) { marathonById.fetch(undefined, marathonId); }
        }}
        value={value}
        onChange={handleCopy}
        popupMatchSelectWidth={false}
        style={{ minWidth: '220px' }}
        loading={marathonById.loading}
        notFoundContent={(
          <Empty
            image={marathonById.loading ? <Spin size="default" /> : undefined}
            imageStyle={{ maxHeight: '50px', lineHeight: '50px' }}
            description={(
              <Typography.Text type="secondary">
                {marathonById.loading ? 'Загрузка...' : 'Нет данных'}
              </Typography.Text>
            )}
          />
        )}
      />
    </>
  );
}

export default CopySelect;
