import React, { HTMLAttributes } from 'react';
import { Badge } from 'antd';
import { ContentStateType } from '../../../types';
import styles from './index.module.scss';
import { ContentStateEnum } from '../../../enums';

export default function StatusBadge({ status }: { status: ContentStateType }): React.JSX.Element {
  const badgeProps: HTMLAttributes<HTMLSpanElement> = {
    className: styles.statusBadge,
    title: `Статус: ${ContentStateEnum[status]}`,
  };

  if (status === 'draft') {
    return <span {...badgeProps}><Badge status="warning" /></span>;
  }
  if (status === 'published') {
    return <span {...badgeProps}><Badge status="success" /></span>;
  }

  return <div />;
}
