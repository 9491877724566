import React, { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { Button, Typography } from 'antd';
import SinglePageContainer from '../../../../Common/SinglePageContainer';
import {
  useMarathonResultStage,
  useMarathonResultsUpdate,
} from '../../../../../hooks/api/results';
import { useMessageError } from '../../../../../hooks/common';
import { UserExtended } from '../../../../../hooks/api/user';
import MarathonResultStageUserItem from './Item';

import styles from './index.module.scss';

function MarathonResultStage() {
  const { marathonId = '', stageId = '' } = useParams<{ marathonId: string, stageId: string; }>();

  const marathonResultStage = useMarathonResultStage();
  const updateResults = useMarathonResultsUpdate();

  const [data, setData] = useState<UserExtended[]>([]);
  const [total, setTotal] = useState<number>(0);

  const handleGetResults = () => {
    if (marathonId) marathonResultStage.fetch(undefined, `${marathonId}/stages/${stageId}`);
  };

  useEffect(() => {
    handleGetResults();
  }, [marathonId]);

  useEffect(() => {
    if (marathonResultStage.data && marathonResultStage.data.data.length) {
      setData(marathonResultStage.data.data);
      setTotal(marathonResultStage.data.meta.totalItems);
    }
  }, [marathonResultStage.data]);

  useMessageError([marathonResultStage]);

  const handleUser = (id: string) => {
    setData(data?.filter((el) => el.id !== id));
    setTotal((prevState) => (prevState > 0 ? prevState - 1 : 0));
  };

  useEffect(() => {
    if (!data?.length) {
      handleGetResults();
    }
  }, [data]);

  const handleReset = () => {
    updateResults.fetch(undefined, `${marathonId}/stages/${stageId}`)
      .then((res) => {
        if (res !== null) {
          handleGetResults();
        }
      });
  };

  return (
    <SinglePageContainer
      breadcrumbItems={[
        { title: <NavLink to="/marathons">Марафоны</NavLink> },
        { title: <NavLink to={`/marathons/${marathonId}`}>Редактировать марафон</NavLink> },
        { title: <NavLink to={`/marathons/${marathonId}/results`}>Результаты</NavLink> },
        { title: `Голосование: Тур ${stageId}` },
      ]}
      title={`Голосование: Тур ${stageId}`}
      extra={`Не просмотрено: ${total || 0}`}
      cardLoading={marathonResultStage.loading}
      className={styles.wrap}
    >
      {data?.map((el) => (
        <MarathonResultStageUserItem key={el.id} data={el} handleUser={handleUser} />
      ))}

      {marathonResultStage.data?.meta.totalItems === 0 ? (
        <div className={styles.empty}>
          <Typography>Голосование завершено</Typography>
          <Button onClick={handleReset} loading={updateResults.loading}>Проголовать еще раз</Button>
        </div>
      ) : null}
    </SinglePageContainer>
  );
}

export default MarathonResultStage;
