import React, { useEffect, useRef, useState } from 'react';
import {
  FormInstance, Upload, Button, UploadFile, UploadProps, Alert, App, Modal, Form, Progress,
} from 'antd';
import clsx from 'clsx';
import {
  CloseOutlined,
  DeleteOutlined, EditOutlined, EyeOutlined, PlusOutlined,
} from '@ant-design/icons';
import { RcFile } from 'antd/es/upload';
import { NamePath } from 'rc-field-form/lib/interface';
import { getMessageInError } from '../../../../hooks/fetch';
import { getBase64 } from '../../../../utils';
import { useUploadMedia } from '../../../../hooks/api/mediaUpload';
import { messages } from '../../../../utils/validations';
import { useMessageError } from '../../../../hooks/common';
import { getFullFileUrl } from '../ImageUpload';

import styles from './index.module.scss';

interface InputCodeWrapperProps extends React.PropsWithChildren {
  name: NamePath;
  fileList: UploadFile[];
  setFileList: React.Dispatch<React.SetStateAction<UploadFile[]>>;
  setIsUploading?: React.Dispatch<React.SetStateAction<boolean>>;
  onLoadingChange?: (loading: boolean) => void;
  required?: boolean;
  multiple?: boolean;
  form?: FormInstance;
  accept?: string;
  uploadUrl?: string;
  className?: string;
  wide?: boolean;
  H170?: boolean;
  H220?: boolean;
  allowToDelete?: boolean;
  abortTrigger?: number;
}

interface PreviewModal {
  video: string;
  title: string;
  visible: boolean;
}

function VideoUpload({
  form, name, fileList, setFileList, required, multiple, accept, className, H170, H220, allowToDelete = true,
  setIsUploading, onLoadingChange, abortTrigger = 0,
}: InputCodeWrapperProps) {
  const { message } = App.useApp();
  const uploadRef = useRef<HTMLButtonElement | null>(null);
  const vidRef = useRef<HTMLVideoElement | null>(null);

  const [percentCompleted, setPercentCompleted] = useState<number | null>(null);
  const controller = new AbortController();
  // eslint-disable-next-line max-len
  const uploader = useUploadMedia((progressEvent) => { setPercentCompleted(Math.round((progressEvent.loaded / (progressEvent?.total || 0)) * 100)); }, controller, 'common/upload-video');

  useEffect(() => {
    if (abortTrigger !== 0) {
      controller.abort('Cancel');
    }
  }, [abortTrigger]);

  useEffect(() => {
    if (onLoadingChange) { onLoadingChange(uploader.loading); }
  }, [uploader.loading, onLoadingChange]);

  const [preview, setPreview] = useState<PreviewModal>({ visible: false, video: '', title: '' });

  useMessageError([uploader]);

  useEffect(() => {
    if (!preview.visible) {
      vidRef.current?.pause();
    }
  }, [preview.visible]);

  /** Handlers */
  const handleCancelPreview = () => setPreview({ ...preview, visible: false });

  const onPreview = async (file: UploadFile & {timeStampString?: string;}) => {
    if (!file.url && !file.preview) {
      // eslint-disable-next-line no-param-reassign
      file.preview = await getBase64(file.originFileObj as Blob) as string;
    }

    setPreview({
      ...preview,
      video: file.url ? getFullFileUrl(file.url) : (file.preview || file.thumbUrl || ''),
      title: file.timeStampString || '',
      visible: true,
    });
  };

  const onRemove = (file: UploadFile) => {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();

    newFileList.splice(index, 1);
    setFileList(newFileList);
  };

  const [replacingIndex, setReplacingIndex] = useState<number | null>(null);
  const onReplace = (file: UploadFile) => {
    const index = fileList.indexOf(file);

    setReplacingIndex(index);
    uploadRef.current?.click();
  };

  const beforeUpload = async (file: RcFile, FileList?: RcFile[], index?: number) => {
    /** Validation: */
    if (!['video/mp4'].includes(file.type)) {
      message.error('Вы можете загрузить только файл MP4!');

      return false;
    }

    if (!(file.size / 1024 / 1024 < 200)) {
      message.error('Видео должно быть меньше 200МБ!');

      return false;
    }

    /** Functionality */
    const newFile: UploadFile = {
      originFileObj: file, uid: file.uid, name: file.name, status: 'done',
    };

    // newFile.preview = await getBase64(file) as string;

    /** If function receive 'index' as prop, then we don't add new img at the end of array, but set in given position */
    if (index !== null && index !== undefined) {
      const newFileList = fileList.slice();

      newFileList[index] = newFile;
      setFileList(newFileList);
    } else /** If we dont have index, then do regular state update depends on 'is multiple' mode or not */
      if (multiple) {
        setFileList([...fileList, newFile]); // as UploadFile
      } else {
        setFileList([newFile]);
      }

    /** Upload image to API */
    const formData = new FormData();

    formData.append('file', newFile.originFileObj as Blob);
    if (setIsUploading) setIsUploading(true);

    uploader.fetch(formData)
      .then((res) => {
        if (res?.url) {
          setFileList((prevFileList) => {
            const updatedData = prevFileList.map((item) => (
              item.uid === file.uid ? { ...item, url: res.url } : item
            ));

            return updatedData;
          });
        }
      })
      .finally(() => {
        setPercentCompleted(null);
        if (setIsUploading) setIsUploading(false);
      });

    return false;
  };

  const props: UploadProps = {
    /** Multiple should be false, to allow upload only one image at once. File list can be any length
     * - It 'multiple' prop from params will work anyway, because of 'beforeUpload' structure.
     * It have 'if (multiple)', which checks is need to replace current item or to add to the end of array */
    name,
    multiple: false,
    showUploadList: false,
    accept,
    onRemove,
    beforeUpload,
    fileList,
    listType: 'picture-card',
  };

  return (
    <div>
      <div className={clsx(styles.uploader, className, {
        [styles.H170]: H170,
        [styles.H220]: H220,
        [styles.singleUpload]: !multiple,
      })}
      >
        {fileList.map((file) => (
          <div key={file.uid} className={clsx(styles.imageContainer, styles.overlay)}>
            <div className={styles.contentInOverlay}>
              <EyeOutlined
                className={styles.btn}
                onClick={(e) => {
                  e.stopPropagation();
                  onPreview(file);
                }}
                title="Просмотреть видео"
              />
              <EditOutlined
                className={styles.btn}
                onClick={(e) => {
                  e.stopPropagation();
                  onReplace(file);
                }}
                title="Заменить видео"
              />
              {allowToDelete ? (
                <DeleteOutlined
                  className={styles.btn}
                  onClick={(e) => {
                    e.stopPropagation();
                    onRemove(file);
                  }}
                  title="Удалить видео"
                />
              ) : null}
            </div>
            <video
              className={styles.uploadedVid}
              src={file.preview || getFullFileUrl(file.url) || file.thumbUrl}
              preload="metadata"
            >
              <track kind="captions" />
            </video>
          </div>
        ))}
        <Form.Item
          name={name}
          rules={required ? [{ required: true, message: messages.required }] : undefined}
          className="no-space-form-item"
        >
          <Upload
            {...props}
            className={styles.upload}
          >
            {!multiple && fileList.length >= 1 ? null : (
              <div className={styles.uploadBtn}>
                <PlusOutlined />
                Загрузить
              </div>
            )}
          </Upload>
        </Form.Item>

        <Upload
          name="upload-for-replace"
          className={styles.hiddenUpload}
          // beforeUpload={(file) => {
          //   beforeUpload(file, undefined);
          //
          //   return false;
          // }}
          beforeUpload={(file) => {
            if (replacingIndex !== null && replacingIndex !== undefined) {
              beforeUpload(file, undefined, replacingIndex);
              setReplacingIndex(null);
            }

            return false;
          }}
          accept={accept}
        >
          <Button ref={uploadRef}>
            Replace
          </Button>
        </Upload>
      </div>

      {percentCompleted ? (<Progress showInfo={false} percent={percentCompleted} style={{ width: 144 }} />) : null}

      {uploader.error ? (
        <Alert
          style={{ marginTop: 8 }}
          type="error"
          message={getMessageInError(uploader.error)}
          closable
          onClose={uploader.clearError}
        />
      ) : null}
      <Modal
        width="67%"
        className={styles.modal}
        open={preview.visible}
        onCancel={handleCancelPreview}
        centered
        closeIcon={false}
        footer={null}
      >
        <div className={styles.modalContent}>
          <video
            ref={vidRef}
            controls
            src={preview.video}
            preload="metadata"
            onClick={(e) => e.stopPropagation()}
          >
            <track kind="captions" />
          </video>
        </div>
      </Modal>
      <div className={clsx(styles.btnWrap, { [styles.active]: preview.visible })}>
        <Button className={styles.btn} icon={<CloseOutlined />} onClick={handleCancelPreview} />
      </div>
    </div>
  );
}

VideoUpload.defaultProps = {
  form: undefined,
  required: false,
  children: undefined,
  accept: '.mp4',
  uploadUrl: 'common/upload-image',
  multiple: false,
  className: undefined,
  cropperProps: undefined,
  setIsUploading: undefined,
  wide: false,
  H170: false,
};

export default VideoUpload;
