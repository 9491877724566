import React from 'react';
import MarathonDiplomaContent from './Content';

export default function MarathonDiploma(): React.ReactNode {
  return (
    <div>
      <MarathonDiplomaContent />
    </div>
  );
}
