import React from 'react';
import {
  Col, DatePicker, Form, Input, InputNumber, Row,
} from 'antd';
import {
  createRulesForAntd,
  messages as errorMessages,
  validationMarathon,
} from '../../../../utils/validations';
import SelectMarathonStatus from '../../../Common/Selects/SelectStatus/SelectMarathonStatus';
import { dateFormatMarathon } from '../../../../contstant';

const validationRules = createRulesForAntd(validationMarathon);

function MarathonFormItems() {
  return (
    <Row gutter={24}>
      <Col span={12}>
        <Form.Item name="nameRU" label="Название (RU)" rules={[{ required: true, ...validationRules }]}>
          <Input placeholder="Введите" />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item name="nameUA" label="Название (UA)" rules={[{ required: true, ...validationRules }]}>
          <Input placeholder="Введите" />
        </Form.Item>
      </Col>

      <Col span={6}>
        <Form.Item name="startDate" label="Дата старта" rules={[{ required: true, ...validationRules }]}>
          <DatePicker
            style={{ width: '100%' }}
            format={dateFormatMarathon}
            disabledDate={(date) => (
              date && date.day() !== 1
            )}
          />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item
          name="durationInWeeks"
          label="Количество недель"
          rules={[{ required: true, message: errorMessages.required }]}
        >
          <InputNumber placeholder="Введите" style={{ width: '100%' }} min={1} max={10} />
        </Form.Item>
      </Col>

      {/** These \/ two items should not be sent to API */}
      <Col span={6}>
        {/** gild_silver field generated from two numbers, goldNumber & silverNumber */}
        <Form.Item name="gold_silver" label="Gold/Silver">
          <Input placeholder="Введите" disabled />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item name="status" label="Статус">
          <SelectMarathonStatus disabled />
        </Form.Item>
      </Col>

    </Row>
  );
}

export default MarathonFormItems;
