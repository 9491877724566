import React from 'react';
import { ProColumns, RequestData } from '@ant-design/pro-components';
import { Button } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { EditOutlined } from '@ant-design/icons';
import Table, { defaultPagingResponse } from '../../../../Common/Table';
import { useMessageError } from '../../../../../hooks/common';
import { MarathonResults, useMarathonResults } from '../../../../../hooks/api/results';

function MarathonsResultTable(): React.JSX.Element {
  const navigate = useNavigate();
  const { marathonId = '' } = useParams<{ marathonId: string }>();
  const marathonResultsGet = useMarathonResults();

  const tableRequest = async (): Promise<RequestData<MarathonResults>> => (
    marathonResultsGet.fetch(undefined, `${marathonId}/stages`).then((data) => {
      if (data) {
        return ({
          data: data.sort((a, b) => b.stage - a.stage),
          success: true,
          total: 0,
        });
      }

      return defaultPagingResponse;
    })
  );

  useMessageError([marathonResultsGet]);

  const columns: ProColumns<MarathonResults>[] = [
    {
      title: 'Название',
      dataIndex: 'stage',
      hideInForm: true,
      renderText: (state) => `Тур ${state}`,
    },
    {
      title: 'Не просмотрено',
      dataIndex: 'remain',
      width: 150,
    },
    {
      title: 'Отклонено',
      width: 150,
      dataIndex: 'skipped',
    },
    {
      key: 'actions',
      width: 100,
      title: 'Действия',
      dataIndex: 'actions',
      hideInSetting: true,
      render: (_, row) => (
        <Button
          size="middle"
          type="text"
          icon={<EditOutlined style={{ fontSize: 18 }} />}
          onClick={() => navigate(`/marathons/${marathonId}${row.stage}`)}
          title="Редактировать"
        />
      ),
    },
  ];

  useMessageError([marathonResultsGet]);

  return (
    <Table<MarathonResults>
      rowKey="stage"
      className="mb-20"
      headerTitle="Список туров голосования"
      columns={columns}
      search={false}
      request={tableRequest}
      pagination={false}
      showSorterTooltip={false}
      onRow={(record, index) => ({
        onClick: () => navigate(`/marathons/${marathonId}/stages/${record.stage}`),
      })}
    />
  );
}
export default MarathonsResultTable;
