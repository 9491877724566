import React, {
  Key, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { ActionType, ProColumns, RequestData } from '@ant-design/pro-components';
import {
  App, Button, FormInstance, InputRef, Space,
} from 'antd';
import Search from 'antd/es/input/Search';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SortOrder } from 'antd/es/table/interface';
import { ParamsType } from '@ant-design/pro-provider';
import {
  DeleteOutlined, EditOutlined, PlusCircleOutlined,
} from '@ant-design/icons';
import dayjs from 'dayjs';
import Table, { defaultPagingResponse } from '../../../Common/Table';
import {
  createDefaultHiddenColumns, getSorterParams, queryFilterParams, triggerEnterKeyClick,
} from '../../../../utils';
import { useMessageError } from '../../../../hooks/common';
import {
  useComplexDelete, useComplexesGet, Complex, ComplexWeek,
} from '../../../../hooks/api/complexes';
import ContentThumbnailAndName from '../../../Common/ContentThumbnailAndName';
import { useSimpleModal } from '../../../Common/Modal/Simple';
import StatusIndicator from '../../../Common/StatusIndicator';
import { AvailabilityEnum } from '../../../../enums';
import SelectContentStatus from '../../../Common/Selects/SelectStatus/SelectContentStatus';
import { dateFormat } from '../../../../contstant';
import { getFullFileUrl } from '../../../Common/Uploaders/ImageUpload';
import { EquipmentEnum, LevelEnum } from '../../../../enums/trainings';
import { EquipmentType } from '../../../../types';

const generateEquipmentSet = (weeks: ComplexWeek[] | undefined) => {
  const equipmentSet = new Set<EquipmentType>();

  (weeks || []).forEach((week) => {
    week.trainingDays.forEach((day) => {
      day.trainings.forEach((training) => {
        training.equipment.forEach((item) => {
          equipmentSet.add(item);
        });
      });
    });
  });

  return equipmentSet;
};

interface TableComplexes {
  params?: Record<string, string>;
  selectedRows?: number[];
  onRowSelection?: ((selectedRows: number[]) => void) | undefined;
}

function ComplexesTable(props: TableComplexes): React.JSX.Element {
  const { params, selectedRows, onRowSelection } = props;
  const { message } = App.useApp();
  const { open, contextHolder } = useSimpleModal();

  const navigate = useNavigate();
  const complexesGet = useComplexesGet();
  const complexDelete = useComplexDelete();
  const formRef = useRef<FormInstance>();
  const actionRef = useRef<ActionType>();

  const [searchParams, setSearchParams] = useSearchParams();

  const toolBarRender = useCallback(() => [
    <Button
      key="add-btn"
      id="add-btn"
      type="primary"
      icon={<PlusCircleOutlined />}
      onClick={() => navigate('/complexes/create')}
    >
      <Space>
        Добавить
      </Space>
    </Button>,
  ], []);

  /** Table request: */
  const tableRequest = async (
    { current, pageSize, ...args }: Record<string, string>
      & { pageSize?: number | undefined; current?: number | undefined; keyword?: string | undefined; },
    sorter: Record<string, SortOrder>,
  ): Promise<RequestData<Complex>> => {
    const newParams = queryFilterParams({
      page: current ? `${current}` : '1',
      pageSize: pageSize ? `${pageSize}` : '10',
      ...args,
      ...getSorterParams(sorter),
    });

    setSearchParams(queryFilterParams({ ...args, ...getSorterParams(sorter) }), { replace: true });

    return complexesGet.fetch({
      ...newParams,
      ...params,
    }).then((data) => {
      if (data) {
        const { data: complexes, meta } = data;

        return ({ data: complexes || [], success: true, total: meta?.totalItems || 0 });
      }

      return defaultPagingResponse;
    });
  };

  const beforeSearchSubmit = (beforeSubmitParams: Partial<ParamsType>) => {
    const newParams = queryFilterParams({
      ...beforeSubmitParams,
      _timestamp: '',
      search: searchParams.get('search') || '',
    });

    setSearchParams({ ...newParams, ...params }, { replace: true });

    return { ...newParams, ...params };
  };

  useMessageError([complexesGet, complexDelete]);

  const handleDeleteClick = (e: React.MouseEvent<HTMLElement>, row: Complex) => {
    e.preventDefault();
    e.stopPropagation();
    open({
      title: 'Удалить комплекс?',
      content: 'Вы уверены, что хотите навсегда удалить этот комплекс?',
      cancelText: 'Отмена',
      centered: true,
      okText: 'Удалить',
      okButtonProps: {
        type: 'primary',
        danger: true,
      },
      onOk: () => complexDelete.fetch(row.id),
      maskClosable: true,
    });
  };

  useEffect(() => {
    if (complexDelete.response?.status === 204 || complexDelete.data) {
      message.success('Комплекс успешно удален.');
      actionRef.current?.reload();
    }
  }, [complexDelete.response]);

  const columns: ProColumns<Complex>[] = [
    {
      title: 'Название',
      dataIndex: 'nameRU',
      sorter: true,
      hideInSearch: true,
      hideInForm: true,
      render: (_, row) => (
        <ContentThumbnailAndName
          imgSrc={row.imageUrl ? getFullFileUrl(row.imageUrl) : 'images/img_training.png'}
          name={row.nameRU}
        />
      ),
    },
    {
      order: 10,
      title: 'Доступность',
      dataIndex: 'availability',
      sorter: true,
      valueEnum: AvailabilityEnum,
    },
    {
      order: 9,
      title: 'Статус',
      dataIndex: 'contentState',
      sorter: true,
      renderFormItem: (_, { defaultRender, ...config }) => (<SelectContentStatus {...config} />),
      renderText: (contentState) => <StatusIndicator status={contentState} />,
    },
    {
      order: 8,
      title: 'Уровень',
      dataIndex: 'level',
      sorter: true,
      valueEnum: LevelEnum,
    },
    {
      order: 7,
      title: 'Инвентарь',
      dataIndex: 'equipment',
      sorter: false,
      hideInSearch: true,
      render: (_, row) => {
        const equipment = Array.from(generateEquipmentSet(row?.weeks));

        return (
          equipment.map((item) => EquipmentEnum[item]).join(', ')
        );
      },
      valueEnum: EquipmentEnum,
    },
    {
      title: 'Дата создания',
      dataIndex: 'createdAt',
      sorter: true,
      renderText: (createdAt) => (createdAt ? dayjs(createdAt).format(dateFormat) : ''),
      hideInSearch: true,
    },
    {
      title: 'Дата изменения',
      dataIndex: 'updatedAt',
      sorter: true,
      renderText: (updatedAt) => (updatedAt ? dayjs(updatedAt).format(dateFormat) : ''),
      hideInSearch: true,
    },
    {
      key: 'actions',
      width: 100,
      title: 'Действия',
      dataIndex: 'actions',
      sorter: false,
      hideInSearch: true,
      hideInSetting: true,
      render: (_, row) => (
        <>
          <Button
            size="middle"
            type="text"
            icon={<EditOutlined style={{ fontSize: 18 }} />}
            onClick={() => navigate(`/complexes/${row.id}`)}
            title="Редактировать"
          />
          <Button
            size="middle"
            type="text"
            icon={<DeleteOutlined style={{ fontSize: 18 }} />}
            onClick={(e) => handleDeleteClick(e, row)}
            title="Удалить"
          />
        </>
      ),
    },
  ];

  const defaultHiddenColumns = useMemo(() => createDefaultHiddenColumns(
    ['createdAt', 'updatedAt', 'level'],
  ), []);

  const onRowChange = useCallback((selectedRowKeys: Key[]) => {
    if (onRowSelection) {
      onRowSelection(selectedRowKeys as number[]);
    }
  }, [onRowSelection]);

  const rowSelection = {
    onChange: onRowChange,
    selectedRowKeys: selectedRows,
    alwaysShowAlert: false,
    preserveSelectedRowKeys: true,
  };

  return (
    <>
      {contextHolder}
      <Table<Complex>
        withCustomSearch
        rowKey="id"
        formRef={formRef}
        actionRef={actionRef}
        headerTitle="Список комплексов"
        toolBarRender={toolBarRender}
        columns={columns}
        request={tableRequest}
        beforeSearchSubmit={beforeSearchSubmit}
        showSorterTooltip={false}
        columnsState={{
          persistenceKey: 'pro-table-complexes',
          persistenceType: 'localStorage',
          defaultValue: defaultHiddenColumns,
        }}
        rowSelection={!!onRowSelection && rowSelection}
        rowClassName="cursor-pointer"
        onRow={(record) => ({
          onClick: () => navigate(`/complexes/${record.id}`),
        })}
      />
    </>
  );
}

ComplexesTable.defaultProps = {
  params: {},
  selectedRows: [],
  onRowSelection: undefined,
};

export default ComplexesTable;
