import React, { useEffect, useState } from 'react';
import { App, Button, Form } from 'antd';
import { NavLink, useParams } from 'react-router-dom';
import SinglePageContainer from '../../../Common/SinglePageContainer';
import { useMessageError } from '../../../../hooks/common';
import { useUnsavedChanges } from '../../../../context/unsavedChanges';
import {
  CreateMarathonShopping,
  MarathonShopping, MarathonShoppingWeek,
  useMarathonId,
  useMarathonPutData,
} from '../../../../hooks/api/marathons';
import { filterObjectProps, isObjEqual } from '../../../../utils';
import MarathonFormItemsShopping, { initialMarathonShoppingWeekState } from './FormItems';
import { MenuTypeEnum } from '../../../../enums/recipes';
import CopySelect from './CopySelect';
import { useGlobalLoadingProvider } from '../../../../context/globalLoading';

const initialState: Partial<CreateMarathonShopping> = {
  weeks: [initialMarathonShoppingWeekState],
};

const keysToFilterInitial: (keyof Partial<MarathonShopping>)[] = ['id', 'calories', 'menuType'];

const getShoppingName = (menuData: MarathonShopping | undefined) => (menuData?.id ? (
  `${MenuTypeEnum[menuData.menuType]} меню (${menuData.calories} ккал)`
) : '');

function MarathonShoppings() {
  const { marathonId = '', id = '' } = useParams<{ marathonId: string; id: string }>();
  const marathonShoppingById = useMarathonId<MarathonShopping>(`${marathonId}/shopping/${id}`);
  const marathonPutData = useMarathonPutData<MarathonShopping>(`${marathonId}/shopping/${id}/weeks`);

  const { message } = App.useApp();
  const [form] = Form.useForm();
  const { unsavedChanges, handleUnsavedChanges } = useUnsavedChanges();
  const { globalLoading } = useGlobalLoadingProvider();

  const fetch = () => {
    marathonShoppingById.fetch();
  };

  useEffect(() => {
    if (marathonId && id) fetch();
  }, [marathonId, id]);

  /** Handle Initial values */
  const [initialValues, setInitialValues] = useState(initialState);

  useEffect(() => {
    if (marathonShoppingById.data) {
      setInitialValues(filterObjectProps(marathonShoppingById.data, keysToFilterInitial));
    }
  }, [marathonShoppingById.data]);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(initialValues);
    handleUnsavedChanges(false);
  }, [initialValues]);

  /** Handle submit */
  const handleSubmit = () => {
    form.validateFields().then((values) => {
      const modifiedVal = {
        ...values,
        weeks: (values.weeks || []).map((week: MarathonShoppingWeek) => ({
          ...week,
          pdfUrlRU: week?.pdfUrlRU || '',
          pdfUrlUA: week?.pdfUrlUA || '',
        })),
      };

      marathonPutData.fetch(modifiedVal)
        .then((res) => {
          if (res?.id) {
            message.success('Изменения успешно сохранены.');
            setInitialValues(filterObjectProps(res, keysToFilterInitial));
          }
        });
    }).catch(() => undefined);
  };

  useMessageError([marathonShoppingById, marathonPutData]);

  const handleFieldsChange = () => {
    handleUnsavedChanges(!isObjEqual(
      initialValues,
      form.getFieldsValue(),
    ));
  };
  const weeksWatch = Form.useWatch('weeks', form);

  useEffect(() => { handleFieldsChange(); }, [weeksWatch]);

  return (
    <SinglePageContainer
      breadcrumbItems={[
        { title: <NavLink to="/marathons">Марафоны</NavLink> },
        { title: <NavLink to={`/marathons/${marathonId}`}>Редактировать марафон</NavLink> },
        { title: `Редактировать закупки ${getShoppingName(marathonShoppingById.data)}` },
      ]}
      title={`Редактировать закупки ${getShoppingName(marathonShoppingById.data)}`}
      cardLoading={marathonShoppingById.loading}
      extra={(
        <>
          <CopySelect
            currentMenuType={marathonShoppingById.data?.menuType}
            currentCalories={marathonShoppingById.data?.calories}
            onCopy={(res) => setInitialValues(filterObjectProps(res, keysToFilterInitial))}
          />
          <Button
            type="primary"
            onClick={handleSubmit}
            loading={marathonPutData.loading}
            disabled={!unsavedChanges || globalLoading}
          >
            Сохранить
          </Button>
        </>
      )}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={initialValues}
        onFinish={handleSubmit}
        name="marathon-update-shopping"
        onFieldsChange={handleFieldsChange}
      >
        <MarathonFormItemsShopping />
      </Form>
    </SinglePageContainer>
  );
}

export default MarathonShoppings;
