import {
  DefaultFetchError,
  FetchGet,
  FetchGetId,
  PagingDataResponse,
  PagingParams,
  useFetchGet, useFetchGetId, useFetchDelete, FetchCreate, useFetchCreate, FetchUpdate, useFetchUpdate,
} from '../fetch';
import {
  AvailabilityType,
  ContentStateType,
  DefaultOrderByColumnProps,
  LevelType,
} from '../../types';
import { RuLangType, UaLangType } from '../../enums/language';
import { Training } from './trainings';

export interface Complex {
  id: string;
  nameUA: string;
  nameRU: string;
  description: string;
  descriptionRU: string;
  descriptionUA: string;
  imageUrl?: string;
  availability: AvailabilityType;
  contentState: ContentStateType;
  level: LevelType;
  durationInWeeks: number;
  weeks?: ComplexWeek[];
  createdAt: string;
  updatedAt: string;
}

export interface ComplexWeek {
  id?: string;
  trainingDays: [ComplexTrainingDay, ComplexTrainingDay, ComplexTrainingDay, ComplexTrainingDay,
    ComplexTrainingDay, ComplexTrainingDay, ComplexTrainingDay]; // Always 7 items
}
export interface ComplexTrainingDay {
  id?: string;
  trainings: Training[];
}

export interface ComplexesGetParams extends PagingParams {
  search?: string;
  lang?: UaLangType | RuLangType;
  availability?: AvailabilityType;
  contentState?: ContentStateType; // status
  level?: LevelType;
  orderByColumn?: DefaultOrderByColumnProps | 'nameRU' | 'availability' | 'contentState' | 'level';
}

export function useComplexesGet<D = PagingDataResponse<Complex>,
  DD = D>(decorateData?: (data: D) => DD): FetchGet<D, ComplexesGetParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, ComplexesGetParams, DD>(
    'complexes',
    {
      decorateData,
      autoStart: false,
      startStateLoading: false,
    },
  );
}

export const useComplexId = (id?: string): FetchGetId<Complex> => useFetchGetId(
  'complexes',
  id,
  { autoStart: false, startStateLoading: false },
);

export const useComplexDelete = () => useFetchDelete<undefined, DefaultFetchError, string>('complexes');

export interface CreateComplexPayload {
  durationInWeeks: number;
  nameUA: string;
  nameRU: string;
  descriptionUA?: string;
  descriptionRU?: string;
  imageUrl?: string;
  availability: AvailabilityType;
  contentState?: ContentStateType;
  level: LevelType;
  weeks: ComplexWeek[];
}

export interface UpdateRecipePayload extends CreateComplexPayload {
  id?: string;
}

export const useComplexCreate = (): FetchCreate<Complex, DefaultFetchError, CreateComplexPayload> => (
  useFetchCreate('complexes')
);

export const useComplexUpdate = (id?: string): FetchUpdate<
  Complex, DefaultFetchError, UpdateRecipePayload
> => (
  useFetchUpdate('complexes', id)
);
