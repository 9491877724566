import React from 'react';
import { config } from '@triare/auth-redux';
import Layout from '../../../components/Layout';
import Content from '../../../components/Pages/Marathons/Habits';

const { displayName } = config;

export default function MarathonHabits(): React.ReactNode | null {
  document.title = `${displayName}: Редактировать список привычек`;

  return (
    <Layout>
      <Content />
    </Layout>
  );
}
