import React, { useCallback, useState } from 'react';
import {
  App, Button, Col, Collapse, Form, Input, Row, Select,
} from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { PlusOutlined } from '@ant-design/icons';
import clsx from 'clsx';
import {
  MarathonMenu,
  MarathonMenuTime,
  MarathonMenuWeek,
} from '../../../../../hooks/api/marathons';
import DayPicker, { DayProps } from '../../../../Common/Pickers/DayPicker';
import WeekPicker from '../../../../Common/Pickers/WeekPicker';
import { MenuType, Option } from '../../../../../types';
import { getDayName } from '../../../../../utils';
import { Recipe, useRecipeId } from '../../../../../hooks/api/recipes';
import RecipesListModal from '../../../Recipes/ModalTable';
import RecipeRow from '../../../Recipes/RecipeRowItem';
import PdfsUpload from './PdfsUpload';
import { useGlobalLoadingProvider } from '../../../../../context/globalLoading';

interface MarathonFormItemsMenuProps {
  defaultSearchParams?: {
    menuType?: MenuType;
  };
  marathonMenuData?: MarathonMenu;
}

function MarathonFormItemsMenu({ defaultSearchParams }: MarathonFormItemsMenuProps) {
  const { message } = App.useApp();
  const form = useFormInstance();
  const recipeById = useRecipeId();
  const { globalLoading } = useGlobalLoadingProvider();

  const [selectedDay, setSelectedDay] = useState<DayProps>({ day: 'mon', dayIndex: 0 });
  const [selectedWeekIndex, setSelectedWeekIndex] = useState<number>(0);

  const weeksWatch: MarathonMenuWeek[] = Form.useWatch('weeks', form) || [];

  /** Collapse controls */
  const [collapseKey, setCollapseKey] = useState<MarathonMenuTime[] | string[]>([]);

  /** Add new training func */
  const [modalState, setModalState] = useState<{ open: boolean; type: MarathonMenuTime | '' }>(
    { open: false, type: '' },
  );
  const handleModal = (open: boolean, type?: MarathonMenuTime | '') => {
    setModalState({ open, type: type || '' });
  };

  const [selectedRecipesIds, setSelectedRecipesIds] = useState<(number | string)[]>([]);

  const handleAddRecipes = async (timeType: MarathonMenuTime, propSelectedRecipes?: (number | string)[]) => {
    let selectedRecipes: Recipe[] = [];
    let recipesManyPortions: Recipe[] = []; // Array to store recipes with >= 2 portions

    const selectedWeek = weeksWatch[selectedWeekIndex];

    const currentDayRecipeIds = selectedWeek?.menuDays
      .find((day, index) => index === selectedDay?.dayIndex)
      ?.[timeType].map((el) => el.id);

    /** Prioritize taking recipeIds from props, if there's no prop - take from state */
    const uniqueRecipesIds = (propSelectedRecipes || selectedRecipesIds).map((el) => el.toString())
      .filter((id) => !currentDayRecipeIds?.includes(id));

    await Promise.all(uniqueRecipesIds?.map((recipeId) => (
      recipeById.fetch(undefined, recipeId)
    ))).then((res) => {
      if (res.every((el: Recipe | null) => el?.id)) {
        const regularRecipes: Recipe[] = [...selectedRecipes,
          ...(res.filter((currRecipe) => ((currRecipe?.portions || 0) <= 1))) as Recipe[],
        ];
        const portionalRecipes: Recipe[] = res
          .filter((currRecipe) => ((currRecipe?.portions || 0) >= 2)) as Recipe[];

        selectedRecipes = regularRecipes;
        recipesManyPortions = portionalRecipes;
      }
    }).catch((err) => {
      if (err) {
        message.error('Ошибка при добавлении рецепта.');
      }
    });

    /** Update value in form after request */
    const namePath = ['weeks', selectedWeekIndex, 'menuDays', selectedDay.dayIndex, timeType];
    const prevValue = form.getFieldValue(namePath);

    form.setFieldValue(namePath, [...prevValue, ...selectedRecipes]);

    /** Iterate through each selected recipe with multiple portions. */
    recipesManyPortions.forEach((portionRecipe) => {
      /** Create array with length same as total portions in recipe */
      const portions = Array.from({ length: portionRecipe.portions }).map((_, index) => (index + 1));

      /** For each portion create namePath and add items to new arr */
      portions.forEach((currPortion) => {
        let targetDayIndex = selectedDay.dayIndex + currPortion - 1;

        /** If target day index not in this week (0-6) then add item to next week */
        if (targetDayIndex > 6) {
          /** Calculate the overflow to determine on which index add item */
          const overflow = targetDayIndex - 6;

          /** Calculate the index of the day in the next week to add the recipe to */
          targetDayIndex = (overflow % portionRecipe.portions) - 1;

          const nextWeekIndex = selectedWeekIndex + 1;

          /** If next week don't exist, then dont add any value */
          if (nextWeekIndex > (weeksWatch.length || 0) - 1) { return; }

          /** Calculate the namePath for the day in the next week */
          const nextWeekNamePath = ['weeks', nextWeekIndex, 'menuDays', targetDayIndex, timeType];
          const prevValueNextWeekDay = form.getFieldValue(nextWeekNamePath);

          /** Add the recipe to the day in the next week */
          form.setFieldValue(
            nextWeekNamePath,
            [...(prevValueNextWeekDay || []), { ...portionRecipe, portionNumber: currPortion }],
          );
        } else {
          /** Handling days which not overflow the current week */
          const currNamePath = ['weeks', selectedWeekIndex, 'menuDays', targetDayIndex, timeType];
          const prevValueOfDay = form.getFieldValue(currNamePath);

          form.setFieldValue(
            currNamePath,
            [...(prevValueOfDay || []), { ...portionRecipe, portionNumber: currPortion }],
          );
        }
      });
    });

    setSelectedRecipesIds([]);
  };

  const handleDeleteRecipes = (
    timeType: MarathonMenuTime,
    { weekInd, dayInd, itemKey }: { weekInd: number, dayInd: number, itemKey: number },
    onRemoveSingle: () => void,
  ) => {
    const namePath = ['weeks', weekInd, 'menuDays', dayInd, timeType, itemKey];
    const prevRecipeValue: Recipe = form.getFieldValue(namePath);

    /** If its single portion item remove it with form.list function */
    if ((prevRecipeValue.portions || 0) <= 1) {
      onRemoveSingle();

      return;
    }

    if (prevRecipeValue.portions >= 2) {
      onRemoveSingle(); // Remove current element
      /** Create an array representing all portions of the recipe */
      const allPortions = Array.from({ length: prevRecipeValue.portions }).map((_, index) => (index + 1));

      allPortions.forEach((portionNumber) => {
        if (portionNumber === prevRecipeValue.portionNumber) {
          return;
        }

        let targetDayIndex = dayInd + portionNumber - (prevRecipeValue.portionNumber || 1);
        // If delete will not work try this code:
        /* let targetDayIndex = portionNumber <= (prevRecipeValue.portionNumber || 1)
          ? (dayInd - portionNumber)
          : dayInd + portionNumber - (prevRecipeValue.portionNumber || 1); */

        /** If the target day index exceeds the number of days in the week, adjust it accordingly */
        if (targetDayIndex > 6) {
          const overflow = targetDayIndex - 6;

          targetDayIndex = (overflow % prevRecipeValue.portions) - 1;

          // If delete in some cases will wont work, try this version:
          /* targetDayIndex = (overflow % prevRecipeValue.portions) - (prevRecipeValue?.portionNumber || 1);
          if (targetDayIndex === 7) {
            targetDayIndex = 0;
          } */

          const nextWeekIndex = selectedWeekIndex + 1;

          /** If next week don't exist, then dont add any value */
          if (nextWeekIndex > (weeksWatch.length || 0) - 1) { return; }

          const nextWeekNamePath = ['weeks', nextWeekIndex, 'menuDays', targetDayIndex, timeType];
          const prevValueNextWeek: Recipe[] = form.getFieldValue(nextWeekNamePath);
          const newValue = prevValueNextWeek.filter((item) => item.id !== prevRecipeValue.id);

          form.setFieldValue(nextWeekNamePath, newValue);
        } else if (targetDayIndex < 0) { /** If one or more portions is in previous week then take last days */
          if (targetDayIndex === -1) {
            targetDayIndex = 6;
          }
          if (targetDayIndex === -2) {
            targetDayIndex = 5;
          }

          const nextWeekIndex = selectedWeekIndex - 1;

          /** If next week don't exist, then dont add any value */
          if (nextWeekIndex < 0) { return; }

          const nextWeekNamePath = ['weeks', nextWeekIndex, 'menuDays', targetDayIndex, timeType];
          const prevValueNextWeek: Recipe[] = form.getFieldValue(nextWeekNamePath);
          const newValue = prevValueNextWeek.filter((item) => item.id !== prevRecipeValue.id);

          form.setFieldValue(nextWeekNamePath, newValue);
        } else { /** If action happening in one week */
          const currNamePath = ['weeks', weekInd, 'menuDays', targetDayIndex, timeType];
          const prevValueDay: Recipe[] = form.getFieldValue(currNamePath);
          const newValue = prevValueDay.filter((item) => item.id !== prevRecipeValue.id);

          form.setFieldValue(currNamePath, newValue);
        }
      });
    }
  };

  const collapseDayItems: { label: string; key: MarathonMenuTime }[] = [
    { label: 'Завтрак', key: 'breakfast' },
    { label: 'Перекус', key: 'snack' },
    { label: 'Обед', key: 'lunch' },
    { label: 'Ужин', key: 'dinner' },
  ];

  /** Copy-Select func */
  const generateCopyOptions = useCallback((timeType: MarathonMenuTime): Option[] => {
    if (weeksWatch?.length) {
      const options: Option[] = weeksWatch.map((week, weekIndex) => ({
        label: `Неделя ${weekIndex + 1}`,
        value: weekIndex + 1,
        options: week.menuDays.map((day, dayIndex) => ({
          label: `${weekIndex + 1} - ${getDayName(dayIndex)}`,
          value: `${weekIndex}.${dayIndex}.${timeType}`,
          disabled: (selectedDay.dayIndex === dayIndex) && (weekIndex === selectedWeekIndex),
        })),
      }));

      return options;
    }

    return [];
  }, [weeksWatch?.length, selectedDay, selectedWeekIndex]);

  const [selectCopy] = useState<ThreeDotSeparatedValues | null>(null);

  /** Name path have 3 items. /WeekIndex.DayIndex.TimeType/ */
  type ThreeDotSeparatedValues = `${string}.${string}.${string}`;
  const copyRecipes = (namePath: ThreeDotSeparatedValues) => {
    const namePathValues = namePath.split('.');

    /** Get value from selected day */
    const namePathWhichWeCopy = ['weeks', namePathValues[0], 'menuDays', namePathValues[1], namePathValues[2]];
    const copiedTrainingsValue: Recipe[] = form.getFieldValue(namePathWhichWeCopy) || [];

    if (!copiedTrainingsValue?.length) {
      message.warning('В выбранном дне нет данных!');
    }

    const namePathToBeChanged = ['weeks', selectedWeekIndex, 'menuDays', selectedDay.dayIndex, namePathValues[2]];
    const prevValue = form.getFieldValue(namePathToBeChanged) || [];

    /** Remove duplicates, and copies all values, single & multiple portion recipes */
    // const newValue: Recipe[] = [...prevValue, ...copiedTrainingsValue]
    //   .filter((obj, index, self) => (
    //     index === self.findIndex((o) => o.id === obj.id)
    //   ));

    const singlePortionsRecipes = copiedTrainingsValue.filter((item) => !(item.portions >= 2));

    /** Remove duplicates from prev values and NEW SINGLE-portion recipes */
    const newValueWithSingle: Recipe[] = [...prevValue, ...singlePortionsRecipes]
      .filter((obj, index, self) => (
        index === self.findIndex((o) => o.id === obj.id)
      ));

    /** Set new values including prev-values and NEW SINGLE-portion recipe values */
    form.setFieldValue(namePathToBeChanged, newValueWithSingle);

    /** If copied day have recipes with multi-portions, then add they too. */
    const multiPortionsRecipes = copiedTrainingsValue.filter((item) => (item.portions >= 2));

    if (multiPortionsRecipes.length) {
      handleAddRecipes(namePathValues[2] as MarathonMenuTime, multiPortionsRecipes.map((item) => item.id))
        .then(() => message.success('Рецепты с несколькими порциями скопированы.'));
    }

    if (copiedTrainingsValue?.length) {
      message.success('Контент успешно скопирован.');
    }
  };

  return (
    <>
      <RecipesListModal
        isOpenModal={modalState.open}
        isLoading={recipeById.loading}
        handleAddRecipes={() => handleAddRecipes(modalState.type || 'breakfast')}
        handleModal={handleModal}
        selectedTrainingsIds={selectedRecipesIds}
        handleSelectedRows={setSelectedRecipesIds}
        defaultParams={defaultSearchParams}
      />
      <Row gutter={24}>
        <Col span={24}>
          <div className="week-day-picker-container">
            <WeekPicker
              weeks={weeksWatch || []}
              selectedWeek={selectedWeekIndex}
              onClick={(weekIndex) => setSelectedWeekIndex(weekIndex)}
              label="Неделя марафона"
              disabled={globalLoading}
            />
            <DayPicker<MarathonMenuWeek>
              selectedDay={selectedDay?.dayIndex}
              onDayClick={(day) => setSelectedDay(day)}
              isFilledProps={{
                marathonMenu: {
                  selectedWeek: weeksWatch?.[selectedWeekIndex] || [],
                  menuTime: collapseKey?.[0] as MarathonMenuTime,
                },
              }}
            />
          </div>
        </Col>

        <Form.Item name="formChanged" className="no-space-form-item hidden-form-item">
          <Input type="hidden" disabled />
        </Form.Item>

        <Col span={24}>
          <Form.List name="weeks">
            {(weeksFields) => (
              <div>
                {weeksFields.map((weekField, weekIndex) => (
                  <div key={weekField.key} className="weekField">
                    {selectedWeekIndex === weekIndex ? (
                      <PdfsUpload
                        weekProps={{ id: weeksWatch?.[weekIndex]?.id, name: weekField.name, index: weekIndex }}
                        pdfUrlRU={weeksWatch?.[weekIndex]?.pdfUrlRU}
                        pdfUrlUA={weeksWatch?.[weekIndex]?.pdfUrlUA}
                      />
                    ) : null}

                    <Form.List name={[weekField.name, 'menuDays']}>
                      {(menuDaysFields) => (
                        <>
                          {menuDaysFields.map((menuDayField, menuDayIndex) => (
                            <div
                              className={clsx('flex-column-wrapper')}
                              key={menuDayField.key}
                            >
                              {/** Display only <Collapse /> of current selected day & week: */}
                              {selectedWeekIndex === weekIndex && selectedDay.dayIndex === menuDayIndex
                                ? (
                                  <Collapse
                                    accordion
                                    activeKey={collapseKey}
                                    onChange={(key) => (
                                      Array.isArray(key) ? setCollapseKey(key) : setCollapseKey([key])
                                    )}
                                    items={collapseDayItems.map((collapseField) => ({
                                      key: collapseField.key,
                                      label: collapseField.label,
                                      children: (
                                        <>
                                          <Form.List name={[menuDayField.name, collapseField.key]}>
                                            {(trainingFields, { remove }) => (
                                              <>
                                                {trainingFields.map((menuField, menuIndex) => (
                                                  <RecipeRow
                                                    key={menuField.key}
                                                    recipe={weeksWatch?.[weekIndex]?.menuDays?.[menuDayIndex]
                                                      ?.[collapseField.key]?.[menuIndex]}
                                                    onRemove={() => {
                                                      handleDeleteRecipes(collapseField.key, {
                                                        weekInd: weekIndex,
                                                        dayInd: menuDayIndex,
                                                        itemKey: menuField.name,
                                                      }, () => remove(menuField.name));
                                                    }}
                                                  />
                                                ))}
                                              </>
                                            )}
                                          </Form.List>
                                          <Col span={24}>
                                            <Button
                                              icon={<PlusOutlined />}
                                              onClick={() => handleModal(true, collapseField.key)}
                                            >
                                              Добавить рецепт
                                            </Button>
                                          </Col>
                                        </>
                                      ),
                                      extra: (
                                        <Select
                                          options={generateCopyOptions(collapseField.key)}
                                          placeholder="Копировать из..."
                                          onClick={(e) => {
                                            if (collapseField.key === collapseKey?.[0]) {
                                              e.stopPropagation();
                                            }
                                          }}
                                          value={selectCopy}
                                          onChange={(value) => { copyRecipes(value); }}
                                          popupMatchSelectWidth={false}
                                          style={{ minWidth: '220px' }}
                                        />
                                      ),
                                    }))}
                                  />
                                ) : null}
                            </div>
                          ))}
                        </>
                      )}
                    </Form.List>
                  </div>
                ))}
              </div>
            )}
          </Form.List>
        </Col>
      </Row>
    </>
  );
}

MarathonFormItemsMenu.defaultProps = {
  trainings: undefined,
  defaultSearchParams: undefined,
  marathonMenuData: undefined,
};

export default MarathonFormItemsMenu;
