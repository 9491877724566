import React, { useEffect, useRef, useState } from 'react';
import { Button, Input, Typography } from 'antd';
import { ItemType } from 'antd/es/breadcrumb/Breadcrumb';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { CopyOutlined } from '@ant-design/icons';
import SinglePageContainer from '../../../Common/SinglePageContainer';
import {
  EmailContent, EmailLanguage, Emails, useEmailsId,
} from '../../../../hooks/api/emails';
import EmailFilters from '../Filters';
import { UsersGroupsEnum } from '../../../../enums/emails';

import styles from './index.module.scss';

const breadcrumbs: ItemType[] = [
  { title: <NavLink to="/emails">Рассылка</NavLink> },
  { title: 'Просмотр шаблона' },
];

export const decorateHTMLContent = (htmlStr: string, obj: EmailContent) => htmlStr.replace(
  /{{\s*([A-Za-z_][A-Za-z0-9_]*)\s*}}/g,
  (match, p1) => obj[p1.toLowerCase() as keyof EmailContent] || match,
);

function EmailCheck() {
  const { id = '' } = useParams<{ id: string; }>();
  const navigate = useNavigate();
  const emailById = useEmailsId();
  const containerRef = useRef<HTMLDivElement>(null);

  const [selectedLanguage, setSelectedLanguage] = useState<EmailLanguage>('ru');
  const [selectedGroup, setSelectedGroup] = useState<UsersGroupsEnum>();
  const [data, setData] = useState<Emails>();
  const [activeContent, setActiveContent] = useState<EmailContent>();

  const fetch = () => {
    emailById.fetch(undefined, id);
  };

  useEffect(() => {
    if (id) fetch();
  }, [id]);

  useEffect(() => {
    if (emailById.data) {
      setData(emailById.data);
      setActiveContent(emailById.data.contentRU);
    }
  }, [emailById.data]);

  const handleSelectGroup = (payload: UsersGroupsEnum) => setSelectedGroup(payload);

  const handleLanguageChange = (payload: EmailLanguage) => {
    if (!data || !activeContent) return;

    setData({ ...data, ...(payload === 'ru' ? { contentUA: activeContent } : { contentRU: activeContent }) });
    setActiveContent(payload === 'ru' ? data.contentRU : data.contentUA);
    setSelectedLanguage(payload);
  };

  useEffect(() => {
    if (containerRef.current && activeContent && data?.htmlRU && data?.htmlUA) {
      const html = selectedLanguage === 'ru' ? data.htmlRU : data.htmlUA;

      containerRef.current.innerHTML = decorateHTMLContent(html, activeContent);
    }
  }, [containerRef.current, data]);

  return (
    <SinglePageContainer
      breadcrumbItems={breadcrumbs}
      title="Просмотр шаблона"
      cardLoading={emailById.loading || !data}
      extra={(
        <Button
          type="default"
          icon={<CopyOutlined />}
          onClick={() => navigate(`/emails/${data?.id}/create`)}
        >
          Создать рассылку
        </Button>
      )}
    >
      <EmailFilters
        isCreate={false}
        handleSelectGroup={handleSelectGroup}
        handleLanguageChange={handleLanguageChange}
        selectedGroup={selectedGroup}
        selectedLanguage={selectedLanguage}
      />
      <div className={styles.titleWrap}>
        <Typography className={styles.label}>Тема письма</Typography>
        <Input disabled value={activeContent?.subject} />
      </div>
      {activeContent ? <div ref={containerRef} className={styles.htmlContainer} /> : null}
    </SinglePageContainer>
  );
}

export default EmailCheck;
