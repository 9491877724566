import React from 'react';
import { Typography } from 'antd';
import Breadcrumb from 'antd/es/breadcrumb';
import { NavLink, useParams } from 'react-router-dom';
import List from './List';

export default function Results(): React.ReactNode {
  const { marathonId = '' } = useParams<{ marathonId: string; }>();

  return (
    <div>
      <Breadcrumb
        className="page-header-breadcrumb"
        items={[
          { title: <NavLink to="/marathons">Марафоны</NavLink> },
          { title: <NavLink to={`/marathons/${marathonId}`}>Редактировать марафон</NavLink> },
          { title: 'Результаты' },
        ]}
      />
      <Typography.Title level={4}>
        Результаты
      </Typography.Title>
      <List />
    </div>
  );
}
