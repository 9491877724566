import {
  DefaultFetchError, FetchCreate,
  FetchGet, FetchGetId, FetchSuccess, FetchUpdate,
  PagingDataResponse,
  PagingParams, useFetchCreate,
  useFetchGet, useFetchGetId, useFetchUpdate,
} from '../fetch';
import { DefaultOrderByColumnProps } from '../../types';
import { UsersGroupsEnum } from '../../enums/emails';

export type EmailTemplates = 'template1' | 'template2' | 'template3';
export type EmailLanguage = 'ru' | 'ua';

export type EmailContent = {
  subject?: string
  title?: string
  content?: string
  banner?: string
  title2?: string
  content2?: string
  banner2?: string
  title3?: string
  date?: string,
  gold_old_price?: string
  gold_new_price?: string
  silver_old_price?: string
  silver_new_price?: string
  content3?: string
  banner3?: string
}
export interface Emails {
  id?: string
  name: string
  template: EmailTemplates
  htmlUA: string
  htmlRU: string
  contentUA: EmailContent
  contentRU: EmailContent
  createdAt?: string
  updatedAt?: string
}

export interface EmailsSend {
  subjectUA: string
  subjectRU: string
  htmlUA: string
  htmlRU: string
  group: UsersGroupsEnum
}

export interface EmailsGetParams extends PagingParams {
  search?: string;
  orderByColumn?: DefaultOrderByColumnProps | 'name';
}

export function useEmailsGet<D = PagingDataResponse<Emails>,
  DD = D>(decorateData?: (data: D) => DD): FetchGet<D, EmailsGetParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, EmailsGetParams, DD>(
    'emails',
    {
      decorateData,
      autoStart: false,
      startStateLoading: false,
    },
  );
}

export const useEmailsId = (id?: string): FetchGetId<Emails> => useFetchGetId(
  'emails',
  id,
  { autoStart: false, startStateLoading: false },
);

export const useUpdateEmail = (id?: string): FetchUpdate<Emails, DefaultFetchError, Emails> => (
  useFetchUpdate('emails', id)
);

export const useEmailSend = (): FetchCreate<FetchSuccess, DefaultFetchError, EmailsSend> => (
  useFetchCreate('emails/send')
);
