import React, { useState } from 'react';
import { PlayCircleFilled } from '@ant-design/icons';
import { getFullFileUrl } from '../../../../../../../Common/Uploaders/ImageUpload';
import EmptyImage from '../../Empty';
import UploadUserMediaModal from '../UploadMediaModal';
import { EditButton, UserMarathonThumbnailProps } from '../ThumbnailImage';

function UserMarathonVideoThumbnail({
  media, editProps, selectedMarathon,
}: UserMarathonThumbnailProps) {
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpen = (open: boolean) => {
    setModalOpen(open);
  };

  if (!editProps?.withEdit) {
    return (
      media ? (
        <>
          <PlayCircleFilled className="video-play-icon" />
          <video src={getFullFileUrl(media)} preload="metadata">
            <track kind="captions" />
          </video>
        </>
      ) : <EmptyImage />
    );
  }

  /** If withEdit then render edit icon and modal for edit image */
  return (
    <>
      <UploadUserMediaModal
        variant="video"
        media={media}
        dataKey={editProps.dataKey}
        open={modalOpen}
        handleOpen={handleOpen}
        measure={editProps.measure}
        participantId={selectedMarathon?.id || ''}
      />

      <EditButton onClick={() => handleOpen(true)} />

      <PlayCircleFilled className="video-play-icon" />
      <video src={getFullFileUrl(media)} preload="metadata">
        <track kind="captions" />
      </video>
    </>
  );
}

UserMarathonVideoThumbnail.defaultProps = {
  media: undefined,
};

export default UserMarathonVideoThumbnail;
