import React from 'react';
import { config } from '@triare/auth-redux';
import Layout from '../../../components/Layout';
import Content from '../../../components/Pages/Marathons/Instructions';

const { displayName } = config;

export default function MarathonInstructions(): React.ReactNode | null {
  document.title = `${displayName}: Редактировать инструкции для фото/видео`;

  return (
    <Layout>
      <Content />
    </Layout>
  );
}
