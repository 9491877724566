import {
  DefaultFetchError, FetchCreate, FetchSuccess, useFetchCreate,
} from '../fetch';

export interface ConfirmEmailParams {
  secretKey: string;
}

export const useConfirmEmail = (): FetchCreate<FetchSuccess, DefaultFetchError, ConfirmEmailParams> => (
  useFetchCreate('auth/confirm-email', {
    authorization: false,
  })
);
