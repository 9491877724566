import React, { useEffect, useState } from 'react';
import TextArea from 'antd/es/input/TextArea';
import {
  Button, Col, Form, Input, Row, UploadFile,
} from 'antd';
import { EmailContent, EmailLanguage } from '../../../../../hooks/api/emails';
import EmailSupport from '../Static/Support';
import EmailFooter from '../Static/Footer';
import ImageUpload, { generateFileObjFromUrl } from '../../../../Common/Uploaders/ImageUpload';

import styles from './index.module.scss';

interface EmailTemplateProps {
  content: EmailContent
  lang: EmailLanguage
  handleChange: (key: keyof EmailContent, payload: string) => void
}

function EmailTemplate3({
  content, lang, handleChange,
}: EmailTemplateProps) {
  const [bannerFile, setBannerFile] = useState<UploadFile[]>([]);
  const [bannerFile2, setBannerFile2] = useState<UploadFile[]>([]);

  useEffect(() => {
    setBannerFile(content.banner?.length ? [generateFileObjFromUrl(content.banner)] : []);
    setBannerFile2(content.banner2?.length ? [generateFileObjFromUrl(content.banner2)] : []);
  }, [lang]);

  useEffect(() => {
    handleChange('banner', (bannerFile[0]?.url || ''));
  }, [bannerFile]);

  useEffect(() => {
    handleChange('banner2', (bannerFile2[0]?.url || ''));
  }, [bannerFile2]);

  return (
    <>
      <div className={styles.container}>
        <Form>
          <ImageUpload
            name="banner"
            setFileList={setBannerFile}
            cropperProps={{ aspect: 560 / 220 }}
            fileList={bannerFile}
            multiple={false}
            allowToPreview={false}
            label="Загрузить изображение 1120x440px"
            email
            H220
          />
          <div className={styles.content}>
            <div className={styles.label}>Заголовок</div>
            <TextArea
              rows={3}
              value={content.title}
              style={{ marginBottom: '24px' }}
              onChange={(e) => handleChange('title', e.target.value)}
            />
            <div className={styles.label}>Текстовый блок</div>
            <TextArea
              rows={7}
              value={content.content}
              style={{ marginBottom: '24px' }}
              onChange={(e) => handleChange('content', e.target.value)}
            />
            <div className={styles.label}>Дата следующего набора в марафон</div>
            <Input
              value={content.date}
              style={{ marginBottom: '24px' }}
              onChange={(e) => handleChange('date', e.target.value)}
            />
            <div className={styles.label}>Текстовый блок</div>
            <TextArea
              rows={7}
              value={content.content2}
              style={{ marginBottom: '24px' }}
              onChange={(e) => handleChange('content2', e.target.value)}
            />
            <Row gutter={[24, 24]}>
              <Col span={12}>
                <div className={styles.label}>Gold (старая цена)</div>
                <Input
                  value={content.gold_old_price}
                  onChange={(e) => handleChange('gold_old_price', e.target.value)}
                />
              </Col>
              <Col span={12}>
                <div className={styles.label}>Silver (старая цена)</div>
                <Input
                  value={content.silver_old_price}
                  onChange={(e) => handleChange('silver_old_price', e.target.value)}
                />
              </Col>
              <Col span={12}>
                <div className={styles.label}>Gold (новая цена)</div>
                <Input
                  value={content.gold_new_price}
                  onChange={(e) => handleChange('gold_new_price', e.target.value)}
                />
              </Col>
              <Col span={12}>
                <div className={styles.label}>Silver (новая цена)</div>
                <Input
                  value={content.silver_new_price}
                  onChange={(e) => handleChange('silver_new_price', e.target.value)}
                />
              </Col>
            </Row>
          </div>
          <div className={styles.content}>
            <div className={styles.label}>Текстовый блок</div>
            <TextArea
              rows={7}
              style={{ marginBottom: '24px' }}
              value={content.content3}
              onChange={(e) => handleChange('content3', e.target.value)}
            />
            <Button className={styles.btn}>{lang === 'ru' ? 'Купить участие' : 'Купити участь'}</Button>
          </div>
          <ImageUpload
            name="banner2"
            setFileList={setBannerFile2}
            cropperProps={{ aspect: 560 / 220 }}
            fileList={bannerFile2}
            multiple={false}
            allowToPreview={false}
            label="Загрузить изображение 1120x440px"
            email
            H140
          />
          <EmailSupport lang={lang} />
        </Form>
      </div>
      <EmailFooter lang={lang} />
    </>
  );
}

export default EmailTemplate3;
