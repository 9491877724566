import { MailOutlined } from '@ant-design/icons';
import { NavLink } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import {
  Alert, Button, Form as AntdForm, Input, Typography,
} from 'antd';
import { validationSignInEmailAndPassword, createRulesForAntd } from '../../../../../utils/validations';
import SendSuccess from '../SendSuccess';
import { usePasswordForgot } from '../../../../../hooks/api/password';
import { getMessageInError } from '../../../../../hooks/fetch';
import Wrapper from '../../Wrapper';
import { useMessageError } from '../../../../../hooks/common';

import styles from './index.module.scss';

const { Paragraph, Title } = Typography;
const validationRules = createRulesForAntd(validationSignInEmailAndPassword);

export default function Form() {
  const passwordCreate = usePasswordForgot();
  const [sendSuccess, setSendSuccess] = useState(false);

  const onFinish = ({ email }: { email: string }) => {
    passwordCreate.fetch({ email })
      .then(() => sessionStorage.setItem('forgotPasswordEmail', email));
  };

  useEffect(() => {
    if (passwordCreate.data || passwordCreate.response?.status === 204) {
      setSendSuccess(true);
    }
  }, [passwordCreate.data]);

  useEffect(() => {
    const email = sessionStorage.getItem('forgotPasswordEmail');

    if (email) { setSendSuccess(true); }

    /** Clean storage if user leaves forgot password page */
    return () => sessionStorage.setItem('forgotPasswordEmail', '');
  }, []);

  useMessageError([passwordCreate]);

  return (
    <Wrapper>
      <AntdForm className={styles.form} layout="vertical" onFinish={onFinish}>
        {!sendSuccess && (
          <>
            <div className="text-centered">
              <Title level={3} className={styles.title}>
                Забыли пароль?
              </Title>
              <Paragraph className={styles.secondary}>
                Мы отправим ссылку для восстановления пароля на вашу электронную почту
              </Paragraph>
            </div>

            {passwordCreate.error && (
              <AntdForm.Item>
                <Alert
                  type="error"
                  onClose={() => passwordCreate.clearError()}
                  message={getMessageInError(passwordCreate.error)}
                  closable
                  showIcon
                />
              </AntdForm.Item>
            )}

            <AntdForm.Item name="email" className={styles.email} rules={[validationRules]}>
              <Input size="large" placeholder="Электронная почта" prefix={<MailOutlined />} />
            </AntdForm.Item>
            <AntdForm.Item shouldUpdate>
              {({ getFieldsValue, getFieldsError }) => {
                const { email: loginValue } = getFieldsValue();
                const fieldsError = getFieldsError();

                return (
                  <Button
                    size="large"
                    type="primary"
                    block
                    loading={passwordCreate.loading}
                    htmlType="submit"
                    disabled={!loginValue || fieldsError.some((item) => item.errors.length)}
                  >
                    Отправить
                  </Button>
                );
              }}
            </AntdForm.Item>
            <AntdForm.Item className="text-centered">
              <NavLink to="/sign-in">Вернуться для входа в систему</NavLink>
            </AntdForm.Item>
          </>
        )}
        {sendSuccess && <SendSuccess />}
      </AntdForm>
    </Wrapper>
  );
}
