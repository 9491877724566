import React, {
  Key,
  useCallback, useEffect, useMemo, useRef,
} from 'react';
import { ActionType, ProColumns, RequestData } from '@ant-design/pro-components';
import {
  App, Button, FormInstance, MenuProps, Space,
} from 'antd';
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import { SortOrder } from 'antd/es/table/interface';
import { ParamsType } from '@ant-design/pro-provider';
import {
  DeleteOutlined, DownOutlined, EditOutlined, PlusCircleOutlined,
} from '@ant-design/icons';
import Dropdown from 'antd/es/dropdown/dropdown';
import dayjs from 'dayjs';
import clsx from 'clsx';
import Table, { defaultPagingResponse } from '../../../Common/Table';
import {
  createDefaultHiddenColumns, getSorterParams, queryFilterParams,
} from '../../../../utils';
import { useMessageError } from '../../../../hooks/common';
import { useTrainingDelete, useTrainingsGet, Training } from '../../../../hooks/api/trainings';
import ContentThumbnailAndName from '../../../Common/ContentThumbnailAndName';
import { useSimpleModal } from '../../../Common/Modal/Simple';
import StatusIndicator from '../../../Common/StatusIndicator';
import {
  DurationEnum, EquipmentEnum, LevelEnum, PlaceEnum, TrainingCategoryEnum, TrainingTargetEnum,
} from '../../../../enums/trainings';
import { AvailabilityEnum, ForCatalogAPIEnum, ForCatalogEnum } from '../../../../enums';
import SelectContentStatus from '../../../Common/Selects/SelectStatus/SelectContentStatus';
import SelectEquipmentMulti from '../../../Common/Selects/SelectEquipmentMulti';
import SelectTargetMulti from '../../../Common/Selects/SelectTargetMulti';
import { dateFormat } from '../../../../contstant';

interface TableTrainings {
  /** PROP - Set permanent filters */
  params?: Record<string, string>;
  /** PROP - Set temporary filters */
  defaultParams?: Record<string, string>;
  selectedRows?: number[];
  onRowSelection?: ((selectedRows: number[]) => void) | undefined;
  inModal?: boolean;
}

function TrainingsTable(props: TableTrainings): React.JSX.Element {
  const {
    params, defaultParams, selectedRows, onRowSelection, inModal,
  } = props;
  const { message } = App.useApp();
  const { open, contextHolder } = useSimpleModal();

  const navigate = useNavigate();
  const trainingsGet = useTrainingsGet();
  const trainingDelete = useTrainingDelete();
  const formRef = useRef<FormInstance>();
  const actionRef = useRef<ActionType>();

  const [searchParams, setSearchParams] = useSearchParams();

  const menuItems: MenuProps['items'] = [
    {
      key: '1',
      label: <NavLink to="/trainings/create/house" className="link-no-underline">Дом</NavLink>,
      className: 'create-house',
    },
    {
      key: '2',
      label: <NavLink to="/trainings/create/gym" className="link-no-underline">Зал</NavLink>,
      className: 'create-gym',
    },
  ];

  const toolBarRender = useCallback(() => [
    <Dropdown
      trigger={['click']}
      key="add-dropdown-button"
      menu={{ items: menuItems }}
    >
      <Button
        key="add-btn"
        id="add-btn"
        type="primary"
        icon={<PlusCircleOutlined />}
      >
        <Space>
          Добавить
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>,
  ], []);

  /** Table request: */
  const tableRequest = async (
    { current, pageSize, ...args }: Record<string, string>
      & { pageSize?: number | undefined; current?: number | undefined; keyword?: string | undefined; },
    sorter: Record<string, SortOrder>,
  ): Promise<RequestData<Training>> => {
    const newParams = queryFilterParams({
      page: current ? `${current}` : '1',
      pageSize: pageSize ? `${pageSize}` : '10',
      ...args,
      ...getSorterParams(sorter),
    });

    setSearchParams(queryFilterParams({ ...args, ...getSorterParams(sorter) }), { replace: true });

    return trainingsGet.fetch({
      ...defaultParams,
      ...newParams,
      ...params,
      forCatalog: newParams?.forCatalog ? ForCatalogAPIEnum[newParams.forCatalog as 'yes' | 'no'] : undefined,
      equipment: newParams?.equipment?.split(',') || undefined,
      target: newParams?.target?.split(',') || undefined,
    }).then((data) => {
      if (data) {
        const { data: trainings, meta } = data;

        return ({ data: trainings || [], success: true, total: meta?.totalItems || 0 });
      }

      return defaultPagingResponse;
    });
  };

  const beforeSearchSubmit = (beforeSubmitParams: Partial<ParamsType>) => {
    const newParams = queryFilterParams({
      ...beforeSubmitParams,
      _timestamp: '',
      search: searchParams.get('search') || '',
    });

    setSearchParams({ ...defaultParams, ...newParams, ...params }, { replace: true });

    return { ...defaultParams, ...newParams, ...params };
  };

  useMessageError([trainingsGet, trainingDelete]);

  const handleDeleteClick = (e: React.MouseEvent<HTMLElement>, row: Training) => {
    e.preventDefault();
    e.stopPropagation();
    open({
      title: 'Удалить тренировку?',
      content: 'Вы уверены, что хотите навсегда удалить эту тренировку?',
      cancelText: 'Отмена',
      centered: true,
      okText: 'Удалить',
      okButtonProps: {
        type: 'primary',
        danger: true,
      },
      onOk: () => trainingDelete.fetch(row.id),
      maskClosable: true,
    });
  };

  useEffect(() => {
    if (trainingDelete.response?.status === 204 || trainingDelete.data) {
      message.success('Тренировка успешно удалена.');
      actionRef.current?.reload();
    }
  }, [trainingDelete.response]);

  const columns: ProColumns<Training>[] = [
    {
      title: 'Название',
      dataIndex: 'nameRU',
      sorter: true,
      hideInSearch: true,
      hideInForm: true,
      render: (_, row) => (
        <ContentThumbnailAndName imgSrc={row.thumbnail} name={row.nameRU} />
      ),
    },
    {
      order: inModal ? 20 : 7,
      title: 'Уровень',
      dataIndex: 'level',
      sorter: true,
      valueEnum: LevelEnum,
      fieldProps: defaultParams?.level ? { defaultValue: defaultParams?.level, allowClear: false } : undefined,
    },
    {
      order: 10,
      title: 'Доступность',
      dataIndex: 'availability',
      sorter: true,
      valueEnum: AvailabilityEnum,
    },
    {
      order: 9,
      title: 'Статус',
      dataIndex: 'contentState',
      hideInSearch: inModal,
      sorter: true,
      renderFormItem: (_, { defaultRender, ...config }) => (<SelectContentStatus {...config} />),
      renderText: (contentState) => <StatusIndicator status={contentState} />,
    },
    {
      order: 8,
      title: 'Отображение в каталоге',
      dataIndex: 'forCatalog',
      valueEnum: ForCatalogEnum,
      renderText: (forCatalog) => (forCatalog ? 'Да' : 'Нет'),
      sorter: false,
    },
    {
      order: inModal ? 19 : 6,
      title: 'Место',
      dataIndex: 'place',
      sorter: true,
      valueEnum: PlaceEnum,
      fieldProps: defaultParams?.place ? { defaultValue: defaultParams.place, allowClear: false } : undefined,
    },
    {
      order: 5,
      title: 'Тип', /** Training category */
      dataIndex: 'category',
      sorter: true,
      valueEnum: TrainingCategoryEnum,
    },
    {
      order: 4,
      title: 'Целевая область',
      dataIndex: 'target',
      sorter: true,
      valueEnum: TrainingTargetEnum,
      renderFormItem: (_, { defaultRender, ...config }) => (
        <SelectTargetMulti
          {...config}
          value={Array.isArray(config.value) ? config.value : config.value?.split(',')}
        />
      ),
    },
    {
      order: 3,
      title: 'Продолжительность',
      dataIndex: 'duration',
      sorter: true,
      renderText: (duration) => (duration ? `${duration} мин` : ''),
      valueEnum: DurationEnum,
    },
    {
      order: 2,
      title: 'Инвентарь',
      dataIndex: 'equipment',
      sorter: true,
      // renderText: (equipment: EquipmentType[]) => (equipment || []).map((item) => EquipmentEnum[item]).join(', '),
      valueEnum: EquipmentEnum, // generates not only text for the table but also options for the filter
      renderFormItem: (_, { defaultRender, ...config }) => (
        <SelectEquipmentMulti
          {...config}
          value={Array.isArray(config.value) ? config.value || [] : config.value?.split(',') || []}
        />
      ),
    },
    {
      order: 1,
      title: 'Дата создания',
      dataIndex: 'createdAt',
      sorter: true,
      renderText: (createdAt) => (createdAt ? dayjs(createdAt).format(dateFormat) : ''),
      hideInSearch: true,
    },
    {
      order: 0,
      title: 'Дата изменения',
      dataIndex: 'updatedAt',
      hideInTable: inModal,
      hideInForm: inModal,
      sorter: true,
      renderText: (updatedAt) => (updatedAt ? dayjs(updatedAt).format(dateFormat) : ''),
      hideInSearch: true,
    },
    {
      key: 'actions',
      width: 100,
      title: 'Действия',
      dataIndex: 'actions',
      sorter: false,
      hideInSearch: true,
      hideInSetting: true,
      render: (_, row) => (
        <>
          <Button
            size="middle"
            type="text"
            icon={<EditOutlined style={{ fontSize: 18 }} />}
            onClick={() => navigate(`/trainings/${row.id}`)}
            title="Редактировать"
          />
          <Button
            size="middle"
            type="text"
            icon={<DeleteOutlined style={{ fontSize: 18 }} />}
            onClick={(e) => handleDeleteClick(e, row)}
            title="Удалить"
          />
        </>
      ),
    },
  ];

  const defaultHiddenColumns = useMemo(() => createDefaultHiddenColumns(
    ['createdAt', 'updatedAt', 'level', 'category', 'target', 'duration', 'equipment'],
  ), []);

  const defaultHiddenColumnsInModal = useMemo(() => createDefaultHiddenColumns(
    ['createdAt', 'updatedAt', 'category', 'target', 'forCatalog', 'duration', 'equipment', 'contentState', 'actions'],
  ), []);

  const onRowChange = useCallback((selectedRowKeys: Key[]) => {
    if (onRowSelection) {
      onRowSelection(selectedRowKeys as number[]);
    }
  }, [onRowSelection]);

  const rowSelection = {
    onChange: onRowChange,
    selectedRowKeys: selectedRows,
    alwaysShowAlert: false,
    preserveSelectedRowKeys: true,
  };

  return (
    <>
      {contextHolder}
      <Table<Training>
        withCustomSearch
        rowKey="id"
        formRef={formRef}
        actionRef={actionRef}
        headerTitle="Список тренировок"
        toolBarRender={!inModal ? toolBarRender : undefined}
        columns={columns}
        request={tableRequest}
        beforeSearchSubmit={beforeSearchSubmit}
        showSorterTooltip={false}
        pagination={params?.pageSize ? {
          defaultPageSize: +params.pageSize,
          showSizeChanger: false,
          size: 'default',
        } : undefined}
        columnsState={inModal ? {
          defaultValue: defaultHiddenColumnsInModal,
        } : {
          persistenceKey: 'pro-table-trainings',
          persistenceType: 'localStorage',
          defaultValue: defaultHiddenColumns,
        }}
        rowSelection={!!onRowSelection && rowSelection}
        rowClassName={clsx({ 'cursor-pointer': !inModal })}
        onRow={(record) => ({
          onClick: !inModal ? () => navigate(`/trainings/${record.id}`) : undefined,
        })}
      />
    </>
  );
}

TrainingsTable.defaultProps = {
  params: {},
  defaultParams: {},
  selectedRows: [],
  onRowSelection: undefined,
  inModal: false,
};

export default TrainingsTable;
