import React from 'react';
import { config } from '@triare/auth-redux';
import Layout from '../../../components/Layout';
import Content from '../../../components/Pages/Admins/Edit';

const { displayName } = config;

function EditAdmin(): React.JSX.Element {
  document.title = `${displayName}: Редактировать администратора`;

  return (
    <Layout>
      <Content />
    </Layout>
  );
}

export default EditAdmin;
