import React from 'react';
import { Button, ButtonProps } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import clsx from 'clsx';

import styles from './index.module.scss';

interface DualButtonProps {
  onClickTop: (e: React.MouseEvent<HTMLElement>) => void;
  onClickBottom: (e: React.MouseEvent<HTMLElement>) => void;
  disabledTop?: boolean;
  disabledBottom?: boolean;
  type?: ButtonProps['type']
  iconTop?: React.ReactNode;
  iconBottom?: React.ReactNode;
}

function DualButton({
  onClickTop, onClickBottom, type, disabledTop, disabledBottom, iconTop, iconBottom,
}: DualButtonProps) {
  return (
    <div className={styles.dualButton}>
      <Button
        type={type}
        onClick={onClickTop}
        disabled={disabledTop}
        icon={iconTop}
        className={clsx(styles.button, styles.first)}
      />
      <Button
        type={type}
        onClick={onClickBottom}
        disabled={disabledBottom}
        icon={iconBottom}
        className={clsx(styles.button, styles.second)}
      />
    </div>
  );
}

DualButton.defaultProps = {
  type: 'default',
  disabledTop: false,
  disabledBottom: false,
  iconTop: <UpOutlined style={{ fontSize: 10 }} />,
  iconBottom: <DownOutlined style={{ fontSize: 10 }} />,
};

export default DualButton;
