import React, { useState } from 'react';
import { Image } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import clsx from 'clsx';
import { getFullFileUrl } from '../../../../../../../Common/Uploaders/ImageUpload';
import EmptyImage from '../../Empty';
import { MediaDataKey } from '../../index';
import UploadUserMediaModal, { MediaMeasureType } from '../UploadMediaModal';
import { Participant } from '../../../../../../../../hooks/api/user';

import styles from '../index.module.scss';

export interface UserMarathonThumbnailProps {
  media?: string;
  editProps?: {
    withEdit: boolean;
    dataKey: MediaDataKey | undefined;
    measure: MediaMeasureType;
  };
  selectedMarathon?: Participant;
}

export default function UserMarathonThumbnail({
  media = undefined, editProps, selectedMarathon,
}: UserMarathonThumbnailProps) {
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpen = (open: boolean) => {
    setModalOpen(open);
  };

  if (!editProps?.withEdit) {
    return (
      media ? <Image src={getFullFileUrl(media)} preview={false} /> : <EmptyImage />
    );
  }

  /** If withEdit then render edit icon and modal for edit image */
  return (
    <>
      <UploadUserMediaModal
        variant="image"
        media={media}
        dataKey={editProps.dataKey}
        open={modalOpen}
        handleOpen={handleOpen}
        measure={editProps.measure}
        participantId={selectedMarathon?.id || ''}
      />
      <div className={styles.mediaThumbnailContainer}>
        <EditButton onClick={() => handleOpen(true)} />

        {media ? <Image src={getFullFileUrl(media)} preview={false} /> : <EmptyImage />}
      </div>
    </>
  );
}

export function EditButton({ onClick, className }: { onClick: () => void, className?: string}) {
  return (
    <span
      role="button"
      tabIndex={-1}
      aria-label="edit-marathon-img"
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        onClick();
      }}
      className={clsx(styles.editButton, className)}
    >
      <EditOutlined className={styles.icon} style={{ stroke: 'white', strokeWidth: 2 }} />
    </span>
  );
}
