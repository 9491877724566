import {
  App, Col, Modal, ModalProps, Typography, UploadFile,
} from 'antd';
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import ImageUpload, { generateFileObjFromUrl } from '../../../../../../../Common/Uploaders/ImageUpload';
import {
  UserMarathonMeasurementsUpdate, useUserMarathonMeasurementsUpdate,
} from '../../../../../../../../hooks/api/user';
import { useUserProfileProvider } from '../../../../../../../../context/userProfile';
import { useMessageError } from '../../../../../../../../hooks/common';
import VideoUpload from '../../../../../../../Common/Uploaders/VideoUpload';
import { useGlobalLoadingProvider } from '../../../../../../../../context/globalLoading';
import { useSimpleModal } from '../../../../../../../Common/Modal/Simple';
import { asyncDelay } from '../../../../../../../../utils';

import styles from '../index.module.scss';

interface UploadUserImagesProps extends ModalProps {
  variant: 'image' | 'video';

  media: string | undefined;
  dataKey: string | undefined;
  handleOpen: (open: boolean) => void;
  measure: MediaMeasureType;
  participantId: string;
}

export type MediaMeasureType = 'before' | 'after';

export default function UploadUserMediaModal({
  variant,
  media, participantId, dataKey, measure,
  open, handleOpen, ...rest
}: UploadUserImagesProps) {
  const { open: openConfirm, contextHolder, modal } = useSimpleModal();
  const { message } = App.useApp();
  const { id: userId = '' } = useParams<{ id: string; }>();
  const userMarathonMeasureUpdate = useUserMarathonMeasurementsUpdate();
  const { userById } = useUserProfileProvider();
  const { globalLoading, handleGlobalLoading } = useGlobalLoadingProvider();

  const [mediaFiles, setMediaFiles] = useState<UploadFile[]>([]);

  const [abortTrigger, setAbortTrigger] = useState(0);

  const handleSubmit = () => {
    const fileUrl = mediaFiles[0]?.url;

    /** If file url dataKey and participantId is set, and file url not the same as prev url then send data  */
    if ((fileUrl && dataKey && participantId) && (media !== fileUrl)) {
      const preparedData: UserMarathonMeasurementsUpdate = {
        participantId,
      };

      if (measure === 'before') {
        preparedData.measureBefore = { [dataKey]: fileUrl };
      }
      if (measure === 'after') {
        preparedData.measureAfter = { [dataKey]: fileUrl };
      }

      userMarathonMeasureUpdate.fetch(preparedData, `${userId}/as-participant`)
        .then((res) => {
          if (res?.id) {
            message.success(
              variant === 'image' ? 'Фото успешно изменено!' : 'Видео успешно изменено!',
            );
            userById.fetch(undefined, userId).then(() => {
              handleOpen(false);
            });
          }
        });
    } else {
      message.error('Файл не загружен либо не изменен!');
    }
  };

  /** confirmModalInstance. Used to destroy modal when video loading finished */
  const [confirmModal, setConfirmModal] = useState<ReturnType<typeof Modal.confirm> | null>(null);

  const handleCancel = () => {
    if (globalLoading) {
      const confirmInstance = openConfirm({
        title: 'Остановить загрузку видео?',
        content: 'Вы уверены, что хотите остановить загрузку видео?',
        cancelText: 'Отмена',
        centered: true,
        okText: 'Остановить',
        okButtonProps: {
          type: 'primary',
          danger: true,
          onClick: async (event) => {
            event.stopPropagation();
            setAbortTrigger(Date.now());
            await asyncDelay(100);
            handleClose();
          },
        },
        // There's needed event.stopPropagation, so onOk event moved into onClick of buttonProps.
        // onOk: async () => {},
        onCancel: () => setConfirmModal(null),
        maskClosable: true,
        // wrapProps: { onClick: (e: MouseEvent) => e.stopPropagation() },
      });

      setConfirmModal(confirmInstance);
    } else {
      handleClose();
    }
  };

  const handleClose = () => {
    handleOpen(false);
    handleGlobalLoading(false);
    setAbortTrigger(0);

    handleInitialImage();
  };

  useEffect(() => {
    if (!globalLoading && confirmModal) {
      confirmModal?.destroy();
    }
  }, [globalLoading]);

  /** Handle initial state */
  const handleInitialImage = () => {
    if (media) {
      const banner = generateFileObjFromUrl(media);

      setMediaFiles([banner]);
    } else {
      setMediaFiles([]);
    }
  };

  useEffect(() => {
    handleInitialImage();
  }, [media]);

  useMessageError([userMarathonMeasureUpdate]);

  return (
    <>
      {contextHolder}
      <Modal
        open={open}
        title="Изменить медиа"
        cancelText="Отмена"
        centered
        okText="Сохранить"
        okButtonProps={{
          type: 'primary',
          loading: userMarathonMeasureUpdate.loading || userById.loading || globalLoading,
        }}
        cancelButtonProps={{ disabled: userMarathonMeasureUpdate.loading }}
        width={510}
        onOk={() => handleSubmit()}
        onCancel={handleCancel}
        maskClosable={!userMarathonMeasureUpdate.loading}
        wrapProps={{ onClick: (e: MouseEvent) => e.stopPropagation() }} // Needs for stopping propagation on view modal
        destroyOnClose
        {...rest}
      >
        <Col span={24}>
          <div className={clsx('image-banner-block', styles.bannerBlock)}>
            {variant === 'image' ? (
              <>
                <ImageUpload
                  required={false}
                  name="imageUrl"
                  multiple={false}
                  allowToDelete={false}
                  fileList={mediaFiles}
                  setFileList={setMediaFiles}
                  cropperProps={{ aspect: 3 / 4, aspectSlider: true }}
                  H220
                  onLoadingChange={(isLoading) => handleGlobalLoading(isLoading)}
                />
                <div>
                  <Typography.Paragraph className="upload-paragraph">
                    Фото марафона
                  </Typography.Paragraph>
                  <Typography.Paragraph type="secondary" className="upload-text">
                    Требования к файлу: JPG, PNG. Рекомендованное соотношение сторон: 3:4.
                  </Typography.Paragraph>
                </div>
              </>
            ) : (
              <>
                <VideoUpload
                  name="videoUrl"
                  multiple={false}
                  allowToDelete={false}
                  fileList={mediaFiles}
                  setFileList={setMediaFiles}
                  onLoadingChange={handleGlobalLoading}
                  H220
                  abortTrigger={abortTrigger}
                />
                <div>
                  <Typography.Paragraph className="upload-paragraph">
                    Видео марафона
                  </Typography.Paragraph>
                  <Typography.Paragraph type="secondary" className="upload-text">
                    Требования к файлу: MP4. Рекомендованное соотношение сторон: 3:4.
                  </Typography.Paragraph>
                </div>
              </>
            )}
          </div>
        </Col>
      </Modal>
    </>
  );
}
