import React, { useEffect } from 'react';
import { Collapse, CollapseProps, Empty } from 'antd';
import dayjs from 'dayjs';
import { IUserPayments, UserExtended } from '../../../../../hooks/api/user';
import { dateFormatMarathon } from '../../../../../contstant';

import styles from './index.module.scss';

function RecursiveDisplay({ data }: {data: IUserPayments['data']}) {
  if (typeof data === 'object' && data !== null) {
    return (
      <>
        {Object.entries(data).map(([key, value]) => (
          <div key={key} className={styles.item}>
            <div>{`${key}:`}</div>
            <div>
              {typeof value === 'object' && value !== null ? (
                <RecursiveDisplay data={value} />
              ) : (
                value?.toString()
              )}
            </div>
          </div>
        ))}
      </>
    );
  }

  return <div />;
}

function PurchaseList({ data }: { data: UserExtended }) {
  const [state, setState] = React.useState<CollapseProps['items']>([]);

  useEffect(() => {
    let tempData: CollapseProps['items'] = [];

    data.payments.forEach((el, index) => {
      tempData = tempData?.concat({
        key: `${el.createdAt + index}`,
        label: dayjs(el.createdAt).format(dateFormatMarathon),
        children: <RecursiveDisplay data={el.data} />,
      });
    });

    setState(tempData);
  }, [data]);

  if (!data.payments.length) {
    return (
      <Empty
        className={styles.empty}
        imageStyle={{ width: '185px', height: '120px', margin: '0 auto' }}
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description="Нет покупок для отображения"
      />
    );
  }

  return (
    <Collapse items={state} />
  );
}

export default PurchaseList;
