import React from 'react';
import { Button, Modal } from 'antd';
import {
  useLocation, useNavigate, useSearchParams,
} from 'react-router-dom';
import RecipesTable from '../List';

interface TrainingsListModal {
  isOpenModal: boolean;
  isLoading: boolean;
  handleAddRecipes: () => Promise<void>;
  handleModal: (payload: boolean) => void;
  selectedTrainingsIds: (number | string)[];
  handleSelectedRows: (selected: (number | string)[]) => void;
  defaultParams?: Record<string, string>;
}

function RecipesListModal({
  isOpenModal,
  isLoading,
  handleAddRecipes,
  selectedTrainingsIds,
  handleModal,
  handleSelectedRows,
  defaultParams,
}: TrainingsListModal) {
  const [_, setSearchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Modal
      open={isOpenModal}
      title="Выбрать рецепт"
      cancelText="Отмена"
      centered
      destroyOnClose
      okText="Добавить"
      className="modal-with-table"
      okButtonProps={{ type: 'primary', loading: isLoading, disabled: !selectedTrainingsIds.length }}
      width={1200}
      onOk={async () => {
        await handleAddRecipes();
        handleModal(false);
      }}
      onCancel={() => {
        if (!isLoading) {
          handleModal(false);
        }
      }}
      afterClose={() => setSearchParams({})}
      maskClosable={!isLoading}
      footer={(__, { OkBtn, CancelBtn }) => [
        <CancelBtn key="cancel-btn" />,
        <Button
          key="add-btn"
          id="add-btn"
          type="default"
          style={{ marginInlineStart: '8px' }}
          onClick={() => navigate(`/recipes/create?from=${location.pathname}`)}
        >
          Создать новый
        </Button>,
        <OkBtn key="ok-btn" />,
      ]}
    >
      <RecipesTable
        params={{ pageSize: '5', contentState: 'published' }}
        defaultParams={{ ...defaultParams }}
        inModal
        selectedRows={selectedTrainingsIds}
        onRowSelection={handleSelectedRows}
      />
    </Modal>
  );
}

RecipesListModal.defaultProps = {
  defaultParams: {},
};

export default RecipesListModal;
