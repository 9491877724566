import React from 'react';
import { SelectProps } from 'antd/lib/select';
import { Select } from 'antd';
import { UserSubscription } from '../../../../hooks/api/user';
import { Option } from '../../../../types';

function SelectLevel({ ...props }: SelectProps<UserSubscription, Option>) {
  return (
    <Select {...props} />
  );
}

enum LevelEnum {
  'Новичок' = 'low',
  'Средний' = 'medium',
  'Высокий' = 'high',
}

SelectLevel.defaultProps = {
  options: Object.entries(LevelEnum).map(([key, value]) => ({ label: key, value })),
  allowClear: true,
  placeholder: 'Выберите',
};

export default SelectLevel;
