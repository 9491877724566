import { AxiosProgressEvent } from 'axios';
import {
  DefaultFetchError, FetchCreate, useFetchCreate,
} from '../fetch';

interface UploadMediaSuccess {
  url: string;
}

export const useUploadImage = (uploadUrl = 'common/upload-image', authorization = true): FetchCreate<
  UploadMediaSuccess,
  DefaultFetchError,
  FormData // { keepName: 'yes' | 'no' }
> => useFetchCreate(
  uploadUrl,
  {
    config: {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
    authorization,
  },
);

export const useUploadMedia = (
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void,
  abortController?: AbortController,
  uploadUrl = 'common/upload-audio',
): FetchCreate<
  UploadMediaSuccess,
  DefaultFetchError,
  FormData // { keepName: 'yes' | 'no' }
> => useFetchCreate(
  uploadUrl,
  {
    config: {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      signal: abortController?.signal,
    },
  },
  onUploadProgress,
);

export default {
  useUploadImage,
};
