import {
  DefaultFetchError,
  FetchGet,
  FetchGetId,
  PagingDataResponse,
  PagingParams,
  useFetchGet, useFetchGetId, useFetchDelete, FetchCreate, useFetchCreate, FetchUpdate, useFetchUpdate,
} from '../fetch';
import {
  AvailabilityType, ContentStateType, DefaultOrderByColumnProps, KindType,
} from '../../types';
import { RuLangType, UaLangType } from '../../enums/language';

export type LectureCategoryType = 'trainings' | 'nutrition' | 'theory' | 'psychology';

export interface Lecture {
  id: string;
  nameUA: string;
  nameRU: string;
  imageUrl: string;
  audioUrlRU: string;
  audioUrlUA: string;
  durationRU?: number;
  durationUA?: number;
  availability: AvailabilityType;
  contentState: ContentStateType;
  category: LectureCategoryType;
  descriptionRU?: string;
  descriptionUA?: string;
  contentUA: string;
  contentRU: string;
  forCatalog: boolean;
  createdAt: string;
  updatedAt: string;
  kind: KindType;
}

export interface LecturesGetParams extends PagingParams {
  search?: string;
  lang?: UaLangType | RuLangType;
  availability?: AvailabilityType;
  contentState?: ContentStateType; // status
  forCatalog?: string;
  orderByColumn?: DefaultOrderByColumnProps | 'nameRU' | 'availability' | 'contentState';
}

export function useLecturesGet<D = PagingDataResponse<Lecture>,
  DD = D>(decorateData?: (data: D) => DD): FetchGet<D, LecturesGetParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, LecturesGetParams, DD>(
    'lectures',
    {
      decorateData,
      autoStart: false,
      startStateLoading: false,
    },
  );
}

export const useLectureId = (id?: string): FetchGetId<Lecture> => useFetchGetId(
  'lectures',
  id,
  { autoStart: false, startStateLoading: false },
);

export const useLectureDelete = () => useFetchDelete<undefined, DefaultFetchError, string>('lectures');

export interface CreateLecturePayload {
  nameUA: string;
  nameRU: string;
  descriptionUA?: string;
  descriptionRU?: string;
  contentRU: string;
  contentUA: string;
  imageUrl?: string;
  audioUrlRU?: string;
  audioUrlUA?: string;
  durationRU?: number;
  durationUA?: number;
  availability: AvailabilityType;
  contentState?: ContentStateType;
  forCatalog: boolean;
}

export interface UpdateLecturePayload extends CreateLecturePayload {
  id?: string;
}

export const useLectureCreate = (): FetchCreate<Lecture, DefaultFetchError, CreateLecturePayload> => (
  useFetchCreate('lectures')
);

export const useLectureUpdate = (id?: string): FetchUpdate<
  Lecture, DefaultFetchError, UpdateLecturePayload
> => (
  useFetchUpdate('lectures', id)
);
