import React, { useCallback, useRef } from 'react';
import { ActionType, ProColumns, RequestData } from '@ant-design/pro-components';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { App, Button, Space } from 'antd';
import {
  DeleteOutlined, EditOutlined, PlusCircleOutlined, RedoOutlined,
} from '@ant-design/icons';
import Table, { defaultPagingResponse } from '../../../Common/Table';
import StatusIndicator from '../../../Common/StatusIndicator';
import { useMessageError } from '../../../../hooks/common';
import SelectStatus from '../../../Common/Selects/SelectStatus';
import { dateFormat } from '../../../../contstant';
import {
  Admin, TableAdminRow, useAdminDelete, useAdminReInvite, useTableAdminsGet,
} from '../../../../hooks/api/admins';
import { useSimpleModal } from '../../../Common/Modal/Simple';
import { useAuthState } from '../../../../store/auth';

function AdminsTable(): React.JSX.Element {
  const navigate = useNavigate();
  const adminsGet = useTableAdminsGet();
  const adminDelete = useAdminDelete();
  const adminReInvite = useAdminReInvite();
  const { open, contextHolder } = useSimpleModal();
  const { message } = App.useApp();
  const actionRef = useRef<ActionType>();
  const { user } = useAuthState();

  const tableRequest = async (): Promise<RequestData<TableAdminRow>> => adminsGet.fetch().then((data) => {
    if (data) {
      return ({ data: data.admins || [], success: true, total: data.admins.length });
    }

    return defaultPagingResponse;
  });

  useMessageError([adminsGet, adminDelete, adminReInvite]);

  const handleDeleteClick = (e: React.MouseEvent<HTMLElement>, admin: Admin) => {
    e.preventDefault();
    e.stopPropagation();

    if (admin.email === user?.email) {
      open({
        title: 'Удаление невозможно',
        content: 'Вы не можете удалить свой аккаут.',
        centered: true,
        okText: 'Понятно',
        cancelButtonProps: { style: { display: 'none' } },
        okButtonProps: { type: 'default' },
        maskClosable: true,
      });

      return;
    }

    open({
      title: 'Удалить админстратора?',
      content: 'Вы уверены, что хотите навсегда удалить этого администратора?',
      cancelText: 'Отмена',
      centered: true,
      okText: 'Удалить',
      okButtonProps: {
        type: 'primary',
        danger: true,
      },
      onOk: () => adminDelete.fetch(admin.id).then(() => {
        message.success('Администратор успешно удален');
        actionRef.current?.reload();
      }),
      maskClosable: true,
    });
  };

  const handleResendClick = (e: React.MouseEvent<HTMLElement>, admin: Admin) => {
    e.preventDefault();
    e.stopPropagation();

    adminReInvite.fetch(undefined, admin.id).then(() => {
      message.success('Повторное приглашение отправлено');
    });
  };

  const toolBarRender = useCallback(() => [
    <Button
      key="add-btn"
      id="add-btn"
      type="primary"
      icon={<PlusCircleOutlined />}
      onClick={() => navigate('/admins/create')}
    >
      <Space>
        Добавить
      </Space>
    </Button>,
  ], []);

  const columns: ProColumns<TableAdminRow>[] = [
    {
      title: 'Имя',
      dataIndex: 'name',
      sorter: false,
      hideInSearch: true,
    },
    {
      title: 'Эл. почта',
      dataIndex: 'email',
      sorter: false,
      hideInSearch: true,
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      sorter: false,
      renderFormItem: (_, { defaultRender, ...config }) => <SelectStatus {...config} />,
      renderText: (status) => <StatusIndicator status={status} />,
    },
    {
      title: 'Дата создания',
      dataIndex: 'createdAt',
      sorter: false,
      renderText: (createdAt) => (createdAt ? dayjs(createdAt).format(dateFormat) : ''),
      hideInSearch: true,
    },
    {
      title: 'Дата изменения',
      dataIndex: 'updatedAt',
      sorter: false,
      renderText: (updatedAt) => (updatedAt ? dayjs(updatedAt).format(dateFormat) : ''),
      hideInSearch: true,
    },
    {
      key: 'actions',
      width: 130,
      title: 'Действия',
      dataIndex: 'actions',
      sorter: false,
      hideInSearch: true,
      hideInSetting: true,
      render: (_, row) => (
        <>
          <Button
            size="middle"
            type="text"
            icon={<EditOutlined style={{ fontSize: 18 }} />}
            onClick={() => navigate(`/admins/${row.id}`)}
            title="Редактировать"
          />
          <Button
            size="middle"
            type="text"
            icon={<DeleteOutlined style={{ fontSize: 18 }} />}
            onClick={(e) => handleDeleteClick(e, row)}
            title="Удалить"
          />
          {row.status === 'pending' ? (
            <Button
              size="middle"
              type="text"
              icon={<RedoOutlined style={{ fontSize: 18 }} />}
              onClick={(e) => handleResendClick(e, row)}
              title="Resend"
            />
          ) : null}
        </>
      ),
    },
  ];

  return (
    <>
      {contextHolder}
      <Table<TableAdminRow>
        search={false}
        actionRef={actionRef}
        style={{ paddingBottom: '24px', background: '#fff', borderRadius: '6px' }}
        headerTitle="Список администраторов"
        columns={columns}
        request={tableRequest}
        toolBarRender={toolBarRender}
        pagination={false}
        showSorterTooltip={false}
        rowClassName="cursor-pointer"
        onRow={(record) => ({
          onClick: () => navigate(`/admins/${record.id}`),
        })}
        columnsState={{
          persistenceKey: 'pro-table-admins',
          persistenceType: 'localStorage',
        }}
      />
    </>
  );
}

export default AdminsTable;
