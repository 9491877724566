import React from 'react';
import Badge, { BadgeProps } from 'antd/es/badge';
import { UserStatus } from '../../../hooks/api/user';
import { UserStatusEnum, ContentStateEnum } from '../../../enums';
import { ContentStateType } from '../../../types';
import { MarathonStatusEnum } from '../../../enums/marathon';
import { MarathonStatusType } from '../../../hooks/api/marathons';

const StatusIndicatorCombined = {
  ...UserStatusEnum,
  ...ContentStateEnum,
  ...MarathonStatusEnum,
};

interface StatusIndicator {
  status: UserStatus | ContentStateType | MarathonStatusType;
}

export const getBadgeColorProps = (status: StatusIndicator['status']): Partial<BadgeProps> => {
  switch (status) {
    /** User */
    case 'active': return { status: 'success' };
    case 'pending': return { status: 'warning' };
    case 'inactive': return { status: 'default' };
    /** Training */
    case 'published': return { status: 'success' };
    case 'draft': return { status: 'warning' };
    /** Marathon */
    case 'new': return { status: 'warning' };
    case 'upcoming': return { color: 'magenta' };
    case 'sales': return { status: 'processing' };
    // case 'active': return { status: 'success' };
    case 'finished': return { color: 'geekblue' };
    case 'closed': return { status: 'default' };

    default: return { status: 'default' };
  }
};

function StatusIndicator({ status }: StatusIndicator) {
  return (
    <Badge {...getBadgeColorProps(status)} text={StatusIndicatorCombined[status] || status} />
  );
}

export default StatusIndicator;
