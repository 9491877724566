/* eslint-disable max-len */

import React, { useCallback, useState } from 'react';
import {
  App, Button, Col, Collapse, Divider, Form, Input, InputNumber, Row, Select,
} from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import {
  MarathonIngredient,
  MarathonShoppingCategory, MarathonShoppingWeek,
} from '../../../../../hooks/api/marathons';
import WeekPicker from '../../../../Common/Pickers/WeekPicker';
import { Option } from '../../../../../types';
import { enumToOptionsArray, filterObjectProps } from '../../../../../utils';
import PdfsUpload from './PdfsUpload';
import { messages as errorMessages } from '../../../../../utils/validations';
import { MeasureMinEnum } from '../../../../../enums/recipes';
import { useGlobalLoadingProvider } from '../../../../../context/globalLoading';

import styles from './index.module.scss';

/** Initial form states */
const initialMarIngredientState: MarathonIngredient = {
  nameRU: '',
  nameUA: '',
  value: 0,
  measure: 'g',
};

const initialShoppingCategoryState: MarathonShoppingCategory = {
  id: '',
  nameUA: '',
  nameRU: '',
  items: [initialMarIngredientState],
};

export const initialMarathonShoppingWeekState = {
  id: crypto.randomUUID(),
  pdfUrlUA: '',
  pdfUrlRU: '',
  categories: [initialShoppingCategoryState],
} as MarathonShoppingWeek;

const ingredientsRules = [{ required: true, message: errorMessages.required }];
const disabledIngredientField = ['not-specified', 'by-taste'];

function MarathonFormItemsShopping() {
  const { message } = App.useApp();
  const form = useFormInstance();
  const { globalLoading } = useGlobalLoadingProvider();

  /** Collapse controls */
  const [collapseKey, setCollapseKey] = useState<string[]>([]);
  const [selectedWeekIndex, setSelectedWeekIndex] = useState<number>(0);

  const weeksWatch: MarathonShoppingWeek[] = Form.useWatch('weeks', form) || [];

  /** Copy-Select func */
  const generateCopyOptions = useCallback((categoryIndex: number): Option[] => {
    if (weeksWatch?.length) {
      const options: Option[] = weeksWatch.map((week, weekIndex) => ({
        label: `Неделя ${weekIndex + 1}`,
        value: `${weekIndex}.${categoryIndex}`,
        disabled: weekIndex === selectedWeekIndex,
      }));

      return options;
    }

    return [];
  }, [weeksWatch?.length, selectedWeekIndex]);

  const [selectCopy] = useState<TwoDotSeparatedValues | null>(null);

  /** Name path have 2 items. /WeekIndex.CategoryIndex/ */
  type TwoDotSeparatedValues = `${string}.${string}`;
  const copyRecipes = (namePath: TwoDotSeparatedValues) => {
    const namePathValues = namePath.split('.');

    /** Get value from selected day */
    const namePathWhichWeCopy = ['weeks', namePathValues[0], 'categories', namePathValues[1], 'items'];
    const copiedTrainingsValue = (form.getFieldValue(namePathWhichWeCopy) || [])
      .map((item: MarathonIngredient) => (filterObjectProps(item, ['id']))); /** Remove when before copy */

    if (!copiedTrainingsValue?.length) {
      message.warning('В выбранной неделе нет данных для этой категории!');
    }

    const namePathToBeChanged = ['weeks', selectedWeekIndex, 'categories', namePathValues[1], 'items'];
    const prevValue = form.getFieldValue(namePathToBeChanged) || [];

    /** Remove duplicates - If object have ID (created on API) then filter by id, in other case filter by name */
    const newValue = [...prevValue, ...copiedTrainingsValue]
      .filter((obj, index, self) => (
        index === self.findIndex((p) => p?.nameRU === obj?.nameRU)));

    /** Set new values */
    form.setFieldValue(namePathToBeChanged, newValue);
    if (copiedTrainingsValue?.length) {
      message.success('Контент успешно скопирован.');
    }
  };

  return (
    <Row gutter={24}>
      <Col span={24}>
        <div className="week-day-picker-container">
          <WeekPicker
            weeks={weeksWatch || []}
            selectedWeek={selectedWeekIndex}
            onClick={(weekIndex) => setSelectedWeekIndex(weekIndex)}
            label="Неделя марафона"
            disabled={globalLoading}
          />
        </div>
      </Col>

      <Col span={24}>
        <Form.List name="weeks">
          {(weeksFields) => (
            <div className={styles.fieldsWrapper}>
              {weeksFields.map((weekField, weekIndex) => (
                <div key={weekField.key} className="weekField">
                  {selectedWeekIndex === weekIndex ? (
                    <PdfsUpload
                      weekProps={{ id: weeksWatch?.[weekIndex]?.id, name: weekField.name, index: weekIndex }}
                      pdfUrlRU={weeksWatch?.[weekIndex]?.pdfUrlRU}
                      pdfUrlUA={weeksWatch?.[weekIndex]?.pdfUrlUA}
                    />
                  ) : null}

                  <Form.List name={[weekField.name, 'categories']}>
                    {(categoriesFields) => (
                      <div>
                        {selectedWeekIndex === weekIndex ? (
                          <Collapse
                            accordion
                            activeKey={collapseKey}
                            onChange={(key) => (
                              Array.isArray(key) ? setCollapseKey(key) : setCollapseKey([key])
                            )}
                            items={categoriesFields.map((categoryField, categoryIndex) => ({
                              key: categoryField.key,
                              label: weeksWatch?.[weekIndex]?.categories?.[categoryIndex]?.nameRU,
                              children: (
                                <Col span={24}>
                                  {/* ingredients */}
                                  <Form.List name={[categoryField.name, 'items']}>
                                    {(ingredientFields, { add, remove }) => (
                                      <div className="flex-column-wrapper">
                                        {ingredientFields.map((ingredientField) => (
                                          <div className={styles.fieldGrid} key={ingredientField.key}>
                                            <Row gutter={[12, 12]} className={styles.row}>
                                              <Col span={14}>
                                                <Form.Item
                                                  name={[ingredientField.name, 'nameRU']}
                                                  className="no-space-form-item"
                                                  label="Продукты"
                                                  rules={ingredientsRules}
                                                >
                                                  <Input placeholder="Ввведите" addonBefore="RU" />
                                                </Form.Item>
                                              </Col>
                                              <Col span={4}>
                                                <Form.Item
                                                  name={[ingredientField.name, 'value']}
                                                  label="Количество"
                                                  className="no-space-form-item"
                                                  rules={ingredientsRules}
                                                >
                                                  <InputNumber
                                                    min={0}
                                                    placeholder="Введите"
                                                    style={{ width: '100%' }}
                                                    disabled={disabledIngredientField
                                                      .includes(weeksWatch?.[weekIndex]?.categories?.[categoryIndex]
                                                        ?.items?.[ingredientField.name]?.measure)}
                                                    precision={1}
                                                    formatter={(value) => `${Math.round((value || 0) * 10) / 10}`}
                                                  />
                                                </Form.Item>
                                              </Col>
                                              <Col span={6}>
                                                <Form.Item
                                                  name={[ingredientField.name, 'measure']}
                                                  label=" "
                                                  className="no-space-form-item"
                                                >
                                                  <Select
                                                    options={enumToOptionsArray(MeasureMinEnum)}
                                                    placeholder="Не указано"
                                                    onChange={(val) => {
                                                      if (val) {
                                                        if (disabledIngredientField.includes(val || '')) {
                                                          form.setFieldValue(['weeks', weekField.name, 'categories', categoryField.name, 'items', ingredientField.name, 'value'], 0);
                                                        }
                                                      }
                                                    }}
                                                  />
                                                </Form.Item>
                                              </Col>

                                              <Col span={14}>
                                                <Form.Item name={[ingredientField.name, 'nameUA']} className="no-space-form-item" rules={ingredientsRules}>
                                                  <Input placeholder="Ввведите" addonBefore="UA" />
                                                </Form.Item>
                                              </Col>
                                              <Divider style={{ margin: '16px 0' }} />
                                            </Row>

                                            <Form.Item label=" ">
                                              <Button
                                                size="middle"
                                                type="default"
                                                icon={<DeleteOutlined style={{ fontSize: 18 }} />}
                                                onClick={() => remove(ingredientField.name)}
                                                title="Удалить"
                                              />
                                            </Form.Item>
                                          </div>
                                        ))}
                                        <Button
                                          type="default"
                                          onClick={() => add(initialMarIngredientState)}
                                          icon={<PlusOutlined />}
                                          className={styles.addBtn}
                                        >
                                          Добавить продукт
                                        </Button>
                                      </div>
                                    )}
                                  </Form.List>
                                </Col>
                              ),
                              extra: (
                                <Select
                                  options={generateCopyOptions(categoryField.name)}
                                  placeholder="Копировать из..."
                                  onClick={(e) => {
                                    /** If current panel already active, then stop propagation event, to not close the panel */
                                    if (collapseKey?.[0] === categoryField.name?.toString()) {
                                      e.stopPropagation();
                                    }
                                  }}
                                  value={selectCopy}
                                  onChange={(value) => { copyRecipes(value); }}
                                  popupMatchSelectWidth={false}
                                  style={{ minWidth: '220px' }}
                                />
                              ),
                            }))}
                          />
                        ) : null}
                      </div>
                    )}
                  </Form.List>
                </div>
              ))}
            </div>
          )}
        </Form.List>
      </Col>
    </Row>
  );
}

export default MarathonFormItemsShopping;
