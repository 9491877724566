import React, { useState } from 'react';
import {
  Alert, App, Button, Form, FormInstance, Input, Progress, Upload, UploadFile, UploadProps,
} from 'antd';
import clsx from 'clsx';
import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { RcFile } from 'antd/es/upload';
import { getMessageInError } from '../../../../hooks/fetch';
import { getBase64 } from '../../../../utils';
import { useUploadMedia } from '../../../../hooks/api/mediaUpload';
import { messages } from '../../../../utils/validations';
import { useMessageError } from '../../../../hooks/common';
import { getFullFileUrl } from '../ImageUpload';

import styles from './index.module.scss';

interface InputCodeWrapperProps extends React.PropsWithChildren {
  name: string;
  fileList: UploadFile[];
  setFileList: React.Dispatch<React.SetStateAction<UploadFile[]>>;
  required?: boolean;
  form?: FormInstance;
  accept?: string;
  className?: string;
  onDurationChange?: (duration: number) => void
}

function AudioUpload({
  form, name, fileList, setFileList, required, accept, className, onDurationChange,
}: InputCodeWrapperProps) {
  const { message } = App.useApp();

  const [percentCompleted, setPercentCompleted] = useState<number | null>(null);
  const controller = new AbortController();
  // eslint-disable-next-line max-len
  const uploader = useUploadMedia((progressEvent) => { setPercentCompleted(Math.round((progressEvent.loaded / (progressEvent?.total || 0)) * 100)); }, controller);

  useMessageError([uploader]);

  /** Handlers */
  const onRemove = (file: UploadFile) => {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();

    newFileList.splice(index, 1);
    setFileList(newFileList);
  };

  const beforeUpload = async (file: RcFile, FileList?: RcFile[]) => {
    /** Validation: */
    if (!['audio/mpeg', 'audio/m4a', 'audio/x-m4a', 'audio/mp4'].includes(file.type)) {
      message.error('Вы можете загрузить только файл в формате MP3 или M4A!');

      return false;
    }

    if (!(file.size / 1024 / 1024 < 200)) {
      message.error('Аудио должно быть меньше 200МБ!');

      return false;
    }

    if (onDurationChange) {
      const audio = new Audio();

      audio.src = URL.createObjectURL(file);
      audio.ondurationchange = () => onDurationChange(audio.duration);
    }

    /** Functionality */
    const newFile: UploadFile = {
      originFileObj: file, uid: file.uid, name: file.name, status: 'done',
    };

    newFile.preview = await getBase64(file) as string;

    setFileList([newFile]);

    /** Upload image to API */
    const formData = new FormData();

    formData.append('file', newFile.originFileObj as Blob);
    uploader.fetch(formData)
      .then((res) => {
        if (res?.url) {
          setFileList((prevFileList) => {
            const updatedData = prevFileList.map((item) => (
              item.uid === file.uid ? { ...item, url: res.url } : item
            ));

            return updatedData;
          });
        }
      })
      .finally(() => setPercentCompleted(null));

    return false;
  };

  const props: UploadProps = {
    name,
    multiple: false,
    showUploadList: false,
    accept,
    onRemove,
    beforeUpload,
    fileList,
    listType: 'text',
  };

  return (
    <>
      <div>
        <div className={clsx(styles.uploader, className)}>
          {fileList.length ? (
            <Button
              type="default"
              icon={<DeleteOutlined />}
              onClick={() => {
                onRemove(fileList[0]);
                onDurationChange?.(0);
              }}
              loading={uploader.loading}
            />
          ) : (
            <Upload
              {...props}
              className={styles.upload}
            >
              {!fileList.length ? <Button type="default" icon={<UploadOutlined />}>Загрузить</Button> : null}
            </Upload>
          )}
        </div>

        {uploader.error ? (
          <Alert
            style={{ marginTop: 8 }}
            type="error"
            message={getMessageInError(uploader.error)}
            closable
            onClose={uploader.clearError}
          />
        ) : null}
      </div>

      <Form.Item
        name={name}
        rules={required ? [{ required: true, message: messages.required }] : undefined}
        className="no-space-form-item"
      >
        <div>
          <Input type="hidden" />
          {fileList.map((file) => (
            <div key={file.uid} className={clsx(styles.audioContainer)}>
              {percentCompleted ? (<Progress showInfo percent={percentCompleted} />) : null}

              <audio controls className={styles.audioFile} preload="metadata">
                <source src={file.preview || getFullFileUrl(file.url)} type="audio/mp3" />
                <track kind="captions" />
              </audio>
            </div>
          ))}
        </div>
      </Form.Item>
    </>
  );
}

AudioUpload.defaultProps = {
  form: undefined,
  required: false,
  children: undefined,
  accept: '.mp3,.m4a',
  multiple: false,
  className: undefined,
  cropperProps: undefined,
  onDurationChange: undefined,
  wide: false,
};

export default AudioUpload;
