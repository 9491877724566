import React from 'react';
import MarathonTelegramsContent from './Content';

export default function MarathonTelegrams(): React.ReactNode {
  return (
    <div>
      <MarathonTelegramsContent />
    </div>
  );
}
