import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Alert } from 'antd';
import Wrapper from '../Wrapper';
import { useConfirmEmail } from '../../../../hooks/api/auth';
import { getMessageInError } from '../../../../hooks/fetch';

export default function ConfirmEmail(): React.ReactNode {
  const { secretKey } = useParams();
  const confirmEmail = useConfirmEmail();

  useEffect(() => {
    if (secretKey) {
      confirmEmail.fetch({
        secretKey,
      });
    }
  }, [secretKey]);

  return (
    <Wrapper>
      <h1 style={{ textAlign: 'center' }}>
        Your email confirm successfully!
      </h1>

      {confirmEmail.error ? (
        <Alert
          style={{ marginTop: 8 }}
          type="error"
          message={getMessageInError(confirmEmail.error)}
          closable
          onClose={confirmEmail.clearError}
        />
      ) : null}
    </Wrapper>
  );
}
