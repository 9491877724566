export enum MarathonStatusEnum {
  new = 'Новый',
  upcoming = 'Предстоящий',
  sales = 'Продажи стартовали',
  active = 'Активный',
  finished = 'Окончен',
  closed = 'Прошедший',
}

export default {
  MarathonStatusEnum,
};
