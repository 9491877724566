import React from 'react';
import { Button } from 'antd';
import clsx from 'clsx';
import { CloseOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';

import styles from './index.module.scss';

interface UserMarathonModalActionsProps {
  handleSelected: (index?: number) => void
  itemsLength: number
  selectedIndex?: number
}

function UserMarathonModalActions({
  itemsLength, selectedIndex, handleSelected,
}: UserMarathonModalActionsProps) {
  return (
    <div className={clsx(styles.wrap, { [styles.active]: selectedIndex !== undefined })}>
      <Button className={styles.btn} icon={<CloseOutlined />} onClick={() => handleSelected()} />
      {itemsLength ? (
        <>
          <Button
            className={clsx(styles.btn, styles.right)}
            icon={<RightOutlined />}
            disabled={selectedIndex === itemsLength}
            onClick={() => {
              if (selectedIndex !== undefined && selectedIndex < itemsLength) {
                handleSelected((selectedIndex || 0) + 1);
              }
            }}
          />
          <Button
            className={clsx(styles.btn, styles.left)}
            icon={<LeftOutlined />}
            disabled={selectedIndex === 0}
            onClick={() => {
              if (selectedIndex !== undefined && selectedIndex > 0) {
                handleSelected((selectedIndex || 0) - 1);
              }
            }}
          />
        </>
      ) : null}
    </div>
  );
}

UserMarathonModalActions.defaultProps = {
  selectedIndex: undefined,
};

export default UserMarathonModalActions;
