import React from 'react';
import { config } from '@triare/auth-redux';
import Layout from '../../../components/Layout';
import Content from '../../../components/Pages/Complexes/Update';

const { displayName } = config;

function ComplexUpdate(): React.JSX.Element {
  document.title = `${displayName}: Редактировать комплекс`;

  return (
    <Layout>
      <Content />
    </Layout>
  );
}

export default ComplexUpdate;
