import React from 'react';
import { Button, Modal } from 'antd';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import LecturesTable from '../../../../Lectures/List';

interface LecturesListModal {
  isOpenModal: boolean;
  isLoading: boolean;
  handleAddLectures: () => Promise<void>;
  handleModal: (payload: boolean) => void;
  selectedLecturesIds: number[];
  handleSelectedRows: (selected: number[]) => void;
  defaultParams?: Record<string, string>;
}

function LecturesListModal({
  isOpenModal,
  isLoading,
  handleAddLectures,
  selectedLecturesIds,
  handleModal,
  handleSelectedRows,
}: LecturesListModal) {
  const [_, setSearchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Modal
      open={isOpenModal}
      title="Выбрать лекцию"
      cancelText="Отмена"
      centered
      destroyOnClose
      okText="Добавить"
      className="modal-with-table"
      okButtonProps={{ type: 'primary', loading: isLoading, disabled: !selectedLecturesIds.length }}
      width={1200}
      onOk={async () => {
        await handleAddLectures();
        handleModal(false);
      }}
      onCancel={() => {
        if (!isLoading) {
          handleModal(false);
        }
      }}
      afterClose={() => setSearchParams({})}
      maskClosable={!isLoading}
      footer={(__, { OkBtn, CancelBtn }) => [
        <CancelBtn key="cancel-btn" />,
        <Button
          key="add-btn"
          type="default"
          onClick={() => navigate(`/lectures/create?from=${location.pathname}`)}
          style={{ marginInlineStart: '8px' }}
        >
          Создать новую
        </Button>,
        <OkBtn key="ok-btn" />,
      ]}
    >
      <LecturesTable
        params={{ pageSize: '5', contentState: 'published' }}
        inModal
        selectedRows={selectedLecturesIds}
        onRowSelection={handleSelectedRows}
      />
    </Modal>
  );
}

LecturesListModal.defaultProps = {
  defaultParams: {},
};

export default LecturesListModal;
