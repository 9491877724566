import React from 'react';
import {
  App,
  Form, Input, Modal, Typography,
} from 'antd';
import { createRulesForAntd, validationChangePassword } from '../../../../utils/validations';
import { useUserChangePassword } from '../../../../hooks/api/user';
import { useMessageError } from '../../../../hooks/common';

interface TrainingsListModal {
  isOpenModal: boolean
  handleModal: (payload: boolean) => void
}

interface ChangePasswordForm {
  currentPassword: string,
  password: string,
  confirmPassword: string
}

const validationRules = createRulesForAntd(validationChangePassword);

function ChangePasswordModal({
  isOpenModal,
  handleModal,
}: TrainingsListModal) {
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const userChangePassword = useUserChangePassword();
  const onFinish = async () => {
    const values = form.getFieldsValue() as ChangePasswordForm;

    await userChangePassword.fetch({
      oldPassword: values.currentPassword,
      newPassword: values.password,
    }).then((res) => {
      if (!res) {
        message.success('Изменения успешно сохранены').then(() => handleModal(false));
      }
    });
  };

  useMessageError([userChangePassword]);

  return (
    <Modal
      open={isOpenModal}
      title="Изменить пароль"
      cancelText="Отмена"
      centered
      okText="Сохранить"
      okButtonProps={{ type: 'primary', loading: userChangePassword.loading }}
      cancelButtonProps={{ disabled: userChangePassword.loading }}
      width={400}
      onOk={() => form.submit()}
      onCancel={() => handleModal(false)}
      maskClosable={!userChangePassword.loading}
    >
      <Form<ChangePasswordForm>
        form={form}
        layout="vertical"
        onFinish={onFinish}
      >
        <div style={{ marginBottom: '24px' }}>
          <Typography.Paragraph>
            Пароль должен быть не менее 12 символов и состоять из букв, цифр и специальных символов.
          </Typography.Paragraph>
        </div>

        <Form.Item name="currentPassword" label="Старый пароль" rules={[validationRules]}>
          <Input.Password placeholder="Введить пароль" />
        </Form.Item>

        <Form.Item name="password" label="Новый пароль" rules={[validationRules]}>
          <Input.Password placeholder="Введить пароль" />
        </Form.Item>

        <Form.Item
          name="confirmPassword"
          label="Повторите пароль"
          dependencies={['password']}
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }

                return Promise.reject(new Error('Пароли не совпадают.'));
              },
            }),
          ]}
        >
          <Input.Password placeholder="Введить пароль" />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default ChangePasswordModal;
