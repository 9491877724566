import React from 'react';
import { SelectProps } from 'antd/lib/select';
import { Select } from 'antd';
import { UserSubscription } from '../../../../hooks/api/user';
import { Option } from '../../../../types';

function SelectEquipmentMulti({ ...props }: SelectProps<UserSubscription, Option>) {
  return (
    <Select {...props} mode="multiple" optionFilterProp="label" />
  );
}

SelectEquipmentMulti.defaultProps = {
  options: [
    { label: 'Гантели', value: 'dumbbells' },
    { label: 'Эластичная лента', value: 'elasticBand' },
    { label: 'Фитбол', value: 'fitBall' },
    { label: 'Стул', value: 'chair' },
  ],
  allowClear: true,
  placeholder: 'Выберите',
};

export default SelectEquipmentMulti;
