import React from 'react';
import { config } from '@triare/auth-redux';
import Layout from '../../../../components/Layout';
import Content from '../../../../components/Pages/Trainings/Create/House';

const { displayName } = config;

function TrainingCreateHouse(): React.JSX.Element {
  document.title = `${displayName}: Добавить тренировку (дом)`;

  return (
    <Layout>
      <Content />
    </Layout>
  );
}

export default TrainingCreateHouse;
