import {
  App, Button, Col, Form, InputNumber, Modal, ModalProps, Row,
} from 'antd';
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { EditOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import {
  Measure,
  Participant,
  UserMarathonMeasurementsUpdate,
  useUserMarathonMeasurementsUpdate,
} from '../../../../../../../hooks/api/user';
import { useUserProfileProvider } from '../../../../../../../context/userProfile';
import { useMessageError } from '../../../../../../../hooks/common';

interface MeasureItem {
  weight: number | undefined,
  chest: number | undefined,
  hip: number | undefined,
  waist: number | undefined,
}

const emptyMeasureItem: MeasureItem = {
  weight: undefined,
  hip: undefined,
  chest: undefined,
  waist: undefined,
};

export interface MeasurementsForm {
  measureBefore?: MeasureItem,
  measureAfter?: MeasureItem,
}

interface UpdateUserMeasurementProps extends ModalProps {
  selectedMarathon: Participant | undefined;
  measureKey: 'measureBefore' | 'measureAfter';
}

export default function UpdateUserMeasurementModal({
  selectedMarathon,
  measureKey,
  ...rest
}: UpdateUserMeasurementProps) {
  const { message } = App.useApp();
  const { id: userId = '' } = useParams<{ id: string; }>();
  const userMarathonMeasureUpdate = useUserMarathonMeasurementsUpdate();
  const { userById } = useUserProfileProvider();
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const currentDate = dayjs();
  const [disabled, setDisabled] = useState<boolean>(true);

  useEffect(() => {
    if (!selectedMarathon) return;
    setDisabled(dayjs(selectedMarathon.dateEnd).isBefore(currentDate));
  }, [selectedMarathon]);

  const participantId = selectedMarathon?.id || '';

  const handleSubmit = () => {
    if (participantId) { // participantId
      form.validateFields().then(async (values) => {
        const preparedValues: UserMarathonMeasurementsUpdate = {
          participantId,
          // We update only one measure type at once, so send only current measureType, and ignore other.
          [measureKey]: JSON.stringify(values[measureKey]) === '{}' ? undefined : values[measureKey],
        };

        userMarathonMeasureUpdate.fetch(preparedValues, `${userId}/as-participant`)
          .then((res) => {
            if (res?.id) {
              message.success('Замеры успешно изменены!');
              userById.fetch(undefined, userId).then(() => {
                setOpen(false);
              });
            }
          });
      });
    } else {
      message.error('Что-то пошло не так!');
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  /** Handle initial state */
  const [initialValues, setInitialValues] = useState<MeasurementsForm>({
    measureBefore: emptyMeasureItem,
    measureAfter: emptyMeasureItem,
  });

  const prepareMeasureData = (selectedParticipant?: Participant) : MeasurementsForm => {
    if (selectedParticipant) {
      const prepareItem = ({
        weight, weight2, hip, chest, waist,
      }: Partial<Measure>): MeasureItem => ({
        weight: weight2 || weight, hip, chest, waist,
      });

      return ({
        measureBefore: prepareItem(selectedParticipant.measureBefore || emptyMeasureItem),
        measureAfter: prepareItem(selectedParticipant.measureAfter || emptyMeasureItem),
      });
    }

    return {
      measureBefore: emptyMeasureItem,
      measureAfter: emptyMeasureItem,
    };
  };

  useEffect(() => {
    if (selectedMarathon) {
      setInitialValues(prepareMeasureData(selectedMarathon));
    }
  }, [selectedMarathon]);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(initialValues);
  }, [initialValues]);

  useMessageError([userMarathonMeasureUpdate]);

  return (
    <div>
      <Button onClick={() => setOpen(true)} disabled={disabled}>
        <span>
          <EditOutlined />
          {' '}
          Замеры
          {' '}
          {measureKey === 'measureBefore' ? 'ДО' : 'ПОСЛЕ'}
        </span>
      </Button>
      <Modal
        open={open}
        title={(`Редактировать замеры ${measureKey === 'measureBefore' ? 'ДО' : 'ПОСЛЕ'}`)}
        cancelText="Отмена"
        centered
        okText="Сохранить"
        okButtonProps={{ type: 'primary', loading: userMarathonMeasureUpdate.loading || userById.loading }}
        cancelButtonProps={{ disabled: userMarathonMeasureUpdate.loading }}
        width={510}
        onOk={() => handleSubmit()}
        onCancel={handleClose}
        maskClosable={!userMarathonMeasureUpdate.loading}
        destroyOnClose
        {...rest}
      >
        <Form
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
          initialValues={initialValues}
        >
          <Row gutter={24}>
            {measureKey === 'measureBefore' ? (
              <Col span={24}>
                <Form.Item name={['measureBefore', 'weight']} label="Вес / До">
                  <InputNumber placeholder="Введите" style={{ width: '100%' }} min={0} precision={1} suffix="кг" />
                </Form.Item>
                <Form.Item name={['measureBefore', 'chest']} label="ОГ / До">
                  <InputNumber placeholder="Введите" style={{ width: '100%' }} min={0} suffix="см" />
                </Form.Item>
                <Form.Item name={['measureBefore', 'waist']} label="ОТ / До">
                  <InputNumber placeholder="Введите" style={{ width: '100%' }} min={0} suffix="см" />
                </Form.Item>
                <Form.Item name={['measureBefore', 'hip']} label="ОБ / До">
                  <InputNumber placeholder="Введите" style={{ width: '100%' }} min={0} suffix="см" />
                </Form.Item>
              </Col>
            ) : null}
            {measureKey === 'measureAfter' ? (
              <Col span={24}>
                <Form.Item name={['measureAfter', 'weight']} label="Вес / После">
                  <InputNumber placeholder="Введите" style={{ width: '100%' }} min={0} precision={1} suffix="кг" />
                </Form.Item>
                <Form.Item name={['measureAfter', 'chest']} label="ОГ / После">
                  <InputNumber placeholder="Введите" style={{ width: '100%' }} min={0} suffix="см" />
                </Form.Item>
                <Form.Item name={['measureAfter', 'waist']} label="ОТ / После">
                  <InputNumber placeholder="Введите" style={{ width: '100%' }} min={0} suffix="см" />
                </Form.Item>
                <Form.Item name={['measureAfter', 'hip']} label="ОБ / После">
                  <InputNumber placeholder="Введите" style={{ width: '100%' }} min={0} suffix="см" />
                </Form.Item>
              </Col>
            ) : null}
          </Row>
        </Form>
      </Modal>
    </div>
  );
}
