import React from 'react';
import { Button, Input } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { Lecture } from '../../../../hooks/api/lectures';
import ImageThumbnail from '../../../Common/ImageThumbnail';
import { getFullFileUrl } from '../../../Common/Uploaders/ImageUpload';
import SelectContentStatus from '../../../Common/Selects/SelectStatus/SelectContentStatus';

import styles from './index.module.scss';

interface LectureItem {
  lecture: Lecture;
  onRemove: (id: string) => void;
}

function LectureItem({ lecture, onRemove }: LectureItem) {
  const navigate = useNavigate();

  if (!lecture?.id) return null;

  return (
    <div key={lecture.id} className={styles.item}>
      <div className={styles.content}>
        <ImageThumbnail thumbnailUrl={lecture.imageUrl} />
        <div className={styles.inner}>

          <div className={styles.audioFile}>
            <span className={styles.audioLangTag}>RU</span>

            <audio controls preload="metadata">
              <source src={getFullFileUrl(lecture.audioUrlRU)} type="audio/mp3" />
              <track kind="captions" />
            </audio>
          </div>
          <Input name="nameRU" addonBefore="RU" value={lecture.nameRU} disabled />

          <div className={styles.audioFile}>
            <span className={styles.audioLangTag}>UA</span>

            <audio controls preload="metadata">
              <source src={getFullFileUrl(lecture.audioUrlUA)} type="audio/mp3" />
              <track kind="captions" />
            </audio>
          </div>
          <Input name="nameUA" addonBefore="UA" value={lecture.nameUA} disabled />

          <div className={styles.additionalInfo}>
            <SelectContentStatus value={lecture?.contentState} disabled />

            <div className={styles.actions}>
              <Button icon={<EditOutlined />} onClick={() => navigate(`/lectures/${lecture.id}`)} />
              <Button icon={<DeleteOutlined />} onClick={() => onRemove(lecture.id)} />
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}

LectureItem.defaultProps = {
  disabled: false,
};

export default LectureItem;
