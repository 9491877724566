import React from 'react';
import { FileImageOutlined } from '@ant-design/icons';
import clsx from 'clsx';

import styles from './index.module.scss';

function EmptyImage({ large }: {large?: boolean}) {
  return (
    <div className={clsx(styles.wrap, { [styles.large]: large })}>
      <FileImageOutlined />
    </div>
  );
}

EmptyImage.defaultProps = {
  large: false,
};

export default EmptyImage;
