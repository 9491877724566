import React from 'react';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';

import styles from './index.module.scss';

interface LinkButton extends React.PropsWithChildren {
  to: string;
  disabled?: boolean;
  className?: string;
  style?: React.CSSProperties;
}

function LinkButton({
  to, children, disabled, className, style,
}: LinkButton) {
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (disabled) {
      event.preventDefault();
    }
  };

  return (
    <NavLink
      to={to}
      className={clsx(styles.linkButton, className, { [styles.disabled]: disabled })}
      style={style}
      onClick={handleClick}
    >
      {children}
    </NavLink>
  );
}

LinkButton.defaultProps = {
  disabled: false,
  className: undefined,
  style: undefined,
};

export default LinkButton;
