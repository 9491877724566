import React from 'react';
import {
  App,
  Form, Input, Modal,
} from 'antd';
import { createRulesForAntd, validationCertificateReGift } from '../../../../utils/validations';
import { useMessageError } from '../../../../hooks/common';
import { Certificate, useCertificateReGift } from '../../../../hooks/api/certificates';

interface IReGiftModal {
  isOpenModal: boolean
  handleModal: (payload: boolean) => void
  selectedCertificate: Certificate,
  handleReloadTable: () => void
}

interface ReGiftForm {
  email: string,
}

const validationRules = createRulesForAntd(validationCertificateReGift);

function ReGiftModal({
  isOpenModal, handleModal, selectedCertificate, handleReloadTable,
}: IReGiftModal) {
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const certificateReGift = useCertificateReGift();

  const onFinish = async () => {
    const values = form.getFieldsValue() as ReGiftForm;

    await certificateReGift.fetch({ email: values.email }, `${selectedCertificate.id}/re-gift`).then((res) => {
      if (res?.id) {
        message.success('Изменения успешно сохранены');
        handleModal(false);
        handleReloadTable();
      } else {
        message.error('Что-то пошло не так');
      }
    });
  };

  useMessageError([certificateReGift]);

  return (
    <Modal
      open={isOpenModal}
      title="Емейл кому подарен"
      cancelText="Отмена"
      centered
      okText="Сохранить"
      okButtonProps={{ type: 'primary', loading: certificateReGift.loading }}
      cancelButtonProps={{ disabled: certificateReGift.loading }}
      width={400}
      onOk={() => form.submit()}
      onCancel={() => handleModal(false)}
      maskClosable={!certificateReGift.loading}
      destroyOnClose
    >
      <Form<ReGiftForm>
        form={form}
        onFinish={onFinish}
        initialValues={{
          email: selectedCertificate.giftedTo,
        }}
      >
        <Form.Item name="email" label="Емeйл" rules={[validationRules]}>
          <Input placeholder="Введить емейл" />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default ReGiftModal;
