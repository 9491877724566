import React, { useEffect, useState } from 'react';
import { ItemType } from 'antd/es/breadcrumb/Breadcrumb';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import {
  App, Button, Form, UploadFile,
} from 'antd';
import SinglePageContainer from '../../../Common/SinglePageContainer';
import { useMessageError } from '../../../../hooks/common';
import LectureFormItems from '../Form';
import { useSimpleModal } from '../../../Common/Modal/Simple';
import { generateFileObjFromUrl } from '../../../Common/Uploaders/ImageUpload';
import { asyncDelay, filterObjectProps, isObjEqual } from '../../../../utils';
import { useUnsavedChanges } from '../../../../context/unsavedChanges';
import {
  CreateLecturePayload,
  Lecture,
  useLectureDelete,
  useLectureId,
  useLectureUpdate,
} from '../../../../hooks/api/lectures';

const breadcrumbs: ItemType[] = [
  {
    title: <NavLink to="/lectures">Лекции</NavLink>,
  },
  {
    title: 'Редактировать лекцию',
  },
];

const initialState: Partial<CreateLecturePayload> = {
  nameRU: '',
  nameUA: '',
  contentUA: '',
  contentRU: '',
  descriptionUA: '',
  descriptionRU: '',
  availability: undefined,
  contentState: undefined,
  forCatalog: true,
  audioUrlRU: undefined,
  audioUrlUA: undefined,
  durationRU: undefined,
  durationUA: undefined,
  imageUrl: undefined,
};

const keysToFilterInitial: (keyof Partial<Lecture>)[] = ['createdAt', 'updatedAt', 'id', 'kind'];

export default function LectureUpdate() {
  const { id = '' } = useParams<{ id: string; }>();
  const lectureById = useLectureId();
  const lectureUpdate = useLectureUpdate();
  const lectureDelete = useLectureDelete();
  const navigate = useNavigate();

  const { open, contextHolder } = useSimpleModal();
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const {
    unsavedChanges, handleUnsavedChanges, htmlEditorTouched, handleEditorTouched,
  } = useUnsavedChanges();

  const fetch = () => {
    lectureById.fetch(undefined, id);
  };

  useEffect(() => {
    if (id) {
      fetch();
    }
  }, [id]);

  const [bannerFile, setBannerFile] = useState<UploadFile[]>([]);
  const [audioFileRU, setAudioFileRU] = useState<UploadFile[]>([]);
  const [audioFileUA, setAudioFileUA] = useState<UploadFile[]>([]);

  /** Handle Initial values */
  const [initialValues, setInitialValues] = useState(initialState);

  const handleInitialFileList = (data: Lecture) => {
    if (data?.imageUrl) {
      const banner = generateFileObjFromUrl(data.imageUrl);

      setBannerFile([banner]);
    }
    if (data?.audioUrlRU) {
      const audio = generateFileObjFromUrl(data.audioUrlRU);

      setAudioFileRU([audio]);
    }
    if (data?.audioUrlUA) {
      const audio = generateFileObjFromUrl(data.audioUrlUA);

      setAudioFileUA([audio]);
    }
  };

  useEffect(() => {
    if (lectureById.data) {
      setInitialValues(filterObjectProps(lectureById.data, keysToFilterInitial));
      handleInitialFileList(lectureById.data);
    }
  }, [lectureById.data]);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(initialValues);
    handleUnsavedChanges(false);
    asyncDelay(200).then(() => (handleEditorTouched(false)));
  }, [initialValues]);

  const handleSubmit = () => {
    form.validateFields().then((values) => (
      lectureUpdate.fetch(values, id)
        .then((res) => {
          if (res?.id) {
            message.success('Изменения успешно сохранены.');
            setInitialValues(filterObjectProps(res, keysToFilterInitial));
          }
        })
    ));
  };

  /** Handle Delete */
  const handleDeleteClick = () => {
    if (!id) return;
    open({
      title: 'Удалить лекцию?',
      content: 'Вы уверены, что хотите навсегда удалить эту лекцию?',
      cancelText: 'Отмена',
      centered: true,
      okText: 'Удалить',
      okButtonProps: {
        type: 'primary',
        danger: true,
      },
      onOk: () => lectureDelete.fetch(id),
      maskClosable: true,
    });
  };

  useEffect(() => {
    if (lectureDelete.response?.status === 204) {
      handleUnsavedChanges(false).then(() => {
        message.success('Лекция успешно удалена.');
        navigate('/lectures');
      });
    }
  }, [lectureDelete.response]);

  useMessageError([lectureById, lectureUpdate, lectureDelete]);

  const handleFieldsChange = () => {
    handleUnsavedChanges(!isObjEqual(
      filterObjectProps(initialValues, ['contentRU', 'contentUA']),
      filterObjectProps(form.getFieldsValue(), ['contentRU', 'contentUA']),
    ));
  };

  useEffect(() => { handleFieldsChange(); }, [bannerFile, audioFileRU, audioFileUA]);

  return (
    <>
      {contextHolder}
      <SinglePageContainer
        breadcrumbItems={breadcrumbs}
        title="Редактировать лекцию"
        cardLoading={lectureById.loading}
        extra={(
          <>
            <Button type="primary" danger onClick={handleDeleteClick}>Удалить</Button>
            <Button
              type="primary"
              onClick={form.submit}
              loading={lectureUpdate.loading}
              disabled={!(unsavedChanges || htmlEditorTouched)}
            >
              Сохранить
            </Button>
          </>
      )}
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={initialValues}
          onFinish={handleSubmit}
          name="lecture-update"
          onFieldsChange={handleFieldsChange}
        >
          <LectureFormItems
            audioFileRU={audioFileRU}
            setAudioFileRU={setAudioFileRU}
            audioFileUA={audioFileUA}
            setAudioFileUA={setAudioFileUA}
            bannerFile={bannerFile}
            setBannerFile={setBannerFile}
          />
        </Form>
      </SinglePageContainer>
    </>
  );
}
