import React from 'react';
import { config } from '@triare/auth-redux';
import Layout from '../../components/Layout';
import LecturesContent from '../../components/Pages/Lectures';

const { displayName } = config;

export default function Lectures(): React.ReactNode | null {
  document.title = `${displayName}: Лекции`;

  return (
    <Layout>
      <LecturesContent />
    </Layout>
  );
}
