import React from 'react';
import { FileOutlined } from '@ant-design/icons';
import clsx from 'clsx';
import { getFullFileUrl } from '../Uploaders/ImageUpload';

import styles from './index.module.scss';

function ImageThumbnail({ thumbnailUrl }: { thumbnailUrl?: string}) {
  return (
    <div className={clsx(styles.imageWrap, { [styles.noImage]: !thumbnailUrl })}>
      {thumbnailUrl ? (
        <img src={getFullFileUrl(thumbnailUrl)} alt="thumbnail" />
      ) : <FileOutlined className={styles.iconCentered} />}
    </div>
  );
}

ImageThumbnail.defaultProps = {
  thumbnailUrl: '',
};

export default ImageThumbnail;
