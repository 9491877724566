import React, {
  createContext, ReactNode, useContext, useMemo, useState,
} from 'react';

interface GlobalLoadingProviderProps {
  globalLoading: boolean;
  handleGlobalLoading: (loading: boolean) => void;
}

const defaultValue: GlobalLoadingProviderProps = {
  globalLoading: false,
  handleGlobalLoading: () => undefined,
};

export const GlobalLoadingContext = createContext<GlobalLoadingProviderProps>(defaultValue);

function GlobalLoadingProvider({ children }: { children: ReactNode }) {
  const [globalLoading, setGlobalLoading] = useState<boolean>(false);

  const handleGlobalLoading = (payload: boolean) => {
    setGlobalLoading(payload);
  };

  const memoizedValue = useMemo(() => ({
    globalLoading,
    handleGlobalLoading,
  }), [globalLoading, handleGlobalLoading]);

  return (
    <GlobalLoadingContext.Provider value={memoizedValue}>
      {children}
    </GlobalLoadingContext.Provider>
  );
}

export default GlobalLoadingProvider;

export const useGlobalLoadingProvider = (): GlobalLoadingProviderProps => useContext(GlobalLoadingContext);
