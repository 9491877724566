import React, {
  Key, useCallback, useEffect, useMemo, useRef,
} from 'react';
import { ActionType, ProColumns, RequestData } from '@ant-design/pro-components';
import {
  App, Button, FormInstance, Space,
} from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SortOrder } from 'antd/es/table/interface';
import { ParamsType } from '@ant-design/pro-provider';
import {
  DeleteOutlined, EditOutlined, PlusCircleOutlined,
} from '@ant-design/icons';
import dayjs from 'dayjs';
import clsx from 'clsx';
import Table, { defaultPagingResponse } from '../../../Common/Table';
import {
  createDefaultHiddenColumns, getSorterParams, queryFilterParams,
} from '../../../../utils';
import { useMessageError } from '../../../../hooks/common';
import ContentThumbnailAndName from '../../../Common/ContentThumbnailAndName';
import { useSimpleModal } from '../../../Common/Modal/Simple';
import StatusIndicator from '../../../Common/StatusIndicator';
import { AvailabilityEnum, ForCatalogAPIEnum, ForCatalogEnum } from '../../../../enums';
import SelectContentStatus from '../../../Common/Selects/SelectStatus/SelectContentStatus';
import { dateFormat } from '../../../../contstant';
import { getFullFileUrl } from '../../../Common/Uploaders/ImageUpload';
import { KindEnum, LecturesCategoryEnum } from '../../../../enums/lectures';
import { Lecture, useLectureDelete, useLecturesGet } from '../../../../hooks/api/lectures';

interface TableProps {
  params?: Record<string, string>;
  selectedRows?: number[];
  onRowSelection?: ((selectedRows: number[]) => void) | undefined;
  inModal?: boolean;
}

function LecturesTable(props: TableProps): React.JSX.Element {
  const {
    params, selectedRows, onRowSelection, inModal,
  } = props;
  const { message } = App.useApp();
  const { open, contextHolder } = useSimpleModal();

  const navigate = useNavigate();
  const lecturesGet = useLecturesGet();
  const lectureDelete = useLectureDelete();
  const formRef = useRef<FormInstance>();
  const actionRef = useRef<ActionType>();

  const [searchParams, setSearchParams] = useSearchParams();

  const toolBarRender = useCallback(() => [
    <Button
      key="add-btn"
      id="add-btn"
      type="primary"
      icon={<PlusCircleOutlined />}
      onClick={() => navigate('/lectures/create')}
    >
      <Space>
        Добавить
      </Space>
    </Button>,
  ], []);

  /** Table request: */
  const tableRequest = async (
    { current, pageSize, ...args }: Record<string, string>
      & { pageSize?: number | undefined; current?: number | undefined; keyword?: string | undefined; },
    sorter: Record<string, SortOrder>,
  ): Promise<RequestData<Lecture>> => {
    const newParams = queryFilterParams({
      page: current ? `${current}` : '1',
      pageSize: pageSize ? `${pageSize}` : '10',
      ...args,
      ...getSorterParams(sorter),
    });

    setSearchParams(queryFilterParams({ ...args, ...getSorterParams(sorter) }), { replace: true });

    return lecturesGet.fetch({
      ...newParams,
      ...params,
      forCatalog: newParams?.forCatalog ? ForCatalogAPIEnum[newParams.forCatalog as 'yes' | 'no'] : undefined,
    }).then((data) => {
      if (data) {
        const { data: lectures, meta } = data;

        return ({ data: lectures || [], success: true, total: meta?.totalItems || 0 });
      }

      return defaultPagingResponse;
    });
  };

  const beforeSearchSubmit = (beforeSubmitParams: Partial<ParamsType>) => {
    const newParams = queryFilterParams({
      ...beforeSubmitParams,
      _timestamp: '',
      search: searchParams.get('search') || '',
    });

    setSearchParams({ ...newParams, ...params }, { replace: true });

    return { ...newParams, ...params };
  };

  useMessageError([lecturesGet, lectureDelete]);

  const handleDeleteClick = (e: React.MouseEvent<HTMLElement>, row: Lecture) => {
    e.preventDefault();
    e.stopPropagation();
    open({
      title: 'Удалить лекцию?',
      content: 'Вы уверены, что хотите навсегда удалить эту лекцию?',
      cancelText: 'Отмена',
      centered: true,
      okText: 'Удалить',
      okButtonProps: {
        type: 'primary',
        danger: true,
      },
      onOk: () => lectureDelete.fetch(row.id),
      maskClosable: true,
    });
  };

  useEffect(() => {
    if (lectureDelete.response?.status === 204 || lectureDelete.data) {
      message.success('Лекция успешно удалена.');
      actionRef.current?.reload();
    }
  }, [lectureDelete.response]);

  const columns: ProColumns<Lecture>[] = [
    {
      title: 'Название',
      dataIndex: 'nameRU',
      sorter: true,
      hideInSearch: true,
      hideInForm: true,
      render: (_, row) => (
        <ContentThumbnailAndName
          imgSrc={row.imageUrl ? getFullFileUrl(row.imageUrl) : '/images/img_lecture.png'}
          name={row.nameRU}
        />
      ),
    },
    {
      order: 10,
      title: 'Доступность',
      dataIndex: 'availability',
      sorter: true,
      valueEnum: AvailabilityEnum,
    },
    {
      order: 9,
      title: 'Статус',
      dataIndex: 'contentState',
      sorter: true,
      renderFormItem: (_, { defaultRender, ...config }) => (<SelectContentStatus {...config} />),
      renderText: (contentState) => <StatusIndicator status={contentState} />,
    },
    {
      order: 8,
      title: 'Отображение в каталоге',
      dataIndex: 'forCatalog',
      valueEnum: ForCatalogEnum,
      renderText: (forCatalog) => (forCatalog ? 'Да' : 'Нет'),
      sorter: false,
    },
    {
      order: 7,
      title: 'Категория',
      dataIndex: 'category',
      sorter: true,
      valueEnum: LecturesCategoryEnum,
    },
    {
      title: 'Дата создания',
      dataIndex: 'createdAt',
      sorter: true,
      renderText: (createdAt) => (createdAt ? dayjs(createdAt).format(dateFormat) : ''),
      hideInSearch: true,
    },
    {
      title: 'Дата изменения',
      dataIndex: 'updatedAt',
      sorter: true,
      renderText: (updatedAt) => (updatedAt ? dayjs(updatedAt).format(dateFormat) : ''),
      hideInSearch: true,
    },
    {
      key: 'actions',
      width: 100,
      title: 'Действия',
      dataIndex: 'actions',
      sorter: false,
      hideInSearch: true,
      hideInSetting: true,
      render: (_, row) => (
        <>
          <Button
            size="middle"
            type="text"
            icon={<EditOutlined style={{ fontSize: 18 }} />}
            onClick={() => navigate(`/lectures/${row.id}`)}
            title="Редактировать"
          />
          <Button
            size="middle"
            type="text"
            icon={<DeleteOutlined style={{ fontSize: 18 }} />}
            onClick={(e) => handleDeleteClick(e, row)}
            title="Удалить"
          />
        </>
      ),
    },
  ];

  const defaultHiddenColumns = useMemo(() => createDefaultHiddenColumns(
    ['createdAt', 'updatedAt', 'forCatalog', 'kind'],
  ), []);

  const onRowChange = useCallback((selectedRowKeys: Key[]) => {
    if (onRowSelection) {
      onRowSelection(selectedRowKeys as number[]);
    }
  }, [onRowSelection]);

  const rowSelection = {
    onChange: onRowChange,
    selectedRowKeys: selectedRows,
    alwaysShowAlert: false,
    preserveSelectedRowKeys: true,
  };

  const defaultHiddenColumnsInModal = useMemo(() => createDefaultHiddenColumns(
    ['createdAt', 'updatedAt', 'contentState', 'actions', 'kind', 'forCatalog'],
  ), []);

  return (
    <>
      {contextHolder}
      <Table<Lecture>
        withCustomSearch
        rowKey="id"
        formRef={formRef}
        actionRef={actionRef}
        headerTitle="Список лекций"
        toolBarRender={!inModal ? toolBarRender : undefined}
        columns={columns}
        request={tableRequest}
        beforeSearchSubmit={beforeSearchSubmit}
        showSorterTooltip={false}
        columnsState={inModal ? {
          defaultValue: defaultHiddenColumnsInModal,
        } : {
          persistenceKey: 'pro-table-lectures',
          persistenceType: 'localStorage',
          defaultValue: defaultHiddenColumns,
        }}
        rowSelection={!!onRowSelection && rowSelection}
        rowClassName={clsx({ 'cursor-pointer': !inModal })}
        onRow={(record) => ({
          onClick: !inModal ? () => navigate(`/lectures/${record.id}`) : undefined,
        })}
      />
    </>
  );
}

LecturesTable.defaultProps = {
  params: {},
  selectedRows: [],
  onRowSelection: undefined,
  inModal: false,
};

export default LecturesTable;
