import React, { useEffect, useState } from 'react';
import { App, Button, Form } from 'antd';
import { NavLink, useParams } from 'react-router-dom';
import SinglePageContainer from '../../../../Common/SinglePageContainer';
import { useMessageError } from '../../../../../hooks/common';
import { useUnsavedChanges } from '../../../../../context/unsavedChanges';
import { isObjEqual } from '../../../../../utils';
import MarathonLecturesFormItems from './Form';
import {
  LectureWeek,
  MarathonLecturesWeeks, useMarathonId, useMarathonPutData,
} from '../../../../../hooks/api/marathons';

function MarathonDiplomaContent() {
  const { marathonId = '' } = useParams<{ marathonId: string; }>();
  const { unsavedChanges, handleUnsavedChanges } = useUnsavedChanges();

  const { message } = App.useApp();
  const [form] = Form.useForm();

  const lecturesWeeksById = useMarathonId<MarathonLecturesWeeks>(`${marathonId}/lectures-weeks`);
  const lecturesWeeksUpdate = useMarathonPutData<MarathonLecturesWeeks>(`${marathonId}/lectures-weeks`);

  const [initialValues, setInitialValues] = useState<MarathonLecturesWeeks>();

  const fetch = () => {
    lecturesWeeksById.fetch();
  };

  useEffect(() => {
    if (marathonId) fetch();
  }, [marathonId]);

  useEffect(() => {
    if (lecturesWeeksById.data) {
      setInitialValues(lecturesWeeksById.data);
    }
  }, [lecturesWeeksById.data]);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(initialValues);
    handleUnsavedChanges(false);
  }, [initialValues]);

  const handleSubmit = () => {
    if (!initialValues) return;

    form.validateFields().then((values) => {
      const modifiedVal = {
        ...values,
        weeks: (values.weeks || [])
          .map((week: LectureWeek) => ({
            id: week.id,
            lectures: week.lectures.map((el) => ({ id: el.id })),
          })),
      };

      lecturesWeeksUpdate.fetch(modifiedVal)
        .then((res) => {
          if (res !== null) {
            message.success('Изменения успешно сохранены.');
            handleUnsavedChanges(false);
            setInitialValues(res);
          }
        });
    });
  };

  useMessageError([lecturesWeeksById, lecturesWeeksUpdate]);

  const handleFieldsChange = () => {
    if (!initialValues) return;
    handleUnsavedChanges(!isObjEqual(initialValues, form.getFieldsValue()));
  };

  return (
    <SinglePageContainer
      breadcrumbItems={[
        { title: <NavLink to="/marathons">Марафоны</NavLink> },
        { title: <NavLink to={`/marathons/${marathonId}`}>Редактировать марафон</NavLink> },
        { title: 'Редактировать лекции' },
      ]}
      title="Редактировать лекции"
      cardLoading={lecturesWeeksById.loading || !initialValues}
      extra={(
        <Button
          type="primary"
          onClick={form.submit}
          loading={lecturesWeeksUpdate.loading}
          disabled={!unsavedChanges || !initialValues}
        >
          Сохранить
        </Button>
        )}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={initialValues}
        onFinish={handleSubmit}
        name="lectures-weeks"
        onFieldsChange={handleFieldsChange}
      >
        <MarathonLecturesFormItems />
      </Form>
    </SinglePageContainer>
  );
}

export default MarathonDiplomaContent;
