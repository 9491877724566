import React from 'react';
import {
  Col, Form, Row, Typography, UploadFile,
} from 'antd';
import QuillRichTextEditor from '../../../../Common/QuillRichTextEditor';
import VideoUpload from '../../../../Common/Uploaders/VideoUpload';

import styles from './index.module.scss';

interface MarathonInstructionsFormItemsProps {
  videoRulesFile: UploadFile[]
  setVideoRulesFile: React.Dispatch<React.SetStateAction<UploadFile[]>>
  photoRulesFile: UploadFile[]
  setPhotoRulesFile: React.Dispatch<React.SetStateAction<UploadFile[]>>
  setIsUploading: React.Dispatch<React.SetStateAction<boolean>>
}

function MarathonInstructionsFormItems({
  setPhotoRulesFile,
  setVideoRulesFile,
  photoRulesFile,
  videoRulesFile,
  setIsUploading,
}: MarathonInstructionsFormItemsProps) {
  return (
    <Row gutter={24}>
      <Col span={12}>
        <Form.Item
          name="contestRulesPhotoRU"
          label="Инструкция для фото (RU)"
        >
          <QuillRichTextEditor />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name="contestRulesPhotoUA"
          label="Инструкция для фото (UA)"
        >
          <QuillRichTextEditor />
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item
          name="contestRulesVideoRU"
          label="Инструкция для видео (RU)"
        >
          <QuillRichTextEditor />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name="contestRulesVideoUA"
          label="Инструкция для видео (UA)"
        >
          <QuillRichTextEditor />
        </Form.Item>
      </Col>
      <Col span={24}>
        <div className={styles.imagesWrap}>
          <div className={styles.imageBlock}>
            <VideoUpload
              allowToDelete
              name="photoRulesVideoUrl"
              multiple={false}
              fileList={photoRulesFile}
              setFileList={setPhotoRulesFile}
              setIsUploading={setIsUploading}
              H170
            />
            <div>
              <Typography.Paragraph className="upload-paragraph">
                Видеоинструкция для фото
              </Typography.Paragraph>
              <Typography.Paragraph type="secondary" className="upload-text">
                Требования к файлу: MP4
              </Typography.Paragraph>
            </div>
          </div>
          <div className={styles.imageBlock}>
            <VideoUpload
              allowToDelete
              name="videoRulesVideoUrl"
              multiple={false}
              fileList={videoRulesFile}
              setFileList={setVideoRulesFile}
              setIsUploading={setIsUploading}
              H170
            />
            <div>
              <Typography.Paragraph className="upload-paragraph">
                Видеоинструкция для видео
              </Typography.Paragraph>
              <Typography.Paragraph type="secondary" className="upload-text">
                Требования к файлу: MP4
              </Typography.Paragraph>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default MarathonInstructionsFormItems;
