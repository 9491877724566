import React from 'react';
import { config } from '@triare/auth-redux';
import Simple from '../../../components/Layout/Simple';
import ConfirmEmailContent from '../../../components/Pages/Auth/ConfirmEmail';

const { displayName } = config;

export default function ConfirmEmail(): React.ReactNode {
  document.title = `${displayName}: Email confirmed successfully`;

  return (
    <Simple>
      <ConfirmEmailContent />
    </Simple>
  );
}
