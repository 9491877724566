import React from 'react';
import { config } from '@triare/auth-redux';
import Layout from '../../components/Layout';
import MarathonsContent from '../../components/Pages/Marathons';

const { displayName } = config;

export default function Marathons(): React.ReactNode | null {
  document.title = `${displayName}: Марафоны`;

  return (
    <Layout>
      <MarathonsContent />
    </Layout>
  );
}
