import React from 'react';
import Breadcrumb from 'antd/es/breadcrumb';
import { Typography } from 'antd';
import Card from 'antd/es/card/Card';
import { ItemType } from 'antd/es/breadcrumb/Breadcrumb';

import clsx from 'clsx';
import styles from './index.module.scss';

interface SinglePageContainer extends React.PropsWithChildren {
  breadcrumbItems: ItemType[];
  title: React.ReactNode;
  extra?: React.ReactNode;
  cardLoading?: boolean;
  className?: string;
  style?: React.CSSProperties;
}

function SinglePageContainer({
  breadcrumbItems, title, cardLoading, extra, children, className, style,
}: SinglePageContainer) {
  return (
    <div>
      <Breadcrumb
        className="page-header-breadcrumb"
        items={breadcrumbItems}
      />

      <div className={styles.heading}>
        <Typography.Title level={4} className={styles.pageTitle}>
          {title}
        </Typography.Title>
        <div className={clsx('flex-row-wrapper', styles.extra)}>
          {extra}
        </div>
      </div>

      <Card loading={cardLoading} className={className} style={style}>
        {children}
      </Card>
    </div>
  );
}

SinglePageContainer.defaultProps = {
  extra: undefined,
  cardLoading: false,
  className: '',
};

export default SinglePageContainer;
