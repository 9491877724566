import {
  App, Button, Form, Modal, ModalProps,
} from 'antd';
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { EditOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import {
  Participant, UserMarathonUpdate, useUserMarathonUpdate,
} from '../../../../../../../hooks/api/user';
import { useUserProfileProvider } from '../../../../../../../context/userProfile';
import { useMessageError } from '../../../../../../../hooks/common';
import { LevelType, MenuType, PlaceType } from '../../../../../../../types';
import SelectMenuType from '../../../../../../Common/Selects/SelectMenuType';
import SelectLevel from '../../../../../../Common/Selects/SelectLevel';
import SelectPlace from '../../../../../../Common/Selects/SelectPlace';
import SelectCalories from '../../../../../../Common/Selects/SelectCalories';

export interface IUserMarathonUpdateForm {
  place: PlaceType
  level: LevelType
  menuType: MenuType
  calories: number
}

interface UpdateUserMeasurementProps extends ModalProps {
  selectedMarathon: Participant | undefined;
}

export default function EditModal({
  selectedMarathon,
  ...rest
}: UpdateUserMeasurementProps) {
  const { message } = App.useApp();
  const { id: userId = '' } = useParams<{ id: string; }>();
  const [form] = Form.useForm();
  const currentDate = dayjs();
  const [disabled, setDisabled] = useState<boolean>(true);

  useEffect(() => {
    if (!selectedMarathon) return;
    setDisabled(dayjs(selectedMarathon.dateEnd).isBefore(currentDate));
  }, [selectedMarathon]);

  const userMarathonUpdate = useUserMarathonUpdate();
  const { userById } = useUserProfileProvider();

  const [open, setOpen] = useState(false);
  const [isFormWithErrors, setWithErrors] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<IUserMarathonUpdateForm>();

  const handleSubmit = () => {
    if (selectedMarathon?.id) {
      form.validateFields().then(async (values) => {
        const preparedValues: UserMarathonUpdate = {
          level: values.level,
          menuType: values.menuType,
          place: values.place,
          calories: values.calories,
        };

        userMarathonUpdate.fetch(preparedValues, `${userId}/participants/${selectedMarathon.id}`)
          .then((res) => {
            if (res?.id) {
              message.success('Изменения успешно сохранены!');
              userById.fetch(undefined, userId).then(() => {
                setOpen(false);
              });
            }
          });
      });
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (selectedMarathon) {
      setInitialValues({
        place: selectedMarathon.place,
        menuType: selectedMarathon.menuType,
        level: selectedMarathon.level,
        calories: selectedMarathon.calories,
      });
    }
  }, [selectedMarathon]);

  useEffect(() => {
    if (initialValues) {
      form.resetFields();
      form.setFieldsValue(initialValues);
      checkFormErrors(initialValues);
    }
  }, [initialValues]);

  useMessageError([userMarathonUpdate]);

  const checkFormErrors = (values: IUserMarathonUpdateForm) => {
    const hasErrors = Object.values(values).some((value) => value == null);

    setWithErrors(hasErrors);
  };

  return (
    <div>
      <Button onClick={() => setOpen(true)} disabled={disabled} className="flex items-center gap-[3px]">
        <EditOutlined />
        Редактировать
      </Button>
      <Modal
        open={open}
        centered
        title="Редактировать"
        cancelText="Отмена"
        okText="Сохранить"
        okButtonProps={{
          type: 'primary',
          loading: userMarathonUpdate.loading || userById.loading,
          disabled: isFormWithErrors,
        }}
        cancelButtonProps={{ disabled: userMarathonUpdate.loading }}
        width={510}
        onOk={() => handleSubmit()}
        onCancel={handleClose}
        maskClosable={!userMarathonUpdate.loading}
        destroyOnClose
        {...rest}
      >
        <Form<IUserMarathonUpdateForm>
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
          initialValues={initialValues}
          onValuesChange={() => checkFormErrors(form.getFieldsValue())}
        >
          <Form.Item name="menuType" label="Тип меню">
            <SelectMenuType />
          </Form.Item>
          <Form.Item name="level" label="Интенсивность тренировок">
            <SelectLevel />
          </Form.Item>
          <Form.Item name="place" label="Тип тренировок">
            <SelectPlace />
          </Form.Item>
          <Form.Item name="calories" label="Калораж меню">
            <SelectCalories />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
