export enum WeekDaysEnum {
  mon = 'Пн.',
  tue = 'Вт.',
  wed = 'Ср.',
  thu = 'Чт.',
  fri = 'Пт.',
  sat = 'Сб.',
  sun = 'Вс.',
}

export default {
  WeekDaysEnum,
};
