import React, { useEffect } from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { LeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import OTPVerification from '../../../../Common/Auth/OTPVerification';
import AlertSuccess from '../Form/Alert/Success';
import { useAuthState } from '../../../../../store/auth';

import styles from './index.module.scss';

interface OTPCodeProps {
  email: string
  onBack: () => void
}

function OTPCode({ email, onBack }: OTPCodeProps): React.ReactNode {
  const {
    authorized, user, OTP,
  } = useAuthState();
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (authorized && user && !OTP.error && !OTP.response) {
      navigate('/');
    }
  }, [authorized, user, OTP.error, OTP.response]);

  return (
    <div
      className={styles.wrapper}
    >
      <OTPVerification
        email={email}
        nameBtnFinish="Log in"
        alertSuccess={<AlertSuccess />}
        afterBtnFinish={(
          <Button
            type="text"
            onClick={onBack}
            size="large"
            style={{ color: '#8C8C8C' }}
            icon={<LeftOutlined />}
          >
            {t('Back')}
          </Button>
        )}
      />
    </div>
  );
}

export default OTPCode;
