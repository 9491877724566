import React, { useEffect, useState } from 'react';
import { Layout as AntdLayout, Button, theme } from 'antd';
import {
  LogoutOutlined, RightOutlined,
} from '@ant-design/icons';
import clsx from 'clsx';
import { useMediaQuery } from 'react-responsive';
import { NavLink, useNavigate } from 'react-router-dom';
import { signOut } from '@triare/auth-redux/dist/saga/auth/signOut';
import { Header } from 'antd/es/layout/layout';
import Menu from './Menu';
import { Logo } from '../Common/Icon';
import ReduxRedirect from '../Common/ReduxRedirect';
import { useSimpleModal } from '../Common/Modal/Simple';
import store from '../../store';

import styles from './index.module.scss';
import { useSidebarProvider } from '../../context/sidebar';

const { Sider, Content } = AntdLayout;

interface LayoutProps {
  HeaderMenu?: React.FC;
  headerMenu?: React.ReactNode;
  transparent?: boolean;
  disableHeaderMenu?: boolean;
  children: React.ReactNode;
  collapsedByDefault?: boolean;
  minWidth?: boolean;
}

let defaultStateCollapsed = false;

function Layout({
  disableHeaderMenu,
  HeaderMenu,
  headerMenu,
  transparent,
  children,
  collapsedByDefault,
  minWidth,
}: LayoutProps): React.ReactNode {
  const navigate = useNavigate();
  const { open, contextHolder } = useSimpleModal();
  const { collapsed, handleCollapsedChanges } = useSidebarProvider();
  // const [collapsed, setCollapsed] = useState(defaultStateCollapsed);
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const isTablet = useMediaQuery({
    query: '(max-width: 1024px)',
  });
  const disableMenu = disableHeaderMenu || (!HeaderMenu && !headerMenu);

  useEffect(() => {
    defaultStateCollapsed = collapsed;
  }, [collapsed]);

  useEffect(() => {
    if ((isTablet && !disableMenu) || collapsedByDefault) {
      handleCollapsedChanges(true);
    } else {
      handleCollapsedChanges(false);
    }
  }, [isTablet, disableMenu]);

  return (
    <>
      <ReduxRedirect />
      <AntdLayout hasSider className={styles.layout}>
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          className={clsx(styles.sider, { [styles.absolute]: isTablet && !disableMenu })}
          width={224}
          collapsedWidth={48}
        >
          <NavLink to="/" className={clsx(styles.logo, { [styles.collapsed]: collapsed })}>
            <Logo size="small" className={styles.logoSvg} />
          </NavLink>

          <div className={clsx(styles.siderMenu, { [styles.collapsed]: collapsed })}>
            <Menu />
          </div>

          <div className={clsx(styles.logoutWrapper, {
            [styles.collapsedLogout]: collapsed,
          })}
          >
            <Button
              id="btn-logout"
              className={styles.logout}
              onClick={() => {
                open({
                  icon: <LogoutOutlined style={{ color: '#FF4D4F' }} />,
                  title: 'Выйти',
                  content: 'Вы уверены, что хотите выйти из аккаунта?',
                  cancelText: 'Отмена',
                  centered: true,
                  okText: 'Выйти',
                  onOk: () => {
                    store.dispatch(signOut());
                    navigate('/');
                  },
                  maskClosable: true,
                });
              }}
              type="text"
              title="Выйти"
            >
              <LogoutOutlined />
              {!collapsed ? <span style={{ marginLeft: 8 }}>Выйти</span> : null}
            </Button>
          </div>
          {contextHolder}

          <Button
            id="btn-collapse"
            type="text"
            onClick={() => handleCollapsedChanges(!collapsed)}
            className={clsx(styles.btn, { [styles.collapsed]: collapsed })}
            icon={(
              <RightOutlined
                className={styles.collapseIcon}
                style={{ transform: `rotate(${collapsed ? '0deg' : '180deg'})` }}
              />
            )}
          />
        </Sider>
        <AntdLayout className={clsx(styles.wrapper, { [styles.tablet]: isTablet && !disableMenu })}>
          {disableMenu ? null : (
            <Header
              style={{ background: colorBgContainer }}
              className={clsx(styles.header, { [styles.headerLogo]: disableMenu })}
            >
              {HeaderMenu ? <HeaderMenu /> : headerMenu}
            </Header>
          )}

          <div className={clsx(styles.scroll, {
            [styles.collapsed]: collapsed,
            [styles.withHeader]: !disableMenu,
          })}
          >
            <Content
              className={clsx(
                styles.content,
                { [styles.transparent]: transparent },
                { [styles.minWidth]: isTablet && minWidth },
              )}
              id="layout-content"
            >
              {children}
            </Content>
          </div>
        </AntdLayout>
      </AntdLayout>
    </>
  );
}

Layout.defaultProps = {
  disableHeaderMenu: false,
  collapsedByDefault: false,
  transparent: undefined,
  HeaderMenu: undefined,
  headerMenu: undefined,
  minWidth: undefined,
};

export default Layout;
