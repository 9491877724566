import React, { useEffect } from 'react';
import {
  Button,
  Col, Divider, Form, Input, InputNumber, Row, Select, Switch, Typography, UploadFile,
} from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { enumToOptionsArray } from '../../../../utils';
import { AvailabilityEnum } from '../../../../enums';
import SelectContentStatus from '../../../Common/Selects/SelectStatus/SelectContentStatus';
import QuillRichTextEditor from '../../../Common/QuillRichTextEditor';
import { createRulesForAntd, validationRecipe, messages as errorMessages } from '../../../../utils/validations';
import {
  MeasureEnum, MenuTypeEnum, PreparationTimeEnum, RecipesCategoryEnum,
} from '../../../../enums/recipes';
import ImageUpload from '../../../Common/Uploaders/ImageUpload';
import { Ingredient } from '../../../../hooks/api/recipes';

import styles from './index.module.scss';

const validationRules = createRulesForAntd(validationRecipe);

export const initialIngredientState: Partial<Ingredient> = {
  itemRU: '',
  itemUA: '',
  value: 0,
  measure: 'g',
};

const defaultSelectProps = { placeholder: 'Выберите', allowClear: true };
const ingredientsRules = [{ required: true, message: errorMessages.required }];
const disabledIngredientField = ['not-specified', 'by-taste'];

interface RecipeFormItems {
  bannerFile: UploadFile[];
  setBannerFile: React.Dispatch<React.SetStateAction<UploadFile[]>>;
  fileList: UploadFile[];
  setFileList: React.Dispatch<React.SetStateAction<UploadFile[]>>;
}

function RecipeFormItems({
  bannerFile, setBannerFile, fileList, setFileList,
}: RecipeFormItems) {
  const form = useFormInstance();

  const ingredientsWatch = Form.useWatch('ingredients', form);

  useEffect(() => {
    form.setFieldValue('titleUrl', bannerFile[0]?.url ? bannerFile[0].url : '');
    if (bannerFile[0]?.url) {
      form.validateFields(['titleUrl']);
    }
  }, [bannerFile]);

  useEffect(() => {
    form.setFieldValue('imageUrls', fileList.map((img) => img.url));
    if (fileList.length) {
      form.validateFields(['imageUrls']);
    }
  }, [fileList]);

  return (
    <Row gutter={24}>
      <Col span={12}>
        <Form.Item name="nameRU" label="Название (RU)" rules={[{ required: true, ...validationRules }]}>
          <Input placeholder="Введите" />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item name="nameUA" label="Название (UA)" rules={[{ required: true, ...validationRules }]}>
          <Input placeholder="Введите" />
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item name="availability" label="Доступность" rules={[{ required: true, ...validationRules }]}>
          <Select options={enumToOptionsArray(AvailabilityEnum)} {...defaultSelectProps} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item name="contentState" label="Статус">
          <SelectContentStatus />
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item name="forCatalog" label="Отображение в каталоге" valuePropName="checked">
          <Switch checkedChildren="Да" unCheckedChildren="Нет" />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item name="menuType" label="Тип меню" rules={[{ required: true, ...validationRules }]}>
          <Select options={enumToOptionsArray(MenuTypeEnum)} {...defaultSelectProps} />
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item name="category" label="Категория" rules={[{ required: true, ...validationRules }]}>
          <Select options={enumToOptionsArray(RecipesCategoryEnum)} {...defaultSelectProps} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name="calories"
          label="Калорийность, ккал"
          rules={[{ required: true, message: errorMessages.required }]}
        >
          <InputNumber placeholder="Введите" style={{ width: '100%' }} min={0} />
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item name="pfc" label="Б/Ж/У" rules={[{ required: true, ...validationRules }]}>
          <Input placeholder="Введите" />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item name="preparingTime" label="Время приготовления" rules={[{ required: true, ...validationRules }]}>
          <Select options={enumToOptionsArray(PreparationTimeEnum)} {...defaultSelectProps} />
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item
          name="portions"
          label="Кол-во порций"
          rules={[{ required: true, message: errorMessages.required }]}
        >
          <InputNumber placeholder="Введите" style={{ width: '100%' }} min={1} max={3} />
        </Form.Item>
      </Col>

      <Col span={24}>
        <div className="image-banner-block">
          <ImageUpload
            required
            name="titleUrl"
            multiple={false}
            fileList={bannerFile}
            setFileList={setBannerFile}
          />
          <div>
            <Typography.Paragraph className="upload-paragraph required">
              Фото обложки
            </Typography.Paragraph>
            <Typography.Paragraph type="secondary" className="upload-text">
              Требования к файлу: JPG, PNG. Рекомендованный минимальный размер 1125 х 1125 px.
            </Typography.Paragraph>
          </div>
        </div>
      </Col>
      <Col span={24}>
        <div className="container-with-image">
          <div>
            <Typography.Paragraph className="upload-paragraph required">
              Фото Рецепта
            </Typography.Paragraph>
            <Typography.Paragraph type="secondary" className="upload-text">
              Требования к файлу: JPG, PNG. Рекомендованный минимальный размер 1125 х 1125 px.
            </Typography.Paragraph>
          </div>
          <ImageUpload
            required
            name="imageUrls"
            multiple
            fileList={fileList}
            setFileList={setFileList}
          />
        </div>
      </Col>

      {/* TODO create reusable component for ingredients? and use same on marathon update */}
      <Col span={24}>
        <Form.List name="ingredients">
          {(fields, { add, remove }) => (
            <div className="flex-column-wrapper">
              {fields.map((field) => (
                <div className={styles.fieldGrid} key={field.key}>
                  <Row gutter={[12, 12]} className={styles.row}>
                    <Col span={14}>
                      <Form.Item
                        name={[field.name, 'itemRU']}
                        className="no-space-form-item"
                        label="Ингредиенты"
                        rules={ingredientsRules}
                      >
                        <Input placeholder="Ввведите" addonBefore="RU" />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        name={[field.name, 'value']}
                        label="Количество"
                        className="no-space-form-item"
                        rules={ingredientsRules}
                      >
                        <InputNumber
                          min={0}
                          placeholder="Введите"
                          style={{ width: '100%' }}
                          disabled={disabledIngredientField
                            .includes(ingredientsWatch?.[field.name]?.measure)}
                          precision={1}
                          formatter={(value) => `${Math.round((value || 0) * 10) / 10}`}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        name={[field.name, 'measure']}
                        label=" "
                        className="no-space-form-item"
                      >
                        <Select
                          options={enumToOptionsArray(MeasureEnum)}
                          placeholder="Не указано"
                          onChange={(val) => {
                            if (val) {
                              if (disabledIngredientField.includes(val || '')) {
                                form.setFieldValue(['ingredients', field.name, 'value'], 0);
                              }
                            }
                          }}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={14}>
                      <Form.Item name={[field.name, 'itemUA']} className="no-space-form-item" rules={ingredientsRules}>
                        <Input placeholder="Ввведите" addonBefore="UA" />
                      </Form.Item>
                    </Col>
                    <Divider style={{ margin: '16px 0' }} />
                  </Row>

                  <Form.Item label=" ">
                    <Button
                      size="middle"
                      type="default"
                      icon={<DeleteOutlined style={{ fontSize: 18 }} />}
                      onClick={() => remove(field.name)}
                      title="Удалить"
                      disabled={fields.length === 1}
                    />
                  </Form.Item>
                </div>
              ))}
              <Button
                type="default"
                onClick={() => add(initialIngredientState)}
                icon={<PlusOutlined />}
                className={styles.addBtn}
              >
                Добавить ингредиент
              </Button>
            </div>
          )}
        </Form.List>
      </Col>

      <Col span={12}>
        <Form.Item name="contentRU" label="Способ приготовления (RU)">
          <QuillRichTextEditor />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item name="contentUA" label="Способ приготовления (UA)">
          <QuillRichTextEditor />
        </Form.Item>
      </Col>
    </Row>
  );
}

export default RecipeFormItems;
