import {
  DefaultFetchError, FetchCreate, FetchDelete,
  FetchGetId, FetchSuccess, FetchUpdate, useFetchCreate, useFetchDelete,
  useFetchGetId, useFetchUpdate,
} from '../fetch';
import { Training } from './trainings';

export interface Program {
  id: string
  name: string
  description: string
  nameUA: string
  nameRU: string
  descriptionUA: string
  descriptionRU: string
  availability: string
  contentState: string
  weeks: Week[]
  createdAt: string
  updatedAt: string
}

export interface Week {
  id?: string
  isoWeek: number
  year: number
  trainingDays: TrainingDay[]
}

export interface TrainingDay {
  id?: string
  date: string
  trainings: Training[]
}

export const useProgramSixWeeksGet = (path?: string): FetchGetId<Week[]> => useFetchGetId(
  'programs/current/six-weeks',
  path,
  { autoStart: false, startStateLoading: false },
);

export const useProgramUpdate = (id?: string): FetchUpdate<Week, DefaultFetchError, Week> => (
  useFetchUpdate('programs/current/weeks', id)
);
export const useProgramWeekDelete = (id?: string): FetchDelete<FetchSuccess> => (
  useFetchDelete('programs/current/weeks', id)
);

export const useProgramWeekCreate = (): FetchCreate<Week, DefaultFetchError, Week> => (
  useFetchCreate('programs/current/weeks')
);
