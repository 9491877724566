import React, { useEffect, useState } from 'react';
import { SignInAction, SignInResponse } from '@triare/auth-redux/dist/saga/auth/signIn';
import OTPCode from './OTPCode';
import { useAuthState } from '../../../../store/auth';
import Wrapper from '../Wrapper';

export interface SignInProps {
  // eslint-disable-next-line
  content: any
}

export default function SignIn({ content: Content }: SignInProps) {
  const {
    signIn: { response },
  } = useAuthState();
  const [state, setState] = useState<SignInResponse>();
  const [requestData, setRequestData] = useState<SignInAction | null>(null);

  useEffect(() => {
    if (response && response.secretKey) {
      setState(response);
    }
  }, [response]);

  return (
    <Wrapper>
      {state && state.secretKey && requestData ? (
        <OTPCode
          onBack={() => setState(undefined)}
          email={requestData?.email || 'email'}
        />
      ) : (
        <Content onSubmit={setRequestData} />
      )}
    </Wrapper>
  );
}
