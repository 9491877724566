import React, { useCallback, useState } from 'react';
import {
  App, Button, Col, Collapse, Form, Row, Select,
} from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { PlusOutlined } from '@ant-design/icons';
import clsx from 'clsx';
import { MarathonTrainingDay, MarathonTrainingTime, MarathonTrainingWeek } from '../../../../../hooks/api/marathons';
import { Training, useTrainingId } from '../../../../../hooks/api/trainings';
import DayPicker, { DayProps } from '../../../../Common/Pickers/DayPicker';
import TrainingsListModal from '../../../Trainings/ModalTable';
import WeekPicker from '../../../../Common/Pickers/WeekPicker';
import TrainingRow from '../../../Trainings/TrainingRowItem';
import { LevelType, Option, PlaceType } from '../../../../../types';
import { getDayName } from '../../../../../utils';

/** Initial form states */
const initialTrainingDayState = {
  id: '',
  morningTrainings: [],
  mainTrainings: [],
  eveningTrainings: [],
};

export const initialMarathonTrainingWeekState = {
  id: crypto.randomUUID(),
  trainingDays: Array.from({ length: 7 }).map(() => (initialTrainingDayState as MarathonTrainingDay)),
} as MarathonTrainingWeek;

interface MarathonFormItemsTrainingsProps {
  defaultSearchParams?: {
    level?: LevelType;
    place?: PlaceType;
  };
}

function MarathonFormItemsTrainings({ defaultSearchParams }: MarathonFormItemsTrainingsProps) {
  const { message } = App.useApp();
  const form = useFormInstance();
  const trainingById = useTrainingId();

  const [selectedDay, setSelectedDay] = useState<DayProps>({ day: 'mon', dayIndex: 0 });
  const [selectedWeekIndex, setSelectedWeekIndex] = useState<number>(0);

  const weeksWatch: MarathonTrainingWeek[] = Form.useWatch('weeks', form) || [];

  /** Collapse controls */
  const [collapseKey, setCollapseKey] = useState<MarathonTrainingTime[] | string[]>(['morningTrainings']);

  /** Add new training func */
  const [modalState, setModalState] = useState<{ open: boolean; type: MarathonTrainingTime | '' }>(
    { open: false, type: '' },
  );
  const handleModal = (open: boolean, type?: MarathonTrainingTime | '') => {
    setModalState({ open, type: type || '' });
  };

  const [selectedTrainingsIds, setSelectedTrainingsIds] = useState<number[]>([]);

  const handleAddTrainings = async (timeType: MarathonTrainingTime) => {
    let selectedTrainings: Training[] = [];

    const selectedWeek = weeksWatch[selectedWeekIndex];

    const currentDayTrainingsIds = selectedWeek?.trainingDays
      .find((day, index) => index === selectedDay?.dayIndex)
      ?.[timeType].map((el) => el.id);

    const uniqueTrainingsIds = selectedTrainingsIds.map((el) => el.toString())
      .filter((id) => !currentDayTrainingsIds?.includes(id));

    await Promise.all(uniqueTrainingsIds?.map((trainingId) => (
      trainingById.fetch(undefined, trainingId)
    ))).then((res) => {
      if (res.every((el) => el?.id)) {
        const normalizedResponse = res.map((el) => {
          // eslint-disable-next-line no-param-reassign
          delete el?.usedIn;

          return el;
        });

        selectedTrainings = [...selectedTrainings, ...normalizedResponse as Training[]];
      }
    }).catch((err) => {
      if (err) {
        message.error('Ошибка при добавлении тренировки.');
      }
    });

    /** Update value in form after request */
    const namePath = ['weeks', selectedWeekIndex, 'trainingDays', selectedDay.dayIndex, timeType];
    const prevValue = form.getFieldValue(namePath);

    form.setFieldValue(namePath, [...prevValue, ...selectedTrainings]);
    setSelectedTrainingsIds([]);
  };

  const collapseDayItems: { label: string; key: MarathonTrainingTime }[] = [
    { label: 'Зарядка', key: 'morningTrainings' },
    { label: 'Основная тренировка', key: 'mainTrainings' },
    { label: 'Вечернее задание', key: 'eveningTrainings' },
  ];

  /** Copy-Select func */
  const generateCopyOptions = useCallback((timeType: MarathonTrainingTime): Option[] => {
    if (weeksWatch?.length) {
      const options: Option[] = weeksWatch.map((week, weekIndex) => ({
        label: `Неделя ${weekIndex + 1}`,
        value: weekIndex + 1,
        options: week.trainingDays.map((day, dayIndex) => ({
          label: `${weekIndex + 1} - ${getDayName(dayIndex)}`,
          value: `${weekIndex}.${dayIndex}.${timeType}`,
          disabled: (selectedDay.dayIndex === dayIndex) && (weekIndex === selectedWeekIndex),
        })),
      }));

      return options;
    }

    return [];
  }, [weeksWatch?.length, selectedDay, selectedWeekIndex]);

  const [selectCopy] = useState<ThreeDotSeparatedValues | null>(null);

  /** Name path have 3 items. /WeekIndex.DayIndex.TimeType/ */
  type ThreeDotSeparatedValues = `${string}.${string}.${string}`;
  const copyTrainings = (namePath: ThreeDotSeparatedValues) => {
    const namePathValues = namePath.split('.');

    /** Get value from selected day */
    const namePathWhichWeCopy = ['weeks', namePathValues[0], 'trainingDays', namePathValues[1], namePathValues[2]];
    const copiedTrainingsValue = form.getFieldValue(namePathWhichWeCopy) || [];

    if (!copiedTrainingsValue?.length) {
      message.warning('В выбранном дне нет данных!');
    }

    const namePathToBeChanged = ['weeks', selectedWeekIndex, 'trainingDays', selectedDay.dayIndex, namePathValues[2]];
    const prevValue = form.getFieldValue(namePathToBeChanged) || [];

    /** Remove duplicates */
    const newValue = [...prevValue, ...copiedTrainingsValue]
      .filter((obj, index, self) => (
        index === self.findIndex((o) => o.id === obj.id)
      ));

    /** Set new values */
    form.setFieldValue(namePathToBeChanged, newValue);
    if (copiedTrainingsValue?.length) {
      message.success('Контент успешно скопирован.');
    }
  };

  return (
    <>
      <TrainingsListModal
        isOpenModal={modalState.open}
        isLoading={trainingById.loading}
        handleAddTrainings={() => handleAddTrainings(modalState.type || 'morningTrainings')}
        handleModal={handleModal}
        selectedTrainingsIds={selectedTrainingsIds}
        handleSelectedRows={setSelectedTrainingsIds}
        defaultParams={defaultSearchParams}
      />
      <Row gutter={24}>
        <Col span={24}>
          <div className="week-day-picker-container">
            <WeekPicker
              weeks={weeksWatch || []}
              selectedWeek={selectedWeekIndex}
              onClick={(weekIndex) => setSelectedWeekIndex(weekIndex)}
              label="Неделя марафона"
            />
            <DayPicker<MarathonTrainingWeek>
              selectedDay={selectedDay?.dayIndex}
              onDayClick={(day) => setSelectedDay(day)}
              isFilledProps={{
                marathonTrain: {
                  selectedWeek: weeksWatch?.[selectedWeekIndex] || [],
                  trainingTime: collapseKey?.[0] as MarathonTrainingTime,
                },
              }}
            />
          </div>
        </Col>

        <Col span={24}>
          <Form.List name="weeks">
            {(weeksFields) => (
              <div>
                {weeksFields.map((weekField, weekIndex) => (

                  <div key={weekField.key} className="weekField">
                    <Form.List name={[weekField.name, 'trainingDays']}>
                      {(trainingDaysFields) => (
                        <>
                          {trainingDaysFields.map((trainingDayField, trainingDayIndex) => (
                            <div
                              className={clsx('flex-column-wrapper')}
                              key={trainingDayField.key}
                            >
                              {/** Display only <Collapse /> of current selected day & week: */}
                              {selectedWeekIndex === weekIndex && selectedDay.dayIndex === trainingDayIndex
                                ? (
                                  <Collapse
                                    accordion
                                    activeKey={collapseKey}
                                    onChange={(key) => (
                                      Array.isArray(key) ? setCollapseKey(key) : setCollapseKey([key])
                                    )}
                                    items={collapseDayItems.map((collapseField) => ({
                                      key: collapseField.key,
                                      label: collapseField.label,
                                      children: (
                                        <>
                                          <Form.List name={[trainingDayField.name, collapseField.key]}>
                                            {(trainingFields, { remove }) => (
                                              <>
                                                {trainingFields.map((trainingField, trainingIndex) => (
                                                  <TrainingRow
                                                    key={trainingField.key}
                                                    training={weeksWatch?.[weekIndex]?.trainingDays?.[trainingDayIndex]
                                                      ?.[collapseField.key]?.[trainingIndex]}
                                                    onRemove={() => remove(trainingField.name)}
                                                  />
                                                ))}
                                              </>
                                            )}
                                          </Form.List>
                                          <Col span={24}>
                                            <Button
                                              icon={<PlusOutlined />}
                                              onClick={() => handleModal(true, collapseField.key)}
                                            >
                                              Добавить тренировку
                                            </Button>
                                          </Col>
                                        </>
                                      ),
                                      extra: (
                                        <Select
                                          options={generateCopyOptions(collapseField.key)}
                                          placeholder="Копировать из..."
                                          onClick={(e) => {
                                            if (collapseField.key === collapseKey?.[0]) {
                                              e.stopPropagation();
                                            }
                                          }}
                                          value={selectCopy}
                                          onChange={(value) => { copyTrainings(value); }}
                                          popupMatchSelectWidth={false}
                                          style={{ minWidth: '220px' }}
                                        />
                                      ),
                                    }))}
                                  />
                                ) : null}
                            </div>
                          ))}
                        </>
                      )}
                    </Form.List>
                  </div>

                ))}
              </div>
            )}
          </Form.List>
        </Col>
      </Row>
    </>
  );
}

MarathonFormItemsTrainings.defaultProps = {
  trainings: undefined,
  defaultSearchParams: undefined,
};

export default MarathonFormItemsTrainings;
