import React from 'react';
import { FileOutlined, PlayCircleFilled } from '@ant-design/icons';

import styles from './index.module.scss';

interface VideoThumbnail {
  videoUrl?: string;
  thumbnailUrl?: string;
  duration?: number;
}

function VideoThumbnail({
  videoUrl, thumbnailUrl, duration,
}: VideoThumbnail) {
  return (
    <div className={styles.thumbnail}>
      {thumbnailUrl ? (
        <div className={styles.linkContainer}>
          <a href={videoUrl} target="_blank" rel="noreferrer" className={styles.link}>
            <img src={thumbnailUrl} alt="video-thumbnail" />
            {videoUrl ? (<PlayCircleFilled className={styles.iconCentered} />) : null}
            {duration ? (
              <div className={styles.duration}>
                {`${duration} мин`}
              </div>
            ) : null}
          </a>
        </div>
      ) : (
        <div className={styles.noLink}>
          <FileOutlined className={styles.iconCentered} />
        </div>
      )}
    </div>
  );
}

VideoThumbnail.defaultProps = {
  videoUrl: '',
  thumbnailUrl: '',
  duration: 0,
};

export default VideoThumbnail;
