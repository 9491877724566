import { MarathonMenuMin, MarathonTrainingMin } from '../../../../../hooks/api/marathons';
import { LevelType } from '../../../../../types';

/** Trainings */
export const sortByLevel = (trainings: MarathonTrainingMin[]) => {
  const sortByLevelFunc = (a: MarathonTrainingMin, b: MarathonTrainingMin) => {
    const levelOrder: Record<LevelType, number> = {
      low: 1,
      medium: 2,
      high: 3,
    };

    return levelOrder[a.level] - levelOrder[b.level];
  };

  return trainings.sort(sortByLevelFunc);
};

export const sortTrainings = (trainings: MarathonTrainingMin[]) => {
  const houseTrainings = sortByLevel(trainings.filter(({ place }) => place === 'house'));
  const gymTrainings = sortByLevel(trainings.filter(({ place }) => place === 'gym'));

  return [...houseTrainings, ...gymTrainings];
};

/** Menus */
export const sortByCalories = (menus: MarathonMenuMin[]) => {
  const sortByCaloriesFunc = (a: MarathonMenuMin, b: MarathonMenuMin) => a.calories - b.calories;

  return menus.sort(sortByCaloriesFunc);
};

export const sortShoppingMenus = (menus: MarathonMenuMin[]) => {
  const classicMenu = sortByCalories(menus.filter(({ menuType }) => menuType === 'classic'));
  const veganMenu = sortByCalories(menus.filter(({ menuType }) => menuType === 'vegan'));

  return [...classicMenu, ...veganMenu];
};
