/** For filters: */
export enum TrainingCategoryEnum {
  general = 'Другое',

  'warm-up' = 'Разминка', /* Разминка */
  'cool-down' = 'Заминка', /* Заминка */
  stretching = 'Зарядка', /* Зарядка / Утренняя зарядка */
  cardio = 'Кардио', /* Кардио */
  dance = 'Танцевальная', /* Танцевальная */
  strength = 'Силовая', /* Силовая */
}

export enum TrainingTargetEnum {
  legs = 'Ноги',
  glutes = 'Ягодицы',
  upperBody = 'Верх тела',
  core = 'Пресс',
  fullBody = 'Все тело',
}

export enum EquipmentEnum {
  dumbbells = 'Гантели',
  elasticBand = 'Эластичная лента',
  fitBall = 'Фитбол',
  chair = 'Стул',
}

export enum LevelEnum {
  low = 'Новичок',
  medium = 'Средний',
  high = 'Высокий',
}

export enum DurationEnum {
  short = '< 15 мин',
  medium = '15-45 мин',
  long = '> 45 мин',
}

export enum PlaceEnum {
  house = 'Дом',
  gym = 'Зал'
}
