import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Card from 'antd/es/card/Card';
import { useTrainingId } from '../../../../hooks/api/trainings';
import TrainingHouseContent from './HouseContent';
import { useMessageError } from '../../../../hooks/common';
import TrainingGymContent from './GymContent';

function TrainingUpdate() {
  const { id = '' } = useParams<{ id: string; }>();
  const trainingById = useTrainingId();

  const fetch = () => {
    trainingById.fetch(undefined, id);
  };

  useEffect(() => {
    if (id) {
      fetch();
    }
  }, [id]);

  useMessageError([trainingById]);

  return (
    <div>
      {!trainingById.loading ? (
        <div>
          {trainingById.data?.place === 'house'
            ? (
              <TrainingHouseContent trainingById={trainingById} />
            ) : (
              <TrainingGymContent trainingById={trainingById} />
            )}
        </div>
      ) : (
        <Card loading />
      )}
    </div>
  );
}

export default TrainingUpdate;
