export type UaLangType = 'ua' | 'uk-UA' | 'UA-UA' | 'UA';
export type RuLangType = 'ru' | 'ru-RU' | 'RU-RU' | 'RU';
export type EnLangType = 'en' | 'en-US' | 'EN-EN' | 'EN';

const uaLang = 'Украинский';
const ruLang = 'Русский';
const enLang = 'Английский';

export enum LanguageMapEnum {
  ua = uaLang,
  'uk' = uaLang,
  'uk-UA' = uaLang,
  'UA-UA' = uaLang,
  'UA' = uaLang,

  ru = ruLang,
  'ru-RU' = ruLang,
  'RU-RU' = ruLang,
  'RU' = ruLang,

  en = enLang,
  'en-US' = enLang,
  'EN-EN' = enLang,
  'EN' = enLang,
}
