import React from 'react';
import Content from './Content';

export default function MarathonPrizes(): React.ReactNode {
  return (
    <div>
      <Content />
    </div>
  );
}
