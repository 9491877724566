import React from 'react';
import { Button } from 'antd';
import clsx from 'clsx';
import { ComplexWeek } from '../../../../hooks/api/complexes';
import { WeekDaysEnum } from '../../../../enums/program';
import {
  MarathonMenuTime,
  MarathonMenuWeek,
  MarathonTrainingTime,
  MarathonTrainingWeek,
} from '../../../../hooks/api/marathons';

import styles from './index.module.scss';

export type DayIndex = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export type DayProps = { day: DaysType, dayIndex: DayIndex };
export type DaysType = 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat' | 'sun';
const days: DayProps[] = [
  { day: 'mon', dayIndex: 0 },
  { day: 'tue', dayIndex: 1 },
  { day: 'wed', dayIndex: 2 },
  { day: 'thu', dayIndex: 3 },
  { day: 'fri', dayIndex: 4 },
  { day: 'sat', dayIndex: 5 },
  { day: 'sun', dayIndex: 6 },
];

interface DayPicker<T> {
  selectedDay: DayIndex | undefined;
  onDayClick: (props: DayProps) => void;

  weeks?: T[];
  isFilledProps?: {
    marathonTrain?: {
      selectedWeek: MarathonTrainingWeek;
      trainingTime: MarathonTrainingTime;
    },
    marathonMenu?: {
      selectedWeek: MarathonMenuWeek;
      menuTime: MarathonMenuTime;
    }
    complex?: {
      selectedWeek: ComplexWeek;
    };
  };
}

function DayPicker<T = ComplexWeek>({
  selectedDay,
  onDayClick,
  isFilledProps,
}: DayPicker<T>) {
  /** Marathon trainings */
  const selectedMarWeek = isFilledProps?.marathonTrain?.selectedWeek;
  const marTrainingTime = isFilledProps?.marathonTrain?.trainingTime || 'eveningTrainings';

  /** Marathon menu */
  const selectedMarMenuWeek = isFilledProps?.marathonMenu?.selectedWeek;
  const marMenuTime = isFilledProps?.marathonMenu?.menuTime || 'breakfast';

  /** Complexes */
  const selectedComplexWeek = isFilledProps?.complex?.selectedWeek;

  const getIsFilled = (dayIndex: number): boolean => {
    if (isFilledProps) {
      if (isFilledProps.marathonTrain) {
        return !!selectedMarWeek?.trainingDays?.[dayIndex]?.[marTrainingTime]?.length;
      }
      if (isFilledProps.marathonMenu) {
        return !!selectedMarMenuWeek?.menuDays?.[dayIndex]?.[marMenuTime]?.length;
      }
      if (isFilledProps.complex) {
        return !!selectedComplexWeek?.trainingDays?.[dayIndex]?.trainings?.length;
      }

      return false;
    }

    return false;
  };

  return (
    <div className={styles.weekDays}>
      <div>День недели</div>
      <div className={styles.days}>
        {days.map(({ day, dayIndex }) => (
          <Button
            key={day}
            className={clsx(styles.day, {
              [styles.selectedDay]: dayIndex === selectedDay,
              [styles.filled]: getIsFilled(dayIndex),
            })}
            onClick={() => onDayClick({ day, dayIndex })}
          >
            {Object.values(WeekDaysEnum)[dayIndex]}
          </Button>
        ))}
      </div>
    </div>
  );
}

DayPicker.defaultProps = {
  weeks: [],
  selectedWeek: undefined,
  isFilledProps: undefined,
};

export default DayPicker;
