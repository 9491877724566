import React from 'react';
import { SelectProps } from 'antd/lib/select';
import { Select } from 'antd';
import { UserSubscription } from '../../../../hooks/api/user';
import { Option } from '../../../../types';

function SelectMenuType({ ...props }: SelectProps<UserSubscription, Option>) {
  return (
    <Select {...props} />
  );
}

enum MenuTypeEnum {
  'Классическое' = 'classic',
  'Вегетарианское' = 'vegan',
}

SelectMenuType.defaultProps = {
  options: Object.entries(MenuTypeEnum).map(([key, value]) => ({ label: key, value })),
  allowClear: true,
  placeholder: 'Выберите',
};

export default SelectMenuType;
