import React, { useEffect, useState } from 'react';
import {
  App, Button, Form, Input,
} from 'antd';
import { ItemType } from 'antd/es/breadcrumb/Breadcrumb';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import SinglePageContainer from '../../../../Common/SinglePageContainer';
import {
  CreateTrainingPayload,
  useTrainingUpdate,
  useTrainingDelete,
  useVideoThumbnail,
  Training,
} from '../../../../../hooks/api/trainings';
import { useSimpleModal } from '../../../../Common/Modal/Simple';
import { useMessageError } from '../../../../../hooks/common';
import VideoThumbnail from '../../../../Common/VideoThumbnail';
import FormTrainingHome from '../../Forms/House';
import TrainingUsedInTable from '../TrainingUsedInTable';
import { asyncDelay, filterObjectProps, isObjEqual } from '../../../../../utils';
import { useUnsavedChanges } from '../../../../../context/unsavedChanges';
import { FetchGetId } from '../../../../../hooks/fetch';

import styles from '../../Create/House/index.module.scss';

const breadcrumbs: ItemType[] = [
  {
    title: <NavLink to="/trainings">Тренировки</NavLink>,
  },
  {
    title: 'Редактировать тренировку (дом)',
  },
];

const initialState: Partial<CreateTrainingPayload> = {
  url: '',
  thumbnail: '',
  nameRU: '',
  nameUA: '',
  availability: undefined,
  contentState: undefined,
  level: undefined,
  place: undefined,
  category: undefined,
  target: [],
  duration: 0,
  equipment: [],
  descriptionRU: '',
  descriptionUA: '',
};

const keysToFilterInitial: (keyof Partial<Training>)[] = ['createdAt', 'updatedAt', 'id', 'usedIn', 'exercises'];

interface TrainingContentProps {
  trainingById: FetchGetId<Training>;
}

export default function TrainingHouseContent({ trainingById }: TrainingContentProps): React.ReactNode {
  const { id = '' } = useParams<{ id: string; }>();
  const navigate = useNavigate();
  const videoThumbnail = useVideoThumbnail();
  const trainingUpdate = useTrainingUpdate();
  const trainingDelete = useTrainingDelete();

  const [form] = Form.useForm();
  const { message } = App.useApp();
  const { open, contextHolder } = useSimpleModal();
  const {
    unsavedChanges, handleUnsavedChanges, htmlEditorTouched, handleEditorTouched,
  } = useUnsavedChanges();

  /** Handle Initial values */
  const [initialValues, setInitialValues] = useState(initialState);

  useEffect(() => {
    if (trainingById.data) {
      setInitialValues(filterObjectProps(trainingById.data, keysToFilterInitial));
      setRequestedVideoUrl(trainingById.data.url);
    }
  }, [trainingById.data]);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(initialValues);
    handleUnsavedChanges(false);
    asyncDelay(200).then(() => (handleEditorTouched(false)));
  }, [initialValues]);

  /** Handle thumbnail and video url */
  const urlWatch = Form.useWatch('url', form);
  const [requestedVideoUrl, setRequestedVideoUrl] = useState('');

  const fetchVideoThumbnail = (currentUrl: string) => {
    if (currentUrl) {
      videoThumbnail.fetch({ url: currentUrl })
        .then((res) => { if (res?.thumbnail) { setRequestedVideoUrl(currentUrl); } });
    }
  };

  useEffect(() => {
    if (videoThumbnail.data?.duration) {
      form.setFieldValue('duration', videoThumbnail.data.duration);
      form.setFieldValue('thumbnail', videoThumbnail.data.thumbnail);
      form.validateFields(['url', 'duration']);
    }
  }, [videoThumbnail.data?.duration]);

  const handleSubmit = () => {
    form.validateFields().then((values) => (
      trainingUpdate.fetch(values, id)
        .then((res) => {
          if (res?.id) {
            message.success('Изменения успешно сохранены.');
            setInitialValues(filterObjectProps(res, keysToFilterInitial));
          }
        })

    ));
  };

  /** Handle Delete */
  const handleDeleteClick = () => {
    if (!id) return;
    open({
      title: 'Удалить тренировку?',
      content: 'Вы уверены, что хотите навсегда удалить эту тренировку?',
      cancelText: 'Отмена',
      centered: true,
      okText: 'Удалить',
      okButtonProps: {
        type: 'primary',
        danger: true,
      },
      onOk: () => trainingDelete.fetch(id),
      maskClosable: true,
    });
  };

  useEffect(() => {
    if (trainingDelete.response?.status === 204) {
      handleUnsavedChanges(false).then(() => {
        message.success('Тренировка успешно удалена.');
        navigate('/trainings');
      });
    }
  }, [trainingDelete.response]);

  useMessageError([trainingUpdate, trainingDelete, videoThumbnail]);

  const handleFieldsChange = () => {
    handleUnsavedChanges(!isObjEqual(
      filterObjectProps(initialValues, ['descriptionRU', 'descriptionUA']),
      filterObjectProps(form.getFieldsValue(), ['descriptionRU', 'descriptionUA']),
    ));
  };

  return (
    <>
      {contextHolder}
      <SinglePageContainer
        breadcrumbItems={breadcrumbs}
        title="Редактировать тренировку (дом)"
        cardLoading={trainingById.loading}
        extra={(
          <>
            <Button type="primary" danger onClick={handleDeleteClick}>Удалить</Button>
            <Button
              type="primary"
              onClick={form.submit}
              loading={trainingUpdate.loading}
              disabled={requestedVideoUrl !== urlWatch || !(unsavedChanges || htmlEditorTouched)}
            >
              Сохранить
            </Button>
          </>
        )}
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={initialValues}
          onFinish={handleSubmit}
          name="training-update-house"
          onFieldsChange={handleFieldsChange}
        >
          <div className={styles.videoContainer}>
            <VideoThumbnail
              videoUrl={requestedVideoUrl}
              thumbnailUrl={videoThumbnail.data?.thumbnail || form.getFieldValue('thumbnail')}
            />

            <div className={styles.videoInput}>
              <Form.Item
                name="url"
                label="Ссылка на YouTube видео"
                rules={[
                  { required: true, message: 'Это поле обязательно к заполнению.' },
                  {
                    validator: (_, value) => {
                      if (requestedVideoUrl === urlWatch) { return Promise.resolve(); }

                      return Promise.reject(new Error(
                        'Ссылка не соответствует видео. Пожалуйста, обновите ссылку и получите данные повторно!',
                      ));
                    },
                  },
                ]}
              >
                <Input placeholder="Введите" autoComplete="off" />
              </Form.Item>
              <Button
                type="default"
                onClick={() => fetchVideoThumbnail(urlWatch)}
                loading={videoThumbnail.loading}
                disabled={requestedVideoUrl === urlWatch}
              >
                Обновить данные
              </Button>
            </div>
          </div>

          <FormTrainingHome />
        </Form>

        {trainingById.data?.usedIn ? <TrainingUsedInTable usedIn={trainingById.data.usedIn} /> : null}
      </SinglePageContainer>
    </>
  );
}
