import React, { useEffect, useState } from 'react';
import { ItemType } from 'antd/es/breadcrumb/Breadcrumb';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { App, Button, Form } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import SinglePageContainer from '../../../Common/SinglePageContainer';
import { useMessageError } from '../../../../hooks/common';
import MarathonFormItems from '../Form';
import {
  CreateMarathonPayload,
  Marathon, MarathonStatusType,
  useMarathonId,
  useMarathonUpdate,
  useMarathonDelete,
} from '../../../../hooks/api/marathons';
import { filterObjectProps, isObjEqual } from '../../../../utils';
import { useUnsavedChanges } from '../../../../context/unsavedChanges';
import EntitiesCollapse from '../Form/EntitiesCollapse';
import { useSimpleModal } from '../../../Common/Modal/Simple';
import GiftCertificateButton from '../GiftCertificate';

const breadcrumbs: ItemType[] = [
  {
    title: <NavLink to="/marathons">Марафоны</NavLink>,
  },
  {
    title: 'Редактировать марафон',
  },
];

export interface MarathonInitialVal extends Partial<Omit<CreateMarathonPayload, 'startDate'>> {
  gold_silver?: string;
  status?: MarathonStatusType;
  startDate?: Dayjs;
}

export const initialMarathonMainState: MarathonInitialVal = {
  nameRU: '',
  nameUA: '',
  durationInWeeks: 4,
  startDate: dayjs(),
  status: 'new',
  gold_silver: '0/0',
};

export const prepareMarathonInitialValues = (data: Marathon, isCopy?: boolean) => {
  const {
    nameRU, nameUA, startDate, durationInWeeks, goldNumber, silverNumber, status,
  } = data;

  return {
    nameRU: isCopy ? `${nameRU} (Копия)` : nameRU,
    nameUA: isCopy ? `${nameUA} (Копия)` : nameUA,
    startDate: isCopy ? undefined : dayjs(startDate),
    durationInWeeks,
    gold_silver: isCopy ? '0/0' : `${goldNumber || 0}/${silverNumber || 0}`,
    status: isCopy ? 'new' : status,
  };
};

const keysToFilterInitial: (keyof Partial<Marathon>)[] = ['createdAt', 'updatedAt', 'id',
  'contestRulesPhotoUA', 'contestRulesPhotoRU', 'contestRulesVideoRU', 'contestRulesVideoRU', 'lectures',
  'shoppings', 'menus', 'trainings', 'photoRulesVideoUrl', 'videoRulesVideoUrl'];

export default function MarathonUpdate() {
  const { id = '' } = useParams<{ id: string; }>();
  const marathonById = useMarathonId();
  const marathonUpdate = useMarathonUpdate();
  const marathonDelete = useMarathonDelete();
  const navigate = useNavigate();

  const { open, contextHolder } = useSimpleModal();
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const { unsavedChanges, handleUnsavedChanges } = useUnsavedChanges();

  const fetch = () => {
    marathonById.fetch(undefined, id);
  };

  useEffect(() => {
    if (id) {
      fetch();
    }
  }, [id]);

  /** Handle Initial values */
  const [initialValues, setInitialValues] = useState<MarathonInitialVal>(initialMarathonMainState);

  useEffect(() => {
    if (marathonById.data) {
      setInitialValues(prepareMarathonInitialValues(marathonById.data));
    }
  }, [marathonById.data]);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(initialValues);
    handleUnsavedChanges(false);
  }, [initialValues]);

  /** Handle submit */
  const handleSubmit = () => {
    // If status of marathon is 'finished' then we create copy on submit.

    form.validateFields().then((values) => {
      const modifiedVal = {
        ...filterObjectProps(values, ['status', 'gold_silver']),
        startDate: dayjs(values?.startDate).format('YYYY-MM-DD'),
      };

      return (
        marathonUpdate.fetch(modifiedVal, id)
          .then((res) => {
            if (res?.id) {
              message.success('Изменения успешно сохранены.');
              setInitialValues(prepareMarathonInitialValues(res));
            }
          })
      );
    });
  };

  const handleSubmitClick = () => {
    form.submit();
  };

  /** Handle Delete */
  const handleDeleteClick = () => {
    if (!id) return;
    if (marathonById.data?.status !== 'new') {
      message.error('Удаление невозможно. Вы не можете удалить активный или прошедший марафон');

      return;
    }

    open({
      title: 'Удалить марафон?',
      content: 'Вы уверены, что хотите навсегда удалить этот марафон?',
      cancelText: 'Отмена',
      centered: true,
      okText: 'Удалить',
      okButtonProps: {
        type: 'primary',
        danger: true,
      },
      onOk: () => marathonDelete.fetch(id),
      maskClosable: true,
    });
  };

  useEffect(() => {
    if (marathonDelete.response?.status === 204) {
      handleUnsavedChanges(false).then(() => {
        message.success('Марафон успешно удален.');
        navigate('/marathons');
      });
    }
  }, [marathonDelete.response]);

  useMessageError([marathonById, marathonUpdate, marathonDelete]);

  const handleFieldsChange = () => {
    handleUnsavedChanges(!isObjEqual(
      initialValues,
      form.getFieldsValue(),
    ));
  };

  return (
    <>
      {contextHolder}
      <SinglePageContainer
        breadcrumbItems={breadcrumbs}
        title="Редактировать марафон"
        cardLoading={marathonById.loading}
        extra={(
          <>
            <Button type="primary" danger onClick={handleDeleteClick}>Удалить</Button>
            <GiftCertificateButton
              disabled={marathonById.data?.status !== 'upcoming' && marathonById.data?.status !== 'sales'
                && marathonById.data?.status !== 'active'}
            />
            <Button
              type="primary"
              onClick={handleSubmitClick}
              loading={marathonUpdate.loading}
              disabled={!unsavedChanges}
            >
              Сохранить
            </Button>
          </>
        )}
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={initialValues}
          onFinish={handleSubmit}
          name="marathon-update"
          onFieldsChange={handleFieldsChange}
        >
          <MarathonFormItems />
        </Form>

        {marathonById.data ? (
          <EntitiesCollapse data={marathonById.data} />
        ) : null}
      </SinglePageContainer>
    </>
  );
}
