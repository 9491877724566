import React, { useEffect, useState } from 'react';
import {
  App,
  Button, Col, Form, Row,
} from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { PlusOutlined } from '@ant-design/icons';
import DayPicker, { DayProps } from '../../../../Common/Pickers/DayPicker';
import WeekPicker from '../../../../Common/Pickers/WeekPicker';
import { ComplexTrainingDay, ComplexWeek } from '../../../../../hooks/api/complexes';
import TrainingsListModal from '../../../Trainings/ModalTable';
import { Training, useTrainingId } from '../../../../../hooks/api/trainings';
import TrainingRow from '../../../Trainings/TrainingRowItem';

/** Initial form states */
const initialTrainingDayState = {
  trainings: [],
};

export const initialWeekState = {
  trainingDays: Array.from({ length: 7 }).map(() => (initialTrainingDayState as ComplexTrainingDay)),
} as ComplexWeek;

function ComplexesFormItemsTrainings() {
  const { message } = App.useApp();
  const form = useFormInstance();
  const trainingById = useTrainingId();

  const durationInWeeks = Form.useWatch('durationInWeeks', form);
  const weeksWatch: ComplexWeek[] = Form.useWatch('weeks', form);

  const [selectedDay, setSelectedDay] = useState<DayProps>({ day: 'mon', dayIndex: 0 });
  const [selectedWeekIndex, setSelectedWeekIndex] = useState<number>(0);

  /** Handling adding and removing new week from weeks array */
  const handleAddWeek = (addAmount: number) => {
    const newWeeks = [...weeksWatch];

    Array.from({ length: addAmount }).forEach(() => {
      newWeeks.push(initialWeekState as ComplexWeek);
    });

    form.setFieldValue('weeks', newWeeks);
  };

  const handleRemoveWeek = (removeAmount: number) => {
    const newWeeks = [...weeksWatch];

    newWeeks.splice(-removeAmount);

    form.setFieldValue('weeks', newWeeks);
  };

  /** When duration in weeks changes, then we updates length of weeks array. */
  useEffect(() => {
    if (durationInWeeks) {
      setSelectedWeekIndex(0);

      if (weeksWatch.length < durationInWeeks) {
        handleAddWeek(durationInWeeks - weeksWatch.length);
      } else if (weeksWatch.length > durationInWeeks) {
        handleRemoveWeek(weeksWatch.length - durationInWeeks);
      }
    }
  }, [durationInWeeks]);

  /** Add new training func */
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedTrainingsIds, setSelectedTrainingsIds] = useState<number[]>([]);

  const handleAddTrainings = async () => {
    let selectedTrainings: Training[] = [];

    const selectedWeek = weeksWatch[selectedWeekIndex];

    const currentDayTrainingsIds = selectedWeek?.trainingDays
      .find((day, index) => index === selectedDay?.dayIndex)
      ?.trainings.map((el) => el.id);

    const uniqueTrainingsIds = selectedTrainingsIds.map((el) => el.toString())
      .filter((id) => !currentDayTrainingsIds?.includes(id));

    await Promise.all(uniqueTrainingsIds?.map((trainingId) => (
      trainingById.fetch(undefined, trainingId)
    ))).then((res) => {
      if (res.every((el) => el?.id)) {
        const normalizedResponse = res.map((el) => {
          // eslint-disable-next-line no-param-reassign
          delete el?.usedIn;

          return el;
        });

        selectedTrainings = [...selectedTrainings, ...normalizedResponse as Training[]];
      }
    }).catch((err) => {
      if (err) {
        message.error('Ошибка при добавлении тренировки.');
      }
    });

    /** Update value in form after request */
    const namePath = ['weeks', selectedWeekIndex, 'trainingDays', selectedDay.dayIndex, 'trainings'];
    const prevValue = form.getFieldValue(namePath);

    form.setFieldValue(namePath, [...prevValue, ...selectedTrainings]);
    setSelectedTrainingsIds([]);
  };

  return (
    <>
      <TrainingsListModal
        isOpenModal={isModalOpen}
        isLoading={trainingById.loading}
        handleAddTrainings={handleAddTrainings}
        handleModal={setIsModalOpen}
        selectedTrainingsIds={selectedTrainingsIds}
        handleSelectedRows={setSelectedTrainingsIds}
      />
      <Row gutter={24}>
        <Col span={24}>
          <div className="week-day-picker-container">
            <WeekPicker
              weeks={weeksWatch || []}
              selectedWeek={selectedWeekIndex}
              onClick={(weekIndex) => setSelectedWeekIndex(weekIndex)}
            />
            <DayPicker
              selectedDay={selectedDay?.dayIndex}
              onDayClick={(day) => setSelectedDay(day)}
              isFilledProps={{
                complex: {
                  selectedWeek: weeksWatch?.[selectedWeekIndex] || [],
                },
              }}
            />
          </div>
        </Col>

        <Col span={24}>
          <Form.List name="weeks">
            {(weeksFields) => (
              <div>
                {weeksFields.map((weekField, weekIndex) => (

                  <div key={weekField.key} className="weekField">
                    <Form.List name={[weekField.name, 'trainingDays']}>
                      {(trainingDaysFields) => (
                        <>
                          {trainingDaysFields.map((trainingDayField, trainingDayIndex) => (
                            <div key={trainingDayField.key}>
                              <Form.List name={[trainingDayField.name, 'trainings']}>
                                {(trainingFields, { add, remove }) => (
                                  <>
                                    {trainingFields.map((trainingField, trainingIndex) => (
                                      selectedWeekIndex === weekIndex && selectedDay.dayIndex === trainingDayIndex
                                        ? (
                                          <TrainingRow
                                            key={trainingField.key}
                                            training={weeksWatch?.[weekIndex]?.trainingDays?.[trainingDayIndex]
                                              ?.trainings?.[trainingIndex]}
                                            onRemove={() => remove(trainingField.name)}
                                          />
                                        ) : null
                                    ))}
                                  </>
                                )}
                              </Form.List>
                            </div>
                          ))}
                        </>
                      )}
                    </Form.List>
                  </div>

                ))}
              </div>
            )}
          </Form.List>
        </Col>

        <Col span={24}>
          <Button icon={<PlusOutlined />} onClick={() => setIsModalOpen(true)}>Добавить тренировку</Button>
        </Col>
      </Row>
    </>
  );
}

export default ComplexesFormItemsTrainings;
