import React from 'react';
import { SelectProps } from 'antd/lib/select';
import { Select } from 'antd';
import { UserSubscription } from '../../../../hooks/api/user';
import { Option } from '../../../../types';

function SelectPlace({ ...props }: SelectProps<UserSubscription, Option>) {
  return (
    <Select {...props} />
  );
}

enum PlaceEnum {
  'Дом' = 'house',
  'Зал' = 'gym'
}

SelectPlace.defaultProps = {
  options: Object.entries(PlaceEnum).map(([key, value]) => ({ label: key, value })),
  allowClear: true,
  placeholder: 'Выберите',
};

export default SelectPlace;
