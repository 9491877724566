export enum UsersGroupsEnum {
  users = 'Все пользователи',
  gold = 'Марафон Gold',
  silver = 'Марафон Silver',
  marathon = 'Всем в марафоне',
  month = 'Месяц по подписке',
  year = 'Год по подписке',
  admins = 'Администраторы',
}

export enum EmailTemplatesEnum {
  template1 = 'template1',
  template2 = 'template2',
  template3 = 'template3',
}
