import React from 'react';
import { SelectProps } from 'antd/lib/select';
import { Badge, Select } from 'antd';
import { Option } from '../../../../../types';
import { MarathonStatusType } from '../../../../../hooks/api/marathons';

function SelectMarathonStatus({ ...props }: SelectProps<MarathonStatusType, Option>) {
  return (
    <Select {...props} />
  );
}

SelectMarathonStatus.defaultProps = {
  options: [
    { label: <Badge status="warning" text="Новый" />, value: 'new' },
    { label: <Badge color="magenta" text="Предстоящий" />, value: 'upcoming' },
    { label: <Badge status="processing" text="Продажи стартовали" />, value: 'sales' },
    { label: <Badge status="success" text="Активный" />, value: 'active' },
    { label: <Badge color="geekblue" text="Окончен" />, value: 'finished' },
    { label: <Badge status="default" text="Прошедший" />, value: 'closed' },
  ],
  allowClear: true,
  placeholder: 'Выберите',
};

export default SelectMarathonStatus;
