import React, {
  useRef,
} from 'react';
import { ProColumns, RequestData } from '@ant-design/pro-components';
import { FormInstance } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { SortOrder } from 'antd/es/table/interface';
import { ParamsType } from '@ant-design/pro-provider';
import dayjs from 'dayjs';
import Table, { defaultPagingResponse } from '../../../Common/Table';
import { getSorterParams, queryFilterParams } from '../../../../utils';
import { useMessageError } from '../../../../hooks/common';
import { Feedback, useFeedbackGet } from '../../../../hooks/api/reviews';
import { UserSimple } from '../../../../hooks/api/user';
import FeedBack from '../FeedBack';
import { FeedBackStarsEnum, FeedBackTypeEnum } from '../../../../enums/reviews';

import styles from './index.module.scss';

function ReviewsTable(): React.JSX.Element {
  const feedbackGet = useFeedbackGet();
  const formRef = useRef<FormInstance>();

  const [searchParams, setSearchParams] = useSearchParams();

  const tableRequest = async (
    { current, pageSize, ...args }: Record<string, string>
      & { pageSize?: number | undefined; current?: number | undefined; keyword?: string | undefined; },
    sorter: Record<string, SortOrder>,
  ): Promise<RequestData<Feedback>> => {
    const newParams = queryFilterParams({
      page: current ? `${current}` : '1',
      pageSize: pageSize ? `${pageSize}` : '10',
      ...args,
      ...getSorterParams(sorter),
    });

    setSearchParams(queryFilterParams({ ...args, ...getSorterParams(sorter) }), { replace: true });

    const decoratedParams = JSON.parse(JSON.stringify(newParams));

    delete decoratedParams.type;

    if (decoratedParams?.stars) {
      decoratedParams.stars = FeedBackStarsEnum[decoratedParams.stars as keyof typeof FeedBackStarsEnum];
    }

    return feedbackGet.fetch(decoratedParams).then((data) => {
      if (data) {
        const { data: feedback, meta } = data;

        return ({ data: feedback || [], success: true, total: meta?.totalItems || 0 });
      }

      return defaultPagingResponse;
    });
  };

  const beforeSearchSubmit = (beforeSubmitParams: Partial<ParamsType>) => {
    const newParams = queryFilterParams({
      ...beforeSubmitParams,
      _timestamp: '',
      search: searchParams.get('search') || '',
    });

    setSearchParams(newParams, { replace: true });

    return newParams;
  };

  useMessageError([feedbackGet]);

  const columns: ProColumns<Feedback>[] = [
    {
      title: 'Пользователь',
      dataIndex: 'user',
      sorter: false,
      hideInSearch: true,
      hideInForm: true,
      width: 250,
      renderText: (user: UserSimple, record) => (
        <>
          <div className={styles.userName}>{user.name}</div>
          <div className={styles.secondary}>{user.email}</div>
          <div className={styles.secondary}>{dayjs(record.createdAt).format('MM/DD/YYYY')}</div>
        </>
      ),
    },
    {
      title: 'Отзывы',
      dataIndex: 'createdAt',
      sorter: false,
      hideInSearch: true,
      hideInForm: true,
      renderText: (_, record) => (
        <>
          <FeedBack
            starsCount={record.calendarStars}
            title={FeedBackTypeEnum.calendar}
            feedback={record.calendarContent}
            type="calendar"
          />
          <FeedBack
            starsCount={record.trainingsStars}
            title={FeedBackTypeEnum.trainings}
            feedback={record.trainingsContent}
            type="trainings"
          />
          <FeedBack
            starsCount={record.nutritionStars}
            title={FeedBackTypeEnum.nutrition}
            feedback={record.nutritionContent}
            type="nutrition"
          />
          <FeedBack
            starsCount={record.lecturesStars}
            title={FeedBackTypeEnum.lectures}
            feedback={record.lecturesContent}
            type="lectures"
          />
          <FeedBack
            starsCount={record.profileStars}
            title={FeedBackTypeEnum.profile}
            feedback={record.profileContent}
            type="profile"
          />
        </>
      ),
    },
    {
      title: 'Раздел',
      dataIndex: 'type',
      sorter: false,
      hideInSetting: true,
      hideInTable: true,
      valueEnum: FeedBackTypeEnum,
    },
    {
      title: 'Оценки',
      dataIndex: 'stars',
      sorter: false,
      hideInSetting: true,
      hideInTable: true,
      valueEnum: FeedBackStarsEnum,
    },
  ];

  return (
    <Table<Feedback>
      withCustomSearch
      rowKey="id"
      showHeader={false}
      formRef={formRef}
      className="reviews-table"
      headerTitle="Список отзывов"
      columns={columns}
      request={tableRequest}
      beforeSearchSubmit={beforeSearchSubmit}
      showSorterTooltip={false}
      columnsState={{
        persistenceKey: 'pro-table-reviews',
        persistenceType: 'localStorage',
      }}
    />
  );
}

export default ReviewsTable;
