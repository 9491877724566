import React, {
  createContext, ReactNode, useContext, useMemo, useState,
} from 'react';

interface SidebarProviderProps {
  collapsed: boolean;
  handleCollapsedChanges: (payload: boolean) => void;
}

const defaultValue: SidebarProviderProps = {
  collapsed: false,
  handleCollapsedChanges: () => undefined,
};

export const SidebarContext = createContext<SidebarProviderProps>(defaultValue);

function SidebarProvider({ children }: { children: ReactNode }) {
  const [collapsed, setCollapsed] = useState<boolean>(false);

  const handleCollapsedChanges = (payload: boolean) => {
    setCollapsed(payload);
  };

  const memoizedValue = useMemo(() => ({
    collapsed,
    handleCollapsedChanges,
  }), [collapsed, handleCollapsedChanges]);

  return (
    <SidebarContext.Provider value={memoizedValue}>
      {children}
    </SidebarContext.Provider>
  );
}

export default SidebarProvider;

export const useSidebarProvider = (): SidebarProviderProps => useContext(SidebarContext);
