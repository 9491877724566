import React from 'react';
import clsx from 'clsx';
import { Tooltip, Typography } from 'antd';
import { FileDoneOutlined } from '@ant-design/icons';
import { ComplexWeek } from '../../../../hooks/api/complexes';
import {
  LectureWeek,
  MarathonMenuWeek,
  MarathonShoppingWeek,
  MarathonTrainingWeek,
} from '../../../../hooks/api/marathons';

import styles from './index.module.scss';

type WeekType = (ComplexWeek[] | MarathonTrainingWeek[] | MarathonMenuWeek[] | MarathonShoppingWeek[] | LectureWeek[])

interface WeekPicker {
  weeks: WeekType;
  selectedWeek: number;
  onClick: (weekIndex: number) => void;
  label?: string;
  disabled?: boolean;
}

function WeekPicker({
  weeks, selectedWeek, onClick, label, disabled,
}: WeekPicker) {
  return (
    <div className={styles.container}>
      {label ? <Typography.Text className={styles.label}>Неделя марафона</Typography.Text> : null}
      {(weeks || []).map((week, index) => (
        <div
          className={clsx(styles.weekSelector, {
            [styles.active]: selectedWeek === index,
            [styles.disabled]: disabled,
          })}
          key={week.id || crypto.randomUUID()}
          onClick={() => (disabled ? undefined : onClick(index))}
          role="button"
          tabIndex={0}
        >
          <span>{`${index + 1} неделя`}</span>

          {'pdfUrlRU' in week && week?.pdfUrlRU && week?.pdfUrlUA ? (
            <Tooltip title="Все PDF файлы загружены" mouseEnterDelay={0.3} className={styles.pdfMark}>
              <span><FileDoneOutlined className={styles.icon} /></span>
            </Tooltip>
          ) : null}
        </div>
      ))}
    </div>
  );
}

WeekPicker.defaultProps = {
  label: undefined,
  disabled: false,
};

export default WeekPicker;
