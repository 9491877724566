import React, {
  Key, useCallback, useEffect, useMemo, useRef,
} from 'react';
import { ActionType, ProColumns, RequestData } from '@ant-design/pro-components';
import {
  App, Button, FormInstance, Space,
} from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SortOrder } from 'antd/es/table/interface';
import { ParamsType } from '@ant-design/pro-provider';
import {
  DeleteOutlined, EditOutlined, PlusCircleOutlined,
} from '@ant-design/icons';
import dayjs from 'dayjs';
import clsx from 'clsx';
import Table, { defaultPagingResponse } from '../../../Common/Table';
import {
  createDefaultHiddenColumns, getSorterParams, queryFilterParams,
} from '../../../../utils';
import { useMessageError } from '../../../../hooks/common';
import { useRecipeDelete, useRecipesGet, Recipe } from '../../../../hooks/api/recipes';
import ContentThumbnailAndName from '../../../Common/ContentThumbnailAndName';
import { useSimpleModal } from '../../../Common/Modal/Simple';
import StatusIndicator from '../../../Common/StatusIndicator';
import { AvailabilityEnum, ForCatalogAPIEnum, ForCatalogEnum } from '../../../../enums';
import SelectContentStatus from '../../../Common/Selects/SelectStatus/SelectContentStatus';
import { dateFormat } from '../../../../contstant';
import {
  CaloriesEnum, MenuTypeEnum, PreparationTimeEnum, RecipesCategoryEnum,
} from '../../../../enums/recipes';
import { getFullFileUrl } from '../../../Common/Uploaders/ImageUpload';

interface TableRecipes {
  /** PROP - Set permanent filters */
  params?: Record<string, string>;
  /** PROP - Set temporary filters */
  defaultParams?: Record<string, string>;
  selectedRows?: (number | string)[];
  onRowSelection?: ((selectedRows: (number | string)[]) => void) | undefined;
  inModal?: boolean;
}

function RecipesTable(props: TableRecipes): React.JSX.Element {
  const {
    params, defaultParams, selectedRows, onRowSelection, inModal,
  } = props;
  const { message } = App.useApp();
  const { open, contextHolder } = useSimpleModal();

  const navigate = useNavigate();
  const recipesGet = useRecipesGet();
  const recipeDelete = useRecipeDelete();
  const formRef = useRef<FormInstance>();
  const actionRef = useRef<ActionType>();

  const [searchParams, setSearchParams] = useSearchParams();

  const toolBarRender = useCallback(() => [
    <Button
      key="add-btn"
      id="add-btn"
      type="primary"
      icon={<PlusCircleOutlined />}
      onClick={() => navigate('/recipes/create')}
    >
      <Space>
        Добавить
      </Space>
    </Button>,
  ], []);

  /** Table request: */
  const tableRequest = async (
    { current, pageSize, ...args }: Record<string, string>
      & { pageSize?: number | undefined; current?: number | undefined; keyword?: string | undefined; },
    sorter: Record<string, SortOrder>,
  ): Promise<RequestData<Recipe>> => {
    const newParams = queryFilterParams({
      page: current ? `${current}` : '1',
      pageSize: pageSize ? `${pageSize}` : '10',
      ...args,
      ...getSorterParams(sorter),
    });

    setSearchParams(queryFilterParams({ ...args, ...getSorterParams(sorter) }), { replace: true });

    return recipesGet.fetch({
      ...defaultParams,
      ...newParams,
      ...params,
      forCatalog: newParams?.forCatalog ? ForCatalogAPIEnum[newParams.forCatalog as 'yes' | 'no'] : undefined,
    }).then((data) => {
      if (data) {
        const { data: trainings, meta } = data;

        return ({ data: trainings || [], success: true, total: meta?.totalItems || 0 });
      }

      return defaultPagingResponse;
    });
  };

  const beforeSearchSubmit = (beforeSubmitParams: Partial<ParamsType>) => {
    const newParams = queryFilterParams({
      ...beforeSubmitParams,
      _timestamp: '',
      search: searchParams.get('search') || '',
    });

    setSearchParams({ ...defaultParams, ...newParams, ...params }, { replace: true });

    return { ...defaultParams, ...newParams, ...params };
  };

  useMessageError([recipesGet, recipeDelete]);

  const handleDeleteClick = (e: React.MouseEvent<HTMLElement>, row: Recipe) => {
    e.preventDefault();
    e.stopPropagation();
    open({
      title: 'Удалить рецепт?',
      content: 'Вы уверены, что хотите навсегда удалить этот рецепт?',
      cancelText: 'Отмена',
      centered: true,
      okText: 'Удалить',
      okButtonProps: {
        type: 'primary',
        danger: true,
      },
      onOk: () => recipeDelete.fetch(row.id),
      maskClosable: true,
    });
  };

  useEffect(() => {
    if (recipeDelete.response?.status === 204 || recipeDelete.data) {
      message.success('Рецепт успешно удален.');
      actionRef.current?.reload();
    }
  }, [recipeDelete.response]);

  const columns: ProColumns<Recipe>[] = [
    {
      title: 'Название',
      dataIndex: 'nameRU',
      sorter: true,
      hideInSearch: true,
      hideInForm: true,
      render: (_, row) => (
        <ContentThumbnailAndName
          size="small"
          imgSrc={getFullFileUrl(row.titleUrl)}
          name={row.nameRU}
        />
      ),
    },
    {
      order: 10,
      title: 'Доступность',
      dataIndex: 'availability',
      sorter: true,
      valueEnum: AvailabilityEnum,
    },
    {
      order: 9,
      title: 'Статус',
      dataIndex: 'contentState',
      hideInSearch: inModal,
      sorter: true,
      renderFormItem: (_, { defaultRender, ...config }) => (<SelectContentStatus {...config} />),
      renderText: (contentState) => <StatusIndicator status={contentState} />,
    },
    {
      order: 8,
      title: 'Отображение в каталоге',
      dataIndex: 'forCatalog',
      valueEnum: ForCatalogEnum,
      renderText: (forCatalog) => (forCatalog ? 'Да' : 'Нет'),
      sorter: false,
    },
    {
      order: inModal ? 11 : 7,
      title: 'Тип меню',
      dataIndex: 'menuType',
      sorter: true,
      valueEnum: MenuTypeEnum,
      fieldProps: defaultParams?.menuType ? { defaultValue: defaultParams?.menuType, allowClear: false } : undefined,
    },
    {
      order: 6,
      title: 'Категория',
      dataIndex: 'category',
      sorter: true,
      valueEnum: RecipesCategoryEnum,
    },
    {
      order: 5,
      title: 'Калорийность',
      dataIndex: 'calories',
      sorter: true,
      valueEnum: CaloriesEnum,
      renderText: (calories) => (calories ? `${calories} ккал` : ''),
    },
    {
      order: 4,
      title: 'Время приготовления',
      dataIndex: 'preparingTime',
      sorter: true,
      valueEnum: PreparationTimeEnum,
      renderText: (preparingTime) => (preparingTime ? `${preparingTime} мин` : ''),
    },
    {
      title: 'Дата создания',
      dataIndex: 'createdAt',
      sorter: true,
      renderText: (createdAt) => (createdAt ? dayjs(createdAt).format(dateFormat) : ''),
      hideInSearch: true,
    },
    {
      title: 'Дата изменения',
      dataIndex: 'updatedAt',
      sorter: true,
      renderText: (updatedAt) => (updatedAt ? dayjs(updatedAt).format(dateFormat) : ''),
      hideInSearch: true,
    },
    {
      key: 'actions',
      width: 100,
      title: 'Действия',
      dataIndex: 'actions',
      sorter: false,
      hideInSearch: true,
      hideInSetting: true,
      render: (_, row) => (
        <>
          <Button
            size="middle"
            type="text"
            icon={<EditOutlined style={{ fontSize: 18 }} />}
            onClick={() => navigate(`/recipes/${row.id}`)}
            title="Редактировать"
          />
          <Button
            size="middle"
            type="text"
            icon={<DeleteOutlined style={{ fontSize: 18 }} />}
            onClick={(e) => handleDeleteClick(e, row)}
            title="Удалить"
          />
        </>
      ),
    },
  ];

  const defaultHiddenColumns = useMemo(() => createDefaultHiddenColumns(
    ['createdAt', 'updatedAt', 'menuType', 'forCatalog', 'category', 'preparingTime', 'calories'],
  ), []);

  const defaultHiddenColumnsInModal = useMemo(() => createDefaultHiddenColumns(
    ['createdAt', 'updatedAt', 'forCatalog', 'preparingTime', 'actions'],
  ), []);

  const onRowChange = useCallback((selectedRowKeys: Key[]) => {
    if (onRowSelection) {
      onRowSelection(selectedRowKeys as string[]);
    }
  }, [onRowSelection]);

  const rowSelection = {
    onChange: onRowChange,
    selectedRowKeys: selectedRows,
    alwaysShowAlert: false,
    preserveSelectedRowKeys: true,
  };

  return (
    <>
      {contextHolder}
      <Table<Recipe>
        withCustomSearch
        rowKey="id"
        formRef={formRef}
        actionRef={actionRef}
        headerTitle="Список рецептов"
        toolBarRender={!inModal ? toolBarRender : undefined}
        columns={columns}
        request={tableRequest}
        beforeSearchSubmit={beforeSearchSubmit}
        showSorterTooltip={false}
        pagination={params?.pageSize ? {
          defaultPageSize: +params.pageSize,
          showSizeChanger: false,
          size: 'default',
        } : undefined}
        columnsState={inModal ? {
          defaultValue: defaultHiddenColumnsInModal,
        } : {
          persistenceKey: 'pro-table-recipes',
          persistenceType: 'localStorage',
          defaultValue: defaultHiddenColumns,
        }}
        rowSelection={!!onRowSelection && rowSelection}
        rowClassName={clsx({ 'cursor-pointer': !inModal })}
        onRow={(record) => ({
          onClick: !inModal ? () => navigate(`/recipes/${record.id}`) : undefined,
        })}
      />
    </>
  );
}

RecipesTable.defaultProps = {
  params: {},
  defaultParams: {},
  selectedRows: [],
  onRowSelection: undefined,
  inModal: false,
};

export default RecipesTable;
