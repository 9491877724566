export enum FeedBackStarsEnum {
  one = '1',
  two = '2',
  three = '3',
  four = '4',
  five = '5',
}

export enum FeedBackTypeEnum {
  calendar = 'Календарь',
  trainings = 'Тренировки',
  nutrition = 'Питание',
  lectures = 'Лекции',
  profile = 'Профиль',
}
