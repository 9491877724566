import React from 'react';
import { config } from '@triare/auth-redux';
import Layout from '../../components/Layout';
import ReviewsContent from '../../components/Pages/Reviews';

const { displayName } = config;

export default function Reviews(): React.ReactNode | null {
  document.title = `${displayName}: Отзывы`;

  return (
    <Layout>
      <ReviewsContent />
    </Layout>
  );
}
