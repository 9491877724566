import React from 'react';
import { SelectProps } from 'antd/lib/select';
import { Select } from 'antd';
import { Option } from '../../../../types';
import { UsersGroupsEnum } from '../../../../enums/emails';
import { enumToOptionsArray } from '../../../../utils';

function SelectUsersGroups({ ...props }: SelectProps<UsersGroupsEnum, Option>) {
  return (
    <Select {...props} />
  );
}

SelectUsersGroups.defaultProps = {
  options: enumToOptionsArray(UsersGroupsEnum),
  allowClear: true,
  placeholder: 'Выберите',
};

export default SelectUsersGroups;
