/* eslint-disable max-len */

import * as yup from 'yup';
import 'yup-phone';
import { Rule, RuleObject } from 'rc-field-form/lib/interface';
import { Dayjs } from 'dayjs';

export const messages = {
  required: 'Это поле обязательно к заполнению.',
  zipCode: 'Пожалуйста, введите верный почтовый индекс.',
  nameMax255: 'Название должно быть не более 255 символов.',
};

export const textField: yup.StringSchema = yup.string()
  .min(3, 'Поле должно содержать не менее 3 символов.')
  .max(100, 'Поле не может быть более 100 символов.')
  .required(messages.required);

export const name: yup.StringSchema = yup.string()
  .min(3, 'Поле должно содержать не менее 3 символов.')
  .max(50, 'Поле не может быть более 50 символов.')
  .required(messages.required);

export const username: yup.StringSchema = yup.string()
  .min(6, 'Поле должно содержать не менее 6 символов.')
  .max(100, 'Поле не может быть более 100 символов.')
  .required(messages.required);

export const password: yup.StringSchema = yup.string()
  .min(12, 'Пароль должен быть не менее 12 символов.')
  .max(100, 'Пароль должен быть не более 100 символов.')
  .matches(/^(?=.*[0-9])(?=.*[\D])([\D0-9]+)$/, 'Пароль должен содержать цифры и буквы.')
  .matches(/[^A-Za-zА-Яа-яІЇҐЄіїґє0-9]|[A-ZА-ЯІЇҐЄ]/, 'Пароль должен содержать хотя бы одну заглавную букву или специальный символ.')
  .matches(/[a-zа-яіїґє]/, 'Должен содержать одну строчную букву.')
  .matches(/^\S+$/g, 'Пароль не должен содержать пробелы.')
  .required(messages.required);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const phone: yup.StringSchema = yup.string().phone()
  .max(15, 'Пожалуйста, введите действующий телефонный номер.')
  .required(messages.required);

export const email: yup.StringSchema = yup.string().email('Пожалуйста, введите корректный адрес электронной почты.')
  .required(messages.required).matches(/^.+.\w{2}$/, 'Минимум два символа после точки.');

export const confirmEmail: yup.StringSchema = yup.string()
  .oneOf([yup.ref('email')], 'Почта должна совпадать.')
  .required(messages.required);

export const zipCode: yup.StringSchema = yup.string()
  .min(5, messages.zipCode)
  .max(10, messages.zipCode)
  .matches(/^\d{5}$|^\d{5}-\d{4}$/, messages.zipCode)
  .required(messages.required);

export const validationSignUpEmailOnly = yup.object().shape({
  email,
});

export const validationSignUpEmail = yup.object().shape({
  email,
});

export const validationSignUpEmailAndPassword = yup.object().shape({
  email,
  password,
});

export const validationSignUpPhoneOnly = yup.object().shape({
  phone,
});

export const validationSignUp = yup.object().shape({
  password,
  email,
  phone,
  firstName: name,
  lastName: name,
  address: name,
});

export const validationGeneralInfo = yup.object().shape({
  name,
  address: textField,
  phone,
  email,
});

export const validationUserInfo = yup.object().shape({
  firstName: name,
  lastName: name,
  phone,
  email,
});

export const validationChangePassword = yup.object().shape({
  currentPassword: password,
  password,
});

export const validationCertificateReGift = yup.object().shape({
  email,
});

export const validationNewPassword = yup.object().shape({
  password,
});

export const validationChangeEmail = yup.object().shape({
  email,
  confirmEmail,
});

export const validationUserAdd = yup.object().shape({
  lastName: textField,
  firstName: textField,
  phone,
  email,
});

export const validationSignInEmailAndPassword = yup.object().shape({
  email,
  password,
});

export const validationSignInUsernameAndPassword = yup.object().shape({
  username,
  password,
});

export const validationSignInPhoneOnly = yup.object().shape({
  phone,
});

export const validationSignInPhoneAndPassword = yup.object().shape({
  phone,
  password,
});

export const validationForgotPassword = yup.object().shape({
  email,
});

export const validationAdminCreate = yup.object().shape({
  name: yup.string().max(255, 'Имя должно быть не более 255 символов.').required(messages.required),
  email,
});

export const validationAdminEdit = yup.object().shape({
  name: yup.string().max(255, 'Имя должно быть не более 255 символов.').required(messages.required),
  email,
  status: yup.string().required(messages.required),
});

export const validationTrainingHouse = yup.object().shape({
  nameRU: yup.string().required(messages.required),
  nameUA: yup.string().required(messages.required),
  availability: yup.string().required(messages.required),
  level: yup.string().required(messages.required),
  place: yup.string().required(messages.required),
  duration: yup.string().required(messages.required),
});

export const validationRecipe = yup.object().shape({
  nameRU: yup.string().max(255, messages.nameMax255).required(messages.required),
  nameUA: yup.string().max(255, messages.nameMax255).required(messages.required),
  availability: yup.string().required(messages.required),
  menuType: yup.string().required(messages.required),
  category: yup.string().required(messages.required),
  pfc: yup.string()
    .matches(/^\d+\/\d+\/\d+$/, 'Неверный формат. Введите значения в формате "Б/Ж/У"')
    .required(messages.required),
  preparingTime: yup.string().required(messages.required),
  titleUrl: yup.string().required(messages.required),
  imageUrls: yup.array().required(messages.required),
});

export const validationComplex = yup.object().shape({
  nameRU: yup.string().max(255, messages.nameMax255).required(messages.required),
  nameUA: yup.string().max(255, messages.nameMax255).required(messages.required),
  availability: yup.string().required(messages.required),
  level: yup.string().required(messages.required),
});

export const validationLecture = yup.object().shape({
  nameRU: yup.string().max(255, messages.nameMax255).required(messages.required),
  nameUA: yup.string().max(255, messages.nameMax255).required(messages.required),
  availability: yup.string().required(messages.required),
  category: yup.string().required(messages.required),
  kind: yup.string().required(messages.required),
  contentRU: yup.string().required(messages.required),
  contentUA: yup.string().required(messages.required),
});

export const validationNotificationGroup = yup.object().shape({
  group: yup.string().required(messages.required),
  titleRU: yup.string().required(messages.required),
  titleUA: yup.string().required(messages.required),
  messageRU: yup.string().required(messages.required),
  messageUA: yup.string().required(messages.required),
});

export const validationMarathon = yup.object().shape({
  nameRU: yup.string().max(255, messages.nameMax255).required(messages.required),
  nameUA: yup.string().max(255, messages.nameMax255).required(messages.required),
  startDate: yup.string().required(messages.required),
  // weeksCount,
});

export const validationCertificateGift = yup.object().shape({
  subscription: yup.string().required(messages.required),
  email: yup.string().required(messages.required),
});

const { shape } = yup.object();

export const createRulesForAntd = (yupRule: ReturnType<typeof shape>): Rule => ({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  async validator({ field }: RuleObject, value) {
    await yupRule.validateSyncAt(field, { [field]: value });
  },
});

const validatorRules: {[key: string]: Rule} = {};

export const getRulesForAntd = (yupRule: ReturnType<typeof shape>): Rule => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (validatorRules[yupRule.name]) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return validatorRules[yupRule.name];
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  validatorRules[yupRule.name] = createRulesForAntd(yupRule);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return validatorRules[yupRule.name];
};

export default {
  validationSignUp,
  validationChangePassword,
  validationNewPassword,
  validationChangeEmail,
  validationSignIn: validationSignInEmailAndPassword,
  validationForgotPassword,
  password,
  email,
  confirmEmail,
};
