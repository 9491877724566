import React, { useEffect, useState } from 'react';
import {
  App, Button, Input, Typography,
} from 'antd';
import { ItemType } from 'antd/es/breadcrumb/Breadcrumb';
import { NavLink, useParams } from 'react-router-dom';
import { SendOutlined } from '@ant-design/icons';
import SinglePageContainer from '../../../Common/SinglePageContainer';
import {
  EmailContent, EmailLanguage, Emails, EmailsSend, useEmailSend, useEmailsId,
} from '../../../../hooks/api/emails';
import EmailFilters from '../Filters';
import { EmailTemplatesEnum, UsersGroupsEnum } from '../../../../enums/emails';
import EmailTemplate1 from './Template1';
import { useUnsavedChanges } from '../../../../context/unsavedChanges';
import { isObjEqual } from '../../../../utils';
import { decorateHTMLContent } from '../Check';
import { useMessageError } from '../../../../hooks/common';

import styles from '../Check/index.module.scss';
import EmailTemplate2 from './Template2';
import EmailTemplate3 from './Template3';

const breadcrumbs: ItemType[] = [
  { title: <NavLink to="/emails">Рассылка</NavLink> },
  { title: 'Создать рассылку' },
];

function hasEmptyValue(obj: Emails, exceptions?: (keyof EmailContent)[]) {
  const contentUA = new Map(Object.entries(obj.contentUA));
  const contentRU = new Map(Object.entries(obj.contentRU));

  exceptions?.forEach((key) => {
    contentUA.delete(key);
    contentRU.delete(key);
  });

  const hasEmptyUA = Object.values(Object.fromEntries(contentUA)).some((value) => value.trim() === '');
  const hasEmptyRU = Object.values(Object.fromEntries(contentRU)).some((value) => value.trim() === '');

  return hasEmptyUA || hasEmptyRU;
}

function EmailCheck() {
  const { id = '' } = useParams<{ id: string; }>();

  const emailById = useEmailsId();
  const emailSend = useEmailSend();

  const { message } = App.useApp();
  const { handleUnsavedChanges } = useUnsavedChanges();
  const [isDisable, setIsDisable] = useState<boolean>(false);
  const [selectedLanguage, setSelectedLanguage] = useState<EmailLanguage>('ru');
  const [selectedGroup, setSelectedGroup] = useState<UsersGroupsEnum>();
  const [data, setData] = useState<Emails>();
  const [initialData, setInitialData] = useState<Emails>();
  const [activeContent, setActiveContent] = useState<EmailContent>();

  const fetch = () => {
    emailById.fetch(undefined, id);
  };

  useEffect(() => {
    if (id) fetch();
  }, [id]);

  useEffect(() => {
    if (emailById.data) {
      setInitialData(emailById.data);
      setData(emailById.data);
      setActiveContent(emailById.data.contentRU);
    }
  }, [emailById.data]);

  const handleSelectGroup = (payload: UsersGroupsEnum) => setSelectedGroup(payload);

  const handleLanguageChange = (payload: EmailLanguage) => {
    if (!data || !activeContent) return;

    setData({ ...data, ...(payload === 'ru' ? { contentUA: activeContent } : { contentRU: activeContent }) });
    setActiveContent(payload === 'ru' ? data.contentRU : data.contentUA);
    setSelectedLanguage(payload);
  };

  useMessageError([emailById, emailSend]);

  const handleSend = () => {
    if (!data || !selectedGroup) return;

    const newData: EmailsSend = {
      subjectUA: data.contentUA.subject || '',
      subjectRU: data.contentRU.subject || '',
      htmlUA: decorateHTMLContent(data.htmlUA, data.contentUA),
      htmlRU: decorateHTMLContent(data.htmlRU, data.contentRU),
      group: selectedGroup,
    };

    emailSend.fetch(newData).then((res) => {
      if (res !== null) {
        message.success('Письма успешно отправлены.');
        handleUnsavedChanges(false);
      }
    });
  };

  const handleContentChange = (key: keyof EmailContent, payload: string) => {
    setActiveContent({ ...activeContent, [key]: payload });
  };

  useEffect(() => {
    if (!data || !activeContent) return;

    const newData: Emails = {
      ...data,
      ...(selectedLanguage === 'ru' ? { contentRU: activeContent } : { contentUA: activeContent }),
    };

    setIsDisable(hasEmptyValue(newData));
    setData(newData);
  }, [activeContent]);

  useEffect(() => {
    if (!data || !initialData) return;

    handleUnsavedChanges(!isObjEqual(data, initialData));
  }, [data]);

  return (
    <SinglePageContainer
      breadcrumbItems={breadcrumbs}
      title="Создать рассылку"
      cardLoading={emailById.loading || !data}
      extra={(
        <Button
          type="primary"
          icon={<SendOutlined />}
          onClick={handleSend}
          loading={emailSend.loading}
          disabled={isDisable || !selectedGroup}
        >
          Отправить
        </Button>
      )}
    >
      <EmailFilters
        isCreate
        handleSelectGroup={handleSelectGroup}
        handleLanguageChange={handleLanguageChange}
        selectedGroup={selectedGroup}
        selectedLanguage={selectedLanguage}
      />
      <div className={styles.titleWrap}>
        <Typography className={styles.label}>Тема письма</Typography>
        <Input
          value={activeContent?.subject}
          onChange={(e) => handleContentChange('subject', e.target.value)}
        />
      </div>
      {activeContent ? (
        <div className={styles.htmlContainer}>
          {data?.template === EmailTemplatesEnum.template1 ? (
            <EmailTemplate1
              content={activeContent}
              lang={selectedLanguage}
              handleChange={handleContentChange}
            />
          ) : null}
          {data?.template === EmailTemplatesEnum.template2 ? (
            <EmailTemplate2
              content={activeContent}
              lang={selectedLanguage}
              handleChange={handleContentChange}
            />
          ) : null}
          {data?.template === EmailTemplatesEnum.template3 ? (
            <EmailTemplate3
              content={activeContent}
              lang={selectedLanguage}
              handleChange={handleContentChange}
            />
          ) : null}
        </div>
      ) : null}
    </SinglePageContainer>
  );
}

export default EmailCheck;
