import React, { useEffect, useState } from 'react';
import {
  App, Button, Form, Input, Row, Switch,
} from 'antd';
import { NavLink, useParams } from 'react-router-dom';
import { LinkOutlined } from '@ant-design/icons';
import SinglePageContainer from '../../../../Common/SinglePageContainer';
import { isObjEqual } from '../../../../../utils';
import { useMessageError } from '../../../../../hooks/common';
import { useUnsavedChanges } from '../../../../../context/unsavedChanges';
import { messages } from '../../../../../utils/validations';
import {
  MarathonTelegrams,
  useMarathonTelegramsUpdate,
  useMarathonId,
} from '../../../../../hooks/api/marathons';

function MarathonTelegramsContent() {
  const { marathonId = '' } = useParams<{ marathonId: string; }>();
  const { unsavedChanges, handleUnsavedChanges } = useUnsavedChanges();
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const formWatch = Form.useWatch('links', form);

  const marathonTelegramsById = useMarathonId<MarathonTelegrams>(`${marathonId}/telegram-links`);
  const marathonTelegramsUpdate = useMarathonTelegramsUpdate();

  const [initialValues, setInitialValues] = useState<MarathonTelegrams>();

  const fetch = () => {
    marathonTelegramsById.fetch(undefined, `${marathonId}/telegram-links`);
  };

  useEffect(() => {
    if (marathonId) fetch();
  }, [marathonId]);

  useEffect(() => {
    if (marathonTelegramsById.data) {
      setInitialValues({
        links: marathonTelegramsById.data?.links?.map((el) => ({
          ...el,
          link: el?.link || '',
        })),
      });
    }
  }, [marathonTelegramsById.data]);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(initialValues);
    handleUnsavedChanges(false);
  }, [initialValues]);

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      handleUnsavedChanges(false);

      marathonTelegramsUpdate.fetch(values, `${marathonId}/telegram-links`)
        .then((res) => {
          if (res !== null) message.success('Изменения успешно сохранены');
        });
    });
  };

  useMessageError([marathonTelegramsById]);

  const handleFieldsChange = () => {
    if (!initialValues) return;

    handleUnsavedChanges(!isObjEqual(initialValues, form.getFieldsValue()));
  };

  return (
    <SinglePageContainer
      breadcrumbItems={[
        { title: <NavLink to="/marathons">Марафоны</NavLink> },
        { title: <NavLink to={`/marathons/${marathonId}`}>Редактировать марафон</NavLink> },
        { title: 'Редактировать ссылки на Телеграм-каналы' },
      ]}
      title="Редактировать ссылки на Телеграм-каналы"
      cardLoading={marathonTelegramsById.loading}
      extra={(
        <Button
          type="primary"
          onClick={form.submit}
          loading={marathonTelegramsUpdate.loading}
          disabled={!unsavedChanges}
        >
          Сохранить
        </Button>
      )}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        name="marathon-telegram-chanels"
        initialValues={initialValues}
        onFieldsChange={handleFieldsChange}
      >
        <Form.List name="links">
          {(fields) => (
            fields.map((field, index) => (
              <Row key={field.key} style={{ gap: '8px' }}>
                <Form.Item
                  name={[field.name, 'link']}
                  label={formWatch[field.name]?.name}
                  rules={field.name < 2 ? [{ required: true, message: messages.required }] : []}
                  style={{ flexGrow: '1' }}
                >
                  <Input prefix={<LinkOutlined />} placeholder="Введите" />
                </Form.Item>
                {index !== 0 ? (
                  <Form.Item
                    name={[field.name, 'isRuOnly']}
                    label="RU-only"
                    valuePropName="checked"
                  >
                    <Switch checkedChildren="Да" unCheckedChildren="Нет" />
                  </Form.Item>
                ) : null}
              </Row>
            ))
          )}
        </Form.List>
      </Form>
    </SinglePageContainer>
  );
}

export default MarathonTelegramsContent;
