import React from 'react';
import { config } from '@triare/auth-redux';
import Layout from '../../components/Layout';
import RecipesContent from '../../components/Pages/Recipes';

const { displayName } = config;

export default function Recipes(): React.ReactNode | null {
  document.title = `${displayName}: Рецепты`;

  return (
    <Layout>
      <RecipesContent />
    </Layout>
  );
}
