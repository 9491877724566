import React, { useEffect, useState } from 'react';
import {
  Col, Form, FormListFieldData, Input, InputNumber, Row, Typography, UploadFile,
} from 'antd';
import { messages as errorMessages } from '../../../../../../../utils/validations';
import { generateFileObjFromUrl } from '../../../../../../Common/Uploaders/ImageUpload';
import QuillRichTextEditor from '../../../../../../Common/QuillRichTextEditor';
import { Exercise } from '../../../../../../../hooks/api/trainings';
import VideoUpload from '../../../../../../Common/Uploaders/VideoUpload';
import { useUnsavedChanges } from '../../../../../../../context/unsavedChanges';
import { isObjEqual } from '../../../../../../../utils';

const exercisesRules = [{ required: true, message: errorMessages.required }];

interface ExerciseRow {
  field: FormListFieldData;
  index: number;
  exerciseInitialValues?: Exercise;
  checkUnsavedChanges?: boolean;
  trigger?: number;
  exercises: Exercise[];
}

function ExerciseRow({
  field, index, exerciseInitialValues, checkUnsavedChanges, trigger, exercises,
}: ExerciseRow) {
  const form = Form.useFormInstance();
  const { handleUnsavedChanges } = useUnsavedChanges();

  const [videoFiles, setVideoFiles] = useState<UploadFile[]>([]);

  /** Change handlers */
  useEffect(() => {
    form.setFieldValue(['exercises', field.name, 'urls'], videoFiles.map((img) => img.url));
    if (videoFiles.length) {
      form.validateFields(['urls']);
    }
  }, [videoFiles]);

  /** Initial files handler */
  const handleSetFiles = (data: Exercise | undefined) => {
    if (data?.urls && (data?.urls?.toString() !== '[object Object]')) {
      const newFiles = data.urls?.map((url) => (generateFileObjFromUrl(url))) || [];

      setVideoFiles(newFiles);
    } else {
      setVideoFiles([]);
    }
  };

  useEffect(() => {
    if (exerciseInitialValues) {
      if (!trigger) { /** If form items did not changed order, then render initial values */
        handleSetFiles(exerciseInitialValues);
      } else { /** In other case get current values and set files */
        handleSetFiles(exercises?.[index]);
      }
    }
  }, [exerciseInitialValues]);

  /** On reorder trigger set new files useState */
  useEffect(() => {
    if (trigger) {
      handleSetFiles(exercises?.[index]);
    }
  }, [trigger]);

  useEffect(() => {
    if (checkUnsavedChanges) {
      handleUnsavedChanges(!isObjEqual(
        exerciseInitialValues,
        form.getFieldValue('exercises')?.[index],
      ));
    }
  }, [videoFiles]);

  return (
    <Row gutter={24}>
      <Col span={12}>
        <Form.Item
          name={[field.name, 'nameRU']}
          label="Название (RU)"
          rules={exercisesRules}
        >
          <Input placeholder="Введите" />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name={[field.name, 'nameUA']}
          label="Название (UA)"
          rules={exercisesRules}
        >
          <Input placeholder="Введите" />
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item
          name={[field.name, 'numberOfSetsStr']}
          label="Подход"
          rules={[...exercisesRules, { max: 100 }]}
        >
          <Input placeholder="Введите" />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name={[field.name, 'numberOfRepetitions']}
          label="Повтор"
          rules={exercisesRules}
        >
          <InputNumber placeholder="Введите" style={{ width: '100%' }} min={1} max={100} />
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item
          name={[field.name, 'weightRU']}
          label="Вес (RU)"
        >
          <Input placeholder="Введите" />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name={[field.name, 'weightUA']}
          label="Вес (UA)"
        >
          <Input placeholder="Введите" />
        </Form.Item>
      </Col>

      <Col span={24}>
        <div className="container-with-image">
          <div>
            <Typography.Paragraph className="upload-paragraph required">
              Видео (ракурс 1)
            </Typography.Paragraph>
            <Typography.Paragraph type="secondary" className="upload-text">
              Требования к файлу: MP4
            </Typography.Paragraph>
          </div>
          <VideoUpload
            allowToDelete
            multiple
            required
            form={form}
            name={[field.name, 'urls']}
            fileList={videoFiles}
            setFileList={setVideoFiles}
          />
        </div>
      </Col>

      <Col span={12}>
        <Form.Item name={[field.name, 'descriptionRU']} label="Описание (RU)">
          <QuillRichTextEditor />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item name={[field.name, 'descriptionUA']} label="Описание (UA)">
          <QuillRichTextEditor />
        </Form.Item>
      </Col>
    </Row>
  );
}

ExerciseRow.defaultProps = {
  exerciseInitialValues: undefined,
  checkUnsavedChanges: false,
  trigger: 0,
};

export default ExerciseRow;
