import React, { useEffect, useState } from 'react';
import {
  App, Button, Col, Divider, Form, Input, InputNumber, Modal, Row, Select,
} from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { createRulesForAntd, messages as errorMessages, validationRecipe } from '../../../../../utils/validations';
import { asyncDelay, enumToOptionsArray } from '../../../../../utils';
import { MeasureEnum } from '../../../../../enums/recipes';
import { initialIngredientState } from '../../Form';
import {
  Ingredient, Recipe, RecipeNutritionItem, useRecipeNutritionPut,
} from '../../../../../hooks/api/recipes';
import { MarathonMenu, MarathonMenuDay, MarathonMenuWeek } from '../../../../../hooks/api/marathons';

import styles from './index.module.scss';
import { useMarathonMenuProvider } from '../../../../../context/marathonMenu';

const validationRules = createRulesForAntd(validationRecipe);
const ingredientsRules = [{ required: true, message: errorMessages.required }];
const disabledIngredientField = ['not-specified', 'by-taste'];

const initialNutritionVal = {
  pfc: '',
  calories: 0,
  ingredients: [{
    itemRU: '', itemUA: '', value: 0, measure: 'g',
  }] as Ingredient[],
};

interface RecipeNutritionUpdateProps {
  recipe: Recipe;
}

function RecipeNutritionUpdate({ recipe }: RecipeNutritionUpdateProps) {
  const { message } = App.useApp();
  const [nutritionForm] = Form.useForm();
  const [nutritionModalOpen, setNutritionModalOpen] = useState(false);
  const { marathonMenuById } = useMarathonMenuProvider();

  const ingredientsWatch = Form.useWatch('ingredients', nutritionForm);

  /** Update recipe per calories */
  const recipeNutritionPut = useRecipeNutritionPut();
  const form = useFormInstance();

  const handleSubmit = () => {
    nutritionForm.validateFields().then((values: RecipeNutritionItem) => {
      const currentCalories = marathonMenuById.data?.calories || 1500;

      recipeNutritionPut.fetch(values, `${recipe.id}/nutrition/${currentCalories}`)
        .then((res) => {
          if (res?.nutrition) {
            /** Updating this recipe in all appearances of the form. (multiple portions) */
            const newWeeks = (form.getFieldValue('weeks') as MarathonMenuWeek[] || []).map((week) => ({
              ...week,
              menuDays: week.menuDays.map((day) => ({
                ...day,
                breakfast: day.breakfast.map((r) => (r.id === recipe.id ? { ...r, nutrition: res.nutrition } : r)),
                lunch: day.lunch.map((r) => (r.id === recipe.id ? { ...r, nutrition: res.nutrition } : r)),
                dinner: day.dinner.map((r) => (r.id === recipe.id ? { ...r, nutrition: res.nutrition } : r)),
                snack: day.snack.map((r) => (r.id === recipe.id ? { ...r, nutrition: res.nutrition } : r)),
              })) as [MarathonMenuDay, MarathonMenuDay, MarathonMenuDay, MarathonMenuDay,
                MarathonMenuDay, MarathonMenuDay, MarathonMenuDay],
            }));

            form.setFieldValue('weeks', newWeeks);

            message.success('Рецепт успешно обновлен!');
            setNutritionModalOpen(false);
          }
        });
    });
  };

  /** Handle Initial values */
  const [initialValues, setInitialValues] = useState<RecipeNutritionItem>(initialNutritionVal);

  useEffect(() => {
    const marathonCalories = (marathonMenuById.data?.calories || 1500) as 1300 | 1500 | 1700 | 2000;

    if (recipe.nutrition) {
      const currentNutritionItem = recipe.nutrition[`k${marathonCalories}`] || recipe.nutrition.k1500;

      // TODO try to get initial value from form instead of form response?
      if (currentNutritionItem) {
        setInitialValues(currentNutritionItem);
      }
    } else {
      message.error('Ошибка! У рецепта нет ингредиентов!');
    }
  }, [recipe]);

  useEffect(() => {
    nutritionForm.resetFields();
    nutritionForm.setFieldsValue(initialValues);
  }, [initialValues]);

  const handleOk = () => {
    nutritionForm.submit();
  };

  return (
    <>
      <Button
        style={{ backgroundColor: 'rgba(255, 255, 0, 0.20)' }}
        onClick={() => setNutritionModalOpen(true)}
        icon={<EditOutlined />}
      >
        Ингредиенты
      </Button>
      <Modal
        title={(
          <div>
            <h3>
              Меню
              {' '}
              {marathonMenuById.data?.calories}
            </h3>
            <span>
              Редактировать ингредиенты:
              {' '}
              {recipe.nameRU}
            </span>
          </div>
        )}
        open={nutritionModalOpen}
        onOk={handleOk}
        okText="Сохранить"
        confirmLoading={recipeNutritionPut.loading}
        onCancel={() => setNutritionModalOpen(false)}
        width={768}
        centered
      >
        <Form
          form={nutritionForm}
          layout="vertical"
          initialValues={initialValues}
          name="recipe-nutrition-form"
          onFinish={handleSubmit}
        >
          <Row gutter={24} className={styles.modalScrollContent}>
            <Col span={12}>
              <Form.Item
                name="calories"
                label="Калорийность, ккал"
                rules={[{ required: true, message: errorMessages.required }]}
              >
                <InputNumber placeholder="Введите" style={{ width: '100%' }} min={0} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item name="pfc" label="Б/Ж/У" rules={[{ required: true, ...validationRules }]}>
                <Input placeholder="Введите" />
              </Form.Item>
            </Col>

            {/* TODO create reusable component for ingredients? and use same on marathon update */}
            <Col span={24}>
              <Form.List name="ingredients">
                {(fields, { add, remove }) => (
                  <div className="flex-column-wrapper">
                    {fields.map((field) => (
                      <div className={styles.fieldGrid} key={field.key}>
                        <Row gutter={[12, 12]} className={styles.row}>
                          <Col span={14}>
                            <Form.Item
                              name={[field.name, 'itemRU']}
                              className="no-space-form-item"
                              label="Ингредиенты"
                              rules={ingredientsRules}
                            >
                              <Input placeholder="Ввведите" addonBefore="RU" />
                            </Form.Item>
                          </Col>
                          <Col span={4}>
                            <Form.Item
                              name={[field.name, 'value']}
                              label="Количество"
                              className="no-space-form-item"
                              rules={ingredientsRules}
                            >
                              <InputNumber
                                min={0}
                                placeholder="Введите"
                                style={{ width: '100%' }}
                                disabled={disabledIngredientField
                                  .includes(ingredientsWatch?.[field.name]?.measure)}
                                precision={1}
                                formatter={(value) => `${Math.round((value || 0) * 10) / 10}`}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item
                              name={[field.name, 'measure']}
                              label=" "
                              className="no-space-form-item"
                            >
                              <Select
                                options={enumToOptionsArray(MeasureEnum)}
                                placeholder="Не указано"
                                onChange={(val) => {
                                  if (val) {
                                    if (disabledIngredientField.includes(val || '')) {
                                      nutritionForm.setFieldValue(['ingredients', field.name, 'value'], 0);
                                    }
                                  }
                                }}
                              />
                            </Form.Item>
                          </Col>

                          <Col span={14}>
                            <Form.Item
                              name={[field.name, 'itemUA']}
                              className="no-space-form-item"
                              rules={ingredientsRules}
                            >
                              <Input placeholder="Ввведите" addonBefore="UA" />
                            </Form.Item>
                          </Col>
                          <Divider style={{ margin: '16px 0' }} />
                        </Row>

                        <Form.Item label=" ">
                          <Button
                            size="middle"
                            type="default"
                            icon={<DeleteOutlined style={{ fontSize: 18 }} />}
                            onClick={() => remove(field.name)}
                            title="Удалить"
                            disabled={fields.length === 1}
                          />
                        </Form.Item>
                      </div>
                    ))}
                    <Button
                      type="default"
                      onClick={() => add(initialIngredientState)}
                      icon={<PlusOutlined />}
                      className={styles.addBtn}
                    >
                      Добавить ингредиент
                    </Button>
                  </div>
                )}
              </Form.List>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

export default RecipeNutritionUpdate;
