/* eslint-disable max-len */

import React from 'react';
import { Link } from 'react-router-dom';
import { EmailLanguage } from '../../../../../../hooks/api/emails';

import styles from './index.module.scss';

interface EmailFooterProps {
  lang: EmailLanguage
}

function EmailFooter({ lang }: EmailFooterProps) {
  return (
    <div className={styles.wrap}>
      <div className={styles.socials}>
        <Link to="#" target="_blank" className={styles.icon}>
          <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
            <path
              d="M13.9956 2.00391C10.7366 2.00391 10.328 2.01772 9.04803 2.07612C7.77076 2.13438 6.89845 2.33725 6.13516 2.63392C5.34605 2.94054 4.67683 3.35086 4.00967 4.01797C3.34255 4.68513 2.93224 5.35434 2.62561 6.14345C2.32895 6.90675 2.12608 7.77907 2.06782 9.05633C2.00942 10.3363 1.99561 10.7449 1.99561 14.004C1.99561 17.2629 2.00942 17.6716 2.06782 18.9516C2.12608 20.2288 2.32895 21.1011 2.62561 21.8644C2.93224 22.6535 3.34255 23.3227 4.00967 23.9899C4.67683 24.657 5.34605 25.0673 6.13516 25.374C6.89845 25.6706 7.77076 25.8735 9.04803 25.9318C10.328 25.9901 10.7366 26.0039 13.9956 26.0039C17.2546 26.0039 17.6633 25.9901 18.9432 25.9318C20.2205 25.8735 21.0928 25.6706 21.8561 25.374C22.6451 25.0673 23.3144 24.657 23.9816 23.9899C24.6487 23.3227 25.059 22.6535 25.3657 21.8644C25.6623 21.1011 25.8652 20.2288 25.9234 18.9516C25.9818 17.6716 25.9956 17.2629 25.9956 14.004C25.9956 10.7449 25.9818 10.3363 25.9234 9.05633C25.8652 7.77907 25.6623 6.90675 25.3657 6.14345C25.059 5.35434 24.6487 4.68513 23.9816 4.01797C23.3144 3.35086 22.6451 2.94054 21.8561 2.63392C21.0928 2.33725 20.2205 2.13438 18.9432 2.07612C17.6633 2.01772 17.2546 2.00391 13.9956 2.00391ZM9.99562 14.004C9.99562 16.2131 11.7865 18.004 13.9956 18.004C16.2048 18.004 17.9957 16.2131 17.9957 14.004C17.9957 11.7948 16.2048 10.0039 13.9956 10.0039C11.7865 10.0039 9.99562 11.7948 9.99562 14.004ZM7.83345 14.004C7.83345 10.6006 10.5923 7.84175 13.9956 7.84175C17.3989 7.84175 20.1578 10.6006 20.1578 14.004C20.1578 17.4072 17.3989 20.166 13.9956 20.166C10.5923 20.166 7.83345 17.4072 7.83345 14.004ZM20.4012 9.03828C21.1966 9.03828 21.8413 8.3936 21.8413 7.5983C21.8413 6.80299 21.1966 6.15827 20.4012 6.15827C19.606 6.15827 18.9613 6.80299 18.9613 7.5983C18.9613 8.3936 19.606 9.03828 20.4012 9.03828Z"
              fill="#1F1F1F"
            />
          </svg>
        </Link>
        <Link to="#" target="_blank" className={styles.icon}>
          <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
            <g clipPath="url(#clip0_4082_49873)">
              <path
                d="M11.1838 17.6198V9.76324C13.9729 11.0756 16.133 12.3434 18.6878 13.7103C16.5807 14.8789 13.9729 16.1901 11.1838 17.6198ZM26.7274 5.85582C26.2463 5.22195 25.4263 4.72854 24.5533 4.56519C21.9874 4.07792 5.97938 4.07654 3.41481 4.56519C2.71475 4.69643 2.09137 5.01364 1.55587 5.5065C-0.700508 7.60076 0.00653265 18.8316 0.550406 20.6508C0.77911 21.4383 1.07477 22.0062 1.44711 22.379C1.92683 22.8718 2.58365 23.2112 3.3381 23.3633C5.45084 23.8003 16.3353 24.0447 24.5087 23.429C25.2617 23.2977 25.9283 22.9474 26.4541 22.4336C28.5403 20.3478 28.3981 8.48621 26.7274 5.85582Z"
                fill="#1F1F1F"
              />
            </g>
            <defs>
              <clipPath id="clip0_4082_49873">
                <rect width="28" height="28" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </Link>
      </div>
      <div className={styles.menuWrap}>
        <Link to="#" target="_blank">{lang === 'ru' ? 'Правила пользования' : 'Правила використання'}</Link>
        <Link to="#" target="_blank">{lang === 'ru' ? 'Политика конфиденциальности' : 'Політика конфіденційності'}</Link>
      </div>
      <div className={styles.copyright}>
        {lang === 'ru' ? (
          'Вы получили это письмо, потому что регистрировались в проекте «TGYM». Отказаться от подписки на рассылку можна в Настройках рассылки приложения TGYM'
        ) : 'Ви отримали цей лист, оскільки реєструвалися у проекті «TGYM». Відмовитися від підписки на розсилку можна в Налаштуваннях розсилки додатка TGYM.'}
        <div>© 2024 TGYM</div>
      </div>
    </div>
  );
}

export default EmailFooter;
