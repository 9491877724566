import React, { useState } from 'react';
import {
  App,
  Button, Col, Form, Row,
} from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { PlusOutlined } from '@ant-design/icons';
import WeekPicker from '../../../../../Common/Pickers/WeekPicker';
import { LectureWeek } from '../../../../../../hooks/api/marathons';
import LectureItem from '../../../../Lectures/LectureRowItem';
import LecturesListModal from '../Modal';
import { Lecture, useLectureId } from '../../../../../../hooks/api/lectures';
import { useUnsavedChanges } from '../../../../../../context/unsavedChanges';

function MarathonLecturesFormItems() {
  const { handleUnsavedChanges } = useUnsavedChanges();

  const { message } = App.useApp();
  const form = useFormInstance();
  const lectureById = useLectureId();

  const weeksWatch: LectureWeek[] = Form.useWatch('weeks', form) || [];

  const [selectedWeekIndex, setSelectedWeekIndex] = useState<number>(0);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedLecturesIds, setSelectedLecturesIds] = useState<number[]>([]);

  const handleAddLectures = async () => {
    let selectedTrainings: Lecture[] = [];

    const currentWeekLecturesIds = weeksWatch[selectedWeekIndex]?.lectures.map((el) => el.id);

    const uniqueIds = selectedLecturesIds.map((el) => el.toString())
      .filter((id) => !currentWeekLecturesIds?.includes(id));

    await Promise.all(uniqueIds?.map((id) => lectureById.fetch(undefined, id)))
      .then((res) => {
        if (res.every((el) => el?.id)) {
          selectedTrainings = [...selectedTrainings, ...res as Lecture[]];
        }
      }).catch((err) => {
        if (err) message.error('Ошибка при добавлении лекции.');
      });

    /** Update value in form after request */
    const namePath = ['weeks', selectedWeekIndex, 'lectures'];
    const prevValue = form.getFieldValue(namePath);

    form.setFieldValue(namePath, [...prevValue, ...selectedTrainings]);
    handleUnsavedChanges(true);
    setSelectedLecturesIds([]);
  };

  return (
    <>
      <LecturesListModal
        isOpenModal={isModalOpen}
        isLoading={lectureById.loading}
        handleAddLectures={handleAddLectures}
        handleModal={setIsModalOpen}
        selectedLecturesIds={selectedLecturesIds}
        handleSelectedRows={setSelectedLecturesIds}
      />
      <Row gutter={24}>
        <Col span={24}>
          <div className="week-day-picker-container">
            <WeekPicker
              weeks={weeksWatch || []}
              selectedWeek={selectedWeekIndex}
              label="Неделя марафона"
              onClick={(weekIndex) => setSelectedWeekIndex(weekIndex)}
            />
          </div>
        </Col>
        <Col span={24}>
          <Form.List name="weeks">
            {(fields) => (
              <>
                {fields.map((field, weekIndex) => (
                  <Form.List name={[field.name, 'lectures']} key={field.name}>
                    {(lectureFields, { remove }) => (
                      <>
                        {lectureFields.map((lectureField, lecturesIndex) => (
                          selectedWeekIndex === weekIndex ? (
                            <LectureItem
                              key={lectureField.name}
                              lecture={weeksWatch[weekIndex]?.lectures[lecturesIndex]}
                              onRemove={() => remove(lectureField.name)}
                            />
                          ) : null
                        ))}
                      </>
                    )}
                  </Form.List>
                ))}
              </>
            )}
          </Form.List>
        </Col>
        <Col span={24}>
          <Button icon={<PlusOutlined />} onClick={() => setIsModalOpen(true)}>Добавить лекцию</Button>
        </Col>
      </Row>
    </>
  );
}

export default MarathonLecturesFormItems;
