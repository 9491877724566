import React, { useState } from 'react';
import { ItemType } from 'antd/es/breadcrumb/Breadcrumb';
import { NavLink, useNavigate } from 'react-router-dom';
import {
  App, Button, Form, UploadFile,
} from 'antd';
import Tabs from 'antd/es/tabs';
import SinglePageContainer from '../../../Common/SinglePageContainer';
import { ComplexWeek, useComplexCreate } from '../../../../hooks/api/complexes';
import { useMessageError } from '../../../../hooks/common';
import ComplexesFormItemsGeneral from '../Form/General';
import ComplexesFormItemsTrainings from '../Form/Trainings';
import { useUnsavedChanges } from '../../../../context/unsavedChanges';

const breadcrumbs: ItemType[] = [
  {
    title: <NavLink to="/complexes">Комплексы</NavLink>,
  },
  {
    title: 'Добавить комплекс',
  },
];

export default function ComplexCreate() {
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const complexCreate = useComplexCreate();
  const navigate = useNavigate();
  const { handleEditorTouched } = useUnsavedChanges();

  const [bannerFile, setBannerFile] = useState<UploadFile[]>([]);

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      const modifiedVal = {
        ...values,
        weeks: (values.weeks || []).map((week: ComplexWeek) => ({
          trainingDays: week.trainingDays.map((trainingDay) => ({
            trainings: trainingDay.trainings.map((training) => ({
              id: training.id,
            })),
          })),
        })),
      };

      return (
        complexCreate.fetch(modifiedVal)
          .then((res) => {
            if (res?.id) {
              handleEditorTouched(false).then(() => {
                message.success('Комплекс успешно добавлен.');
                navigate(`/complexes/${res.id}`);
              });
            }
          })
      );
    });
  };

  const weeksWatch = Form.useWatch('weeks', form);
  const [withErrors, setWithErrors] = useState(false);

  const handleSubmitClick = () => {
    form.validateFields().catch(() => { setWithErrors(true); });
    form.submit();
  };

  useMessageError([complexCreate]);

  return (
    <SinglePageContainer
      breadcrumbItems={breadcrumbs}
      title="Добавить комплекс"
      cardLoading={false}
      extra={(
        <Button
          type="primary"
          onClick={handleSubmitClick}
          loading={complexCreate.loading}
          disabled={!weeksWatch?.length}
        >
          Сохранить
        </Button>
      )}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          imageUrl: '',
          descriptionRU: '',
          descriptionUA: '',
          contentState: 'draft',
          durationInWeeks: 4,
          weeks: [],
        }}
        onFinish={handleSubmit}
        name="complex-create"
        onFieldsChange={() => {
          setWithErrors(form.getFieldsError().some((field) => field.errors.length));
        }}
      >
        <Tabs
          defaultActiveKey="1"
          items={[
            {
              key: '1',
              label: <span className={withErrors ? 'with-error' : undefined}>Общее</span>,
              children: (
                <ComplexesFormItemsGeneral
                  bannerFile={bannerFile}
                  setBannerFile={setBannerFile}
                />
              ),
            },
            {
              key: '2',
              label: 'Тренировки',
              children: (
                <ComplexesFormItemsTrainings />
              ),
            },
          ]}
        />
      </Form>
    </SinglePageContainer>
  );
}
