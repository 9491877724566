import React, { useEffect, useState } from 'react';
import { Form as AntdForm, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { signIn as signInAction, SignInAction } from '@triare/auth-redux/dist/saga/auth/signIn';
import { Action } from '@triare/auth-redux';
import { useNavigate } from 'react-router-dom';
import { Error, AnyObject } from '@triare/auth-redux/dist/saga/common';
import { LockOutlined, MailOutlined } from '@ant-design/icons';
import Form from '../Form';
import { validationSignInEmailAndPassword, createRulesForAntd } from '../../../../../utils/validations';
import { RootState } from '../../../../../store';
import { moduleName } from '../../../../../store/auth';

const validationRules = createRulesForAntd(validationSignInEmailAndPassword);

interface EmailAndPasswordProps {
  authorized: boolean;
  response: AnyObject | null;
  error: Error | null;
  onSubmit?: (values: SignInAction | null) => void
  signIn: (payload: SignInAction) => Action
}

function EmailAndPassword({
  authorized, response, error, signIn, onSubmit,
}: EmailAndPasswordProps): React.ReactNode {
  const [signInData, setSignInData] = useState<SignInAction | null>(null);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!error && response && !authorized && onSubmit) {
      onSubmit(signInData);
    }
  }, [authorized, response, error, onSubmit]);

  useEffect(() => {
    if (authorized && signInData && !error && !response) {
      navigate('/');
    }
  }, [authorized, signInData, error, response]);

  return (
    <Form<SignInAction>
      validationRules={validationRules}
      validateSubmit={({ email, password }) => !email || !password}
      signIn={(props) => {
        const data: SignInAction = {
          ...props,
          // _query: {
          //   lang: 'ru',
          // },
        };

        setSignInData(data);
        signIn(data);
      }}
      name="signInEmail"
    >
      <AntdForm.Item
        name="email"
        rules={[validationRules]}
      >
        <Input
          prefix={<MailOutlined />}
          placeholder="Электронная почта"
          size="large"
          readOnly
          onFocus={(e) => e.currentTarget.removeAttribute('readonly')}
        />
      </AntdForm.Item>

      <AntdForm.Item
        name="password"
        // label={`${t('password')} *`}
        rules={[validationRules]}
      >
        <Input.Password placeholder="Пароль" size="large" prefix={<LockOutlined />} />
      </AntdForm.Item>
    </Form>
  );
}

EmailAndPassword.defaultProps = {
  onSubmit: undefined,
};

export default connect((state: RootState) => ({
  authorized: state[moduleName].authorized,
  response: state[moduleName].signIn.response,
  error: state[moduleName].signIn.error,
}), {
  signIn: signInAction,
})(EmailAndPassword);
