import React, { useEffect, useState } from 'react';
import { App, Button, Form } from 'antd';
import { NavLink, useParams } from 'react-router-dom';
import SinglePageContainer from '../../../Common/SinglePageContainer';
import { useMessageError } from '../../../../hooks/common';
import { useUnsavedChanges } from '../../../../context/unsavedChanges';
import {
  CreateMarathonTraining,
  MarathonTraining, MarathonTrainingWeek,
  useMarathonId,
  useMarathonPutData,
} from '../../../../hooks/api/marathons';
import { filterObjectProps, isObjEqual } from '../../../../utils';
import { LevelEnum, PlaceEnum } from '../../../../enums/trainings';
import MarathonFormItemsTrainings, { initialMarathonTrainingWeekState } from './FormItems';
import CopySelect from './CopySelect';

const initialState: Partial<CreateMarathonTraining> = {
  weeks: [initialMarathonTrainingWeekState],
};

const keysToFilterInitial: (keyof Partial<MarathonTraining>)[] = ['id', 'level', 'place'];

const getTrainingName = (trainingData: MarathonTraining | undefined) => (trainingData?.id ? (
  `${PlaceEnum[trainingData.place]} (${LevelEnum[trainingData.level]})`
) : '');

function MarathonTrainings() {
  const { marathonId = '', id = '' } = useParams<{ marathonId: string; id: string }>();
  const marathonTrainingById = useMarathonId<MarathonTraining>(`${marathonId}/trainings/${id}`);
  const marathonPutData = useMarathonPutData<MarathonTraining>(`${marathonId}/trainings/${id}/weeks`);

  const { message } = App.useApp();
  const [form] = Form.useForm();
  const { unsavedChanges, handleUnsavedChanges } = useUnsavedChanges();

  const fetch = () => {
    marathonTrainingById.fetch();
  };

  useEffect(() => {
    if (marathonId && id) fetch();
  }, [marathonId, id]);

  /** Handle Initial values */
  const [initialValues, setInitialValues] = useState(initialState);

  useEffect(() => {
    if (marathonTrainingById.data) {
      setInitialValues(filterObjectProps(marathonTrainingById.data, keysToFilterInitial));
    }
  }, [marathonTrainingById.data]);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(initialValues);
    handleUnsavedChanges(false);
  }, [initialValues]);

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      const modifiedVal = {
        ...values,
        weeks: (values.weeks || []).map((week: MarathonTrainingWeek) => ({
          id: week.id,
          trainingDays: week.trainingDays.map((trainingDay) => ({
            id: trainingDay.id,
            morningTrainings: trainingDay.morningTrainings.map((training) => ({
              id: training.id,
            })),
            mainTrainings: trainingDay.mainTrainings.map((training) => ({
              id: training.id,
            })),
            eveningTrainings: trainingDay.eveningTrainings.map((training) => ({
              id: training.id,
            })),
          })),
        })),
      };

      marathonPutData.fetch(modifiedVal)
        .then((res) => {
          if (res?.id) {
            message.success('Изменения успешно сохранены.');
            setInitialValues(filterObjectProps(res, keysToFilterInitial));
          }
        });
    });
  };

  useMessageError([marathonTrainingById, marathonPutData]);

  const handleFieldsChange = () => {
    handleUnsavedChanges(!isObjEqual(
      initialValues,
      form.getFieldsValue(),
    ));
  };
  const weeksWatch = Form.useWatch('weeks', form);

  useEffect(() => { handleFieldsChange(); }, [weeksWatch]);

  return (
    <SinglePageContainer
      breadcrumbItems={[
        { title: <NavLink to="/marathons">Марафоны</NavLink> },
        { title: <NavLink to={`/marathons/${marathonId}`}>Редактировать марафон</NavLink> },
        { title: `Редактировать тренировки ${getTrainingName(marathonTrainingById.data)}` },
      ]}
      title={`Редактировать тренировки ${getTrainingName(marathonTrainingById.data)}`}
      cardLoading={marathonTrainingById.loading}
      extra={(
        <>
          <CopySelect
            currentPlace={marathonTrainingById.data?.place}
            currentLevel={marathonTrainingById.data?.level}
            onCopy={(res) => setInitialValues(filterObjectProps(res, keysToFilterInitial))}
          />
          <Button
            type="primary"
            onClick={handleSubmit}
            loading={marathonPutData.loading}
            disabled={!unsavedChanges}
          >
            Сохранить
          </Button>
        </>
      )}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={initialValues}
        onFinish={handleSubmit}
        name="marathon-update-trainings"
        onFieldsChange={handleFieldsChange}
      >
        <MarathonFormItemsTrainings
          defaultSearchParams={{ level: marathonTrainingById.data?.level, place: marathonTrainingById.data?.place }}
        />
      </Form>
    </SinglePageContainer>
  );
}

export default MarathonTrainings;
