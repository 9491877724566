import React, { useEffect, useState } from 'react';
import {
  Collapse, List, Typography,
} from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import {
  Marathon, MarathonMenuMin, MarathonTrainingMin,
} from '../../../../../hooks/api/marathons';
import { LevelEnum, PlaceEnum } from '../../../../../enums/trainings';
import LinkButton from '../../../../Common/LinkButton';
import { MenuTypeEnum } from '../../../../../enums/recipes';
import { sortShoppingMenus, sortTrainings } from './sortUtils';

import styles from './index.module.scss';

function EditLinkBtn({ to, disabled }: { to: string; disabled?: boolean }) {
  return (<LinkButton to={to} disabled={disabled}><EditOutlined /></LinkButton>);
}

interface EntitiesCollapseProps {
  data: Marathon;
}

function EntitiesCollapse({ data }: EntitiesCollapseProps) {
  const navigate = useNavigate();
  const [trainingsData, setTrainingsData] = useState<MarathonTrainingMin[]>([]);
  const [menusData, setMenusData] = useState<MarathonMenuMin[]>([]);
  const [shoppingData, setShoppingData] = useState<MarathonMenuMin[]>([]);

  useEffect(() => {
    if (data?.id) {
      setTrainingsData(sortTrainings(data.trainings || []));
      setMenusData(sortShoppingMenus(data.menus || []));
      setShoppingData(sortShoppingMenus(data.shoppings || []));
    }
  }, [data]);

  const contestData = [
    { id: 'results', label: 'Результаты', disabled: data.status === 'upcoming' || data.status === 'new' },
    { id: 'instructions', label: 'Инструкции для фото/видео' },
    { id: 'prizes', label: 'Призы' },
  ];

  return (
    <Collapse
      ghost
      className="collapse-marathons"
      size="small"
      items={[
        {
          key: '1',
          label: 'Тренировки',
          children: (
            <List
              size="small"
              dataSource={trainingsData}
              renderItem={(item) => (
                <List.Item
                  key={item.id}
                  extra={(<EditLinkBtn to={`/marathons/${data.id}/trainings/${item.id}`} />)}
                  onClick={() => navigate(`/marathons/${data.id}/trainings/${item.id}`)}
                  className="cursor-pointer with-hover"
                >
                  <Typography.Text>{`${PlaceEnum[item.place]} (${LevelEnum[item.level]})`}</Typography.Text>
                </List.Item>
              )}
            />
          ),
        },
        {
          key: '2',
          label: 'Меню',
          children: (
            <List
              size="small"
              dataSource={menusData}
              renderItem={(item) => (
                <List.Item
                  key={item.id}
                  extra={(<EditLinkBtn to={`/marathons/${data.id}/menus/${item.id}`} />)}
                  onClick={() => navigate(`/marathons/${data.id}/menus/${item.id}`)}
                  className="cursor-pointer with-hover"
                >
                  <Typography.Text>{`${MenuTypeEnum[item.menuType]} (${item.calories || 0} ккал)`}</Typography.Text>
                </List.Item>
              )}
            />
          ),
        },
        {
          key: '3',
          label: 'Закупки',
          children: (
            <List
              size="small"
              dataSource={shoppingData}
              renderItem={(item) => (
                <List.Item
                  key={item.id}
                  extra={(<EditLinkBtn to={`/marathons/${data.id}/shopping/${item.id}`} />)}
                  onClick={() => navigate(`/marathons/${data.id}/shopping/${item.id}`)}
                  className="cursor-pointer with-hover"
                >
                  <Typography.Text>
                    {`${MenuTypeEnum[item.menuType]} меню (${item.calories || 0} ккал)`}
                  </Typography.Text>
                </List.Item>
              )}
            />
          ),
        },
        {
          key: '4',
          label: 'Привычки',
          showArrow: false,
          collapsible: 'icon',
          extra: (<EditLinkBtn to={`/marathons/${data.id}/habits`} />),
          className: clsx(styles.uncollapsiblePanel, 'cursor-pointer with-hover'),
          onClick: () => navigate(`/marathons/${data.id}/habits`),
        },
        {
          key: '5',
          label: 'Лекции',
          showArrow: false,
          collapsible: 'icon',
          extra: (<EditLinkBtn to={`/marathons/${data.id}/lectures`} />),
          className: clsx(styles.uncollapsiblePanel, 'cursor-pointer with-hover'),
          onClick: () => navigate(`/marathons/${data.id}/lectures`),
        },
        {
          key: '6',
          label: 'Конкурс',
          children: (
            <List
              size="small"
              dataSource={contestData}
              renderItem={(contest) => (
                <List.Item
                  key={contest.id}
                  extra={(<EditLinkBtn to={`/marathons/${data.id}/${contest.id}`} disabled={contest.disabled} />)}
                  onClick={!contest.disabled ? () => navigate(`/marathons/${data.id}/${contest.id}`) : undefined}
                  className="cursor-pointer with-hover"
                >
                  <Typography.Text>{contest.label}</Typography.Text>
                </List.Item>
              )}
            />
          ),
        },
        {
          key: '7',
          label: 'Ссылки на Телеграм-каналы',
          showArrow: false,
          collapsible: 'icon',
          extra: (<EditLinkBtn to={`/marathons/${data.id}/telegram`} />),
          className: clsx(styles.uncollapsiblePanel, 'cursor-pointer with-hover'),
          onClick: () => navigate(`/marathons/${data.id}/telegram`),
        },
        {
          key: '8',
          label: 'Диплом',
          showArrow: false,
          collapsible: 'icon',
          extra: (<EditLinkBtn to={`/marathons/${data.id}/diploma`} />),
          className: clsx(styles.uncollapsiblePanel, 'cursor-pointer with-hover'),
          onClick: () => navigate(`/marathons/${data.id}/diploma`),
        },
      ]}
    />
  );
}

EditLinkBtn.defaultProps = { disabled: false };

EntitiesCollapse.defaultProps = {};

export default EntitiesCollapse;
