import React, { useEffect, useState } from 'react';
import { ItemType } from 'antd/es/breadcrumb/Breadcrumb';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import {
  App, Button, Form, UploadFile,
} from 'antd';
import Tabs from 'antd/es/tabs';
import SinglePageContainer from '../../../../Common/SinglePageContainer';
import {
  CreateTrainingPayload, Exercise,
  Training,
  useTrainingDelete,
  useTrainingUpdate,
} from '../../../../../hooks/api/trainings';
import { useMessageError } from '../../../../../hooks/common';
import TrainingGymFormItemsGeneral from '../../Forms/Gym/General';
import { generateFileObjFromUrl, getFullFileUrl } from '../../../../Common/Uploaders/ImageUpload';
import TrainingGymFormItemsExercises, { initialExerciseState } from '../../Forms/Gym/Exercises';
import { FetchGetId } from '../../../../../hooks/fetch';
import { asyncDelay, filterObjectProps, isObjEqual } from '../../../../../utils';
import { useSimpleModal } from '../../../../Common/Modal/Simple';
import { useUnsavedChanges } from '../../../../../context/unsavedChanges';
import TrainingUsedInTable from '../TrainingUsedInTable';

const breadcrumbs: ItemType[] = [
  {
    title: <NavLink to="/trainings">Тренировки</NavLink>,
  },
  {
    title: 'Редактировать тренировку (зал)',
  },
];

const initialState: Partial<CreateTrainingPayload> = {
  thumbnail: '',
  nameRU: '',
  nameUA: '',
  availability: undefined,
  contentState: undefined,
  level: undefined,
  place: 'gym',
  category: undefined,
  duration: 0,
  exercises: [initialExerciseState],
};

const keysToFilterInitial: (keyof Partial<Training>)[] = [
  'createdAt', 'updatedAt', 'id', 'usedIn', 'target', 'equipment', 'url', 'category', 'descriptionRU', 'descriptionUA',
];

interface TrainingContentProps {
  trainingById: FetchGetId<Training>;
}

export default function TrainingGymContent({ trainingById }: TrainingContentProps) {
  const { id = '' } = useParams<{ id: string; }>();
  const trainingUpdate = useTrainingUpdate();
  const trainingDelete = useTrainingDelete();
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const { message } = App.useApp();
  const { open, contextHolder } = useSimpleModal();
  const {
    unsavedChanges, handleUnsavedChanges, htmlEditorTouched, handleEditorTouched,
  } = useUnsavedChanges();

  const [bannerFile, setBannerFile] = useState<UploadFile[]>([]);

  /** Handle Initial values */
  const [initialValues, setInitialValues] = useState(initialState);

  const handleInitialFileList = (data: Training) => {
    const banner = generateFileObjFromUrl(data.thumbnail);

    setBannerFile([banner]);
  };

  useEffect(() => {
    if (trainingById.data) {
      setInitialValues(filterObjectProps(trainingById.data, keysToFilterInitial));
      handleInitialFileList(trainingById.data);
    }
  }, [trainingById.data]);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(initialValues);
    handleUnsavedChanges(false);
    asyncDelay(200).then(() => (handleEditorTouched(false)));
  }, [initialValues]);

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      const modifiedValues = {
        ...values,
        exercises: (values.exercises || []).map((exercise: Exercise) => ({
          ...exercise,
          weightRU: exercise.weightRU || null,
          weightUA: exercise.weightUA || null,
        })),
        thumbnail: getFullFileUrl(values.thumbnail),
      };

      return (
        trainingUpdate.fetch(modifiedValues, id)
          .then((res) => {
            if (res?.id) {
              message.success('Изменения успешно сохранены.');
              setInitialValues(filterObjectProps(res, keysToFilterInitial));
            }
          })
      );
    });
  };

  /** Handle Delete */
  const handleDeleteClick = () => {
    if (!id) return;
    open({
      title: 'Удалить тренировку?',
      content: 'Вы уверены, что хотите навсегда удалить эту тренировку?',
      cancelText: 'Отмена',
      centered: true,
      okText: 'Удалить',
      okButtonProps: {
        type: 'primary',
        danger: true,
      },
      onOk: () => trainingDelete.fetch(id),
      maskClosable: true,
    });
  };

  useEffect(() => {
    if (trainingDelete.response?.status === 204) {
      handleUnsavedChanges(false).then(() => {
        message.success('Тренировка успешно удалена.');
        navigate('/trainings');
      });
    }
  }, [trainingDelete.response]);

  useMessageError([trainingUpdate, trainingDelete]);

  const exercises = Form.useWatch('exercises', form);

  const [withErrors, setWithErrors] = useState(false);

  const handleSubmitClick = () => {
    form.validateFields().catch((err) => {
      if ((err?.errorFields || []).length) {
        setWithErrors(true);
        message.error('Заполните все обязательные поля!');
      }
    });
    form.submit();
  };

  const handleFieldsChange = () => {
    setWithErrors(form.getFieldsError().some((field) => field.errors.length));

    handleUnsavedChanges(!isObjEqual(
      initialValues,
      form.getFieldsValue(),
    ));
  };

  useEffect(() => { handleFieldsChange(); }, [bannerFile]);

  return (
    <>
      {contextHolder}
      <SinglePageContainer
        breadcrumbItems={breadcrumbs}
        title="Редактировать тренировку (зал)"
        cardLoading={false}
        extra={(
          <>
            <Button type="primary" danger onClick={handleDeleteClick}>Удалить</Button>
            <Button
              type="primary"
              onClick={handleSubmitClick}
              loading={trainingUpdate.loading}
              disabled={!exercises?.[0]?.nameRU || !(unsavedChanges || htmlEditorTouched)}
            >
              Сохранить
            </Button>
          </>
        )}
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={initialValues}
          onFinish={handleSubmit}
          name="training-update-gym"
          onFieldsChange={handleFieldsChange}
        >
          <Tabs
            defaultActiveKey="1"
            items={[
              {
                key: '1',
                label: <span className={withErrors ? 'with-error' : undefined}>Общее</span>,
                children: (
                  <TrainingGymFormItemsGeneral bannerFile={bannerFile} setBannerFile={setBannerFile} />
                ),
              },
              {
                key: '2',
                label: 'Упражнения',
                children: (
                  <TrainingGymFormItemsExercises initialValues={initialValues} checkUnsavedChanges />
                ),
              },
            ]}
          />
        </Form>

        {trainingById.data?.usedIn ? <TrainingUsedInTable usedIn={trainingById.data.usedIn} /> : null}
      </SinglePageContainer>
    </>
  );
}
