import React from 'react';
import { SelectProps } from 'antd/lib/select';
import { Badge, Select } from 'antd';
import { UserStatus } from '../../../../hooks/api/user';
import { Option } from '../../../../types';

function SelectStatus({ ...props }: SelectProps<UserStatus, Option>) {
  return (
    <Select {...props} />
  );
}

SelectStatus.defaultProps = {
  options: [
    { label: <Badge status="success" text="Активный" />, value: 'active' },
    { label: <Badge status="error" text="Неактивный" />, value: 'inactive' },
    { label: <Badge status="warning" text="Ожидание" />, value: 'pending' },
  ],
  allowClear: true,
  placeholder: 'Выберите',
};

export default SelectStatus;
