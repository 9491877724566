import {
  DefaultFetchError, FetchCreate,
  FetchGetId,
  FetchSuccess,
  FetchUpdate,
  PagingDataResponse,
  useFetchCreate,
  useFetchGetId,
} from '../fetch';
import { UserExtended } from './user';

export interface MarathonResults {
  stage: number,
  remain: number,
  skipped: number
}

export type MarathonResultsUserAction = 'next' | 'skip'

export const useMarathonResults = (id?: string): FetchGetId<MarathonResults[]> => useFetchGetId(
  'marathons',
  id,
  { autoStart: false, startStateLoading: false },
);

export const useMarathonResultStage = (id?: string): FetchGetId<PagingDataResponse<UserExtended>> => useFetchGetId(
  'marathons',
  id,
  { autoStart: false, startStateLoading: false },
);

export const useMarathonResultsUpdate = ():
  FetchCreate<FetchSuccess, DefaultFetchError, FetchUpdate> => (
  useFetchCreate('marathons', { autoStart: false })
);
