import { DefaultFetchError, FetchCreate, useFetchCreate } from '../fetch';

export type ReceiversGroupType = 'users' | 'gold' | 'silver' | 'marathon' | 'month' | 'year' | 'admins';

export interface Notification {
  id?: string;
  group: ReceiversGroupType;
  titleRU: string;
  titleUA: string;
  descriptionUA: string;
  descriptionRU: string;
}

export interface CreateNotificationPayload {
  group: ReceiversGroupType;
  titleRU: string;
  titleUA: string;
  messageRU: string;
  messageUA: string;
}

export const useNotificationGroupCreate = (): FetchCreate<Notification, DefaultFetchError, CreateNotificationPayload
> => (
  useFetchCreate('notifications/send-for-group')
);
