import { ConfigProvider, App } from 'antd';
import ruRU from 'antd/lib/locale/ru_RU';
import React from 'react';

interface AntdConfigProviderProps {
  children: React.ReactNode
}

export const COLOR_PRIMARY = 'rgb(31 31 31)'; // #1f1f1f
export const COLOR_PRIMARY_HOVER = '#626262';
export const LINK_DECORATION = 'underline';

export default function AntdConfigProvider({ children }: AntdConfigProviderProps): React.ReactNode {
  return (
    <ConfigProvider
      locale={ruRU}
      theme={{
        token: {
          fontFamily: 'Roboto',
          colorPrimary: COLOR_PRIMARY,
          colorLink: COLOR_PRIMARY,
          colorLinkHover: COLOR_PRIMARY_HOVER,
          colorLinkActive: COLOR_PRIMARY,
          linkDecoration: LINK_DECORATION,
          linkHoverDecoration: LINK_DECORATION,
          linkFocusDecoration: LINK_DECORATION,
          colorInfo: COLOR_PRIMARY,
        },
      }}
    >
      <App>
        {children}
      </App>
    </ConfigProvider>
  );
}
