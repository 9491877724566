import React, { useMemo } from 'react';
import { ProColumns, RequestData } from '@ant-design/pro-components';
import { Button } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SortOrder } from 'antd/es/table/interface';
import { ParamsType } from '@ant-design/pro-provider';
import { CopyOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import Table, { defaultPagingResponse } from '../../../Common/Table';
import { createDefaultHiddenColumns, getSorterParams, queryFilterParams } from '../../../../utils';
import { useMessageError } from '../../../../hooks/common';
import { Emails, useEmailsGet } from '../../../../hooks/api/emails';
import { dateFormat } from '../../../../contstant';

function EmailsTable(): React.JSX.Element {
  const navigate = useNavigate();
  const emailsGet = useEmailsGet();

  const [_, setSearchParams] = useSearchParams();

  const tableRequest = async (
    { current, pageSize, ...args }: Record<string, string>
      & { pageSize?: number | undefined; current?: number | undefined; keyword?: string | undefined; },
    sorter: Record<string, SortOrder>,
  ): Promise<RequestData<Emails>> => {
    const newParams = queryFilterParams({
      page: current ? `${current}` : '1',
      pageSize: pageSize ? `${pageSize}` : '10',
      ...args,
      ...getSorterParams(sorter),
    });

    setSearchParams(queryFilterParams({ ...args, ...getSorterParams(sorter) }), { replace: true });

    return emailsGet.fetch(newParams).then((data) => {
      if (data) {
        const { data: emails, meta } = data;

        return ({ data: emails || [], success: true, total: meta?.totalItems || 0 });
      }

      return defaultPagingResponse;
    });
  };

  const beforeSearchSubmit = (beforeSubmitParams: Partial<ParamsType>) => {
    const newParams = queryFilterParams({
      ...beforeSubmitParams,
      _timestamp: '',
    });

    setSearchParams(newParams, { replace: true });

    return newParams;
  };

  useMessageError([emailsGet]);

  const columns: ProColumns<Emails>[] = [
    {
      title: 'Название',
      dataIndex: 'name',
      sorter: true,
    },
    {
      title: 'Дата создания',
      dataIndex: 'createdAt',
      renderText: (createdAt) => (createdAt ? dayjs(createdAt).format(dateFormat) : ''),
    },
    {
      title: 'Дата изменения',
      dataIndex: 'updatedAt',
      renderText: (updatedAt) => (updatedAt ? dayjs(updatedAt).format(dateFormat) : ''),
    },
    {
      key: 'actions',
      width: 100,
      title: 'Действия',
      dataIndex: 'actions',
      sorter: false,
      hideInSetting: true,
      render: (el, row) => (
        <Button
          size="middle"
          type="text"
          icon={<CopyOutlined style={{ fontSize: 18 }} />}
          onClick={(e) => {
            e.stopPropagation();
            navigate(`/emails/${row.id}/create`);
          }}
          title="Скопировать"
        />
      ),
    },
  ];

  const defaultHiddenColumns = useMemo(() => createDefaultHiddenColumns(
    ['contentUA', 'contentRU', 'createdAt', 'updatedAt'],
  ), []);

  return (
    <Table<Emails>
      rowKey="id"
      search={false}
      headerTitle="Список шаблонов рассылки"
      columns={columns}
      request={tableRequest}
      beforeSearchSubmit={beforeSearchSubmit}
      showSorterTooltip={false}
      columnsState={{
        persistenceKey: 'pro-table-emails',
        persistenceType: 'localStorage',
        defaultValue: defaultHiddenColumns,
      }}
      rowClassName="cursor-pointer"
      onRow={(record) => ({
        onClick: () => navigate(`/emails/${record.id}`),
      })}
    />
  );
}
export default EmailsTable;
