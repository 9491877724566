import React, {
  Key, useCallback, useEffect, useMemo, useRef,
} from 'react';
import { ActionType, ProColumns, RequestData } from '@ant-design/pro-components';
import {
  App, Button, FormInstance, Space,
} from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SortOrder } from 'antd/es/table/interface';
import { ParamsType } from '@ant-design/pro-provider';
import {
  CopyOutlined,
  DeleteOutlined, EditOutlined, PlusCircleOutlined,
} from '@ant-design/icons';
import dayjs from 'dayjs';
import Table, { defaultPagingResponse } from '../../../Common/Table';
import {
  createDefaultHiddenColumns, getSorterParams, queryFilterParams,
} from '../../../../utils';
import { useMessageError } from '../../../../hooks/common';
import { useSimpleModal } from '../../../Common/Modal/Simple';
import StatusIndicator from '../../../Common/StatusIndicator';
import { dateFormat, dateFormatMarathon } from '../../../../contstant';
import { Marathon, useMarathonDelete, useMarathonsGet } from '../../../../hooks/api/marathons';
import { MarathonStatusEnum } from '../../../../enums/marathon';

interface TableMarathons {
  params?: Record<string, string>;
  selectedRows?: number[];
  onRowSelection?: ((selectedRows: number[]) => void) | undefined;
}

function MarathonsTable(props: TableMarathons): React.JSX.Element {
  const { params, selectedRows, onRowSelection } = props;
  const { message } = App.useApp();
  const { open, contextHolder } = useSimpleModal();

  const navigate = useNavigate();
  const marathonsGet = useMarathonsGet();
  const marathonDelete = useMarathonDelete();
  const formRef = useRef<FormInstance>();
  const actionRef = useRef<ActionType>();

  const [searchParams, setSearchParams] = useSearchParams();

  const toolBarRender = useCallback(() => [
    <Button
      key="add-btn"
      id="add-btn"
      type="primary"
      icon={<PlusCircleOutlined />}
      onClick={() => navigate('/marathons/create')}
    >
      <Space>
        Добавить
      </Space>
    </Button>,
  ], []);

  /** Table request: */
  const tableRequest = async (
    { current, pageSize, ...args }: Record<string, string>
      & { pageSize?: number | undefined; current?: number | undefined; keyword?: string | undefined; },
    sorter: Record<string, SortOrder>,
  ): Promise<RequestData<Marathon>> => {
    const newParams = queryFilterParams({
      page: current ? `${current}` : '1',
      pageSize: pageSize ? `${pageSize}` : '10',
      ...args,
      ...getSorterParams(sorter),
    });

    setSearchParams(queryFilterParams({ ...args, ...getSorterParams(sorter) }), { replace: true });

    return marathonsGet.fetch({
      ...newParams,
      ...params,
    }).then((data) => {
      if (data) {
        const { data: marathons, meta } = data;

        return ({ data: marathons || [], success: true, total: meta?.totalItems || 0 });
      }

      return defaultPagingResponse;
    });
  };

  const beforeSearchSubmit = (beforeSubmitParams: Partial<ParamsType>) => {
    const newParams = queryFilterParams({
      ...beforeSubmitParams,
      _timestamp: '',
      search: searchParams.get('search') || '',
    });

    setSearchParams({ ...newParams, ...params }, { replace: true });

    return { ...newParams, ...params };
  };

  useMessageError([marathonsGet, marathonDelete]);

  const handleDeleteClick = (e: React.MouseEvent<HTMLElement>, row: Marathon) => {
    e.preventDefault();
    e.stopPropagation();

    if (row.status !== 'new') {
      message.error(`Удаление невозможно. Вы не можете удалить "${MarathonStatusEnum[row.status]}" марафон`);

      return;
    }

    open({
      title: 'Удалить марафон?',
      content: 'Вы уверены, что хотите навсегда удалить этот марафон?',
      cancelText: 'Отмена',
      centered: true,
      okText: 'Удалить',
      okButtonProps: {
        type: 'primary',
        danger: true,
      },
      onOk: () => marathonDelete.fetch(row.id),
      maskClosable: true,
    });
  };

  const handleCopyClick = (e: React.MouseEvent<HTMLElement>, id: string) => {
    e.preventDefault();
    e.stopPropagation();

    navigate(`/marathons/create?id=${id}`);
  };

  useEffect(() => {
    if (marathonDelete.response?.status === 204 || marathonDelete.data) {
      message.success('Марафон успешно удален.');
      actionRef.current?.reload();
    }
  }, [marathonDelete.response]);

  const columns: ProColumns<Marathon>[] = [
    {
      title: 'Название',
      dataIndex: 'nameRU',
      sorter: true,
      hideInSearch: true,
      hideInForm: true,
    },
    {
      order: 10,
      title: 'Дата старта',
      dataIndex: 'startDate',
      hideInSearch: true,
      sorter: true,
      renderText: (startDate) => (startDate ? dayjs(startDate).format(dateFormatMarathon) : ''),
    },
    {
      order: 9,
      title: 'Статус',
      dataIndex: 'status',
      hideInSearch: true,
      sorter: true,
      renderText: (status) => <StatusIndicator status={status} />,
    },
    {
      order: 9,
      title: 'Количество недель',
      dataIndex: 'durationInWeeks',
      hideInSearch: true,
      sorter: false,
      width: 150,
      // renderText: (durationInWeeks) => `${durationInWeeks} недель`,
    },
    {
      title: 'Дата создания',
      dataIndex: 'createdAt',
      sorter: true,
      renderText: (createdAt) => (createdAt ? dayjs(createdAt).format(dateFormat) : ''),
      hideInSearch: true,
    },
    {
      title: 'Дата изменения',
      dataIndex: 'updatedAt',
      sorter: true,
      renderText: (updatedAt) => (updatedAt ? dayjs(updatedAt).format(dateFormat) : ''),
      hideInSearch: true,
    },
    {
      key: 'actions',
      width: 124,
      title: 'Действия',
      dataIndex: 'actions',
      sorter: false,
      hideInSearch: true,
      hideInSetting: true,
      render: (_, row) => (
        <>
          <Button
            size="middle"
            type="text"
            icon={<EditOutlined style={{ fontSize: 18 }} />}
            onClick={() => navigate(`/marathons/${row.id}`)}
            title="Редактировать"
          />
          <Button
            size="middle"
            type="text"
            icon={<CopyOutlined />}
            onClick={(e) => handleCopyClick(e, row.id)}
            title="Копировать"
          />
          <Button
            size="middle"
            type="text"
            icon={<DeleteOutlined style={{ fontSize: 18 }} />}
            onClick={(e) => handleDeleteClick(e, row)}
            title="Удалить"
          />
        </>
      ),
    },
  ];

  const defaultHiddenColumns = useMemo(() => createDefaultHiddenColumns(
    ['createdAt', 'updatedAt', 'durationInWeeks'],
  ), []);

  const onRowChange = useCallback((selectedRowKeys: Key[]) => {
    if (onRowSelection) {
      onRowSelection(selectedRowKeys as number[]);
    }
  }, [onRowSelection]);

  const rowSelection = {
    onChange: onRowChange,
    selectedRowKeys: selectedRows,
    alwaysShowAlert: false,
    preserveSelectedRowKeys: true,
  };

  return (
    <>
      {contextHolder}
      <Table<Marathon>
        rowKey="id"
        className="search-hidden"
        formRef={formRef}
        actionRef={actionRef}
        headerTitle="Список марафонов"
        toolBarRender={toolBarRender}
        columns={columns}
        request={tableRequest}
        beforeSearchSubmit={beforeSearchSubmit}
        showSorterTooltip={false}
        columnsState={{
          persistenceKey: 'pro-table-marathons',
          persistenceType: 'localStorage',
          defaultValue: defaultHiddenColumns,
        }}
        rowSelection={!!onRowSelection && rowSelection}
        rowClassName="cursor-pointer"
        onRow={(record) => ({
          onClick: () => navigate(`/marathons/${record.id}`),
        })}
      />
    </>
  );
}

MarathonsTable.defaultProps = {
  params: {},
  selectedRows: [],
  onRowSelection: undefined,
};

export default MarathonsTable;
