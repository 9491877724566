import React from 'react';
import { config } from '@triare/auth-redux';
import Layout from '../../components/Layout';
import EmailsContent from '../../components/Pages/Emails';

const { displayName } = config;

export default function Emails(): React.ReactNode | null {
  document.title = `${displayName}: Рассылка`;

  return (
    <Layout>
      <EmailsContent />
    </Layout>
  );
}
