import React from 'react';
import { Typography } from 'antd';
import List from './List';

export default function Users(): React.ReactNode {
  return (
    <div>
      <Typography.Title level={4}>
        Администраторы
      </Typography.Title>
      <List />
    </div>
  );
}
