import React, { useEffect, useState } from 'react';
import { ItemType } from 'antd/es/breadcrumb/Breadcrumb';
import {
  NavLink, useNavigate, useSearchParams,
} from 'react-router-dom';
import { App, Button, Form } from 'antd';
import dayjs from 'dayjs';
import SinglePageContainer from '../../../Common/SinglePageContainer';
import { useMessageError } from '../../../../hooks/common';
import MarathonFormItems from '../Form';
import { useMarathonCreate, useMarathonId } from '../../../../hooks/api/marathons';
import { filterObjectProps } from '../../../../utils';
import { MarathonInitialVal, prepareMarathonInitialValues } from '../Update';

const breadcrumbs: ItemType[] = [
  {
    title: <NavLink to="/marathons">Марафоны</NavLink>,
  },
  {
    title: 'Добавить марафон',
  },
];

export default function MarathonCreate() {
  const marathonById = useMarathonId();
  const [searchParams] = useSearchParams();

  const { message } = App.useApp();
  const [form] = Form.useForm();
  const marathonCreate = useMarathonCreate();
  const navigate = useNavigate();

  const [initialValues, setInitialValues] = useState<MarathonInitialVal>();

  const fetch = () => {
    marathonById.fetch(undefined, searchParams.get('id') || '');
  };

  useEffect(() => {
    if (searchParams.get('id')) {
      fetch();
    }
  }, [searchParams.has('id')]);

  useEffect(() => {
    if (marathonById.data) {
      setInitialValues(prepareMarathonInitialValues(marathonById.data, true));
    }
  }, [marathonById.data]);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(initialValues);
  }, [initialValues]);

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      const modifiedVal = {
        ...filterObjectProps(values, ['status', 'gold_silver']),
        startDate: dayjs(values?.startDate).format('YYYY-MM-DD'),
      };

      return (
        marathonCreate.fetch(modifiedVal, searchParams.has('id')
          ? `from/${searchParams.get('id') || ''}` : undefined)
          .then((res) => {
            if (res?.id) {
              message.success('Марафон успешно добавлен.');
              navigate(`/marathons/${res.id}`);
            }
          })
      );
    });
  };

  useMessageError([marathonCreate]);

  return (
    <SinglePageContainer
      breadcrumbItems={breadcrumbs}
      title="Добавить марафон"
      cardLoading={searchParams.has('id') ? marathonById.loading : false}
      extra={(
        <Button
          type="primary"
          onClick={form.submit}
          loading={marathonCreate.loading}
        >
          Сохранить
        </Button>
      )}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          nameRU: '',
          nameUA: '',
          durationInWeeks: 4,
          gold_silver: '0/0',
          status: 'new',
        }}
        onFinish={handleSubmit}
        name="marathon-create"
      >
        <MarathonFormItems />
      </Form>
    </SinglePageContainer>
  );
}
