import React, { useEffect, useState } from 'react';
import {
  Modal, Form, Input, Select, App, Button, Tooltip,
} from 'antd';
import { GiftOutlined } from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
import { GiftCertificatePayload, useCertificateGift } from '../../../../hooks/api/marathons';
import { enumToOptionsArray } from '../../../../utils';
import { UserSubscriptionEnum } from '../../../../enums/user';
import { createRulesForAntd, validationCertificateGift } from '../../../../utils/validations';

const validationRules = createRulesForAntd(validationCertificateGift);

const initialState: Partial<GiftCertificatePayload> = {
  subscription: undefined,
  email: '',
};

interface GiftCertificateButtonProps {
  disabled?: boolean;
}

function GiftCertificateButton({ disabled }: GiftCertificateButtonProps) {
  const { message } = App.useApp();
  const certificateGift = useCertificateGift();
  const [form] = Form.useForm();

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    form.resetFields();
  };

  const handleSubmit = () => {
    form.validateFields().then((values) => (
      certificateGift.fetch(values)
    ));
  };

  useEffect(() => {
    if (certificateGift.data || certificateGift.response?.status === 204) {
      handleClose();
      message.success('Сертификат успешно отправлен на электронную почту');
      certificateGift.clearResponse();
    }
  }, [certificateGift.data]);

  return (
    <>
      <Tooltip
        title={disabled
          && 'Подарить сертификат можно только на марафон со статусом «Предстоящий», «В продаже» и «Активный»'}
      >
        <Button
          type="default"
          loading={false}
          onClick={() => setOpen(true)}
          icon={<GiftOutlined />}
          disabled={disabled}
        >
          Подарить сертификаты
        </Button>
      </Tooltip>
      <Modal
        open={open}
        onCancel={handleClose}
        onOk={form.submit}
        okText="Отправить"
        okButtonProps={{ loading: certificateGift.loading }}
        title="Подарить сертификат"
        centered
      >
        <Form form={form} layout="vertical" initialValues={initialState} onFinish={handleSubmit}>
          <Form.Item
            name="subscription"
            label="Пакет"
            rules={[{ required: true, ...validationRules }]}
          >
            <Select options={enumToOptionsArray(UserSubscriptionEnum)} placeholder="Выберите" />
          </Form.Item>
          <Form.Item
            name="email"
            label="Адресс электронной почты получателя сертификата"
            rules={[{ required: true, ...validationRules }]}
          >
            <TextArea rows={4} placeholder="Введите" allowClear />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
GiftCertificateButton.defaultProps = {
  disabled: false,
};

export default GiftCertificateButton;
