import {
  DefaultFetchError,
  FetchGet,
  PagingDataResponse,
  PagingParams,
  useFetchGet,
} from '../fetch';
import {
  DefaultOrderByColumnProps,
} from '../../types';
import { UserSimple } from './user';

export interface Feedback {
  id: string
  calendarStars: number
  calendarContent: string
  trainingsStars: number
  trainingsContent: string
  nutritionStars: number
  nutritionContent: string
  lecturesStars: number
  lecturesContent: string
  profileStars: number
  profileContent: string
  user: UserSimple
  createdAt: string
  updatedAt: string
}

export interface FeedbackGetParams extends PagingParams {
  search?: string;
  stars?: string;
  orderByColumn?: DefaultOrderByColumnProps
}

export function useFeedbackGet<D = PagingDataResponse<Feedback>,
  DD = D>(decorateData?: (data: D) => DD): FetchGet<D, FeedbackGetParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, FeedbackGetParams, DD>(
    'feedback',
    {
      decorateData,
      autoStart: false,
      startStateLoading: false,
    },
  );
}
