import React, {
  createContext, ReactNode, useContext, useMemo,
} from 'react';
import { useParams } from 'react-router-dom';
import { defaultFetchData, DefaultFetchError, FetchGetId } from '../hooks/fetch';
import { UserExtended, useUserId } from '../hooks/api/user';

interface UserProfileProviderProps {
  userById: FetchGetId<UserExtended, DefaultFetchError, undefined, UserExtended>;
}

const defaultMarathonMenuById: FetchGetId<UserExtended, DefaultFetchError, undefined, UserExtended> = {
  // data: undefined,
  fetch: async () => null,
  finish: () => undefined,
  ...defaultFetchData,
};

const defaultValue: UserProfileProviderProps = {
  userById: defaultMarathonMenuById,
};

export const UserProfileContext = createContext<UserProfileProviderProps>(defaultValue);

function UserProfileProvider({ children }: { children: ReactNode }) {
  const { id = '' } = useParams<{ id: string; }>();
  const userById = useUserId();

  const memoizedValue = useMemo(() => ({
    userById,
  }), [userById]);

  return (
    <UserProfileContext.Provider value={memoizedValue}>
      {children}
    </UserProfileContext.Provider>
  );
}

export default UserProfileProvider;

export const useUserProfileProvider = (): UserProfileProviderProps => useContext(UserProfileContext);
