import { NavLink, useParams, useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import {
  Alert, Button, Form as AntdForm, Input, Typography, App,
} from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { validationNewPassword, createRulesForAntd } from '../../../../utils/validations';
import { usePasswordReset } from '../../../../hooks/api/password';
import { getMessageInError } from '../../../../hooks/fetch';
import Wrapper from '../Wrapper';

import styles from './index.module.scss';

const validationRules = createRulesForAntd(validationNewPassword);

export default function ResetPassword() {
  const passwordReset = usePasswordReset();
  const { secretKey } = useParams();
  const { message } = App.useApp();
  const navigate = useNavigate();
  const onFinish = ({ password }: { password: string }) => {
    if (secretKey) {
      passwordReset.fetch({ password, secretKey });
    }
  };

  useEffect(() => {
    if (passwordReset.data) {
      message.info('Ваш пароль был успешно изменен');
      navigate('/sign-in');
    }
  }, [passwordReset.data]);

  return (
    <Wrapper>
      <AntdForm className={styles.form} layout="vertical" onFinish={onFinish}>

        <div className="text-centered" style={{ marginBottom: '24px' }}>
          <Typography.Title level={3} className={styles.title}>
            Установить новый пароль
          </Typography.Title>
          <Typography.Paragraph className={styles.secondary}>
            Пароль должен быть не менее 12 символов и состоять из букв, цифр и специальных символов.
          </Typography.Paragraph>
        </div>

        {passwordReset.error && (
          <AntdForm.Item>
            <Alert
              type="error"
              onClose={() => passwordReset.clearError()}
              message={getMessageInError(passwordReset.error)}
              closable
              showIcon
            />
          </AntdForm.Item>
        )}

        <AntdForm.Item name="password" className={styles.input} rules={[validationRules]}>
          <Input.Password placeholder="Новый пароль" size="large" prefix={<LockOutlined />} />
        </AntdForm.Item>

        <AntdForm.Item
          name="confirmPassword"
          className={styles.input}
          dependencies={['password']}
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }

                return Promise.reject(new Error('Пароли не совпадают.'));
              },
            }),
          ]}
        >
          <Input.Password placeholder="Повторите новый пароль" size="large" prefix={<LockOutlined />} />
        </AntdForm.Item>

        <AntdForm.Item shouldUpdate>
          {({ getFieldsValue, getFieldsError }) => {
            const { confirmPassword: confirmPasswordValue, password: passwordValue } = getFieldsValue();
            const fieldsError = getFieldsError();

            return (
              <Button
                disabled={!confirmPasswordValue || !passwordValue || fieldsError.some((item) => item.errors.length)}
                size="large"
                type="primary"
                htmlType="submit"
                block
                className={styles.btn}
                loading={passwordReset.loading}
              >
                Сохранить
              </Button>
            );
          }}
        </AntdForm.Item>
        <AntdForm.Item className="text-centered">
          <NavLink to="/sign-in">Вернуться для входа в систему</NavLink>
        </AntdForm.Item>
      </AntdForm>
    </Wrapper>
  );
}
