import {
  DefaultFetchError, FetchCreate, FetchGetId, useFetchCreate, useFetchGetId,
} from '../fetch';

export interface Habits {
  id: string
  nameUA: string
  nameRU: string
  createdAt: string
  updatedAt: string
}

export interface MarathonHabits {
  habits: Habits[]
}

export const useMarathonHabits = (id?: string): FetchGetId<MarathonHabits> => useFetchGetId(
  'marathons',
  id,
  { autoStart: false, startStateLoading: false },
);

export const useMarathonHabitsUpdate = (): FetchCreate<MarathonHabits,
  DefaultFetchError, MarathonHabits> => (
  useFetchCreate('marathons')
);
