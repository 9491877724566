import React from 'react';
import { Link } from 'react-router-dom';
import { EmailLanguage } from '../../../../../../hooks/api/emails';

import styles from './index.module.scss';

interface EmailSupportProps {
  lang: EmailLanguage
}

function EmailSupport({ lang } : EmailSupportProps) {
  return (
    <div className={styles.wrap}>
      {lang === 'ru' ? (
        'Если у вас есть какие-либо вопросы, пожалуйста, свяжитесь с нашей службой поддержки:'
      ) : 'Якщо у вас є будь-які питання, будь ласка, звертайтеся до нашої служби підтримки:'}
      <Link to="mailto:info@thetgym.com" target="_blank" style={{ marginLeft: '5px' }}>info@thetgym.com</Link>
    </div>
  );
}

export default EmailSupport;
