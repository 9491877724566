export enum MenuTypeEnum {
  classic = 'Классическое',
  vegan = 'Вегетарианское',
}

export enum RecipesCategoryEnum {
  breakfast = 'Завтраки',
  snack = 'Перекусы',
  lunch = 'Обеды',
  salad = 'Салаты',
  dinner = 'Ужины',
  dessert = 'Десерты',
  soup = 'Супы',
}

export enum CaloriesEnum {
  '<100' = 'Менее 100 ккал',
  '100-200' = '100-200 ккал',
  '200-300' = '200-300 ккал',
  '300-400' = '300-400 ккал',
  '400-500' = '400-500 ккал',
  '500-600' = '500-600 ккал',
  '>600' = 'Более 600 ккал',
}

export enum PreparationTimeEnum {
  '<15' = '<15 мин',
  '15-30' = '15 - 30 мин',
  '>30' = '>30 мин',
}

export enum MeasureEnum {
  'not-specified' = 'Не указано',
  g = 'Граммы (г)',
  kg = 'Килограммы (кг)',
  ml = 'Миллилитры (мл)',
  l = 'Литры (л)',
  pcs = 'Штуки (шт.)',
  'tea-spoon' = 'Чайная ложка (ч.л.)',
  'table-spoon' = 'Столовая ложка (с.л.)',
  'by-taste' = 'По вкусу',
}

export enum MeasureMinEnum {
  g = 'Граммы (г)',
  kg = 'Килограммы (кг)',
  ml = 'Миллилитры (мл)',
  l = 'Литры (л)',
  pcs = 'Штуки (шт.)',
  'by-taste' = 'По вкусу',
}
