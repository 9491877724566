import React from 'react';
import { config } from '@triare/auth-redux';
import Form from '../../../components/Pages/Auth/ForgotPassword/Form';
import Simple from '../../../components/Layout/Simple';

const { displayName } = config;

export default function ForgotPassword(): React.ReactNode {
  document.title = `${displayName}: Забыли пароль`;

  return (
    <Simple>
      <Form />
    </Simple>
  );
}
