import React from 'react';
import { Spin } from 'antd';
import clsx from 'clsx';
import { SpinSize } from 'antd/lib/spin';

import styles from './index.module.scss';

interface Loading {
  size?: SpinSize;
  absolute?: boolean;
  visible: boolean;
  transparentBg?: boolean;
}

export default function Loading({
  size, absolute, visible, transparentBg,
}: Loading) {
  if (!visible) {
    return null;
  }

  return (
    <div className={clsx(styles.loading, {
      [styles.absolute]: absolute,
      [styles.transparentBg]: transparentBg,
    })}
    >
      <Spin size={size} />
    </div>
  );
}

Loading.defaultProps = {
  size: 'default',
  absolute: true,
};
