import React from 'react';
import { config } from '@triare/auth-redux';
import Layout from '../../components/Layout';
import Content from '../../components/Pages/Certificates';

const { displayName } = config;

export default function Certificates(): React.ReactNode | null {
  document.title = `${displayName}: Сертификаты`;

  return (
    <Layout>
      <Content />
    </Layout>
  );
}
