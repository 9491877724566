import React from 'react';
import clsx from 'clsx';
import styles from './index.module.scss';

interface VideoThumbAndName {
  imgSrc: string;
  name: string;
  size?: 'small' | 'medium';
}

function ContentThumbnailAndName({ name, imgSrc, size }: VideoThumbAndName) {
  return (
    <div className={styles.container}>
      {imgSrc && (
        <img
          src={imgSrc}
          alt={name?.substring(0, 20)}
          className={clsx(styles.thumbnail, { [styles.small]: size === 'small' })}
          title={name}
        />
      )}
      <span title={name}>
        {name}
      </span>
    </div>
  );
}

ContentThumbnailAndName.defaultProps = {
  size: 'medium',
};

export default ContentThumbnailAndName;
