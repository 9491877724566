import {
  DefaultFetchError,
  FetchCreate,
  FetchSuccess,
  useFetchCreate,
} from '../fetch';

export interface PasswordCreateParams {
  email: string;
}

export const usePasswordForgot = (): FetchCreate<FetchSuccess, DefaultFetchError, PasswordCreateParams> => (
  useFetchCreate('auth/forgot-password', {
    authorization: false,
  })
);

export interface PasswordResetCreateParams {
  password: string;
  secretKey: string;
}

export const usePasswordReset = (): FetchCreate<FetchSuccess, DefaultFetchError, PasswordResetCreateParams> => (
  useFetchCreate('auth/reset-password', {
    authorization: false,
  })
);
