import React, { useState } from 'react';
import {
  Alert, App, Button, Form, FormInstance, Input, Progress, Tooltip, Upload, UploadFile, UploadProps,
} from 'antd';
import clsx from 'clsx';
import {
  DeleteOutlined, InfoCircleFilled, InfoCircleOutlined, PaperClipOutlined, UploadOutlined,
} from '@ant-design/icons';
import { RcFile } from 'antd/es/upload';
import { NamePath } from 'rc-field-form/lib/interface';
import { getMessageInError } from '../../../../hooks/fetch';
import { useUploadMedia } from '../../../../hooks/api/mediaUpload';
import { messages } from '../../../../utils/validations';
import { useMessageError } from '../../../../hooks/common';
import { getFullFileUrl } from '../ImageUpload';
import { useGlobalLoadingProvider } from '../../../../context/globalLoading';

import styles from './index.module.scss';

export const generatePdfObjFromUrl = (url: string) : UploadFile => ({
  url,
  uid: url,
  name: getPdfName(url),
  status: 'done',
  type: 'application/pdf',
});

export const getPdfName = (urlName: string) => {
  if (typeof urlName !== 'string') return 'unknown';

  const nameSplit = urlName?.split('/'); // pdf/{id}/name

  if (nameSplit?.[2]) { return nameSplit[2]; }
  if (nameSplit?.[1]) { return nameSplit[1]; }

  return 'file.pdf';
};

interface InputCodeWrapperProps extends React.PropsWithChildren {
  name: NamePath;
  label?: string;
  onUrl: (url: string) => void;
  onDelete: () => void;
  initialFileList: UploadFile[];
  weekId: string;
  disableIfName?: string;
  form?: FormInstance;
  required?: boolean;
  accept?: string;
  className?: string;
}

function PdfUpload({
  label, name, required, accept, className, onUrl, onDelete, initialFileList, weekId, disableIfName,
}: InputCodeWrapperProps) {
  const { message } = App.useApp();
  const { handleGlobalLoading } = useGlobalLoadingProvider();

  const [percentCompleted, setPercentCompleted] = useState<number | null>(null);
  const controller = new AbortController();
  // eslint-disable-next-line max-len
  const uploader = useUploadMedia((progressEvent) => { setPercentCompleted(Math.round((progressEvent.loaded / (progressEvent?.total || 0)) * 100)); }, controller, `common/upload-pdf/${weekId}`);

  const [fileList, setFileList] = useState<UploadFile[]>(initialFileList || []);

  useMessageError([uploader]);

  /** Handlers */
  const onRemove = (file: UploadFile) => {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();

    newFileList.splice(index, 1);
    setFileList(newFileList);

    onDelete();
  };

  const beforeUpload = async (file: RcFile, FileList?: RcFile[]) => {
    /** Validation: */
    if (!['application/pdf', 'x-pdf'].includes(file.type)) {
      message.error('Вы можете загрузить только файл в формате PDF!');

      return false;
    }

    if (!(file.size / 1024 / 1024 < 100)) {
      message.error('Файл должен быть меньше 100МБ!');

      return false;
    }

    if (file.name === getPdfName(disableIfName || '')) {
      message.error('Вы не можете загрузить один и тот же файл дважды в одну неделю!');

      return false;
    }

    /** Functionality */
    const newFile: UploadFile = {
      originFileObj: file,
      uid: file.uid,
      status: 'done',
      name: file.name,
    };

    setFileList([newFile]);

    /** Upload image to API */
    const formData = new FormData();

    formData.append('file', newFile.originFileObj as Blob);
    handleGlobalLoading(true);
    uploader.fetch(formData)
      .then((res) => {
        if (res?.url) {
          setFileList((prevFileList) => {
            const updatedData = prevFileList.map((item) => (
              item.uid === file.uid ? { ...item, url: res.url } : item
            ));

            return updatedData;
          });
          /** Call 'set url' after upload request */
          onUrl(res.url);
        }
      })
      .finally(() => {
        setPercentCompleted(null);
        handleGlobalLoading(false);
      });

    return false;
  };

  const props: UploadProps = {
    name,
    multiple: false,
    showUploadList: false,
    accept,
    onRemove,
    beforeUpload,
    fileList,
    listType: 'text',
  };

  return (
    <div>
      <div className={clsx(styles.uploader, className)}>
        <Form.Item
          name={name}
          rules={required ? [{ required: true, message: messages.required }] : undefined}
          className="no-space-form-item"
          label={(
            <div style={{ display: 'flex', gap: 6 }}>
              {label}
              <Tooltip title="Название файла должно быть без спецсимволов (%, #, $, @...)">
                <InfoCircleOutlined />
              </Tooltip>
            </div>
          )}
        >
          <Input type="hidden" />
        </Form.Item>
        <div>
          <Upload
            {...props}
            className={styles.upload}
          >
            <Button
              type="default"
              icon={<UploadOutlined />}
              disabled={!!fileList.length}
              loading={uploader.loading}
            >
              Загрузить
            </Button>
          </Upload>
        </div>
      </div>

      {uploader.error ? (
        <Alert
          style={{ marginTop: 8 }}
          type="error"
          message={getMessageInError(uploader.error)}
          closable
          onClose={uploader.clearError}
        />
      ) : null}

      {percentCompleted ? (<Progress showInfo={false} percent={percentCompleted} style={{ width: 200 }} />) : null}
      <div className={styles.fileList}>
        {fileList.map((file) => (
          <div key={file.uid} className={styles.file}>
            <a
              // target="_blank"
              rel="noreferrer"
              href={getFullFileUrl(file.url)}
              download={file.name}
              className={styles.title}
            >
              <PaperClipOutlined />
              {file.name}
            </a>
            <Button
              icon={<DeleteOutlined />}
              type="text"
              size="small"
              onClick={() => onRemove(fileList[0])}
              disabled={uploader.loading}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

PdfUpload.defaultProps = {
  label: undefined,
  disableIfName: undefined,
  form: undefined,
  required: false,
  children: undefined,
  accept: '.pdf',
  multiple: false,
  className: undefined,
  cropperProps: undefined,
  wide: false,
};

export default PdfUpload;
