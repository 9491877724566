import React from 'react';
import { Descriptions } from 'antd';
import { Participant } from '../../../../../../hooks/api/user';
import { UserLevelEnum, UserMenuTypeEnum, UserPlaceEnum } from '../../../../../../enums/user';
import EditModal from './EditModal';

interface IEditCurrentMarathon {
  selectedMarathon: Participant | undefined
}

function EditCurrentMarathon({ selectedMarathon }: IEditCurrentMarathon) {
  return (
    <div>
      <Descriptions
        size="small"
        column={1}
        items={[
          {
            key: '1',
            label: 'Тип меню',
            children: selectedMarathon?.menuType ? UserMenuTypeEnum[selectedMarathon.menuType] : '',
          },
          {
            key: '2',
            label: 'Интенсивность тренировок',
            children: selectedMarathon?.level ? UserLevelEnum[selectedMarathon.level] : '',
          },
          {
            key: '3',
            label: 'Тип тренировок',
            children: selectedMarathon?.place ? UserPlaceEnum[selectedMarathon.place] : '',
          },
          {
            key: '4',
            label: 'Калораж меню',
            children: selectedMarathon?.calories || '',
          },
          {
            key: '5',
            label: '',
            children: (<EditModal selectedMarathon={selectedMarathon} />),
          },
        ]}
      />
    </div>
  );
}

export default EditCurrentMarathon;
