import React, { useEffect, useState } from 'react';
import { ItemType } from 'antd/es/breadcrumb/Breadcrumb';
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import {
  App, Button, Form, Input,
} from 'antd';
import SinglePageContainer from '../../../../Common/SinglePageContainer';
import { useTrainingCreate, useVideoThumbnail } from '../../../../../hooks/api/trainings';
import VideoThumbnail from '../../../../Common/VideoThumbnail';
import { useMessageError } from '../../../../../hooks/common';
import FormTrainingHome from '../../Forms/House';

import styles from './index.module.scss';
import { useUnsavedChanges } from '../../../../../context/unsavedChanges';

const breadcrumbs: ItemType[] = [
  {
    title: <NavLink to="/trainings">Тренировки</NavLink>,
  },
  {
    title: 'Добавить тренировку (дом)',
  },
];

export default function TrainingCreateHouse() {
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const videoThumbnail = useVideoThumbnail();
  const trainingCreate = useTrainingCreate();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { handleEditorTouched } = useUnsavedChanges();

  /** Handle thumbnail */
  const urlWatch = Form.useWatch('url', form);
  const [requestedVideoUrl, setRequestedVideoUrl] = useState('');

  const fetchVideoThumbnail = (currentUrl: string) => {
    if (currentUrl) {
      videoThumbnail.fetch({ url: currentUrl })
        .then((res) => { if (res?.thumbnail) { setRequestedVideoUrl(currentUrl); } });
    }
  };

  useEffect(() => {
    if (videoThumbnail.data?.duration) {
      form.setFieldValue('duration', videoThumbnail.data.duration);
      form.setFieldValue('thumbnail', videoThumbnail.data.thumbnail);
      form.validateFields(['url', 'duration']);
    }
  }, [videoThumbnail.data?.duration]);

  const handleSubmit = () => {
    form.validateFields().then((values) => (
      trainingCreate.fetch(values)
        .then((res) => {
          if (res?.id) {
            handleEditorTouched(false).then(() => {
              message.success('Тренировка успешно добавлена.');
              navigate(searchParams.get('from') || `/trainings/${res.id}`);
            });
          }
        })
    ));
  };

  useMessageError([videoThumbnail, trainingCreate]);

  return (
    <SinglePageContainer
      breadcrumbItems={breadcrumbs}
      title="Добавить тренировку (дом)"
      cardLoading={false}
      extra={(
        <Button
          type="primary"
          onClick={form.submit}
          disabled={requestedVideoUrl !== urlWatch}
          loading={trainingCreate.loading}
        >
          Сохранить
        </Button>
      )}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          place: 'house', descriptionRU: '', descriptionUA: '', forCatalog: true,
        }}
        onFinish={handleSubmit}
        name="training-create-house"
      >
        <div className={styles.videoContainer}>
          <VideoThumbnail videoUrl={requestedVideoUrl} thumbnailUrl={videoThumbnail.data?.thumbnail} />

          <div className={styles.videoInput}>
            <Form.Item
              name="url"
              label="Ссылка на YouTube видео"
              rules={[
                { required: true, message: 'Это поле обязательно к заполнению.' },
                {
                  validator: (_, value) => {
                    if (requestedVideoUrl === urlWatch) { return Promise.resolve(); }

                    /** if request not sent still - don't show error */
                    if (!videoThumbnail.data?.thumbnail) { return Promise.resolve(); }

                    return Promise.reject(new Error(
                      'Ссылка не соответствует видео. Пожалуйста, обновите ссылку и получите данные повторно!',
                    ));
                  },
                },
              ]}
            >
              <Input placeholder="Введите" autoComplete="off" />
            </Form.Item>
            <Button
              type="default"
              onClick={() => fetchVideoThumbnail(urlWatch)}
              loading={videoThumbnail.loading}
              disabled={requestedVideoUrl === urlWatch}
            >
              Получить данные
            </Button>
          </div>
        </div>

        <FormTrainingHome />
      </Form>
    </SinglePageContainer>
  );
}
