import React, { useEffect, useState } from 'react';
import TextArea from 'antd/es/input/TextArea';
import { Form, UploadFile } from 'antd';
import { EmailContent, EmailLanguage } from '../../../../../hooks/api/emails';
import EmailSupport from '../Static/Support';
import EmailFooter from '../Static/Footer';
import ImageUpload, { generateFileObjFromUrl } from '../../../../Common/Uploaders/ImageUpload';

import styles from './index.module.scss';

interface EmailTemplateProps {
  content: EmailContent
  lang: EmailLanguage
  handleChange: (key: keyof EmailContent, payload: string) => void
}

function EmailTemplate1({
  content, lang, handleChange,
}: EmailTemplateProps) {
  const [bannerFile, setBannerFile] = useState<UploadFile[]>([]);
  const [bannerFile2, setBanner2File] = useState<UploadFile[]>([]);

  useEffect(() => {
    setBannerFile(content.banner?.length ? [generateFileObjFromUrl(content.banner)] : []);
    setBanner2File(content.banner2?.length ? [generateFileObjFromUrl(content.banner2)] : []);
  }, [lang]);

  useEffect(() => {
    handleChange('banner', (bannerFile[0]?.url || ''));
  }, [bannerFile]);

  useEffect(() => {
    handleChange('banner2', (bannerFile2[0]?.url || ''));
  }, [bannerFile2]);

  return (
    <>
      <div className={styles.container}>
        <Form>
          <ImageUpload
            name="banner"
            setFileList={setBannerFile}
            cropperProps={{ aspect: 560 / 220 }}
            fileList={bannerFile}
            multiple={false}
            allowToPreview={false}
            label="Загрузить изображение 1120x440px"
            email
            H220
          />
          <div className={styles.content}>
            <div className={styles.label}>Заголовок</div>
            <TextArea
              rows={3}
              value={content.title}
              style={{ marginBottom: '24px' }}
              onChange={(e) => handleChange('title', e.target.value)}
            />
            <div className={styles.label}>Текстовый блок</div>
            <TextArea
              rows={7}
              value={content.content}
              onChange={(e) => handleChange('content', e.target.value)}
            />
          </div>
          <ImageUpload
            name="banner2"
            setFileList={setBanner2File}
            cropperProps={{ aspect: 560 / 140 }}
            fileList={bannerFile2}
            multiple={false}
            allowToPreview={false}
            label="Загрузить изображение 1120x280px"
            email
            H140
          />
          <EmailSupport lang={lang} />
        </Form>
      </div>
      <EmailFooter lang={lang} />
    </>
  );
}

export default EmailTemplate1;
