import { User } from './user';
import {
  DefaultFetchError,
  FetchCreate,
  FetchGet, FetchGetId,
  FetchSuccess, FetchUpdate,
  useFetchCreate,
  useFetchDelete,
  useFetchGet, useFetchGetId, useFetchUpdate,
} from '../fetch';
import { UserRoles } from '../../enums/user';

export type Admin = Omit<User, 'subscription'>

export interface TableAdminRow extends Admin {
  key: string;
}

interface AdminsTableData {
  admins: TableAdminRow[];
}

export interface AdminCreateParams {
  name: string;
  email: string;
  role?: UserRoles
}

export const useTableAdminsGet = (): FetchGet<Admin[], undefined, DefaultFetchError, AdminsTableData> => (
  useAdminsGet((data: Admin[]): AdminsTableData => ({
    admins: data.map((admin: Admin): TableAdminRow => ({
      key: admin.id,
      ...admin,
    })),
  }))
);

export function useAdminsGet<D = Admin[],
  DD = D>(decorateData?: (data: D) => DD): FetchGet<D, undefined, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, undefined, DD>(
    'admins',
    {
      decorateData,
      autoStart: false,
      startStateLoading: false,
    },
  );
}

export const useAdminDelete = () => useFetchDelete<FetchSuccess, DefaultFetchError, string>('admins');

export const useAdminCreate = (): FetchCreate<Admin, DefaultFetchError, AdminCreateParams> => (
  useFetchCreate('admins')
);

export const useAdminReInvite = (): FetchCreate<FetchSuccess, DefaultFetchError, string> => useFetchCreate('admins');

export const useAdminId = (id?: string): FetchGetId<Admin> => useFetchGetId(
  'admins',
  id,
  { autoStart: false, startStateLoading: false },
);

export const useAdminUpdate = (id?: string): FetchUpdate<Admin, DefaultFetchError, AdminCreateParams> => (
  useFetchUpdate('admins', id)
);
