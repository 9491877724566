import React from 'react';
import { StarFilled, StarOutlined } from '@ant-design/icons';
import { useSearchParams } from 'react-router-dom';
import { FeedBackTypeEnum } from '../../../../enums/reviews';

import styles from './index.module.scss';

interface FeedBackProps {
  starsCount: number,
  title: FeedBackTypeEnum,
  feedback: string,
  type: keyof typeof FeedBackTypeEnum
}

const getStars = (count: number) => (
  <div className={styles.starsWrap}>
    {Array.from({ length: 5 }).map((_, index) => (count <= index ? (
      <StarOutlined key={crypto.randomUUID()} style={{ fontSize: 16 }} />
    ) : <StarFilled key={crypto.randomUUID()} style={{ fontSize: 16 }} />))}
  </div>
);

function FeedBack({
  starsCount, feedback, title, type,
}: FeedBackProps) {
  const [searchParams] = useSearchParams();

  if (searchParams.has('type') && searchParams.get('type') !== type) {
    return null;
  }

  return (
    <div className={styles.wrap}>
      <div className={styles.head}>
        {getStars(starsCount || 0)}
        <div className={styles.title}>{title}</div>
      </div>
      <div className={styles.body}>{feedback}</div>
    </div>
  );
}

export default FeedBack;
