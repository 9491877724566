import React from 'react';
import { config } from '@triare/auth-redux';
import Layout from '../../components/Layout';
import AdminsContent from '../../components/Pages/Admins';

const { displayName } = config;

export default function Admins(): React.ReactNode | null {
  document.title = `${displayName}: Администраторы`;

  return (
    <Layout>
      <AdminsContent />
    </Layout>
  );
}
