import React, { useEffect, useState } from 'react';
import Card from 'antd/es/card/Card';
import { App, Button } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { PlusOutlined } from '@ant-design/icons';
import ProgramDatePicker from './WeekPicker';
import {
  TrainingDay,
  useProgramSixWeeksGet, useProgramUpdate, useProgramWeekCreate, useProgramWeekDelete, Week,
} from '../../../../hooks/api/program';
import ProgramDayTrainings from './Trainings';
import { Training, useTrainingId } from '../../../../hooks/api/trainings';
import TrainingsListModal from '../../Trainings/ModalTable';
import { useUnsavedChanges } from '../../../../context/unsavedChanges';
import { useMessageError } from '../../../../hooks/common';
import { WeekDaysEnum } from '../../../../enums/program';
import { datePickerFormat } from '../../../../contstant';

import styles from './index.module.scss';

export const getCurrentWeek = (weeks: Week[], date: Dayjs) => (
  weeks.find((week) => week.isoWeek === date.isoWeek() && week.year === date.year())
);

export const trainingsIdsFromWeek = (week: Week) => week.trainingDays?.map((day) => (
  day.trainings.map((training) => ({ id: training.id }))
));

const getTrainingIds = (training: Training[]) => training?.map((el) => ({ id: el.id }));

const decorateWeek = (week: Week) => ({
  ...week,
  trainingDays: week.trainingDays?.map((day) => ({
    ...day,
    trainings: getTrainingIds(day.trainings),
  })),
}) as Week;

export const createNewTrainingWeek = (date: Dayjs): Week => {
  const firstDayOfWeek = dayjs(date).startOf('isoWeek');

  const isoWeek = date.isoWeek();
  const year = date.year();
  const trainingDays = Object.keys(WeekDaysEnum).map((_, index): TrainingDay => ({
    date: firstDayOfWeek.add(index, 'day').format('YYYY-MM-DD'),
    trainings: [],
  }));

  return { isoWeek, year, trainingDays };
};

function ProgramContent() {
  const { unsavedChanges, handleUnsavedChanges } = useUnsavedChanges();
  const { message } = App.useApp();

  const programSixWeeksGet = useProgramSixWeeksGet();
  const trainingById = useTrainingId();
  const weekUpdate = useProgramUpdate();
  const weekDelete = useProgramWeekDelete();
  const weekCreate = useProgramWeekCreate();

  const [data, setData] = useState<Week[]>([]);

  const [initialSelectedWeek, setInitialSelectedWeek] = useState<Week>();
  const [selectedWeek, setSelectedWeek] = useState<Week>();
  const [selectedDay, setSelectedDay] = useState<string>('');

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [selectedTrainingsIds, setSelectedTrainingsIds] = useState<number[]>([]);

  const [disable, setDisable] = useState<boolean>(false);

  const getInitialData = (date?: Dayjs) => {
    const day = date || dayjs();

    programSixWeeksGet.fetch(undefined, `${day.year()}/${day.startOf('month').isoWeek()}`)
      .then((res) => {
        if (!res) return;

        setData(res);
        let currentWeek = getCurrentWeek(res, day || dayjs());

        if (!currentWeek) {
          currentWeek = createNewTrainingWeek(day || dayjs());
        }

        setSelectedDay(currentWeek.trainingDays[0].date);
        setSelectedWeek(currentWeek);
        setInitialSelectedWeek(currentWeek);
      });
  };

  useEffect(() => {
    getInitialData();
  }, []);

  useEffect(() => {
    setData(data.map((el) => (el.isoWeek === selectedWeek?.isoWeek && el.year ? selectedWeek : el)));
  }, [selectedWeek]);

  const onDayClick = (day: string) => setSelectedDay(day);

  const handleOnWeekSelect = (date: Dayjs, week?: Week) => {
    const clickedDayOfWeek = date.format(datePickerFormat)
      || dayjs(date).startOf('isoWeek').format(datePickerFormat);

    setSelectedDay(clickedDayOfWeek);
    setSelectedWeek(week);
    setInitialSelectedWeek(week);
  };

  const handleSelectedRows = (selected: number[]) => {
    setSelectedTrainingsIds(selected);
  };

  useMessageError([trainingById, weekDelete, weekUpdate, programSixWeeksGet, weekCreate]);

  const handleAddTrainings = async () => {
    let selectedTrainings: Training[] = [];

    const currentDayTrainingsIds = selectedWeek?.trainingDays
      .find((day) => day.date === selectedDay)?.trainings.map((el) => el.id);

    const uniqueTrainingsIds = selectedTrainingsIds.map((el) => el.toString())
      .filter((id) => !currentDayTrainingsIds?.includes(id));

    await Promise.all(uniqueTrainingsIds?.map((trainingId) => (
      trainingById.fetch(undefined, trainingId)
    ))).then((res) => {
      if (res.every((el) => el?.id)) {
        const normalizedResponse = res.map((el) => {
          // eslint-disable-next-line no-param-reassign
          delete el?.usedIn;

          return el;
        });

        selectedTrainings = [...selectedTrainings, ...normalizedResponse as Training[]];
      }
    });

    if (selectedWeek && data) {
      let updatedWeeks;

      const updatedTrainings = selectedWeek.trainingDays.map((el) => (
        el.date === selectedDay ? { ...el, trainings: [...el.trainings, ...selectedTrainings] } : el));

      setSelectedWeek({ ...selectedWeek, trainingDays: updatedTrainings });

      if (data.some((el) => el.isoWeek === selectedWeek.isoWeek && el.year === selectedWeek.year)) {
        updatedWeeks = data.map((el): Week => (
          el.isoWeek === selectedWeek.isoWeek && el.year === selectedWeek.year ? (
            { ...el, trainingDays: updatedTrainings }
          ) : el));
      } else {
        updatedWeeks = [...data, { ...selectedWeek, trainingDays: updatedTrainings }];
      }

      setData(updatedWeeks);
    }

    setSelectedTrainingsIds([]);
  };

  const handleRemoveTraining = (trainingId: string) => {
    if (!selectedWeek) return;

    const updatedTrainings = selectedWeek?.trainingDays.map((el) => (
      el.date === selectedDay ? { ...el, trainings: el.trainings.filter((el2) => el2.id !== trainingId) } : el
    ));

    setSelectedWeek({ ...selectedWeek, trainingDays: updatedTrainings });
  };

  const onSave = async () => {
    if (!selectedWeek) return;

    let response;

    if (selectedWeek.trainingDays.every((el) => !el.trainings.length)) {
      await weekDelete.fetch(selectedWeek.id).then(() => {
        response = createNewTrainingWeek(dayjs(selectedDay));
      });
    } else if (!selectedWeek.id) {
      await weekCreate.fetch(decorateWeek(selectedWeek)).then((res) => {
        response = res;
      });
    } else {
      await weekUpdate.fetch(decorateWeek(selectedWeek), selectedWeek.id).then((res) => {
        response = res;
      });
    }

    if (response) {
      message.success('Изменения успешно сохранены.');
      setSelectedWeek(response);
      setInitialSelectedWeek(response);
    }
    handleUnsavedChanges(false);
  };

  const handleModal = (payload: boolean) => {
    setIsOpenModal(payload);
  };

  useEffect(() => {
    if (selectedWeek && initialSelectedWeek) {
      const initial = JSON.stringify(trainingsIdsFromWeek(initialSelectedWeek));
      const current = JSON.stringify(trainingsIdsFromWeek(selectedWeek));

      handleUnsavedChanges(!(initial === current));
    }
  }, [data]);

  useEffect(() => {
    setDisable(dayjs(selectedDay).year() < dayjs().year());
  }, [selectedDay]);

  const handleResetSelectedWeek = () => {
    if (!initialSelectedWeek) return;
    setSelectedWeek(initialSelectedWeek);
  };

  return (
    <>
      <TrainingsListModal
        isOpenModal={isOpenModal}
        isLoading={trainingById.loading}
        handleAddTrainings={handleAddTrainings}
        handleModal={handleModal}
        selectedTrainingsIds={selectedTrainingsIds}
        handleSelectedRows={handleSelectedRows}
      />
      <Card
        styles={{ body: { paddingTop: '5px' } }}
        title={(
          <div className={styles.head}>
            <h4>Список тренировок программы</h4>
            <Button
              type="primary"
              onClick={onSave}
              disabled={!unsavedChanges}
              loading={weekUpdate.loading || weekCreate.loading || weekDelete.loading}
            >
              Сохранить
            </Button>
          </div>
      )}
      >
        <ProgramDatePicker
          onDayClick={onDayClick}
          weeks={data}
          selectedWeek={selectedWeek}
          selectedDay={selectedDay}
          getInitialData={getInitialData}
          handleOnWeekSelect={handleOnWeekSelect}
          handleResetSelectedWeek={handleResetSelectedWeek}
        />
        <ProgramDayTrainings
          trainings={selectedWeek?.trainingDays?.find((e) => (
            dayjs(e.date).isSame(selectedDay, 'day') && dayjs(e.date).isSame(selectedDay, 'year')))?.trainings || []}
          handleRemoveTraining={handleRemoveTraining}
          disable={disable || weekUpdate.loading}
          selectedDay={selectedDay}
        />
        {!disable ? (
          <Button
            disabled={disable || weekUpdate.loading || weekCreate.loading || weekDelete.loading}
            icon={<PlusOutlined />}
            onClick={() => setIsOpenModal(true)}
          >
            Добавить тренировку
          </Button>
        ) : null}
      </Card>
    </>
  );
}

export default ProgramContent;
