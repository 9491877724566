import React from 'react';
import {
  Button, MenuProps, Modal, Space,
} from 'antd';
import {
  NavLink, useLocation, useSearchParams,
} from 'react-router-dom';
import Dropdown from 'antd/es/dropdown/dropdown';
import { DownOutlined } from '@ant-design/icons';
import TrainingsTable from '../List';

interface TrainingsListModal {
  isOpenModal: boolean;
  isLoading: boolean;
  handleAddTrainings: () => Promise<void>;
  handleModal: (payload: boolean) => void;
  selectedTrainingsIds: number[];
  handleSelectedRows: (selected: number[]) => void;
  defaultParams?: Record<string, string>;
}

function TrainingsListModal({
  isOpenModal,
  isLoading,
  handleAddTrainings,
  selectedTrainingsIds,
  handleModal,
  handleSelectedRows,
  defaultParams,
}: TrainingsListModal) {
  const [_, setSearchParams] = useSearchParams();
  const location = useLocation();

  const menuItems: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <NavLink to={`/trainings/create/house?from=${location.pathname}`} className="link-no-underline">Дом</NavLink>),
      className: 'create-house',
    },
    {
      key: '2',
      label: (
        <NavLink to={`/trainings/create/gym?from=${location.pathname}`} className="link-no-underline">Зал</NavLink>),
      className: 'create-gym',
    },
  ];

  return (
    <Modal
      open={isOpenModal}
      title="Выбрать тренировку"
      cancelText="Отмена"
      centered
      destroyOnClose
      okText="Добавить"
      className="modal-with-table"
      okButtonProps={{ type: 'primary', loading: isLoading, disabled: !selectedTrainingsIds.length }}
      width={1200}
      onOk={async () => {
        await handleAddTrainings();
        handleModal(false);
      }}
      onCancel={() => {
        if (!isLoading) {
          handleModal(false);
        }
      }}
      afterClose={() => setSearchParams({})}
      maskClosable={!isLoading}
      footer={(__, { OkBtn, CancelBtn }) => [
        <CancelBtn key="cancel-btn" />,
        <Dropdown
          trigger={['click']}
          key="add-dropdown-button"
          menu={{ items: menuItems }}
        >
          <Button
            key="add-btn"
            id="add-btn"
            type="default"
            style={{ marginInlineStart: '8px' }}
          >
            <Space>
              Создать новую
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>,
        <OkBtn key="ok-btn" />,
      ]}
    >
      <TrainingsTable
        params={{ pageSize: '5', contentState: 'published' }}
        defaultParams={{ ...defaultParams }}
        inModal
        selectedRows={selectedTrainingsIds}
        onRowSelection={handleSelectedRows}
      />
    </Modal>
  );
}

TrainingsListModal.defaultProps = {
  defaultParams: {},
};

export default TrainingsListModal;
