import React, { useEffect } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import Tabs from 'antd/es/tabs';
import { ItemType } from 'antd/es/breadcrumb/Breadcrumb';
import General from './General';
import SinglePageContainer from '../../../Common/SinglePageContainer';
import UserMarathon from './Marathon';
import { useUserProfileProvider } from '../../../../context/userProfile';
import { useSearchParams } from '../../../../hooks/useSearchParams';
import Loading from '../../../Common/Loading';
import PurchaseList from './PurchaseList';

const breadcrumbs: ItemType[] = [
  {
    title: <NavLink to="/users">Пользователи</NavLink>,
  },
  {
    title: 'Просмотр профиля пользователя',
  },
];

function Profile() {
  const { id = '' } = useParams<{ id: string; }>();
  const { userById } = useUserProfileProvider();
  const [searchParams, setSearchParams] = useSearchParams();

  const fetchId = () => {
    userById.fetch(undefined, id);
  };

  useEffect(() => {
    if (id) {
      fetchId();
    }
  }, [id]);

  return (
    <SinglePageContainer
      breadcrumbItems={breadcrumbs}
      title="Просмотр профиля пользователя"
      // cardLoading={userById.loading}
      style={{ minHeight: '250px' }}
    >
      <Loading visible={userById.loading} absolute transparentBg />

      {userById.data ? (
        <Tabs
          activeKey={searchParams.get('tab') || 'general'}
          onChange={(tab) => setSearchParams({ tab })}
          items={[
            {
              key: 'general',
              label: 'Общее',
              children: <General data={userById.data} />,
            },
            {
              key: 'marathon',
              label: 'Марафон',
              children: <UserMarathon data={userById.data} />,
            },
            {
              key: 'purchaseList',
              label: 'Журнал покупок',
              children: <PurchaseList data={userById.data} />,
            },
          ]}
        />
      ) : null}
    </SinglePageContainer>
  );
}

export default Profile;
