import { SortOrder } from 'antd/es/table/interface';
import { AnyObject } from '@triare/auth-redux';
import dayjs from 'dayjs';
import { InputRef } from 'antd';
import { JsonResult } from '../types';

export function translateStatus(isActive?: boolean): string {
  return isActive ? 'Active' : 'Inactive';
}

export function translateStatusAction(isActive?: boolean): string {
  return isActive ? 'Activate' : 'Deactivate';
}

export function capitalize(str = 'undefined'): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function capitalizeFirstLetter(str: string): string {
  return str ? str.charAt(0).toUpperCase() + str.slice(1) : '';
}

/**
 * Returns a JS object representation of a Javascript Web Token from its common encoded
 * string form.
 *
 * @template T the expected shape of the parsed token
 * @param {string} token a Javascript Web Token in base64 encoded, `.` separated form
 * @returns {(T | undefined)} an object-representation of the token
 * or undefined if parsing failed
 */
export function getParsedJwt<T = AnyObject>(
  token?: string,
): T | undefined {
  try {
    return token ? JSON.parse(atob(token.split('.')[1])) : undefined;
  } catch {
    return undefined;
  }
}

export const getSorterParams = (sorter: Record<string, SortOrder>): { [key: string]: string; } => {
  const sorterEntries = Object.entries(sorter);
  const newParams: { [key: string]: string; } = {};

  if (sorterEntries.length) {
    newParams.orderByColumn = sorterEntries[0][0] || '';
    newParams.orderBy = sorterEntries[0][1] === 'ascend' ? 'ASC' : 'DESC';
  }

  return newParams;
};

export const queryFilterParams = (queryParams: Record<string, string>): Record<string, string> => {
  const params = Object.entries(queryParams);
  const newParams: { [key: string]: string; } = {};

  params.forEach(([key, value]) => {
    if (value) {
      newParams[key] = value.toString();
    }
  });

  return newParams;
};

export const getBase64 = (file: Blob): Promise<unknown> => new Promise((resolve, reject) => {
  const reader = new FileReader();

  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = (error) => reject(error);
});

export const generateRandomString = (): string => Math.random().toString(36).substring(2, 8);

// eslint-disable-next-line
export const arrayEquals = <T = any>(a: T[], b: T[]): boolean => {
  if (Array.isArray(a) && Array.isArray(b) && a.length === b.length) {
    const sortedA = a.sort((prev, next) => (prev < next ? 1 : -1));
    const sortedB = b.sort((prev, next) => (prev < next ? 1 : -1));

    return sortedA.every((val, index) => val === sortedB[index]);
  }

  return false;
};

export interface ValidSearchParams {
  [key: string]: string | string[]
}

export function getValidSearchParams(
  listValidParams: string[] | '*',
  searchParams: URLSearchParams,
  defaultParams?: { [key: string]: number | string | string[] },
): ValidSearchParams {
  const props = {};
  const fn = (key: string) => {
    const value = searchParams.getAll(key);

    if (value.length || !(value.length === 1 && value[0] === '')) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      props[key] = value.length === 1 ? value[0] : value;
    } else if (defaultParams) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      props[key] = typeof defaultParams[key] === 'number' ? defaultParams[key].toString() : defaultParams[key];
    }
  };

  if (listValidParams === '*') {
    // eslint-disable-next-line
    for (const key of searchParams.keys()) {
      fn(key);
    }
  } else {
    listValidParams.forEach(fn);
  }

  return props;
}

export function getValidSearchParamsWithout(
  excludeList: string[],
  searchParams: URLSearchParams,
  listValidParams: string[] | '*' = '*',
): ValidSearchParams {
  const list = getValidSearchParams(listValidParams, searchParams);

  excludeList.forEach((key) => {
    if (typeof list[key] !== 'undefined') {
      delete list[key];
    }
  });

  return list;
}

interface Date {
  $y: number;
  $M: number;
  $D: number;
  $H?: number;
  $m?: number;
}
interface Time {
  $H: number;
  $m: number;
}

export const getDateTimeISO = (date: Date, time: Time) => {
  if (!date || !time) {
    return null;
  }
  const formattedDate = new Date(Date.UTC(date.$y, date.$M, date.$D, time.$H, time.$m));

  return formattedDate.toISOString();
};

export const getDateISO = (date: Date) => {
  if (!date) {
    return null;
  }
  const formattedDate = new Date(Date.UTC(date.$y, date.$M, date.$D, date.$H, date.$m));

  return formattedDate.toISOString();
};

export const getDaysBetween = (startDate: string, endDate: string) => {
  if (!startDate || !endDate) {
    return '';
  }

  return `${dayjs(startDate)?.utc()?.format('ddd, MMM D')} - ${dayjs(endDate)?.utc()?.format('ddd, MMM D')}`;
};

export const getHoursBetween = (startDate: string, endDate: string) => {
  if (!startDate || !endDate) {
    return '';
  }

  return `${dayjs(startDate).utc()?.format('HH:mm')} - ${dayjs(endDate).utc()?.format('HH:mm')}`;
};

export const getDayJsTimeBetween = (startDate: string, endDate: string) => (
  [dayjs(startDate, 'YYYY-MM-DDTHH:mm:ss.SSS[Z]'), dayjs(endDate, 'YYYY-MM-DDTHH:mm:ss.SSS[Z]')]
);

interface ArrayOfIdsProps {
  id: string
}

export const getArrayOfIds = (array: ArrayOfIdsProps[]) => array.map((item) => item.id);

export const triggerEnterKeyClick = (refCurrent: InputRef | null) => {
  if (!refCurrent) {
    return;
  }
  const enterEvent = new KeyboardEvent('keydown', {
    key: 'Enter',
    keyCode: 13,
    bubbles: true,
    cancelable: true,
  });

  refCurrent?.input?.dispatchEvent(enterEvent);
};

/** Creates object with defaultState { show: false } for specified columns */
export const createDefaultHiddenColumns = (columns: string[]) => (columns.reduce(
  (acc, columnName) => {
    acc[columnName] = { show: false };

    return acc;
  },
  {} as Record<string, { show: false }>,
));

// Version which works with localStorage directly, without antd help
/* export const createDefaultHiddenColumns = (columns: string[], persistenceKey?: string) => {
  /!** Generates hidden columns *!/
  const defaultHiddenColumns = columns.reduce(
    (acc, columnName) => {
      acc[columnName] = { show: false };

      return acc;
    },
    {} as Record<string, { show: false }>,
  );

  /!** Gets latest state of columns from storage, and merges to the defaultHiddenColumns *!/
  const prevData: Record<string, { show: false }> = JSON.parse(localStorage.getItem(persistenceKey || '') || '{}');

  return { ...defaultHiddenColumns, ...prevData };
}; */

type EnumMap<T> = { [key: string]: T };
export function enumToOptionsArray<T extends EnumMap<string>>(enumObj: T): { value: string; label: string }[] {
  return Object.entries(enumObj).map(([key, value]) => ({ value: key, label: value }));
}

export function isObjEqual<T extends JsonResult>(object1: T, object2: T): boolean {
  if (!object1 || !object2) return false;

  const objKeys1 = Object.keys(object1);
  const objKeys2 = Object.keys(object2);

  if (objKeys1.length !== objKeys2.length) return false;

  // eslint-disable-next-line no-restricted-syntax
  for (const key of objKeys1) {
    const value1 = object1[key] || '';
    const value2 = object2[key] || '';

    const isObjects = isObject(value1) && isObject(value2);

    if ((isObjects && !isObjEqual(value1, value2))
      || (!isObjects && value1 !== value2)
    ) {
      return false;
    }
  }

  return true;
}

const isObject = (object: object) => object != null && typeof object === 'object';

function isEmptyObject(obj: object): boolean { return Object.keys(obj).length === 0; }
function isEmptyValue(value: unknown): boolean { return value === '' || value === null || value === undefined; }

export function filterObjectProps<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Response extends object = Record<string, any>>(obj: Response, keysToDelete: (keyof Response)[]): Response {
  const filteredEntries = Object.entries(obj).filter(([key]) => !keysToDelete.includes(key as keyof Response));

  return Object.fromEntries(filteredEntries) as Response;
}

/** removeEmptyValues function recursively iterates over the object and performs the following steps:
 1. Create an empty filteredObject to store the filtered values.
 2. Iterate over each key-value pair in the object using Object.entries.
 3. If the value is an object (isObject helper function), recursively call removeEmptyValues on the
 nested object and check if the resulting nested object is empty or not.
 -If the nested object is not empty, add it to the filteredObject.
 4. If the value is not an object and is not an empty value (isEmptyValue helper function),
 add it to the filteredObject.
 5. Return the filteredObject. */
export function removeEmptyValues<T extends object>(object: T): T {
  const filteredObject = {} as T;

  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(object)) {
    if (isObject(value)) {
      const nestedObject = removeEmptyValues(value);

      if (!isEmptyObject(nestedObject)) {
        // @ts-ignore compare -----
        filteredObject[key] = nestedObject;
      }
    } else if (!isEmptyValue(value)) {
      // @ts-ignore compare -----
      filteredObject[key] = value;
    }
  }

  return filteredObject;
}

export function asyncDelay(ms: number) {
  return new Promise((resolve) => { setTimeout(resolve, ms); });
}

export const getDayName = (dayIndex: number) => {
  const normalizedDayIndex = (dayIndex + 7) % 7; // Make sure index < 7

  const ruDayNames = ['Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота', 'Воскресенье'];

  return ruDayNames[normalizedDayIndex];
};

export const downloadFromAnchor = (blobPart: ArrayBuffer, name?: string, type?: string) => {
  const file = new Blob([blobPart], { type });
  const fileURL = window.URL.createObjectURL(file);
  const link = document.createElement('a');

  link.href = fileURL;
  link.setAttribute('download', name || 'filename');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

/** Replaces latest match in string */
export function replaceLatest(input: string, searchValue: string, replaceValue: string): string {
  const lastIndex = input.lastIndexOf(searchValue);

  if (lastIndex !== -1) {
    return input.substring(0, lastIndex) + replaceValue + input.substring(lastIndex + searchValue.length);
  }

  // If the searchValue is not found, return the original input
  return input;
}

export function removeStyleAttributes(input: string): string {
  // Regular expression to match style attribute in HTML tags
  const regex = /(<[^>]+) style="[^"]*"/gi;

  // Replace all occurrences of style attribute with an empty string
  const output = input.replace(regex, '$1');

  return output;
}
