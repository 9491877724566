import React, {
  createContext, ReactNode, useContext, useEffect, useMemo, useState,
} from 'react';
import { useParams } from 'react-router-dom';
import {
  CreateMarathonMenu,
  MarathonMenu,
  MarathonMenuDay,
  MarathonMenuWeek,
  useMarathonId,
} from '../hooks/api/marathons';
import { defaultFetchData, DefaultFetchError, FetchGetId } from '../hooks/fetch';

interface MarathonMenuProviderProps {
  marathonMenuById: FetchGetId<MarathonMenu, DefaultFetchError, undefined, MarathonMenu>;
  initialValues: Partial<CreateMarathonMenu>;
  setInitialValues: React.Dispatch<React.SetStateAction<Partial<CreateMarathonMenu>>>;
}

const defaultMarathonMenuById: FetchGetId<MarathonMenu, DefaultFetchError, undefined, MarathonMenu> = {
  // data: undefined,
  fetch: async () => null,
  finish: () => undefined,
  ...defaultFetchData,
};

const initialTrainingDayState = {
  id: '',
  breakfast: [],
  lunch: [],
  dinner: [],
  snack: [],
};

export const initialMarathonMenuWeekState = {
  id: crypto.randomUUID(),
  pdfUrlUA: '',
  pdfUrlRU: '',
  menuDays: Array.from({ length: 7 }).map(() => (initialTrainingDayState as MarathonMenuDay)),
} as MarathonMenuWeek;

const defaultValue: MarathonMenuProviderProps = {
  marathonMenuById: defaultMarathonMenuById,
  initialValues: {
    weeks: [initialMarathonMenuWeekState],
  },
  setInitialValues: () => undefined,
};

export const MarathonMenuContext = createContext<MarathonMenuProviderProps>(defaultValue);

function MarathonMenuProvider({ children }: { children: ReactNode }) {
  const { marathonId = '', id = '' } = useParams<{ marathonId: string; id: string }>();
  const marathonMenuById = useMarathonId<MarathonMenu>(`${marathonId}/menus/${id}`);

  /** Handle Initial values */
  const [initialValues, setInitialValues] = useState(defaultValue.initialValues);

  const memoizedValue = useMemo(() => ({
    marathonMenuById,
    initialValues,
    setInitialValues,
  }), [marathonMenuById, initialValues, setInitialValues]);

  return (
    <MarathonMenuContext.Provider value={memoizedValue}>
      {children}
    </MarathonMenuContext.Provider>
  );
}

export default MarathonMenuProvider;

export const useMarathonMenuProvider = (): MarathonMenuProviderProps => useContext(MarathonMenuContext);
