import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import {
  Button, DatePicker, Form, message, Modal,
} from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { dateFormatMarathon } from '../../../../../../contstant';
import {
  IUserSubscription,
  UserExtended, useUserSubscribeUpdate,
} from '../../../../../../hooks/api/user';
import { SubscriptionEnum } from '../../../../../../enums/user';
import SelectSubscriptionType from '../../../../../Common/Selects/SelectSubscriptionType';

import styles from './index.module.scss';

interface IEditSubscribe {
  data: UserExtended,
  handleState: (payload: UserExtended) => void,
}

function EditSubscribe({ data, handleState }: IEditSubscribe) {
  const userSubscribeUpdate = useUserSubscribeUpdate();
  const [form] = Form.useForm();

  const [open, setOpen] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [isFormValid, setFormValid] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<IUserSubscription>();

  useEffect(() => {
    setInitialValues({
      subscriptionTill: data.subscription === SubscriptionEnum['Нет подписки']
        ? undefined : dayjs(data.subscriptionTill),
      subscription: data.subscription,
    });
  }, [data]);

  const handleSubmit = () => {
    const values: IUserSubscription = form.getFieldsValue();

    const preparedValues: Partial<IUserSubscription> = {
      subscription: values.subscription,
      ...(values.subscriptionTill && { subscriptionTill: values.subscriptionTill }),
    };

    userSubscribeUpdate.fetch(preparedValues, `${data.id}/subscription`)
      .then((res) => {
        if (res?.id) {
          handleClose();
          message.success('Подписка успешно изменена!');
          handleState(res);
        } else {
          message.error('Что-то пошло не так!');
        }
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleValuesChange = (changedValues: IUserSubscription) => {
    if (changedValues.subscription !== undefined) {
      setIsDisabled(changedValues.subscription === 'none');
    }

    handleValidation();
  };

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(initialValues);

    if (form.getFieldValue('subscription') === SubscriptionEnum['Нет подписки']) {
      setIsDisabled(true);
    }

    handleValidation();
  }, [initialValues]);

  useEffect(() => {
    if (isDisabled) {
      form.setFieldsValue({ subscriptionTill: undefined });
    }
  }, [isDisabled]);

  const handleValidation = () => {
    const subscription = form.getFieldValue('subscription');
    const subscriptionTill = form.getFieldValue('subscriptionTill');

    if (subscription !== SubscriptionEnum['Нет подписки'] && !subscriptionTill) {
      setFormValid(false);
    } else {
      setFormValid(true);
    }
  };

  return (
    <>
      <div className={styles.editSubscribe}>
        {data.subscription !== 'none' ? (
          <div>{dayjs(data.subscriptionTill).format(dateFormatMarathon)}</div>
        ) : null}
        <Button type="link" onClick={() => setOpen(true)}><EditOutlined /></Button>
      </div>
      <Modal
        open={open}
        title=""
        centered
        cancelText="Отмена"
        okText="Сохранить"
        okButtonProps={{ type: 'primary', loading: userSubscribeUpdate.loading, disabled: !isFormValid }}
        cancelButtonProps={{ disabled: userSubscribeUpdate.loading }}
        width={600}
        onOk={() => handleSubmit()}
        onCancel={handleClose}
        maskClosable={false}
        destroyOnClose
      >
        <Form<IUserSubscription>
          form={form}
          onFinish={handleSubmit}
          initialValues={initialValues}
          className={styles.editSubscribeForm}
          onValuesChange={handleValuesChange}
        >
          <Form.Item name="subscription" label="Подписка">
            <SelectSubscriptionType allowClear={false} />
          </Form.Item>
          <Form.Item
            name="subscriptionTill"
            label="активна до"
          >
            <DatePicker
              style={{ width: '100%' }}
              format={dateFormatMarathon}
              disabled={isDisabled}
              disabledDate={(date) => date && date < dayjs().startOf('day')}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default EditSubscribe;
