import React from 'react';
import { Typography } from 'antd';
import CreateForm from './Create';

function Notifications(): React.ReactNode {
  return (
    <div>
      <Typography.Title level={4}>
        Уведомления
      </Typography.Title>
      <CreateForm />
    </div>
  );
}

export default Notifications;
