import React from 'react';
import { Descriptions } from 'antd';
import dayjs from 'dayjs';
import {
  UserLevelEnum, UserMenuTypeEnum, UserPlaceEnum, UserSubscriptionEnum,
} from '../../../../../../enums/user';
import { GenderEnum } from '../../../../../../enums';
import { Participant, UserExtended } from '../../../../../../hooks/api/user';
import UpdateUserMeasurementModal from './MeasurementsUpdateModal';

interface UserMarathonDescriptionProps {
  data: UserExtended
  selectedMarathon: Participant | undefined
}

function UserMarathonDescription({ data, selectedMarathon } : UserMarathonDescriptionProps) {
  return (
    <>
      <Descriptions
        size="small"
        column={4}
        items={[
          {
            key: '1',
            label: 'Имя',
            children: data.name,
          },
          {
            key: '2',
            label: 'Грудное вскармливание',
            children: selectedMarathon?.isBreastFeeding || 'Нет',
          },
          {
            key: '3',
            label: 'Вес / До',
            children: (selectedMarathon?.measureBefore?.weight2 || selectedMarathon?.measureBefore?.weight)
              ? `${selectedMarathon.measureBefore?.weight2 || selectedMarathon.measureBefore?.weight} кг` : '',
          },
          {
            key: '4',
            label: 'Вес / После',
            children: (selectedMarathon?.measureAfter?.weight2 || selectedMarathon?.measureAfter?.weight)
              ? `${selectedMarathon.measureAfter?.weight2 || selectedMarathon.measureAfter?.weight} кг` : '',
          },
          {
            key: '5',
            label: 'Формат участия',
            children: selectedMarathon?.subscription ? UserSubscriptionEnum[selectedMarathon.subscription] : '',
          },
          {
            key: '6',
            label: 'Уровень',
            children: selectedMarathon?.level ? UserLevelEnum[selectedMarathon.level] : '',
          },
          {
            key: '7',
            label: 'ОГ / До',
            children: selectedMarathon?.measureBefore?.chest ? `${selectedMarathon.measureBefore?.chest} см` : '',
          },
          {
            key: '8',
            label: 'ОГ / После',
            children: selectedMarathon?.measureAfter?.chest ? `${selectedMarathon.measureAfter?.chest} см` : '',
          },
          {
            key: '9',
            label: 'Участие в конкурсе',
            children: selectedMarathon?.contestEnteredAt?.length ? 'Да' : 'Нет',
          },
          {
            key: '10',
            label: 'Тип тренировки',
            children: selectedMarathon?.place ? UserPlaceEnum[selectedMarathon.place] : '',
          },
          {
            key: '11',
            label: 'ОТ / До',
            children: selectedMarathon?.measureBefore?.waist ? `${selectedMarathon.measureBefore?.waist} см` : '',
          },
          {
            key: '12',
            label: 'ОТ / После',
            children: selectedMarathon?.measureAfter?.waist ? `${selectedMarathon.measureAfter?.waist} см` : '',
          },
          {
            key: '13',
            label: 'Пол',
            children: data?.gender ? GenderEnum[data.gender] : '',
          },
          {
            key: '14',
            label: 'Тип меню',
            children: selectedMarathon?.menuType ? UserMenuTypeEnum[selectedMarathon.menuType] : '',
          },
          {
            key: '15',
            label: 'ОБ / До',
            children: selectedMarathon?.measureBefore?.hip ? `${selectedMarathon.measureBefore?.hip} см` : '',
          },
          {
            key: '16',
            label: 'ОБ / После',
            children: selectedMarathon?.measureAfter?.hip ? `${selectedMarathon.measureAfter?.hip} см` : '',
          },
          {
            key: '17',
            label: 'Возраст',
            children: dayjs().diff(data.dateOfBirth, 'year'),
          },
          {
            key: '18',
            label: 'Калораж меню',
            children: selectedMarathon?.calories,
          },
          {
            key: '19',
            label: '',
            children: (<UpdateUserMeasurementModal measureKey="measureBefore" selectedMarathon={selectedMarathon} />),
          },
          {
            key: '20',
            label: '',
            children: (<UpdateUserMeasurementModal measureKey="measureAfter" selectedMarathon={selectedMarathon} />),
          },
        ]}
      />
      <Descriptions
        size="small"
        column={4}
        items={[
          {
            key: '21',
            label: 'Рост',
            children: data.height,
          },
        ]}
      />
    </>
  );
}

export default UserMarathonDescription;
