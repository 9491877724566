export enum UserStatusValues {
  PENDING = 'pending',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export enum UserRoles {
  USER = 'user',
  ADMIN = 'admin',
  ROOT = 'root',
}

export const ROLE_LIST = [UserRoles.USER, UserRoles.ADMIN, UserRoles.ROOT];

export function isRoleEnough(
  currentRole: UserRoles | string | undefined,
  requiredRole: UserRoles | string,
): boolean {
  if (currentRole === requiredRole) {
    return true;
  }

  return (
    ROLE_LIST.indexOf(currentRole as UserRoles)
    >= ROLE_LIST.indexOf(requiredRole as UserRoles)
  );
}

export enum UserLevelEnum {
  'low' = 'Новичок',
  'medium' = 'Средний',
  'high' = 'Продвинутый',
}

export enum UserPlaceEnum {
  'house' = 'Дом',
  'gym' = 'Зал',
}
export enum UserMenuTypeEnum {
  'classic' = 'Классическое',
  'vegan' = 'Вегетарианское',
}
export enum UserSubscriptionEnum {
  'gold' = 'Gold',
  'silver' = 'Silver',
}

export enum SubscriptionEnum {
  'Нет подписки' = 'none',
  'Месячная' = 'month',
  'Годовая' = 'year'
}
