import React, { useEffect, useState } from 'react';
import { App, Button, Form } from 'antd';
import { NavLink, useParams } from 'react-router-dom';
import SinglePageContainer from '../../../../Common/SinglePageContainer';
import { isObjEqual } from '../../../../../utils';
import { useMessageError } from '../../../../../hooks/common';
import { useUnsavedChanges } from '../../../../../context/unsavedChanges';
import {
  MarathonPrizes, useMarathonPrizes, useMarathonPrizesUpdate,
} from '../../../../../hooks/api/prizes';
import MarathonPrizesFormFields from './Form';

const initialState: MarathonPrizes = {
  prizes: [
    {
      placeUA: '1 місце',
      placeRU: '1 место',
      prizeUA: '',
      prizeRU: '',
    },
    {
      placeUA: '2 місце',
      placeRU: '2 место',
      prizeUA: '',
      prizeRU: '',
    },
    {
      placeUA: '3 місце',
      placeRU: '3 место',
      prizeUA: '',
      prizeRU: '',
    },
  ],
};

function MarathonPrizesContent() {
  const { marathonId = '' } = useParams<{ marathonId: string; }>();
  const { unsavedChanges, handleUnsavedChanges } = useUnsavedChanges();

  const { message } = App.useApp();
  const [form] = Form.useForm();

  const getPrizes = useMarathonPrizes();
  const prizesUpdate = useMarathonPrizesUpdate();

  const [initialValues, setInitialValues] = useState<MarathonPrizes>(initialState);

  const fetch = () => {
    getPrizes.fetch(undefined, `${marathonId}/prizes`);
  };

  useEffect(() => {
    if (marathonId) fetch();
  }, [marathonId]);

  useEffect(() => {
    if (getPrizes.data) {
      setInitialValues(getPrizes.data?.prizes?.length ? getPrizes.data : initialState);
    }
  }, [getPrizes.data]);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(initialValues);
    handleUnsavedChanges(false);
  }, [initialValues]);

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      handleUnsavedChanges(false);

      prizesUpdate.fetch(values, `${marathonId}/prizes`)
        .then((res) => {
          if (res !== null) message.success('Изменения успешно сохранены');
        });
    });
  };

  useMessageError([getPrizes]);

  const handleFieldsChange = () => {
    if (!initialValues) return;

    handleUnsavedChanges(!isObjEqual(initialValues, form.getFieldsValue()));
  };

  return (
    <SinglePageContainer
      breadcrumbItems={[
        { title: <NavLink to="/marathons">Марафоны</NavLink> },
        { title: <NavLink to={`/marathons/${marathonId}`}>Редактировать марафон</NavLink> },
        { title: 'Редактировать призы' },
      ]}
      title="Редактировать призы"
      cardLoading={getPrizes.loading}
      extra={(
        <Button
          type="primary"
          onClick={form.submit}
          loading={prizesUpdate.loading}
          disabled={!unsavedChanges}
        >
          Сохранить
        </Button>
      )}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        name="marathon-prizes"
        initialValues={initialValues}
        onFieldsChange={handleFieldsChange}
      >
        <MarathonPrizesFormFields />
      </Form>
    </SinglePageContainer>
  );
}

export default MarathonPrizesContent;
