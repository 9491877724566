import React from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { CalendarOutlined } from '@ant-design/icons';
import { Training } from '../../../../../hooks/api/trainings';
import { useSimpleModal } from '../../../../Common/Modal/Simple';

import styles from './index.module.scss';
import TrainingRowItem from '../../../Trainings/TrainingRowItem';

interface ProgramDayTrainingsProps {
  trainings: Training[];
  handleRemoveTraining: (id: string) => void;
  disable: boolean;
  selectedDay: string;
}

function ProgramDayTrainings({
  trainings, handleRemoveTraining, disable, selectedDay,
}: ProgramDayTrainingsProps) {
  const navigate = useNavigate();

  const { open, contextHolder } = useSimpleModal();

  const handleDelete = (id: string) => {
    if (!id) return;

    open({
      title: 'Удалить тренировку?',
      content: 'Вы уверены, что хотите навсегда удалить эту тренировку?',
      cancelText: 'Отмена',
      centered: true,
      okText: 'Удалить',
      okButtonProps: {
        type: 'primary',
        danger: true,
      },
      onOk: () => handleRemoveTraining(id),
      maskClosable: true,
    });
  };

  return (
    <>
      {contextHolder}
      <div className={styles.wrap}>
        {selectedDay ? (
          <div className={styles.selectedDate}>
            {dayjs(selectedDay).format('DD MMM YYYY')}
            <CalendarOutlined />
          </div>
        ) : null}

        {trainings.map((training) => (
          <TrainingRowItem key={training.id} training={training} onRemove={() => handleDelete(training.id)} />
        ))}
      </div>
    </>
  );
}

export default ProgramDayTrainings;
