import {
  DefaultFetchError, FetchCreate, FetchGetId, useFetchCreate, useFetchGetId,
} from '../fetch';

export interface Prize {
  placeUA: string
  placeRU: string
  prizeUA: string
  prizeRU: string
}

export interface MarathonPrizes {
  prizes: Prize[]
}

export const useMarathonPrizes = (id?: string): FetchGetId<MarathonPrizes> => useFetchGetId(
  'marathons',
  id,
  { autoStart: false, startStateLoading: false },
);

export const useMarathonPrizesUpdate = (): FetchCreate<MarathonPrizes,
  DefaultFetchError, MarathonPrizes> => (
  useFetchCreate('marathons')
);
