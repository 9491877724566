import React from 'react';
import { config } from '@triare/auth-redux';
import Layout from '../../components/Layout';
import UsersContent from '../../components/Pages/Users';

const { displayName } = config;

export default function Users(): React.ReactNode | null {
  document.title = `${displayName}: Пользователи`;

  return (
    <Layout>
      <UsersContent />
    </Layout>
  );
}
