import React from 'react';
import { config } from '@triare/auth-redux';
import Layout from '../../../components/Layout';
import Content from '../../../components/Pages/Users/Profile';
import UserProfileProvider from '../../../context/userProfile';

const { displayName } = config;

function Profile(): React.JSX.Element {
  document.title = `${displayName}: Просмотр профиля пользователя`;

  return (
    <Layout>
      <UserProfileProvider>
        <Content />
      </UserProfileProvider>
    </Layout>
  );
}

export default Profile;
