import React, { useState } from 'react';
import { ItemType } from 'antd/es/breadcrumb/Breadcrumb';
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import {
  App, Button, Form, UploadFile,
} from 'antd';
import SinglePageContainer from '../../../Common/SinglePageContainer';
import { useRecipeCreate } from '../../../../hooks/api/recipes';
import { useMessageError } from '../../../../hooks/common';
import RecipeFormItems from '../Form';
import { useUnsavedChanges } from '../../../../context/unsavedChanges';

const breadcrumbs: ItemType[] = [
  {
    title: <NavLink to="/recipes">Рецепты</NavLink>,
  },
  {
    title: 'Добавить рецепт',
  },
];

export default function RecipeCreate() {
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const recipeCreate = useRecipeCreate();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { handleEditorTouched } = useUnsavedChanges();

  const [bannerFile, setBannerFile] = useState<UploadFile[]>([]);
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const handleSubmit = () => {
    form.validateFields().then((values) => (
      recipeCreate.fetch(values)
        .then((res) => {
          if (res?.id) {
            handleEditorTouched(false).then(() => {
              message.success('Рецепт успешно добавлен.');
              navigate(searchParams.get('from') || `/recipes/${res.id}`);
            });
          }
        })
    ));
  };

  useMessageError([recipeCreate]);

  return (
    <SinglePageContainer
      breadcrumbItems={breadcrumbs}
      title="Добавить рецепт"
      cardLoading={false}
      extra={(
        <Button
          type="primary"
          onClick={form.submit}
          loading={recipeCreate.loading}
        >
          Сохранить
        </Button>
      )}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          ingredients: [{
            itemRU: '', itemUA: '', value: 0, measure: 'g',
          }],
          contentState: 'draft',
          forCatalog: true,
          titleUrl: '',
          imageUrls: [],
          contentRU: '',
          contentUA: '',
          portions: 1,
        }}
        onFinish={handleSubmit}
        name="recipe-create"
      >
        <RecipeFormItems
          fileList={fileList}
          setFileList={setFileList}
          bannerFile={bannerFile}
          setBannerFile={setBannerFile}
        />
      </Form>
    </SinglePageContainer>
  );
}
