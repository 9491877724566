import {
  DefaultFetchError,
  FetchGet,
  FetchGetId,
  PagingDataResponse,
  PagingParams,
  useFetchGet, useFetchGetId, useFetchDelete, FetchCreate, useFetchCreate, FetchUpdate, useFetchUpdate, useFetchPut,
} from '../fetch';
import {
  AvailabilityType,
  CaloriesRangeType,
  ContentStateType,
  DefaultOrderByColumnProps,
  MeasureType,
  MenuType,
  PreparingTimeType,
} from '../../types';
import { RuLangType, UaLangType } from '../../enums/language';

export type RecipeCategoryType = 'breakfast' | 'snack' | 'lunch' | 'salad' | 'dinner' | 'dessert' | 'soup';

export interface Recipe {
  id: string;
  nameUA: string;
  nameRU: string;
  contentRU: string;
  contentUA: string;
  titleUrl: string;
  imageUrls: string[];
  availability: AvailabilityType;
  contentState: ContentStateType;
  forCatalog: boolean;
  category: RecipeCategoryType;
  menuType: MenuType;
  preparingTime: PreparingTimeType;
  calories: number;
  pfc: string; // Белки/жиры/углеводы
  ingredients: Ingredient[];
  portions: number;
  portionNumber?: number;
  createdAt: string;
  updatedAt: string;
  // Prop for marathon menu
  nutrition?: {
    k1300?: RecipeNutritionItem,
    k1500?: RecipeNutritionItem, // should always be present
    k1700?: RecipeNutritionItem,
    k2000?: RecipeNutritionItem,
  },
}

export interface Ingredient {
  itemRU: string;
  itemUA: string;
  measure: MeasureType;
  value: number
}

export interface RecipesGetParams extends PagingParams {
  search?: string;
  lang?: UaLangType | RuLangType;
  availability?: AvailabilityType;
  contentState?: ContentStateType; // status
  category?: RecipeCategoryType;
  menuType?: MenuType;
  preparingTime?: PreparingTimeType;
  forCatalog?: string;
  calories?: CaloriesRangeType;
  orderByColumn?: DefaultOrderByColumnProps | 'nameRU' | 'availability' | 'contentState' | 'menuType' | 'calories'
    | 'preparingTime';
}

export function useRecipesGet<D = PagingDataResponse<Recipe>,
  DD = D>(decorateData?: (data: D) => DD): FetchGet<D, RecipesGetParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, RecipesGetParams, DD>(
    'recipes',
    {
      decorateData,
      autoStart: false,
      startStateLoading: false,
    },
  );
}

export const useRecipeId = (id?: string): FetchGetId<Recipe> => useFetchGetId(
  'recipes',
  id,
  { autoStart: false, startStateLoading: false },
);

export const useRecipeDelete = () => useFetchDelete<undefined, DefaultFetchError, string>('recipes');

export interface CreateRecipePayload {
  nameUA: string;
  nameRU: string;
  contentUA?: string;
  contentRU?: string;
  availability: AvailabilityType;
  contentState?: ContentStateType;
  forCatalog: boolean;
  category: RecipeCategoryType;
  menuType: MenuType;
  preparingTime: PreparingTimeType;
  pfc: string;
  calories: number;
  titleUrl: string;
  imageUrls: string[];
  ingredients: Ingredient[];
  portions: number;
  portionNumber?: number; // Index of portion. send it on the Marathon-Menu update, if element have portions.
}

export interface UpdateRecipePayload extends CreateRecipePayload {
  id?: string;
}

export const useRecipeCreate = (): FetchCreate<Recipe, DefaultFetchError, CreateRecipePayload> => (
  useFetchCreate('recipes')
);

export const useRecipeUpdate = (id?: string): FetchUpdate<
  Recipe, DefaultFetchError, UpdateRecipePayload
> => (
  useFetchUpdate('recipes', id)
);

/** New request for nutrition put request */
export interface RecipeNutritionItem {
  pfc: string;
  calories: number;
  ingredients: Ingredient[],
}

export type RecipeNutritionPayload = RecipeNutritionItem;

export const useRecipeNutritionPut = (id?: string): FetchUpdate<
  Recipe, DefaultFetchError, RecipeNutritionPayload
> => (
  useFetchPut('recipes', id) // recipes/{id}/nutrition/{calories}
);
