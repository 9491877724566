import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { App, Button, Typography } from 'antd';
import { usePasswordForgot } from '../../../../../hooks/api/password';

import styles from './index.module.scss';

export default function SendSuccess() {
  const { message } = App.useApp();
  const [email, setEmail] = useState<string | null>(null);
  const passwordCreate = usePasswordForgot();

  useEffect(() => setEmail(sessionStorage.getItem('forgotPasswordEmail')), []);

  const fetchForgotPassword = () => {
    if (email) {
      passwordCreate.fetch({ email })
        .then(() => message.success('Письмо отправлено!'));
    }
  };

  return (
    <div className="text-centered">
      <Typography.Title level={3} className={styles.title}>
        Проверьте свою почту
      </Typography.Title>
      <Typography.Paragraph className={styles.secondary}>
        Мы отправили электронное письмо со ссылкой для сброса пароля на вашу электронную почту
        <span className={styles.email}>{email || ''}</span>
      </Typography.Paragraph>
      <div className={styles.resendEmail}>
        <Typography.Text>
          Не получили письмо?
        </Typography.Text>
        <Button loading={passwordCreate.loading} type="link" onClick={fetchForgotPassword}>Отправить ещё раз</Button>
      </div>
      <NavLink to="/sign-in">Вернуться для входа в систему</NavLink>
    </div>
  );
}
