import React, { useMemo } from 'react';
import {
  createSearchParams, NavLink, useLocation,
} from 'react-router-dom';
import { AnyObject } from '@triare/auth-redux';
import {
  BookOutlined,
  CalendarOutlined,
  ThunderboltOutlined,
  TrophyOutlined,
  UserOutlined,
  FlagOutlined,
  AudioOutlined,
  CommentOutlined, MailOutlined, BellOutlined,
} from '@ant-design/icons';
import { Menu as AntdMenu } from 'antd';
import { connect } from 'react-redux';
import { Route } from '../../../routes';
import { RootState } from '../../../store';
import { moduleName, AuthUser as User } from '../../../store/auth';
import { useSimpleModal } from '../../Common/Modal/Simple';
import { doesUserHaveAccess } from '../../../routes/PrivateRoute';
import SignUp from '../../../pages/Auth/SignUp';
import Information from '../../../pages/Auth/SignUp/Information';
import Users from '../../../pages/Users';
import UserProfile from '../../../pages/Users/profile';
import Marathons from '../../../pages/Marathons';
import MarathonCreate from '../../../pages/Marathons/create';
import MarathonUpdate from '../../../pages/Marathons/update';
import MarathonTrainings from '../../../pages/Marathons/Trainings';
import MarathonMenu from '../../../pages/Marathons/Menu';
import MarathonShopping from '../../../pages/Marathons/Shopping';
import MarathonResults from '../../../pages/Marathons/Results';
import MarathonResultStage from '../../../pages/Marathons/Results/Stage';
import MarathonInstructions from '../../../pages/Marathons/Instructions';
import MarathonTelegrams from '../../../pages/Marathons/Telegrams';
import MarathonDiploma from '../../../pages/Marathons/Diploma';
import MarathonHabits from '../../../pages/Marathons/Habits';
import MarathonLectures from '../../../pages/Marathons/Lectures';
import MarathonPrizes from '../../../pages/Marathons/Prizes';
import Complexes from '../../../pages/Complexes';
import ComplexesCreate from '../../../pages/Complexes/create';
import ComplexesUpdate from '../../../pages/Complexes/update';
import Trainings from '../../../pages/Trainings';
import TrainingCreateHouse from '../../../pages/Trainings/create/house';
import TrainingCreateGym from '../../../pages/Trainings/create/gym';
import TrainingUpdate from '../../../pages/Trainings/update';
import Program from '../../../pages/Program';
import Recipes from '../../../pages/Recipes';
import RecipeCreate from '../../../pages/Recipes/create';
import RecipeUpdate from '../../../pages/Recipes/update';
import Lectures from '../../../pages/Lectures';
import LectureCreate from '../../../pages/Lectures/create';
import LectureUpdate from '../../../pages/Lectures/update';
import Admins from '../../../pages/Admins';
import AddAdmin from '../../../pages/Admins/Add';
import EditAdmin from '../../../pages/Admins/Edit';
import { UserRoles } from '../../../enums/user';
import Reviews from '../../../pages/Reviews';
import Emails from '../../../pages/Emails';
import EmailCreate from '../../../pages/Emails/Create';
import EmailCheck from '../../../pages/Emails/Check';
import NotificationsCreate from '../../../pages/Notifications';

import styles from './index.module.scss';
import Certificates from '../../../pages/Certificates';

export interface MenuRoute extends Route {
  ignoreMenu?: boolean;

  children?: MenuRoute[];
}

export const routes: MenuRoute[] = [
  {
    privateRoute: true,
    name: 'Пользователи',
    icon: <UserOutlined style={{ fontSize: 18 }} />,
    route: {
      path: 'users',
      element: <Users />,
    },
    children: [
      {
        privateRoute: true,
        name: 'Просмотр профиля пользователя',
        route: {
          path: ':id',
          element: <UserProfile />,
        },
      },
    ],
  },
  {
    privateRoute: true,
    name: 'Сертификаты',
    icon: <BookOutlined style={{ fontSize: 18 }} />,
    route: {
      path: 'certificates',
      element: <Certificates />,
    },
  },
  {
    privateRoute: true,
    name: 'Марафоны',
    icon: <TrophyOutlined style={{ fontSize: 18 }} />,
    route: {
      path: 'marathons',
      element: <Marathons />,
    },
    children: [
      {
        privateRoute: true,
        name: 'Добавить марафон',
        route: {
          path: 'create',
          element: <MarathonCreate />,
        },
      },
      {
        privateRoute: true,
        name: 'Редактировать марафон',
        route: {
          path: ':id',
          element: <MarathonUpdate />,
        },
      },
      /** Trainings, Menu, Shopping-s */
      {
        privateRoute: true,
        name: 'Тренировки марафона',
        route: {
          path: ':marathonId/trainings/:id',
          element: <MarathonTrainings />,
        },
      },
      {
        privateRoute: true,
        name: 'Меню марафона',
        route: {
          path: ':marathonId/menus/:id',
          element: <MarathonMenu />,
        },
      },
      {
        privateRoute: true,
        name: 'Закупки марафона',
        route: {
          path: ':marathonId/shopping/:id',
          element: <MarathonShopping />,
        },
      },
      /** Lectures, Habits */
      {
        privateRoute: true,
        name: 'Лекции марафона',
        route: {
          path: ':marathonId/lectures',
          element: <MarathonLectures />,
        },
      },
      {
        privateRoute: true,
        name: 'Привычки марафона',
        route: {
          path: ':marathonId/habits',
          element: <MarathonHabits />,
        },
      },
      /** Конкурс */
      {
        privateRoute: true,
        name: 'Результаты марафона',
        route: {
          path: ':marathonId/results',
          element: <MarathonResults />,
        },
      },
      {
        privateRoute: true,
        name: 'Голосование',
        route: {
          path: ':marathonId/stages/:stageId',
          element: <MarathonResultStage />,
        },
      },
      {
        privateRoute: true,
        name: 'Инструкции для фото/видео марафона',
        route: {
          path: ':marathonId/instructions',
          element: <MarathonInstructions />,
        },
      },
      {
        privateRoute: true,
        name: 'Призы марафона',
        route: {
          path: ':marathonId/prizes',
          element: <MarathonPrizes />,
        },
      },
      /** Others */
      {
        privateRoute: true,
        name: 'Телеграм марафона',
        route: {
          path: ':marathonId/telegram',
          element: <MarathonTelegrams />,
        },
      },
      {
        privateRoute: true,
        name: 'Диплом марафона',
        route: {
          path: ':marathonId/diploma',
          element: <MarathonDiploma />,
        },
      },
    ],
  },
  {
    privateRoute: true,
    name: 'Комплексы',
    icon: <FlagOutlined style={{ fontSize: 18 }} />,
    route: {
      path: 'complexes',
      element: <Complexes />,
    },
    children: [
      {
        privateRoute: true,
        name: 'Добавить комплекс',
        route: {
          path: 'create',
          element: <ComplexesCreate />,
        },
      },
      {
        privateRoute: true,
        name: 'Редактировать комплекс',
        route: {
          path: ':id',
          element: <ComplexesUpdate />,
        },
      },
    ],
  },
  {
    privateRoute: true,
    name: 'Тренировки',
    icon: <ThunderboltOutlined style={{ fontSize: 18 }} />,
    route: {
      path: 'trainings',
      element: <Trainings />,
    },
    children: [
      {
        privateRoute: true,
        name: 'Добавить тренировку (дом)',
        route: {
          path: 'create/house',
          element: <TrainingCreateHouse />,
        },
      },
      {
        privateRoute: true,
        name: 'Добавить тренировку (зал)',
        route: {
          path: 'create/gym',
          element: <TrainingCreateGym />,
        },
      },
      {
        privateRoute: true,
        name: 'Редактировать тренировку',
        route: {
          path: ':id',
          element: <TrainingUpdate />,
        },
      },
    ],
  },
  {
    privateRoute: true,
    name: 'Программа',
    icon: <CalendarOutlined style={{ fontSize: 18 }} />,
    route: {
      path: 'program',
      element: <Program />,
    },
  },
  {
    privateRoute: true,
    name: 'Рецепты',
    icon: <BookOutlined style={{ fontSize: 18 }} />,
    route: {
      path: 'recipes',
      element: <Recipes />,
    },
    children: [
      {
        privateRoute: true,
        name: 'Добавить рецепт',
        route: {
          path: 'create',
          element: <RecipeCreate />,
        },
      },
      {
        privateRoute: true,
        name: 'Редактировать рецепт',
        route: {
          path: ':id',
          element: <RecipeUpdate />,
        },
      },
    ],
  },
  {
    privateRoute: true,
    name: 'Лекции',
    icon: <AudioOutlined style={{ fontSize: 18 }} />,
    route: {
      path: 'lectures',
      element: <Lectures />,
    },
    children: [
      {
        privateRoute: true,
        name: 'Добавить лекцию',
        route: {
          path: 'create',
          element: <LectureCreate />,
        },
      },
      {
        privateRoute: true,
        name: 'Редактировать лекцию',
        route: {
          path: ':id',
          element: <LectureUpdate />,
        },
      },
    ],
  },
  {
    privateRoute: true,
    name: 'Уведомления',
    icon: <BellOutlined style={{ fontSize: 18 }} />,
    route: {
      path: 'notifications/create',
      element: <NotificationsCreate />,
    },
  },
  {
    privateRoute: true,
    name: 'Рассылка',
    icon: <MailOutlined style={{ fontSize: 18 }} />,
    route: {
      path: 'emails',
      element: <Emails />,
    },
    children: [
      {
        privateRoute: true,
        name: 'Создать рассылку',
        route: {
          path: ':id/create',
          element: <EmailCreate />,
        },
      },
      {
        privateRoute: true,
        name: 'Просмотр шаблона',
        route: {
          path: ':id',
          element: <EmailCheck />,
        },
      },
    ],
  },
  {
    privateRoute: true,
    name: 'Отзывы',
    icon: <CommentOutlined style={{ fontSize: 18 }} />,
    route: {
      path: 'reviews',
      element: <Reviews />,
    },
  },
  {
    privateRoute: true,
    roles: [UserRoles.ROOT],
    name: 'Администраторы',
    icon: <UserOutlined style={{ fontSize: 18 }} />,
    route: {
      path: 'admins',
      element: <Admins />,
    },
    children: [
      {
        privateRoute: true,
        hidden: true,
        roles: [UserRoles.ROOT],
        name: 'Добавить администратора',
        route: {
          path: 'create',
          element: <AddAdmin />,
        },
      },
      {
        privateRoute: true,
        hidden: true,
        roles: [UserRoles.ROOT],
        name: 'Редактировать администратора',
        route: {
          path: ':id',
          element: <EditAdmin />,
        },
      },
    ],
  },
];

export const paramsToString = (user?: User | null, params?: ((user: User) => string) | AnyObject | string) => {
  if (!user) {
    return '';
  }

  if (typeof params === 'string') {
    return '?params';
  }

  if (typeof params === 'function') {
    let result = params(user);

    if (result) {
      if (typeof result === 'object') {
        result = createSearchParams(result).toString();
      }

      return `?${result}`;
    }

    return '';
  }

  if (params && typeof params === 'object') {
    return `?${createSearchParams(params).toString()}`;
  }

  return '';
};

export const createItem = ({
  route, children, ignoreMenu, privateRoute, ...data
}: Route, user: User) => {
  let childrenList;

  if (children && children.length) {
    childrenList = children.filter(({ ignoreMenu: childrenIgnoreMenu }) => childrenIgnoreMenu !== true);

    if (childrenList.length) {
      childrenList = childrenList.map(
        ({ ignoreMenu: childrenIgnoreMenu, privateRoute: childrenPrivateRoute, ...child }) => ({
          ...child,
          label: child.route ? (
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            <NavLink to={`/${child.route.path}${paramsToString(user, child.route.path)}`}>
              {child.name || child.key}
            </NavLink>
          ) : (
            child.name
          ),
          key: child.route ? `/${child.route.path}` : `/${child.key || child.name}`,
        }),
      );
    }
  }

  const name = typeof data.name === 'function' ? data.name(user) : data.name;

  return {
    ...data,
    icon: data?.icon,
    children: childrenList && childrenList.length ? childrenList : undefined,
    label: route ? (
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      <NavLink to={`/${route.path}${paramsToString(user, route.params)}`}>{name}</NavLink>
    ) : (
      name
    ),
    key: route ? `/${route.path}` : `/${data.key || name}`,
  };
};

export const mainMenu = routes.filter(({ ignoreMenu }) => ignoreMenu !== true);

// eslint-disable-next-line
export function filter(list: any[], user: User) {
  const newList = list.map((item) => ({ ...item, children: item.children ? [...item.children] : undefined }));

  return newList.filter((item) => {
    if (item && item.children && item.children.length) {
      // eslint-disable-next-line no-param-reassign
      item.children = filter(item.children, user);
    }

    return item?.roles ? doesUserHaveAccess(item?.roles, user) : true;
  });
}

export interface MenuProps {
  user: User;
}

function Menu({ user }: MenuProps): React.ReactNode {
  const { open, contextHolder } = useSimpleModal();
  const { pathname } = useLocation();

  const menuItemsFiltered = useMemo(
    () => filter(mainMenu.map((data) => createItem(data, user)), user).map((item) => ({
      ...item,
      name: typeof item.name === 'function' ? item.name(user) : item.name,
      icon: typeof item.icon === 'function' ? item.icon(user) : item.icon,
    })).map(({ children, ...item }) => item),
    [user],
  );

  const activeMenuItem = `/${pathname.split('/')[1]}`;

  return (
    <div className={styles.menu}>
      <AntdMenu
        theme="light"
        mode="inline"
        selectedKeys={[activeMenuItem]}
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        defaultSelectedKeys={[activeMenuItem]}
        items={menuItemsFiltered}
        style={{ minWidth: 0, flex: 'auto' }}
      />
      {contextHolder}
    </div>
  );
}

export default connect((state: RootState) => ({
  user: state[moduleName].user,
}))(Menu);
