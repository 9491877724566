import React from 'react';
import { Button, Input, Select } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { Training } from '../../../../hooks/api/trainings';
import VideoThumbnail from '../../../Common/VideoThumbnail';
import { enumToOptionsArray } from '../../../../utils';
import { LevelEnum, PlaceEnum } from '../../../../enums/trainings';
import SelectContentStatus from '../../../Common/Selects/SelectStatus/SelectContentStatus';

import styles from './index.module.scss';

interface TrainingRow {
  training: Training;
  onRemove: (id: string) => void;
  disabled?: boolean;
}

function TrainingRow({ training, onRemove, disabled }: TrainingRow) {
  const navigate = useNavigate();

  if (!training?.id) return null;

  return (
    <div key={training.id} className={styles.training}>
      <div className={styles.content}>
        <VideoThumbnail
          videoUrl={training.url}
          thumbnailUrl={training.thumbnail}
          duration={training.duration}
        />
        <div className={styles.inner}>
          <Input name="nameRU" addonBefore="RU" value={training.nameRU} disabled />
          <Input name="nameUA" addonBefore="UA" value={training.nameUA} disabled />

          <div className={styles.additionalInfo}>
            <SelectContentStatus value={training?.contentState} disabled />
            <Select options={enumToOptionsArray(PlaceEnum)} value={training?.place} disabled title="Место" />
            <Select options={enumToOptionsArray(LevelEnum)} value={training?.level} disabled title="Уровень" />

            <div className={styles.actions}>
              <Button icon={<EditOutlined />} onClick={() => navigate(`/trainings/${training.id}`)} />
              <Button disabled={disabled} icon={<DeleteOutlined />} onClick={() => onRemove(training.id)} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

TrainingRow.defaultProps = {
  disabled: false,
};

export default TrainingRow;
