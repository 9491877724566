import React from 'react';
import { SelectProps } from 'antd/lib/select';
import { Select } from 'antd';
import { UserSubscription } from '../../../../hooks/api/user';
import { Option } from '../../../../types';
import { SubscriptionEnum } from '../../../../enums/user';

function SelectSubscriptionType({ ...props }: SelectProps<UserSubscription, Option>) {
  return (
    <Select {...props} />
  );
}

SelectSubscriptionType.defaultProps = {
  options: Object.entries(SubscriptionEnum).map(([key, value]) => ({ label: key, value })),
  allowClear: true,
  placeholder: 'Выберите',
};

export default SelectSubscriptionType;
