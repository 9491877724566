/* eslint-disable no-param-reassign */

import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { AnyObject } from '@triare/auth-redux';
import { EnhancedStore } from '@reduxjs/toolkit/src/configureStore';
import { useSelector } from 'react-redux';
// eslint-disable-next-line import/no-unresolved
import { NavigateOptions } from 'react-router/dist/lib/context';
import { To } from '@remix-run/router';
import { RootState } from '../index';

export const moduleName = 'common';

export interface State {
  redirect: AnyObject | null
}

export const common = createSlice<State, SliceCaseReducers<State>>({
  name: moduleName,
  initialState: {
    redirect: null,
  },
  reducers: {
    triggerRedirect: (state, { payload }) => {
      state.redirect = payload;
    },
  },
});

export const { reducer, actions } = common;

// action creator
export function triggerRedirect(to: To, options?: NavigateOptions) {
  return {
    type: actions.triggerRedirect.type,
    payload: {
      to,
      options,
    },
  };
}

// yield put({
//   type: actions.triggerRedirect.type,
//   payload: {
//     to: params,
//     options: {
//       replace: true,
//     },
//   },
// });

export function useCommonState(): State {
  return useSelector((state: RootState) => state[moduleName]);
}

export function* saga(store: EnhancedStore) {
  // do something
}
