import React from 'react';
import ReactQuill, { ReactQuillProps } from 'react-quill';
import clsx from 'clsx';
import 'react-quill/dist/quill.snow.css';
import './index.scss';
import { useUnsavedChanges } from '../../../context/unsavedChanges';

interface QuillRichTextEditor {
  value?: string;
  onChange?: (value: string) => void;
}

function QuillRichTextEditor({ value, onChange }: QuillRichTextEditor) {
  const { htmlEditorTouched, handleEditorTouched } = useUnsavedChanges();
  /** https://quilljs.com/docs/modules/toolbar/ */
  const modules: ReactQuillProps['modules'] = {
    toolbar: [
      [{ header: [1, 2, 3, false] }], // [{ size: ['small', false, 'large', 'huge'] }],
      ['bold', 'italic', 'underline', 'strike'], // 'blockquote'
      [{ list: 'ordered' }, { list: 'bullet' }], // { indent: '-1' }, { indent: '+1' }
      // [{ color: ['blue', 'grey'] }, { background: [] }], // Color and background color selectors
      // [{ font: [] }], // Chose Font-family
      // [{ align: [] }], // Text-align selector.
      ['link'], // 'image'
      ['clean'],
    ],
    clipboard: { matchVisual: false }, // https://github.com/zenoamaro/react-quill/issues/409
    // If remove 'false', then before 'ul' & 'ol' will appear additional <p><br/></p>
  };

  const handleChange = (val: string) => {
    /** If value is empty then quill returns it as '<p><br></p>' */
    if (!htmlEditorTouched) { handleEditorTouched(true); }

    if (val === '<p><br></p>') { return onChange?.(''); }

    return onChange?.(val);
  };

  return (
    <ReactQuill
      value={value}
      onChange={handleChange}
      className={clsx('quill-customized')}
      placeholder="Введите"
      modules={modules}
    />
  );
}

QuillRichTextEditor.defaultProps = {
  value: '',
  onChange: () => undefined,
};

export default QuillRichTextEditor;
