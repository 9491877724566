import React, { useState } from 'react';
import { Button, Collapse, Form } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import ExerciseRow from './ExerciseRow';
import { CreateTrainingPayload, Exercise } from '../../../../../../hooks/api/trainings';
import DualButton from '../../../../../Common/DualButton';

import styles from './index.module.scss';

/** Initial form states */
export const initialExerciseState: Exercise = {
  urls: [],
  nameUA: '',
  nameRU: '',
  descriptionUA: '',
  descriptionRU: '',
  weightUA: '',
  weightRU: '',
  numberOfSetsStr: '1',
  numberOfRepetitions: 1,
};

interface TrainingGymFromItemsExercises {
  initialValues?: Partial<CreateTrainingPayload>;
  checkUnsavedChanges?: boolean;
}

function TrainingGymFormItemsExercises({ initialValues, checkUnsavedChanges }: TrainingGymFromItemsExercises) {
  const [activeKey, setActiveKey] = useState<string[]>(['0']);
  const form = Form.useFormInstance();
  const exercises: Exercise[] = Form.useWatch('exercises', form) || [];

  const handleChange = (value: number) => {
    setActiveKey([value.toString()]);
  };

  const [triggerReorderState, setTriggerReorderState] = useState(0);
  const handleReoder = (e: React.MouseEvent<HTMLElement>, onClick: () => void, newIndex: number) => {
    e.stopPropagation();

    onClick();
    handleChange(newIndex);
    setTriggerReorderState(Date.now());
  };

  return (
    <Form.List name="exercises">
      {(fields, { add, remove, move }) => (
        <div className="flex-column-wrapper">
          <Collapse
            accordion
            activeKey={activeKey}
            onChange={(key) => (Array.isArray(key) ? setActiveKey(key) : setActiveKey([key]))}
            defaultActiveKey={fields?.[0]?.key}
            items={fields.map((field, index) => ({
              key: field.name,
              label: `Упражнение ${index + 1}`,
              children: <ExerciseRow
                field={field}
                index={index}
                exerciseInitialValues={(initialValues?.exercises || [])[index]}
                checkUnsavedChanges={checkUnsavedChanges}
                trigger={triggerReorderState}
                exercises={exercises}
              />,
              extra: (
                <div style={{ display: 'flex', gap: 8 }}>
                  <DualButton
                    onClickTop={(e) => handleReoder(e, () => move(index, index - 1), index - 1)}
                    onClickBottom={(e) => handleReoder(e, () => move(index, index + 1), index + 1)}
                    disabledTop={index === 0}
                    disabledBottom={index === fields.length - 1}
                  />
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      remove(field.name);
                    }}
                    icon={<DeleteOutlined />}
                    disabled={fields.length <= 1}
                  />
                </div>
              ),
              headerClass: styles.collapseHeader,
            }))}
          />
          <Button
            type="default"
            onClick={() => {
              add(initialExerciseState);

              handleChange(exercises.length);
            }}
            icon={<PlusOutlined />}
            className={styles.addBtn}
            disabled={fields.length >= 150}
          >
            Добавить упражнение
          </Button>
        </div>
      )}
    </Form.List>
  );
}

TrainingGymFormItemsExercises.defaultProps = {
  initialValues: undefined,
  checkUnsavedChanges: false,
};

export default TrainingGymFormItemsExercises;
