import React, { useState } from 'react';
import { ItemType } from 'antd/es/breadcrumb/Breadcrumb';
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import {
  App, Button, Form, UploadFile,
} from 'antd';
import Tabs from 'antd/es/tabs';
import SinglePageContainer from '../../../../Common/SinglePageContainer';
import { Exercise, useTrainingCreate } from '../../../../../hooks/api/trainings';
import { useMessageError } from '../../../../../hooks/common';
import TrainingGymFormItemsGeneral from '../../Forms/Gym/General';
import { getFullFileUrl } from '../../../../Common/Uploaders/ImageUpload';
import TrainingGymFormItemsExercises, { initialExerciseState } from '../../Forms/Gym/Exercises';
import { useUnsavedChanges } from '../../../../../context/unsavedChanges';

const breadcrumbs: ItemType[] = [
  {
    title: <NavLink to="/trainings">Тренировки</NavLink>,
  },
  {
    title: 'Добавить тренировку (зал)',
  },
];

export default function TrainingCreateGym() {
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const trainingCreate = useTrainingCreate();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { handleEditorTouched } = useUnsavedChanges();

  const [bannerFile, setBannerFile] = useState<UploadFile[]>([]);

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      const modifiedValues = {
        ...values,
        exercises: (values.exercises || []).map((exercise: Exercise) => ({
          ...exercise,
          weightRU: exercise.weightRU || null,
          weightUA: exercise.weightUA || null,
        })),
        thumbnail: getFullFileUrl(values.thumbnail),
      };

      return (
        trainingCreate.fetch(modifiedValues)
          .then((res) => {
            if (res?.id) {
              handleEditorTouched(false).then(() => {
                message.success('Тренировка успешно добавлена.');
                navigate(searchParams.get('from') || `/trainings/${res.id}`);
              });
            }
          })
      );
    });
  };

  const exercises = Form.useWatch('exercises', form);

  const [withErrors, setWithErrors] = useState(false);

  const handleSubmitClick = () => {
    form.validateFields().catch(() => { setWithErrors(true); });
    form.submit();
  };

  useMessageError([trainingCreate]);

  return (
    <SinglePageContainer
      breadcrumbItems={breadcrumbs}
      title="Добавить тренировку (зал)"
      cardLoading={false}
      extra={(
        <Button
          type="primary"
          onClick={handleSubmitClick}
          loading={trainingCreate.loading}
          disabled={!exercises?.[0]?.nameRU}
        >
          Сохранить
        </Button>
      )}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          place: 'gym', contentState: 'draft', forCatalog: true, exercises: [initialExerciseState],
        }}
        onFinish={handleSubmit}
        name="training-create-gym"
        onFieldsChange={() => {
          setWithErrors(form.getFieldsError().some((field) => field.errors.length));
        }}
      >
        <Tabs
          defaultActiveKey="1"
          items={[
            {
              key: '1',
              label: <span className={withErrors ? 'with-error' : undefined}>Общее</span>,
              children: (
                <TrainingGymFormItemsGeneral bannerFile={bannerFile} setBannerFile={setBannerFile} />
              ),
            },
            {
              key: '2',
              label: 'Упражнения',
              children: (
                <TrainingGymFormItemsExercises />
              ),
            },
          ]}
        />
      </Form>
    </SinglePageContainer>
  );
}
