import ProTable, { ProTableProps } from '@ant-design/pro-table';
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { Button, InputRef } from 'antd';
import { ParamsType } from '@ant-design/pro-provider';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import Search from 'antd/es/input/Search';
import useCurrentLocation from '../../../hooks/router';
import { queryFilterParams, triggerEnterKeyClick } from '../../../utils';

import styles from './index.module.scss';

const handleFilterCollapseButton = (collapsed: boolean) => {
  const arrow = collapsed ? <DownOutlined /> : <UpOutlined />;

  return (
    <span className={styles.filterCollapse}>
      {collapsed ? 'More' : 'Less'}
      {arrow}
    </span>
  );
};

export const defaultPagingResponse = ({ data: [], success: false, total: 0 });

export default function Table<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  DataType extends Record<string, any>,
  Params extends ParamsType = ParamsType,
  ValueType = 'text'
>(props: ProTableProps<DataType, Params, ValueType> & {
  syncToUrl?: boolean, withCustomSearch?: boolean,
}): React.JSX.Element {
  const key = useRef<number>();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentLocation = useCurrentLocation();

  const {
    pagination, search, syncToUrl, withCustomSearch, toolBarRender,
  } = props;

  const newProps = {
    dateFormatter: 'string',
    ...props,
    key: key.current,
  } as ProTableProps<DataType, Params, ValueType>;

  newProps.options = {
    density: false,
    ...newProps.options,
  };

  newProps.form = search || !syncToUrl ? undefined : {
    syncToUrl: true,
    extraUrlParams: { search: searchParams.get('search') },
    // collapseRender: (collapsed) => handleFilterCollapseButton(collapsed),
    ...newProps.form,
  };

  newProps.pagination = pagination ?? {
    defaultPageSize: 10,
    size: 'default',
    ...newProps.pagination,
  };

  newProps.search = search ?? {
    optionRender: ({ form }) => ([
      <Button
        key="Clear"
        onClick={(e) => {
          e.preventDefault();
          setTimeout(() => {
            key.current = Date.now();
            navigate(currentLocation, { replace: true });
          }, 0);
        }}
      >
        Сбросить
      </Button>,
      <Button
        key="Query"
        type="primary"
        onClick={(e) => {
          e.preventDefault();
          form?.submit();
        }}
      >
        Применить
      </Button>,
    ]),
    ...newProps.search,
  };

  /** Custom search field: */
  const [searchValue, setSearchValue] = useState<string>(searchParams.get('search') || '');
  const searchRef = useRef<InputRef>(null);

  useEffect(() => {
    setSearchValue(searchParams.get('search') || '');
  }, []);

  const onSearch = (value: string) => {
    setSearchParams({
      ...searchParams,
      ...queryFilterParams({
        search: value,
      }),
    }, { replace: true });

    props?.formRef?.current?.submit();
  };

  newProps.toolBarRender = useCallback(() => (
    [
      withCustomSearch
        ? (
          <Search
            key="search"
            id="table-search"
            value={searchValue}
            ref={searchRef}
            style={{ width: 278 }}
            onSearch={onSearch}
            onChange={(value) => setSearchValue(value.target.value)}
            placeholder="Поиск"
          />
        )
        : null,
      ...(toolBarRender ? toolBarRender(undefined, { selectedRows: undefined, selectedRowKeys: undefined }) : []),
    ]
  ), [searchValue, toolBarRender]);

  useEffect(() => {
    if (!searchValue.length) return onSearch('');

    const searchBounce = setTimeout(() => {
      triggerEnterKeyClick(searchRef?.current);
    }, 750);

    return () => clearTimeout(searchBounce);
  }, [searchValue]);

  return (
    <ProTable<DataType, Params, ValueType>
      tableClassName={styles.table}
      {...newProps}
    />
  );
}

Table.defaultProps = {
  syncToUrl: true,
  withCustomSearch: false,
};
