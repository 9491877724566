import React from 'react';
import { SelectProps } from 'antd/lib/select';
import { Badge, Select } from 'antd';
import { ContentStateType, Option } from '../../../../../types';

function SelectContentStatus({ ...props }: SelectProps<ContentStateType, Option>) {
  return (
    <Select {...props} />
  );
}

SelectContentStatus.defaultProps = {
  options: [
    { label: <Badge status="success" text="Опубликовано" />, value: 'published' },
    { label: <Badge status="warning" text="Черновик" />, value: 'draft' },
  ],
  allowClear: true,
  placeholder: 'Выберите',
};

export default SelectContentStatus;
