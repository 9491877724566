import React, { useEffect, useState } from 'react';
import { ItemType } from 'antd/es/breadcrumb/Breadcrumb';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import {
  App, Button, Form, UploadFile,
} from 'antd';
import SinglePageContainer from '../../../Common/SinglePageContainer';
import {
  CreateRecipePayload, Recipe, useRecipeDelete, useRecipeId, useRecipeUpdate,
} from '../../../../hooks/api/recipes';
import { useMessageError } from '../../../../hooks/common';
import RecipeFormItems from '../Form';
import { useSimpleModal } from '../../../Common/Modal/Simple';
import { generateFileObjFromUrl } from '../../../Common/Uploaders/ImageUpload';
import { asyncDelay, filterObjectProps, isObjEqual } from '../../../../utils';
import { useUnsavedChanges } from '../../../../context/unsavedChanges';

const breadcrumbs: ItemType[] = [
  {
    title: <NavLink to="/recipes">Рецепты</NavLink>,
  },
  {
    title: 'Редактировать рецепт',
  },
];

const initialState: Partial<CreateRecipePayload> = {
  nameRU: '',
  nameUA: '',
  contentUA: '',
  contentRU: '',
  availability: undefined,
  contentState: undefined,
  forCatalog: true,
  category: undefined,
  menuType: undefined,
  preparingTime: undefined,
  pfc: '',
  calories: 0,
  titleUrl: '',
  imageUrls: [],
  ingredients: [{
    itemRU: '', itemUA: '', value: 0, measure: 'not-specified',
  }],
  portions: 1,
  portionNumber: undefined,
};

const keysToFilterInitial: (keyof Partial<Recipe>)[] = ['createdAt', 'updatedAt', 'id', 'nutrition'];

export default function RecipeUpdate() {
  const { id = '' } = useParams<{ id: string; }>();
  const recipeById = useRecipeId();
  const recipeUpdate = useRecipeUpdate();
  const recipeDelete = useRecipeDelete();
  const navigate = useNavigate();

  const { open, contextHolder } = useSimpleModal();
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const {
    unsavedChanges, handleUnsavedChanges, htmlEditorTouched, handleEditorTouched,
  } = useUnsavedChanges();

  const fetch = () => {
    recipeById.fetch(undefined, id);
  };

  useEffect(() => {
    if (id) {
      fetch();
    }
  }, [id]);

  const [bannerFile, setBannerFile] = useState<UploadFile[]>([]);
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  /** If portions were changed - show popup with confirm */
  const [portionsChanged, setPortionsChanged] = useState(false);
  const portionsWatch = Form.useWatch('portions', form);

  useEffect(() => {
    setPortionsChanged(portionsWatch !== initialValues.portions);
  }, [portionsWatch]);

  /** Handle Initial values */
  const [initialValues, setInitialValues] = useState(initialState);

  const handleInitialFileList = (data: Recipe) => {
    const banner = generateFileObjFromUrl(data.titleUrl);
    const recipeFiles = data.imageUrls.map((url) => (generateFileObjFromUrl(url)));

    setBannerFile([banner]);
    setFileList(recipeFiles);
  };

  useEffect(() => {
    if (recipeById.data) {
      setInitialValues(filterObjectProps(recipeById.data, keysToFilterInitial));
      handleInitialFileList(recipeById.data);
    }
  }, [recipeById.data]);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(initialValues);
    handleUnsavedChanges(false);
    asyncDelay(200).then(() => (handleEditorTouched(false)));
  }, [initialValues]);

  const handleSubmit = () => {
    const onOk = () => form.validateFields().then((values) => (
      recipeUpdate.fetch(values, id)
        .then((res) => {
          if (res?.id) {
            message.success('Изменения успешно сохранены.');
            setInitialValues(filterObjectProps(res, keysToFilterInitial));
          }
        })
    ));

    if (portionsChanged) {
      open({
        title: 'Внимание',
        content: 'Изменение количества порций может повлиять на отображение рецепта в марафоне. '
          + 'Подтвердить сохранение?',
        cancelText: 'Отмена',
        centered: true,
        okText: 'Сохранить',
        okButtonProps: {
          type: 'primary',
        },
        onOk,
        maskClosable: true,
      });
    } else {
      onOk();
    }
  };

  /** Handle Delete */
  const handleDeleteClick = () => {
    if (!id) return;
    open({
      title: 'Удалить рецепт?',
      content: 'Вы уверены, что хотите навсегда удалить этот рецепт?',
      cancelText: 'Отмена',
      centered: true,
      okText: 'Удалить',
      okButtonProps: {
        type: 'primary',
        danger: true,
      },
      onOk: () => recipeDelete.fetch(id),
      maskClosable: true,
    });
  };

  useEffect(() => {
    if (recipeDelete.response?.status === 204) {
      handleUnsavedChanges(false).then(() => {
        message.success('Рецепт успешно удален.');
        navigate('/recipes');
      });
    }
  }, [recipeDelete.response]);

  useMessageError([recipeById, recipeUpdate, recipeDelete]);

  const handleFieldsChange = () => {
    handleUnsavedChanges(!isObjEqual(
      filterObjectProps(initialValues, ['contentRU', 'contentUA']),
      filterObjectProps(form.getFieldsValue(), ['contentRU', 'contentUA']),
    ));
  };

  useEffect(() => { handleFieldsChange(); }, [bannerFile, fileList]);

  return (
    <>
      {contextHolder}
      <SinglePageContainer
        breadcrumbItems={breadcrumbs}
        title="Редактировать рецепт"
        cardLoading={recipeById.loading}
        extra={(
          <>
            <Button type="primary" danger onClick={handleDeleteClick}>Удалить</Button>
            <Button
              type="primary"
              onClick={form.submit}
              loading={recipeUpdate.loading}
              disabled={!(unsavedChanges || htmlEditorTouched)}
            >
              Сохранить
            </Button>
          </>
      )}
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={initialValues}
          onFinish={handleSubmit}
          name="recipe-update"
          onFieldsChange={handleFieldsChange}
        >
          <RecipeFormItems
            fileList={fileList}
            setFileList={setFileList}
            bannerFile={bannerFile}
            setBannerFile={setBannerFile}
          />
        </Form>
      </SinglePageContainer>
    </>
  );
}
