import React from 'react';
import {
  Button, Input, Select,
} from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import {
  Recipe,
} from '../../../../hooks/api/recipes';
import { getFullFileUrl } from '../../../Common/Uploaders/ImageUpload';
import { enumToOptionsArray } from '../../../../utils';
import { MenuTypeEnum, RecipesCategoryEnum } from '../../../../enums/recipes';
import StatusBadge from '../../../Common/StatusBadge';
import RecipeNutritionUpdate from './RecipeNutritionUpdate';
import { MarathonMenu } from '../../../../hooks/api/marathons';

import styles from './index.module.scss';

interface TrainingRow {
  recipe: Recipe;
  onRemove: (id: string) => void;
  disabled?: boolean;
  marathonMenuData?: MarathonMenu;
}

function RecipeRow({
  recipe, onRemove, disabled,
}: TrainingRow) {
  const navigate = useNavigate();

  if (!recipe?.id) return null;

  return (
    <div key={recipe.id} className={styles.recipe}>
      <div className={styles.content}>
        <img
          className={styles.imageContainer}
          src={getFullFileUrl(recipe.titleUrl)}
          alt={recipe.nameRU}
        />
        <div className={styles.inner}>
          <Input name="nameRU" addonBefore="RU" value={recipe.nameRU} disabled />
          <Input name="nameUA" addonBefore="UA" value={recipe.nameUA} disabled />

          <div className={clsx(styles.additionalInfo, { [styles.withPortions]: recipe.portionNumber })}>
            <Input
              name="portions"
              value={`Порций: ${recipe.portions}`}
              disabled
              title={`Кол-во порций: ${recipe.portions}`}
            />
            {recipe.portionNumber ? (
              <Input
                name="portions"
                value={`№ порции: ${recipe.portionNumber}`}
                disabled
                title={`Номер порции: ${recipe.portionNumber}`}
              />
            ) : null}
            <Select options={enumToOptionsArray(MenuTypeEnum)} value={recipe?.menuType} disabled title="Тип меню" />
            <Select
              options={enumToOptionsArray(RecipesCategoryEnum)}
              value={recipe?.category}
              disabled
              title="Категория"
            />
            {/* <Input name="calories" value={recipe?.calories} disabled title="Б/Ж/У" addonAfter="ккал" /> */}
            <StatusBadge status={recipe.contentState} />

            <RecipeNutritionUpdate recipe={recipe} />

            <div className={styles.actions}>
              <Button icon={<EditOutlined />} onClick={() => navigate(`/recipes/${recipe.id}`)} />
              <Button disabled={disabled} icon={<DeleteOutlined />} onClick={() => onRemove(recipe.id)} />
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}

RecipeRow.defaultProps = {
  disabled: false,
};

export default RecipeRow;
