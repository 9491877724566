import React, { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import {
  App, Button, Form, UploadFile,
} from 'antd';
import SinglePageContainer from '../../../Common/SinglePageContainer';
import { useUnsavedChanges } from '../../../../context/unsavedChanges';
import { useMessageError } from '../../../../hooks/common';
import { isObjEqual } from '../../../../utils';
import { Marathon, useMarathonId, useMarathonUpdate } from '../../../../hooks/api/marathons';
import MarathonInstructionsFormItems from './FormItems';
import { generateFileObjFromUrl } from '../../../Common/Uploaders/ImageUpload';

interface InitialValues {
  contestRulesPhotoUA?: string;
  contestRulesPhotoRU?: string;
  contestRulesVideoUA?: string;
  contestRulesVideoRU?: string;
  videoRulesVideoUrl?: string,
  photoRulesVideoUrl?: string,
}

const prepareInitialValues = (data: Marathon) => {
  const {
    contestRulesPhotoUA,
    contestRulesPhotoRU,
    contestRulesVideoUA,
    contestRulesVideoRU,
    videoRulesVideoUrl,
    photoRulesVideoUrl,
  } = data;

  return {
    contestRulesPhotoUA,
    contestRulesPhotoRU,
    contestRulesVideoUA,
    contestRulesVideoRU,
    videoRulesVideoUrl,
    photoRulesVideoUrl,
  };
};

const replaceNullToStr = <T = object>(obj: T) => {
  let newObj = {};

  Object.entries(obj as object).forEach(([key, value]) => {
    newObj = { ...newObj, [key]: value || '' };
  });

  return newObj as T;
};

export default function MarathonInstructions(): React.ReactNode {
  const { marathonId = '' } = useParams<{ marathonId: string; }>();
  const { unsavedChanges, handleUnsavedChanges, handleEditorTouched } = useUnsavedChanges();
  const { message } = App.useApp();

  const [form] = Form.useForm();

  const marathonById = useMarathonId();
  const marathonUpdate = useMarathonUpdate();

  const [initialValues, setInitialValues] = useState<InitialValues>();

  const [videoRulesFile, setVideoRulesFile] = useState<UploadFile[]>([]);
  const [photoRulesFile, setPhotoRulesFile] = useState<UploadFile[]>([]);

  const [isUploading, setIsUploading] = useState<boolean>(false);

  const fetch = () => {
    marathonById.fetch(undefined, marathonId);
  };

  useEffect(() => {
    handleUnsavedChanges(!isObjEqual(
      { videoRulesVideoUrl: initialValues?.videoRulesVideoUrl, photoRulesVideoUrl: initialValues?.photoRulesVideoUrl },
      { videoRulesVideoUrl: videoRulesFile[0]?.url, photoRulesVideoUrl: photoRulesFile[0]?.url },
    ));
  }, [videoRulesFile, photoRulesFile]);

  useEffect(() => {
    if (marathonId) fetch();
  }, [marathonId]);

  useEffect(() => {
    if (marathonById.data) {
      setInitialValues(prepareInitialValues(marathonById.data));
      if (marathonById.data.videoRulesVideoUrl) {
        setVideoRulesFile([generateFileObjFromUrl(marathonById.data.videoRulesVideoUrl)]);
      }
      if (marathonById.data.photoRulesVideoUrl) {
        setPhotoRulesFile([generateFileObjFromUrl(marathonById.data.photoRulesVideoUrl)]);
      }
    }
  }, [marathonById.data]);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(initialValues);
    handleUnsavedChanges(false);
  }, [initialValues]);

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      const modifiedVal = {
        ...values,
        photoRulesVideoUrl: photoRulesFile.length ? photoRulesFile[0].url : '',
        videoRulesVideoUrl: videoRulesFile.length ? videoRulesFile[0].url : '',
      };

      return (
        marathonUpdate.fetch(replaceNullToStr(modifiedVal), marathonId)
          .then((res) => {
            if (res?.id) {
              handleEditorTouched(false).then(() => {
                message.success('Изменения успешно сохранены.');
                setInitialValues(prepareInitialValues(res));
              });
            }
          })
      );
    });
  };

  useMessageError([marathonUpdate]);

  const handleFieldsChange = () => {
    if (!initialValues) return;

    handleUnsavedChanges(!isObjEqual(initialValues, form.getFieldsValue()));
  };

  return (
    <SinglePageContainer
      breadcrumbItems={[
        { title: <NavLink to="/marathons">Марафоны</NavLink> },
        { title: <NavLink to={`/marathons/${marathonId}`}>Редактировать марафон</NavLink> },
        { title: 'Редактировать инструкции для фото/видео' },
      ]}
      title="Редактировать инструкции для фото/видео"
      cardLoading={!initialValues || marathonById.loading}
      extra={(
        <Button
          type="primary"
          onClick={form.submit}
          loading={marathonUpdate.loading}
          disabled={!unsavedChanges || isUploading}
        >
          Сохранить
        </Button>
      )}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        name="marathon-instructions"
        initialValues={initialValues}
        onFieldsChange={handleFieldsChange}
      >
        <MarathonInstructionsFormItems
          setPhotoRulesFile={setPhotoRulesFile}
          setVideoRulesFile={setVideoRulesFile}
          photoRulesFile={photoRulesFile}
          videoRulesFile={videoRulesFile}
          setIsUploading={setIsUploading}
        />
      </Form>
    </SinglePageContainer>
  );
}
