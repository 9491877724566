import React, { useEffect, useState } from 'react';
import {
  App, Button, Form, Input,
} from 'antd';
import { ItemType } from 'antd/es/breadcrumb/Breadcrumb';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { RedoOutlined } from '@ant-design/icons';
import SinglePageContainer from '../../../Common/SinglePageContainer';
import {
  Admin, AdminCreateParams, useAdminDelete, useAdminId, useAdminReInvite, useAdminUpdate,
} from '../../../../hooks/api/admins';
import { createRulesForAntd, validationAdminEdit } from '../../../../utils/validations';
import { useMessageError } from '../../../../hooks/common';
import { filterObjectProps, isObjEqual } from '../../../../utils';
import { useUnsavedChanges } from '../../../../context/unsavedChanges';
import SelectStatus from '../../../Common/Selects/SelectStatus';
import { useSimpleModal } from '../../../Common/Modal/Simple';
import { useAuthState } from '../../../../store/auth';
import ChangePasswordModal from '../Modal';

const breadcrumbs: ItemType[] = [
  { title: <NavLink to="/admins">Администраторы</NavLink> },
  { title: 'Редактировать администратора' },
];

const validationRules = createRulesForAntd(validationAdminEdit);

function EditAdmin() {
  const { id = '' } = useParams<{ id: string; }>();
  const adminById = useAdminId();
  const adminUpdate = useAdminUpdate();
  const adminDelete = useAdminDelete();
  const adminReInvite = useAdminReInvite();

  const { message } = App.useApp();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { open, contextHolder } = useSimpleModal();
  const { unsavedChanges, handleUnsavedChanges } = useUnsavedChanges();
  const { user } = useAuthState();

  const [initialValues, setInitialValues] = useState<Admin>();

  const fetch = () => {
    adminById.fetch(undefined, id);
  };

  useEffect(() => {
    if (id) fetch();
  }, [id]);

  useEffect(() => {
    if (adminById.data) setInitialValues(adminById.data);
  }, [adminById.data]);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(initialValues);
    handleUnsavedChanges(false);
  }, [initialValues]);

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      const newValues = filterObjectProps((values as Admin), ['status']);

      handleUnsavedChanges(false);

      return adminUpdate.fetch(newValues as AdminCreateParams, initialValues?.id)
        .then((res) => {
          if (res?.id) {
            message.success('Изменения успешно сохранены');
            navigate('/admins');
          }
        });
    });
  };

  useMessageError([adminById, adminUpdate]);

  const handleFieldsChange = () => {
    if (!initialValues) return;

    handleUnsavedChanges(!isObjEqual(
      filterObjectProps(initialValues, ['id', 'createdAt', 'role', 'updatedAt']),
      filterObjectProps(form.getFieldsValue(), ['id', 'createdAt', 'role', 'updatedAt']),
    ));
  };

  const handleDeleteClick = () => {
    open({
      title: 'Удалить админстратора?',
      content: 'Вы уверены, что хотите навсегда удалить этого администратора?',
      cancelText: 'Отмена',
      centered: true,
      okText: 'Удалить',
      okButtonProps: {
        type: 'primary',
        danger: true,
      },
      onOk: () => adminDelete.fetch(initialValues?.id).then(() => {
        message.success('Администратор успешно удален');
        navigate('/admins');
      }),
      maskClosable: true,
    });
  };

  const handleResendClick = () => {
    if (!initialValues) return;

    adminReInvite.fetch(undefined, initialValues.id).then(() => {
      message.success('Повторное приглашение отправлено');
    });
  };

  const handleModal = (payload: boolean) => {
    setIsModalOpen(payload);
  };

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  return (
    <SinglePageContainer
      breadcrumbItems={breadcrumbs}
      title="Добавить администратора"
      cardLoading={adminById.loading}
      extra={(
        <>
          {initialValues && initialValues?.status === 'pending' ? (
            <Button
              type="default"
              icon={<RedoOutlined />}
              onClick={handleResendClick}
              loading={adminReInvite.loading}
            >
              Отправить приглашение
            </Button>
          ) : null}
          {initialValues && initialValues?.email !== user?.email ? (
            <Button type="primary" danger onClick={handleDeleteClick} disabled={adminReInvite.loading}>
              Удалить
            </Button>
          ) : null}
          <Button
            type="primary"
            disabled={!unsavedChanges || adminReInvite.loading}
            onClick={form.submit}
            loading={adminUpdate.loading}
          >
            Сохранить
          </Button>
        </>
      )}
    >
      {contextHolder}
      <Form<Admin>
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        name="admin-create"
        initialValues={initialValues}
        onFieldsChange={handleFieldsChange}
        style={{ width: '465px' }}
      >
        <Form.Item name="name" label="Имя" rules={[{ required: true, ...validationRules }]}>
          <Input placeholder="Введите" />
        </Form.Item>
        <Form.Item name="email" label="Электронная почта" rules={[{ required: true, ...validationRules }]}>
          <Input placeholder="Введите" disabled />
        </Form.Item>
        <Form.Item name="status" label="Статус" rules={[{ required: true, ...validationRules }]}>
          <SelectStatus disabled />
        </Form.Item>
      </Form>
      {initialValues && initialValues?.email === user?.email ? (
        <Button type="default" onClick={() => handleModal(true)} disabled={adminUpdate.loading}>
          Изменить пароль
        </Button>
      ) : null}
      {isModalOpen ? <ChangePasswordModal isOpenModal={isModalOpen} handleModal={handleModal} /> : null}
    </SinglePageContainer>
  );
}

export default EditAdmin;
