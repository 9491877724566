import React, { useCallback, useEffect, useState } from 'react';
import {
  Button, DatePicker, Modal, Space,
} from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import isoweek from 'dayjs/plugin/isoWeek';
import clsx from 'clsx';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { CellRender } from 'rc-picker/lib/interface';
import { WeekDaysEnum } from '../../../../../enums/program';
import { Week } from '../../../../../hooks/api/program';
import { useUnsavedChanges } from '../../../../../context/unsavedChanges';
import { createNewTrainingWeek, getCurrentWeek } from '../index';
import { useSidebarProvider } from '../../../../../context/sidebar';

import styles from './index.module.scss';

dayjs.extend(isoweek);

interface ProgramDatePickerProps {
  onDayClick: (day: string) => void
  weeks: Week[]
  selectedWeek: Week | undefined
  selectedDay: string,
  handleOnWeekSelect: (date: Dayjs, week?: Week) => void
  handleResetSelectedWeek: () => void
  getInitialData: (date?: Dayjs) => void
}

const currentDate = dayjs();

function ProgramDatePicker({
  onDayClick,
  weeks,
  selectedWeek,
  handleOnWeekSelect,
  selectedDay,
  handleResetSelectedWeek,
  getInitialData,
}: ProgramDatePickerProps) {
  const { collapsed } = useSidebarProvider();
  const { unsavedChanges, handleUnsavedChanges } = useUnsavedChanges();

  const [isOpen, setIsOpen] = useState<boolean>(true);

  const [mode, setMode] = useState<string>('week');
  const [open, setOpen] = useState<boolean>(false);

  const cellRender: CellRender<Dayjs, Dayjs | number | string> = useCallback((current: number | string | Dayjs) => {
    const date = dayjs(current);

    const isCurrentDay = date.date() === currentDate.date()
      && date.year() === currentDate.year() && date.isoWeek() === currentDate.isoWeek();

    let matchedWeek: Week | undefined;

    weeks?.forEach((week) => {
      if (week.isoWeek === date.isoWeek() && week.year === date.year()) {
        matchedWeek = week;
      }
    });

    const dayData = matchedWeek?.trainingDays.find((training) => (
      date.isSame(dayjs(training.date), 'day')));

    return (
      <div className={clsx(
        'ant-picker-cell-inner',
        dayData?.trainings.length ? 'active' : '',
        isCurrentDay ? styles.currentDay : '',
      )}
      >
        {date.format('DD')}
      </div>
    );
  }, [weeks]);

  useEffect(() => {
    setIsOpen(false);
    setTimeout(() => setIsOpen(true), 50);
  }, [collapsed]);

  return (
    <div className={styles.container}>
      {
      open ? (
        <Modal
          open
          title={(
            <Space size={12}>
              <ExclamationCircleOutlined style={{ color: '#FAAD14', fontSize: 20 }} />
              Покинуть редактирование недели без сохранения?
            </Space>
        )}
          cancelText="Отмена"
          centered
          okText="Покинуть"
          width={400}
          okButtonProps={{ type: 'primary', danger: true }}
          onOk={() => {
            handleUnsavedChanges(false);
            setOpen(false);
            handleResetSelectedWeek();
          }}
          onCancel={() => setOpen(false)}
        >
          <div style={{ marginLeft: '31px' }}>Все изменения будут потеряны.</div>
        </Modal>
      ) : null
      }
      <DatePicker
        open={isOpen}
        picker="week"
        className={styles.picker}
        popupClassName={clsx('weekPickerDropdown', unsavedChanges ? 'unsavedChanges' : '')}
        cellRender={mode === 'week' ? cellRender : undefined}
        onPanelChange={(day, panel) => {
          setMode(panel);
          getInitialData(day);
        }}
        onOk={(day) => {
          if (day.isoWeek() !== dayjs(selectedDay).isoWeek()) {
            getInitialData(day);
          }
        }}
        defaultValue={currentDate}
        onMouseDown={() => setOpen(unsavedChanges)}
        onChange={(date) => {
          if (!date) return;

          let selected = getCurrentWeek(weeks, date);

          if (!selected) {
            selected = createNewTrainingWeek(date);
          }

          handleOnWeekSelect(date, selected);
        }}
      />
      <div className={styles.weekDays}>
        <div>День недели</div>
        <div className={styles.days}>
          {selectedWeek?.trainingDays?.map((day, index) => (
            <Button
              key={day.date}
              className={clsx(styles.day, day.date === selectedDay ? styles.selectedDay : '')}
              onClick={() => onDayClick(day.date)}
            >
              {Object.values(WeekDaysEnum)[index]}
            </Button>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ProgramDatePicker;
