import React from 'react';
import { Typography } from 'antd';
import ProgramContent from './Content';

export default function Program(): React.ReactNode {
  return (
    <div>
      <Typography.Title level={4}>
        Программа
      </Typography.Title>
      <ProgramContent />
    </div>
  );
}
