import React, { useEffect, useState } from 'react';
import { App, Button, Form } from 'antd';
import { NavLink, useParams } from 'react-router-dom';
import SinglePageContainer from '../../../Common/SinglePageContainer';
import { useMessageError } from '../../../../hooks/common';
import { useUnsavedChanges } from '../../../../context/unsavedChanges';
import {
  MarathonMenu, MarathonMenuWeek, useMarathonPutData,
} from '../../../../hooks/api/marathons';
import { filterObjectProps, isObjEqual } from '../../../../utils';
import MarathonFormItemsMenu from './FormItems';
import { MenuTypeEnum } from '../../../../enums/recipes';
import { useGlobalLoadingProvider } from '../../../../context/globalLoading';
import CopySelect from './CopySelect';
import { Recipe } from '../../../../hooks/api/recipes';
import { useMarathonMenuProvider } from '../../../../context/marathonMenu';

const prepareRecipePayload = (recipes: Recipe[]): { id: string; portionNumber?: number }[] => (
  recipes.map((recipe) => ({
    id: recipe.id,
    // If more than 2 recipe portions then add to body portions & portionNumber field.
    ...(recipe.portions >= 2 && {
      portions: recipe.portions,
      portionNumber: recipe.portionNumber || 3,
    }),
  }))
);

const keysToFilterInitial: (keyof Partial<MarathonMenu>)[] = ['id', 'calories', 'menuType'];

const getMenuName = (menuData: MarathonMenu | undefined) => (menuData?.id ? (
  `${MenuTypeEnum[menuData.menuType]} (${menuData.calories} ккал)`
) : '');

function MarathonMenus() {
  const { marathonId = '', id = '' } = useParams<{ marathonId: string; id: string }>();
  // const marathonMenuById = useMarathonId<MarathonMenu>(`${marathonId}/menus/${id}`);
  const { marathonMenuById, initialValues, setInitialValues } = useMarathonMenuProvider();
  const marathonPutData = useMarathonPutData<MarathonMenu>(`${marathonId}/menus/${id}/weeks`);

  const { message } = App.useApp();
  const [form] = Form.useForm();
  const { unsavedChanges, handleUnsavedChanges } = useUnsavedChanges();
  const { globalLoading } = useGlobalLoadingProvider();

  const fetch = () => {
    marathonMenuById.fetch();
  };

  useEffect(() => {
    if (marathonId && id) fetch();
  }, [marathonId, id]);

  /** Handle Initial values */
  useEffect(() => {
    if (marathonMenuById.data) {
      setInitialValues(filterObjectProps(marathonMenuById.data, keysToFilterInitial));
    }
  }, [marathonMenuById.data]);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(initialValues);
    handleUnsavedChanges(false);
  }, [initialValues]);

  /** Handle submit */
  const handleSubmit = () => {
    form.validateFields().then((values) => {
      const modifiedVal = {
        ...values,
        weeks: (values.weeks || []).map((week: MarathonMenuWeek) => ({
          ...week,
          pdfUrlRU: week?.pdfUrlRU || '',
          pdfUrlUA: week?.pdfUrlUA || '',
          menuDays: week.menuDays.map((menuDay) => ({
            id: menuDay.id,
            breakfast: prepareRecipePayload(menuDay.breakfast),
            lunch: prepareRecipePayload(menuDay.lunch),
            dinner: prepareRecipePayload(menuDay.dinner),
            snack: prepareRecipePayload(menuDay.snack),
          })),
        })),
      };

      marathonPutData.fetch(modifiedVal)
        .then((res) => {
          if (res?.id) {
            message.success('Изменения успешно сохранены.');
            setInitialValues(filterObjectProps(res, keysToFilterInitial));
          }
        });
    }).catch(() => undefined);
  };

  useMessageError([marathonMenuById, marathonPutData]);

  const handleFieldsChange = () => {
    handleUnsavedChanges(!isObjEqual(
      initialValues,
      form.getFieldsValue(),
    ));
  };
  const weeksWatch = Form.useWatch('weeks', form);

  useEffect(() => { handleFieldsChange(); }, [weeksWatch]);

  return (
    <SinglePageContainer
      breadcrumbItems={[
        { title: <NavLink to="/marathons">Марафоны</NavLink> },
        { title: <NavLink to={`/marathons/${marathonId}`}>Редактировать марафон</NavLink> },
        { title: `Редактировать меню ${getMenuName(marathonMenuById.data)}` },
      ]}
      title={`Редактировать меню ${getMenuName(marathonMenuById.data)}`}
      cardLoading={marathonMenuById.loading}
      extra={(
        <>
          <CopySelect
            currentMenuType={marathonMenuById.data?.menuType}
            currentCalories={marathonMenuById.data?.calories}
            onCopy={(res) => setInitialValues(filterObjectProps(res, keysToFilterInitial))}
          />
          <Button
            type="primary"
            onClick={handleSubmit}
            loading={marathonPutData.loading}
            disabled={!unsavedChanges || globalLoading}
          >
            Сохранить
          </Button>
        </>
      )}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={initialValues}
        onFinish={handleSubmit}
        name="marathon-update-menu"
        onFieldsChange={handleFieldsChange}
      >
        <MarathonFormItemsMenu
          defaultSearchParams={{ menuType: marathonMenuById.data?.menuType }}
        />
      </Form>
    </SinglePageContainer>
  );
}

export default MarathonMenus;
