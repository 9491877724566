import React, { useMemo } from 'react';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import PdfUpload, { generatePdfObjFromUrl } from '../../../../../Common/Uploaders/PdfUpload';

import styles from './index.module.scss';

interface PdfsUpload {
  weekProps: { id: string; name: number, index: number };
  pdfUrlRU: string | undefined;
  pdfUrlUA: string | undefined;
  hidden?: boolean;
}

function PdfsUpload({ weekProps, pdfUrlRU, pdfUrlUA }: PdfsUpload) {
  const form = useFormInstance();
  const initialFileRU = useMemo(() => (pdfUrlRU ? [generatePdfObjFromUrl(pdfUrlRU)] : []), [pdfUrlRU]);
  const initialFileUA = useMemo(() => (pdfUrlUA ? [generatePdfObjFromUrl(pdfUrlUA)] : []), [pdfUrlUA]);

  return (
    <div className={styles.filesUpload}>
      <PdfUpload
        label="Меню на неделю в формате PDF (RU)"
        name={[weekProps.name, 'pdfUrlRU']}
        required={false}
        weekId={weekProps.id}
        initialFileList={initialFileRU}
        onUrl={(url) => {
          form.setFieldValue(['weeks', weekProps.index, 'pdfUrlRU'], url);
          form.validateFields([['weeks', weekProps.index, 'pdfUrlRU']]);
        }}
        onDelete={() => form.setFieldValue(['weeks', weekProps.index, 'pdfUrlRU'], '')}
        disableIfName={pdfUrlUA}
      />
      <PdfUpload
        label="Меню на неделю в формате PDF (UA)"
        name={[weekProps.name, 'pdfUrlUA']}
        required={false}
        weekId={weekProps.id}
        initialFileList={initialFileUA}
        onUrl={(url) => {
          form.setFieldValue(['weeks', weekProps.index, 'pdfUrlUA'], url);
          form.validateFields([['weeks', weekProps.index, 'pdfUrlUA']]);
        }}
        onDelete={() => form.setFieldValue(['weeks', weekProps.index, 'pdfUrlUA'], '')}
        disableIfName={pdfUrlRU}
      />
    </div>
  );
}

PdfsUpload.defaultProps = {
  hidden: false,
};

export default PdfsUpload;
