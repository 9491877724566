import React from 'react';
import { SelectProps } from 'antd/lib/select';
import { Select } from 'antd';
import { UserSubscription } from '../../../../hooks/api/user';
import { Option } from '../../../../types';

const caloriesData = [1300, 1500, 1700, 2000];

function SelectCalories({ ...props }: SelectProps<UserSubscription, Option>) {
  return (
    <Select {...props} />
  );
}

SelectCalories.defaultProps = {
  options: caloriesData.map((el) => ({ label: el, value: el })),
  allowClear: true,
  placeholder: 'Выберите',
};

export default SelectCalories;
