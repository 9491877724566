import React from 'react';
import { EditOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import Table from '../../../../Common/Table';
import { TrainingUsedIn } from '../../../../../hooks/api/trainings';
import LinkButton from '../../../../Common/LinkButton';

interface UsedInTableData {
  key: string;
  id: string;
  type: 'programs' | 'complexes' | 'marathons';
  label: string;
}

function generateUsedInTableData(response: TrainingUsedIn): UsedInTableData[] {
  const data: UsedInTableData[] = [];

  if (response.inProgram) {
    data.push({
      key: 'inProgram', id: '', type: 'programs', label: 'В програме',
    });
  }

  response.complexes.forEach((complex) => {
    data.push({
      key: `complex_${complex.id}`, id: complex.id, type: 'complexes', label: `Комплекс: ${complex.nameRU}`,
    });
  });

  response.marathons.forEach((marathon) => {
    data.push({
      key: `marathon_${marathon.id}`,
      id: marathon.id,
      type: 'marathons',
      label: `Марафон: ${dayjs(marathon.startDate).format('DD MMMM YYYY')}`,
    });
  });

  return data;
}

interface TrainingUsedInTable {
  usedIn: TrainingUsedIn;
}

function TrainingUsedInTable({ usedIn }: TrainingUsedInTable) {
  return (
    <Table
      columns={[
        {
          title: 'Тренировка используется в:',
          dataIndex: 'label',
        },
        {
          title: 'Действия',
          render: (_, row) => (
            row.id ? (
              <LinkButton to={`/${row.type}/${row.id}`}><EditOutlined style={{ fontSize: 18 }} /></LinkButton>
            ) : ''
          ),
          width: 64,
        },
      ]}
      dataSource={generateUsedInTableData(usedIn)}
      search={false}
    />
  );
}

export default TrainingUsedInTable;
