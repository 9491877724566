import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { MarathonMedia } from '../index';
import UserMarathonMediaModal from '../Modal';
import UserMarathonThumbnail from './ThumbnailImage';
import UserVideoThumbnail from './ThumbnailVideo';
import { Participant } from '../../../../../../../hooks/api/user';

import styles from './index.module.scss';

interface UserMarathonThumbnails {
  data: MarathonMedia[];
  videoUrls: string[];
  selectedMarathon?: Participant;
}

function UserMarathonThumbnails({ data, videoUrls, selectedMarathon }: UserMarathonThumbnails) {
  const [selectedIndex, setSelectedIndex] = useState<number>();
  const [isVideo, setIsVideo] = useState<boolean>(false);

  const handleSelected = (index?: number) => {
    setIsVideo(false);
    setSelectedIndex(index);
  };

  return (
    <div className={styles.wrap}>
      <UserMarathonMediaModal
        data={data}
        selectedIndex={selectedIndex}
        handleSelected={handleSelected}
        videoUrls={videoUrls}
        isVideo={isVideo}
      />
      {[data].map((el) => el.map(({ before, after, dataKey }, index) => (
        <div
          // to="#"
          role="button"
          tabIndex={-1}
          aria-label="media-button"
          style={{ cursor: 'pointer' }}
          key={`${index + 1}`}
          className={styles.media}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleSelected(index);
          }}
        >
          <UserMarathonThumbnail
            media={before}
            editProps={dataKey ? {
              withEdit: !!dataKey,
              dataKey,
              measure: 'before',
            } : undefined}
            selectedMarathon={selectedMarathon}
          />
          <UserMarathonThumbnail
            media={after}
            editProps={dataKey ? {
              withEdit: !!dataKey,
              dataKey,
              measure: 'after',
            } : undefined}
            selectedMarathon={selectedMarathon}
          />
        </div>
      )))}

      {videoUrls.map((url, index) => (
        <Link
          to="#"
          key={`${index + 1}`}
          className={styles.videoThumb}
          onClick={(e) => {
            e.preventDefault();
            if (url.length) {
              handleSelected(index);
              setIsVideo(true);
            }
          }}
        >
          <UserVideoThumbnail
            media={url}
            editProps={selectedMarathon ? {
              withEdit: !!selectedMarathon,
              dataKey: 'videoUrl',
              measure: index === 0 ? 'before' : 'after',
            } : undefined}
            selectedMarathon={selectedMarathon}
          />
        </Link>
      ))}
    </div>
  );
}

export default UserMarathonThumbnails;
