import React, { useRef } from 'react';
import { Modal } from 'antd';
import clsx from 'clsx';
import { MarathonMedia } from '../index';
import UserMarathonModalActions from './Actions';
import { getFullFileUrl } from '../../../../../../Common/Uploaders/ImageUpload';
import UserMarathonThumbnail from '../Thumbnails/ThumbnailImage';

import styles from './index.module.scss';

export interface UserMediaModalProps {
  selectedIndex: number | undefined
  data?: MarathonMedia[]
  handleSelected: (index?: number) => void
  videoUrls: string[]
  isVideo?: boolean
}

function UserMarathonMediaModal({
  data, selectedIndex, handleSelected, videoUrls, isVideo,
}: UserMediaModalProps) {
  const videoRef = useRef<HTMLVideoElement | null>(null);

  return (
    <>
      <Modal
        width="67%"
        className={clsx(
          styles.modal,
          {
            [styles.empty]: data && selectedIndex !== undefined
            && !data[selectedIndex].before && !data[selectedIndex].after,
          },
        )}
        open={selectedIndex !== undefined}
        onCancel={() => handleSelected()}
        destroyOnClose
        centered
        closeIcon={false}
        footer={null}
        wrapClassName="modal-content-centered"
      >
        <div className={styles.modalContent}>
          {!isVideo && data && selectedIndex !== undefined ? (
            <>
              <div className={styles.imagesWrap}>
                <UserMarathonThumbnail media={data[selectedIndex].before} />
                <UserMarathonThumbnail media={data[selectedIndex].after} />
              </div>
              <div className={styles.count}>{`${selectedIndex + 1}/${data.length}`}</div>
            </>
          ) : null}

          {isVideo && selectedIndex !== undefined && videoUrls[selectedIndex].length ? (
            <video
              ref={videoRef}
              controls
              onClick={(e) => e.stopPropagation()}
            >
              <source src={getFullFileUrl(videoUrls[selectedIndex])} type="video/mp4" />
              <track kind="captions" />
            </video>
          ) : null}
        </div>
      </Modal>
      <UserMarathonModalActions
        selectedIndex={selectedIndex}
        itemsLength={!isVideo ? (data?.length || 1) - 1 : 0}
        handleSelected={handleSelected}
      />
    </>
  );
}

UserMarathonMediaModal.defaultProps = {
  isVideo: false,
  data: undefined,
};

export default UserMarathonMediaModal;
