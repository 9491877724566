import {
  DefaultFetchError,
  FetchGet,
  FetchGetId,
  PagingDataResponse,
  PagingParams,
  useFetchGet, useFetchGetId, useFetchDelete, FetchCreate, useFetchCreate, FetchUpdate, useFetchUpdate, useFetchPut,
} from '../fetch';
import {
  DefaultOrderByColumnProps, KindType,
  LevelType, MeasureType, MenuType, ParticipantSubscriptionType, PlaceType,
} from '../../types';
import { RuLangType, UaLangType } from '../../enums/language';
import { Lecture } from './lectures';
import { Training } from './trainings';
import { Recipe } from './recipes';

export type MarathonStatusType = 'new' | 'upcoming' | 'sales' | 'active' | 'finished' | 'closed';

export interface Marathon {
  id: string;
  nameUA: string;
  nameRU: string;
  contestRulesPhotoUA?: string;
  contestRulesPhotoRU?: string;
  contestRulesVideoUA?: string;
  contestRulesVideoRU?: string;
  photoRulesVideoUrl?: string;
  videoRulesVideoUrl?: string;
  diplomaImageUrlUA?: string;
  diplomaImageUrlRU?: string;
  startDate: string;
  status: MarathonStatusType;
  durationInWeeks: number;
  goldNumber: number;
  silverNumber: number;
  createdAt: string;
  updatedAt: string;
  // By id props:
  trainings?: MarathonTrainingMin[];
  lectures?: MarathonLectureMin[];
  menus?: MarathonMenuMin[];
  shoppings?: MarathonMenuMin[];
}

export interface MarathonTrainingMin { id: string; place: PlaceType; level: LevelType }
export interface MarathonLectureMin { createdAt: string; updatedAt: string; id: string; lectures: Lecture[] }
export interface MarathonMenuMin { id: string; menuType: MenuType; calories: number }
// export interface MarathonShopping { id: string; menuType: MenuType; calories: number }

export interface MarathonsGetParams extends PagingParams {
  search?: string;
  lang?: UaLangType | RuLangType;
  startDate?: string;
  orderByColumn?: DefaultOrderByColumnProps | 'nameRU' | 'startDate' | 'status';
}

export function useMarathonsGet<D = PagingDataResponse<Marathon>,
  DD = D>(decorateData?: (data: D) => DD): FetchGet<D, MarathonsGetParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, MarathonsGetParams, DD>(
    'marathons',
    {
      decorateData,
      autoStart: false,
      startStateLoading: false,
    },
  );
}

/** Use same request for {marathonId}/trainings/{id}, lectures, habits and other too */
export const useMarathonId = <T = Marathon>(id?: string): FetchGetId<T> => useFetchGetId(
  'marathons',
  id,
  { autoStart: false, startStateLoading: false },
);

export const useMarathonDelete = () => useFetchDelete<undefined, DefaultFetchError, string>('marathons');

export interface CreateMarathonPayload {
  nameUA: string;
  nameRU: string;
  startDate: string; // Dayjs
  durationInWeeks: number;
  diplomaImageUrlUA?: string;
  diplomaImageUrlRU?: string;
}

export const useMarathonCreate = (): FetchCreate<Marathon, DefaultFetchError, CreateMarathonPayload> => (
  useFetchCreate('marathons')
);

export const useMarathonUpdate = (id?: string): FetchUpdate<
  Marathon, DefaultFetchError, CreateMarathonPayload
> => (
  useFetchUpdate('marathons', id)
);

/** Use to put data into nested marathon entities  */
export const useMarathonPutData = <Res = MarathonTraining, Payload = CreateMarathonTraining>(id?: string): FetchUpdate<
  Res, DefaultFetchError, Payload
> => (
    useFetchPut('marathons', id)
    // List of all available requests:
    // - /{marathonId}/trainings/{id}/weeks
    // - /{marathonId}/lectures-weeks
    // - /{marathonId}/menus/{id}/weeks
    // - /{marathonId}/shopping/{id}/weeks
  );

/** Marathon Habits */
interface MarathonHabit {
  id: string;
  nameRU: string;
  nameUA: string;
  kind: KindType;
  createdAt: string;
  updatedAt: string;
}

interface CreateHabitPayload {
  nameRU: string;
  nameUA: string;
  kind: KindType;
}

export const useMarathonHabitCreate = (): FetchCreate<MarathonHabit, DefaultFetchError, CreateHabitPayload> => (
  useFetchCreate('marathons') // {marathonId}/habits
);

/** Gift certificates */
export interface GiftCertificatePayload {
  subscription: ParticipantSubscriptionType;
  email: string;
}

export const useCertificateGift = (): FetchCreate<undefined, DefaultFetchError, GiftCertificatePayload> => (
  useFetchCreate('certificates/gift')
);

/** Marathon Trainings */
export interface MarathonTraining extends MarathonTrainingMin {
  id: string;
  level: LevelType;
  place: PlaceType;
  weeks: MarathonTrainingWeek[];
}

export interface MarathonTrainingWeek {
  id: string;
  trainingDays: [MarathonTrainingDay, MarathonTrainingDay, MarathonTrainingDay, MarathonTrainingDay,
    MarathonTrainingDay, MarathonTrainingDay, MarathonTrainingDay]; // Always 7 items
}

export interface MarathonTrainingDay {
  id: string;
  morningTrainings: Training[];
  mainTrainings: Training[];
  eveningTrainings: Training[];
}

export type MarathonTrainingTime = 'morningTrainings' | 'mainTrainings' | 'eveningTrainings';

export interface CreateMarathonTraining {
  weeks: MarathonTrainingWeek[];
}

/** Marathon Menu */
export interface MarathonMenu extends MarathonMenuMin {
  id: string;
  weeks: MarathonMenuWeek[];
}

export interface MarathonMenuWeek {
  id: string;
  pdfUrlRU?: string;
  pdfUrlUA?: string;
  menuDays: [MarathonMenuDay, MarathonMenuDay, MarathonMenuDay, MarathonMenuDay,
    MarathonMenuDay, MarathonMenuDay, MarathonMenuDay]; // Always 7 items
}

export interface MarathonMenuDay {
  id: string;
  breakfast: Recipe[];
  lunch: Recipe[];
  dinner: Recipe[];
  snack: Recipe[];
}

export type MarathonMenuTime = 'breakfast' | 'lunch' | 'dinner' | 'snack';

export interface CreateMarathonMenu {
  weeks: MarathonMenuWeek[];
}

/** Marathon Shopping */
export interface MarathonShopping extends MarathonMenuMin {
  id: string;
  weeks: MarathonShoppingWeek[];
}

export interface MarathonShoppingWeek {
  id: string;
  pdfUrlRU?: string;
  pdfUrlUA?: string;
  categories: MarathonShoppingCategory[];
}

export interface MarathonShoppingCategory {
  id: string;
  nameUA: string;
  nameRU: string;
  items: MarathonIngredient[];
}

export interface MarathonIngredient {
  id?: string;
  nameRU: string;
  nameUA: string;
  measure: MeasureType;
  value: number
}

export interface CreateMarathonShopping {
  weeks: MarathonShoppingWeek[];
}

export const useMarathonEntityCopy = <Res = MarathonShopping>(): FetchCreate<Res, DefaultFetchError, undefined> => (
  useFetchCreate('marathons')
);

/** Marathon Telegram */
export interface TelegramsLink {
  id: string;
  name: string;
  order: number;
  isGold: boolean;
  link: string;
}

export interface MarathonTelegrams {
  links: TelegramsLink[];
}

export const useMarathonTelegramsUpdate = (id?: string): FetchUpdate<MarathonTelegrams,
  DefaultFetchError, MarathonTelegrams> => (
  useFetchUpdate('marathons', id)
);

/** Marathon Lectures */
export interface LectureWeek {
  id: string;
  lectures: Lecture[];
  createdAt: string;
  updatedAt: string;
}

export interface MarathonLecturesWeeks {
  weeks: LectureWeek[];
}
