import React, { useEffect, useState } from 'react';
import { App, Button, Form } from 'antd';
import { NavLink, useParams } from 'react-router-dom';
import SinglePageContainer from '../../../../Common/SinglePageContainer';
import { useMessageError } from '../../../../../hooks/common';
import { useUnsavedChanges } from '../../../../../context/unsavedChanges';
import {
  Habits, MarathonHabits, useMarathonHabits, useMarathonHabitsUpdate,
} from '../../../../../hooks/api/habits';
import HabitsFormFields from './Form';
import { isObjEqual } from '../../../../../utils';

export const initialHabitsState: Partial<Habits> = {
  nameUA: '',
  nameRU: '',
};

function MarathonDiplomaContent() {
  const { marathonId = '' } = useParams<{ marathonId: string; }>();
  const { unsavedChanges, handleUnsavedChanges } = useUnsavedChanges();

  const { message } = App.useApp();
  const [form] = Form.useForm();

  const habitsById = useMarathonHabits();
  const habitsUpdate = useMarathonHabitsUpdate();

  const [initialValues, setInitialValues] = useState<MarathonHabits>();

  const fetch = () => {
    habitsById.fetch(undefined, `${marathonId}/habits`);
  };

  useEffect(() => {
    if (marathonId) fetch();
  }, [marathonId]);

  useEffect(() => {
    if (habitsById.data) {
      setInitialValues(habitsById.data);
    }
  }, [habitsById.data]);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(initialValues);
    handleUnsavedChanges(false);
  }, [initialValues]);

  const handleSubmit = () => {
    if (!initialValues) return;

    form.validateFields().then((values) => {
      habitsUpdate.fetch(values, `${marathonId}/habits`)
        .then((res) => {
          if (res !== null) {
            message.success('Изменения успешно сохранены.');
            handleUnsavedChanges(false);
            setInitialValues(res);
          }
        });
    });
  };

  useMessageError([habitsById, habitsUpdate]);

  const handleFieldsChange = () => {
    if (!initialValues) return;
    handleUnsavedChanges(!isObjEqual(initialValues, form.getFieldsValue()));
  };

  return (
    <SinglePageContainer
      breadcrumbItems={[
        { title: <NavLink to="/marathons">Марафоны</NavLink> },
        { title: <NavLink to={`/marathons/${marathonId}`}>Редактировать марафон</NavLink> },
        { title: 'Редактировать список привычек' },
      ]}
      title="Редактировать список привычек"
      cardLoading={habitsById.loading || !initialValues}
      extra={(
        <Button
          type="primary"
          onClick={form.submit}
          loading={habitsUpdate.loading}
          disabled={!unsavedChanges || !initialValues}
        >
          Сохранить
        </Button>
      )}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        initialValues={initialValues}
        name="habits"
        onFieldsChange={handleFieldsChange}
      >
        <HabitsFormFields />
      </Form>
    </SinglePageContainer>
  );
}

export default MarathonDiplomaContent;
