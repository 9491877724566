import React from 'react';
import { Provider } from 'react-redux';
import localeData from 'dayjs/plugin/localeData';
import dayjs from 'dayjs';
import './index.scss';

import { config } from '@triare/auth-redux';

import store from './store';
import Routes from './routes';
import AntdConfigProvider from './AntdConfigProvider';
import SidebarProvider from './context/sidebar';
import GlobalLoadingProvider from './context/globalLoading';

require('dayjs/locale/ru');

const { displayName } = config;

dayjs.extend(localeData);
dayjs.locale('ru');

export default function App(): React.ReactNode {
  document.title = `${displayName}`;

  return (
    <Provider store={store}>
      <AntdConfigProvider>
        <SidebarProvider>
          <GlobalLoadingProvider>
            <Routes />
          </GlobalLoadingProvider>
        </SidebarProvider>
      </AntdConfigProvider>
    </Provider>
  );
}
