import React, { useEffect, useState } from 'react';
import {
  App, Button, Form, Typography, UploadFile,
} from 'antd';
import { NavLink, useParams } from 'react-router-dom';
import SinglePageContainer from '../../../../Common/SinglePageContainer';
import { useMessageError } from '../../../../../hooks/common';
import { useUnsavedChanges } from '../../../../../context/unsavedChanges';
import {
  Marathon,
  useMarathonId,
  useMarathonUpdate,
} from '../../../../../hooks/api/marathons';
import ImageUpload, { generateFileObjFromUrl } from '../../../../Common/Uploaders/ImageUpload';
import { arrayEquals } from '../../../../../utils';

import styles from './index.module.scss';

function MarathonDiplomaContent() {
  const { marathonId = '' } = useParams<{ marathonId: string; }>();
  const { unsavedChanges, handleUnsavedChanges } = useUnsavedChanges();
  const { message } = App.useApp();

  const marathonById = useMarathonId();
  const marathonUpdate = useMarathonUpdate();

  const [fileRU, setFileRU] = useState<UploadFile[]>([]);
  const [fileUA, setFileUA] = useState<UploadFile[]>([]);
  const [marathon, setMarathon] = useState<Marathon>();

  const fetch = () => {
    marathonById.fetch(undefined, marathonId);
  };

  useEffect(() => {
    if (marathonId) fetch();
  }, [marathonId]);

  useEffect(() => {
    if (marathonById.data) {
      setMarathon(marathonById.data);
      if (marathonById.data.diplomaImageUrlUA) {
        setFileUA([generateFileObjFromUrl(marathonById.data.diplomaImageUrlUA)]);
      }
      if (marathonById.data.diplomaImageUrlRU) {
        setFileRU([generateFileObjFromUrl(marathonById.data.diplomaImageUrlRU)]);
      }
    }
  }, [marathonById.data]);

  const handleSubmit = () => {
    if (!marathon) return;

    const newMarathon = {
      nameUA: marathon.nameUA,
      nameRU: marathon.nameRU,
      startDate: marathon.startDate,
      durationInWeeks: marathon.durationInWeeks,
      diplomaImageUrlUA: fileUA.length ? fileUA[0].url : '',
      diplomaImageUrlRU: fileRU.length ? fileRU[0].url : '',
    };

    marathonUpdate.fetch(newMarathon, marathonId)
      .then((res) => {
        if (res?.id) {
          message.success('Изменения успешно сохранены.');
          setMarathon(res);
        }
      });

    handleUnsavedChanges(false);
  };

  useMessageError([marathonById, marathonUpdate]);

  useEffect(() => {
    if (!marathon) return;

    handleUnsavedChanges(!arrayEquals(
      [marathon.diplomaImageUrlUA || null, marathon.diplomaImageUrlRU] || null,
      [fileUA.length ? fileUA[0].url : null, fileRU.length ? fileRU[0].url : null],
    ));
  }, [marathon, fileUA, fileRU]);

  return (
    <SinglePageContainer
      breadcrumbItems={[
        { title: <NavLink to="/marathons">Марафоны</NavLink> },
        { title: <NavLink to={`/marathons/${marathonId}`}>Редактировать марафон</NavLink> },
        { title: 'Редактировать диплом' },
      ]}
      title="Редактировать диплом"
      cardLoading={marathonById.loading}
      extra={(
        <Button
          type="primary"
          onClick={handleSubmit}
          loading={marathonUpdate.loading}
          disabled={!unsavedChanges || !marathon}
        >
          Сохранить
        </Button>
      )}
    >
      <Form>
        <div className={styles.wrap}>
          <div>
            <Typography style={{ marginBottom: 15 }}>
              Диплом (RU). Требования к файлу: размер 1080 х 1512 px.
            </Typography>
            <ImageUpload
              multiple={false}
              setFileList={setFileRU}
              name="diplomaRu"
              fileList={fileRU}
              allowToPreview={false}
              // beforeCrop which returns 'false' - skips crop step.
              cropperProps={{ beforeCrop: () => false }} // aspect: 2480 / 3508
              label="Загрузить диплом (RU)"
              H685
            />
          </div>
          <div>
            <Typography style={{ marginBottom: 15 }}>
              Диплом (UA). Требования к файлу: размер 1080 х 1512 px.
            </Typography>
            <ImageUpload
              multiple={false}
              setFileList={setFileUA}
              name="diplomaUA"
              fileList={fileUA}
              allowToPreview={false}
              cropperProps={{ beforeCrop: () => false }} // aspect: 2480 / 3508
              label="Загрузить диплом (UA)"
              H685
            />
          </div>
        </div>
      </Form>
    </SinglePageContainer>
  );
}

export default MarathonDiplomaContent;
